import { createAsyncThunk } from '@reduxjs/toolkit';
import { CategoriesDataType } from '@interfaces';
import { categoryApi } from 'src/api/categoryApi';

interface ParamsType {
  [key: string]: any;
}

const url = 'Category';
export const fetchCategories = createAsyncThunk<CategoriesDataType[]>(
  `${url}`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await categoryApi.getCategories();
      return res.data;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const getListBookByCategoryId = createAsyncThunk<any, string>(
  `${url}/getListBookByCategoryId`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await categoryApi.getCategoryByCategoryId(params);
      return res.data;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
