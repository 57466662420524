import React, { Ref, useEffect, useRef } from 'react';
import { Input, InputRef } from 'antd';

import { Search } from '../Icon';
import { OptionProductsType } from '@interfaces';
import { CustomAutoComplete, IconWrapper } from './style';

interface SearchInputProps {
  onSelect: (data: any) => void;
  onSearch: (value: string) => void;
  options: OptionProductsType[];
  placeholder?: string;
  onPressEnter?: () => void;
  allowClear?: boolean;
  onChange?: (value: any) => void;
  onClickIcon?: () => void;
  value?: string;
  maxlength?: number;
  type?: string;
}

export const SearchInput: React.FC<SearchInputProps> = React.memo(
  ({
    onSearch,
    options,
    onSelect,
    placeholder,
    onPressEnter,
    allowClear,
    onChange,
    onClickIcon,
    value,
    maxlength,
    type,
  }) => {
    const inputEl = useRef<InputRef | null>(null);
    useEffect(() => {
      inputEl?.current?.input?.setAttribute('maxlength', '100');
    }, [inputEl]);

    return (
      <CustomAutoComplete
        onSearch={onSearch}
        options={options}
        onSelect={onSelect}
        onChange={onChange}
        value={value}
      >
        <Input
          type={type || 'text'}
          prefix={
            <IconWrapper onClick={onClickIcon}>
              <Search width={20} height={20} color={'#ffffff'} />
            </IconWrapper>
          }
          ref={inputEl}
          placeholder={placeholder}
          onPressEnter={(e) => {
            e.preventDefault();
            onPressEnter && onPressEnter();
            inputEl.current?.blur();
          }}
          allowClear={allowClear}
        />
      </CustomAutoComplete>
    );
  },
);
