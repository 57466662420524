import { IconRecycle, SharedButton } from '@components';
import React, { useEffect, useState } from 'react';
import { DescItem, StyledSubscription, SubscriptionBlock2 } from './style';
import { useNavigateParams } from '@hooks';
import { useDispatch } from 'react-redux';
import {
  cancelSubscriptionProduct,
  checkSubscriptionProduct,
  selectSubscription,
  useAppSelector,
} from '@redux';
import { Modal } from 'antd';
import { useMediaQuery } from '@utils';
import { useLocation } from 'react-router-dom';
import { PATH_CHECKOUT, PATH_MAIN } from '@configs';

export const SubscriptionModule = () => {
  const navigate = useNavigateParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 640px)');

  const { subState, cancel, subscribe } = useAppSelector(selectSubscription);
  const Ticker = ({ fill }: { fill: string }) => (
    <svg className="ticker-icon" fill={fill}>
      <use xlinkHref="#ticker" />
    </svg>
  );

  const confirmCancel = () => {
    dispatch(cancelSubscriptionProduct());
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.body.classList.add('remove-padding');
    return () => {
      if (document.body.classList.contains('remove-padding')) {
        document.body.classList.remove('remove-padding');
      }
    };
  }, []);

  useEffect(() => {
    if (cancel.data && cancel.data.success) {
      dispatch(checkSubscriptionProduct());
    }
  }, [cancel]);

  useEffect(() => {
    if (subscribe.data && subscribe.data.success) {
      dispatch(checkSubscriptionProduct());
    }
  }, [subscribe]);

  return (
    <>
      <svg display="none">
        <symbol viewBox="0 0 24 24" id="ticker">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM19.7071 8.04211C20.0976 7.65159 20.0976 7.01842 19.7071 6.6279C19.3166 6.23738 18.6834 6.23738 18.2929 6.6279L9.66667 15.2541L5.70711 11.2946C5.31658 10.904 4.68342 10.904 4.29289 11.2946C3.90237 11.6851 3.90237 12.3183 4.29289 12.7088L8.95956 17.3754C9.35008 17.766 9.98325 17.766 10.3738 17.3754L19.7071 8.04211Z"
          />
        </symbol>
      </svg>
      <StyledSubscription>
        <SubscriptionBlock2>
          <div className="action">
            {subState.data?.success ? (
              <SharedButton
                style={{ background: '#2EA649' }}
                className="button-link"
                text="サブスクリプションをキャンセルする"
                onClick={() => setIsModalOpen(true)}
              />
            ) : (
              <SharedButton
                className="button-link"
                text="申し込みページのリンク"
                onClick={() => navigate(PATH_CHECKOUT)}
              />
            )}
            <SharedButton
              onClick={() => navigate(PATH_MAIN)}
              className="button-forward"
              text="ホーム画面へ"
            />
          </div>
          <div className="desc">
            <DescItem>
              <Ticker fill={subState.data?.success ? `#2EA649` : `#b6b6b6`} />
              <div className="detail">
                いつでも、どこでも古典、名作が読み放題
                <br />
                KOZUCHIのサブスクサービスでは、KOZUCHIに登録されている約１万点の１００円本、全てを読むことができます。もちろん、読み上げ機能もお使いいただけます。
              </div>
            </DescItem>
            <DescItem>
              <Ticker fill={subState.data?.success ? `#2EA649` : `#b6b6b6`} />
              <div className="detail">
                月額 480円
                <br />
                KOZUCHIのサブスクサービスは月額480円でご利用いただけます。
              </div>
            </DescItem>
            <DescItem>
              <Ticker fill={subState.data?.success ? `#2EA649` : `#b6b6b6`} />
              <div className="detail">
                １ヶ月の無料トライアル実施中！
                <br />
                お申し込みいただいてから1カ月月（30日）の間、読み放題機能を無料でお試しいただけます。以下のリンクよりお申し込みください。
              </div>
            </DescItem>
          </div>
        </SubscriptionBlock2>
      </StyledSubscription>
      <Modal
        className="modal-cart"
        style={{ fontSize: isMobile ? '12px' : '14px' }}
        title="キャンセル 確認"
        cancelText="キャンセル"
        okText={
          <span className="flex-center-all flex-span">
            <IconRecycle stroke={'#fff'} /> <p>OK</p>
          </span>
        }
        centered
        closable={false}
        open={isModalOpen}
        onOk={confirmCancel}
        confirmLoading={false}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>キャンセルしますか?</p>
      </Modal>
    </>
  );
};
