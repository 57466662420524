import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

//@ts-ignore
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import { readBookApi } from '@api';
import {
  AppModal,
  DesktopReadBook,
  MobileReadBook,
  ReadActions,
  ReadBookSetting,
  SharedButton,
} from '@components';
import { selectApp, selectAuth, setLoading, useAppDispatch, useAppSelector } from '@redux';
import {
  centerBookContent,
  centerBookContentInDesktop,
  getLastPage,
  getPlainText,
  getWordAt,
  getWordStart,
  insertHighlightTextSpeaking,
  LogApp,
  useMediaQuery,
} from '@utils';
import {
  enumReadBookOrientation,
  G_SVG_BOOK_DEFAULT_SCALE,
  PATH_DETAILS,
  PATH_SUBSCRIPTION,
  READ_BOOK_PARAMS,
  SWIPE_BOOK_TOUCH_RANGE,
  SWIPE_TEXT_BOOK_TOUCH_RANGE,
  TEXT_HIGHLIGHT_CLASS_NAME,
} from '@configs';
import useSpeechSynthesis from '../useSpeechSynthesis';
import { debounce } from 'lodash';
import styled from 'styled-components';

const INITIAL_SCALE = 1;
const INITIAL_POSITION_X = 0;
const INITIAL_POSITION_Y = 0;

export const ReadBookModule = memo(() => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isIpad = useMediaQuery('(min-width: 768px, max-width: 1024px)');
  const isMobileAndIpad = useMediaQuery('(max-width: 1024px)');
  const isPortrait = useMediaQuery('(orientation: portrait)');

  // view book
  const { bookReadableId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { accessToken } = useAppSelector(selectAuth);
  const { loading } = useAppSelector(selectApp);

  const [title, setTitle] = useState<string>();
  const [showSuggestBuySubcription, setShowSuggestBuySubcription] = useState<boolean>(false);
  const [bookPlainText, setBookPlainText] = useState<string>();
  const [textSpeak, setTextSpeak] = useState<string>();
  const [mobileTextSpeak, setMobileTextSpeak] = useState<string>();
  const [bookDefaultContent, setBookDefaultContent] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOne, setPageOne] = useState();
  const [pageTwo, setPageTwo] = useState<any>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [textIds, setTextIds] = useState<Array<number>>([]);
  const [showSpeakOptions, setShowSpeakOptions] = useState<boolean>(false);
  const [scaleRatio, setScaleRatio] = useState<number>(INITIAL_SCALE);
  const [positionX, setPositionX] = useState<number>(INITIAL_POSITION_X);
  const [positionY, setPositionY] = useState<number>(INITIAL_POSITION_Y);
  const [visibleScroll, setVisibleScroll] = useState<boolean>(false);
  const [bookCoverImage, setBookCoverImage] = useState<string>();
  const [bookCoverImageTwo, setBookCoverImageTwo] = useState<string>();
  const [isTextBooks, setIsTextBooks] = useState<boolean>(false);

  const [speakCharIndex, setSpeakCharIndex] = useState<number>();
  const [speakCharLength, setSpeakCharLength] = useState<number>();
  const [orientationId, setOrientationId] = useState<number>(0);
  const [resizeCompleted, setResizeCompleted] = useState<boolean>(false);

  const swipeBook = useRef({
    touchStart: 0,
    touchEnd: 0,
  });

  // const [isOpenEdit, setIsOpenEdit] = useState(false);
  // const [isOpenEditCharacter, setIsOpenEditCharacter] = useState(false);
  const [openSettingSpeak, setOpenSettingSpeak] = useState(false);

  // list with speaker
  const [pitch, setPitch] = useState<any>(1);
  const [rate, setRate] = useState<any>(1);
  const [volume, setVolume] = useState<number>(1);
  const [voiceIndex, setVoiceIndex] = useState<number>(0);
  const [lang, setLang] = useState<string>();
  const [startActivePosition, setStartActivePosition] = useState<number>();
  const [endActivePosition, setEndActivePosition] = useState<number>(0);

  function dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  const getData = async (id: string) => {
    try {
      LogApp('getData', pageIndex);
      dispatch(setLoading(true));
      const res: any = await readBookApi.getReadBookContent({
        ...READ_BOOK_PARAMS,
        productId: id,
        start: pageIndex,
        length: isMobileAndIpad || isTextBooks ? 1 : 2,
      });
      setTotalPage(res?.total);
      const data = res?.data?.dataRead;
      const orientation = res?.data?.orientationId;
      setTitle(res?.data?.bookName);
      if (orientation) setOrientationId(orientation);
      if (res?.data?.suggestBuySubcription) setShowSuggestBuySubcription(true);
      LogApp('API new', res?.data);
      // Handle pdf book html
      if (data?.[0]?.textContent || data?.[1]?.textContent) {
        setOrientationId(enumReadBookOrientation.Vertical);
        if (!isTextBooks) setIsTextBooks(true);
        const textPageOne = data?.[0]?.textContent || '';
        // const textPageTwo = data?.[1]?.textContent || '';
        const textPageTwo = '';
        const bookDefaultContent = textPageOne.concat(textPageTwo, '\n');
        const bookDefaultText = bookDefaultContent.replace(/(\r\n|\n|\r|\t)/gm, '');
        setBookDefaultContent(bookDefaultContent);
        //
        const hasMiniText =
          bookDefaultText?.includes('<ruby>') && bookDefaultText?.includes('</ruby>');
        if (hasMiniText) {
          LogApp('rubyAsCC0', bookDefaultContent);
          const speakBookText = bookDefaultText
            ?.replace(/\s/g, '')
            ?.replace(/(\r\n|\n|\r)/gm, '')
            // ?.replace(/<rp[^>]*>[\s\S]*<\/rp>/g, '') // remove rp tags and them inner
            // ?.replace(/<rt[^>]*>[\s\S]*<\/rt>/g, '') // remove rt tags and them inner
            ?.replace(/《[\s\S]*?》/g, '') //remove mini text
            ?.replace(/<[^>]*>/gm, ''); // remove all html tags

          setTextSpeak(speakBookText);
          setMobileTextSpeak(speakBookText);
          LogApp('rubyAsCC1', speakBookText);
        } else {
          setTextSpeak(bookDefaultText);
          setMobileTextSpeak(bookDefaultText);
        }
      } else {
        //Handle svg book
        const pageOneData = data[0]?.htmlContent;
        // ?.replace('defs', '');
        const pageTwoData = data[1]?.htmlContent;
        // ?.replace('defs', '');
        LogApp('check23', pageOneData);
        // handle get plain text
        const allPlainText = getPlainText(pageOneData, pageTwoData);
        setMobileTextSpeak(allPlainText);
        setPageOne(pageOneData);
        setPageTwo(pageTwoData);
      }
    } catch (error) {
      LogApp(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onCloseSpeakSetting = () => {
    // setIsOpenEdit(isClose);
    // setIsOpenEditCharacter(isClose);
    setOpenSettingSpeak(false);
  };

  const onOpenSpeakSetting = () => {
    setOpenSettingSpeak(true);
  };

  const onCancelReadBook = () => {
    if (bookReadableId) {
      // navigate(`${PATH_DETAILS}/${bookReadableId}`, { replace: true });
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const removeHighlight = () => {
    const rectHighlights = Array.from(document.getElementsByClassName(TEXT_HIGHLIGHT_CLASS_NAME));
    rectHighlights.forEach((item) => {
      item.remove();
    });
  };

  const onEnd = () => {
    removeHighlight();
    // setTextSpeak('');
    setEndActivePosition(0);
    setStartActivePosition(undefined);
  };

  const onPause = (e: any) => {
    const char = e.utterance.text.charAt(e.charIndex);
    LogApp('PAUSE', char, e);
  };
  const onResume = (e: any) => {
    LogApp('RESUME', e);
  };

  const onBoundaryHandler = (e: SpeechSynthesisEvent) => {
    // remove old highlight
    const rectHighlights = Array.from(document.getElementsByClassName(TEXT_HIGHLIGHT_CLASS_NAME));
    rectHighlights.forEach((item) => {
      item.remove();
    });

    LogApp('HANDLE', e);
    const value = e.utterance.text;
    const charIndex = e.charIndex;
    const length = e.charLength;
    const char = e.utterance.text.charAt(charIndex);
    const word = getWordAt(value, charIndex);
    const anchorPosition = getWordStart(value, charIndex);
    const activePosition = anchorPosition + word.length;

    setSpeakCharIndex(charIndex);
    setSpeakCharLength(length);
    if (lang?.includes('ja')) {
      // setStartActivePosition(index); //for japanese text
      // setEndActivePosition(index + length);
    } else {
      // setStartActivePosition(anchorPosition);
      // setEndActivePosition(activePosition);
    }
    LogApp(
      'HANDLE BOUND, SPEAKING...',
      { value },
      { char },
      charIndex,
      { word },
      anchorPosition,
      activePosition,
    );
    // Handle highlight text
    if (textIds?.includes(charIndex)) {
      const speakingText: any = document.getElementById(`bookText_${charIndex}`);
      if (speakingText) {
        insertHighlightTextSpeaking(speakingText);
        // speakingText?.setAttribute('style', 'fill:#260033 !important; font-family: FF3;');
      }
    } else {
      for (let i = 0; i < textIds.length; i++) {
        // LogApp('RANGE', textIds[i], charIndex, textIds[i+1]);
        if (textIds[i] < charIndex && textIds[i + 1] > charIndex) {
          // LogApp('RANGE', textIds[i]);
          const speakingText: any = document.getElementById(`bookText_${textIds[i]}`);
          if (speakingText) {
            insertHighlightTextSpeaking(speakingText);
            // speakingText?.setAttribute('style', 'fill:#260033 !important; font-family: FF3;');
          }
        }
      }
    }
  };

  const { speak, cancel, pause, resume, speaking, supported, voices, isPause } = useSpeechSynthesis(
    {
      onEnd,
      onPause,
      onResume,
      onBoundaryHandler,
    },
  );

  LogApp('Voice Index', voiceIndex);

  // const availableVoices = isMobileAndIpad
  //   ? voices
  //   : voices.filter((voice: SpeechSynthesisVoice) => voice.lang.includes('ja'));
  const availableVoices = voices.filter((voice: SpeechSynthesisVoice) => voice.lang.includes('ja'));

  const handleSpeak = () => {
    LogApp('ready speaking...', textSpeak);
    if ((textSpeak && !isMobileAndIpad) || (isMobileAndIpad && mobileTextSpeak)) {
      const voice: SpeechSynthesisVoice = availableVoices?.[voiceIndex] || availableVoices[0];
      const lang = voice?.lang;
      // setLang(voice?.lang);
      LogApp('availableVoices:', availableVoices);

      LogApp(
        ', voice:' +
          JSON.stringify(voice) +
          ', rate:' +
          rate +
          ', volume:' +
          volume +
          ', lang:' +
          lang +
          ', pitch:' +
          pitch,
      );
      if (isMobileAndIpad) {
        speak({ text: mobileTextSpeak, voice, rate, pitch, volume, lang });
      } else {
        speak({ text: textSpeak, voice, rate, pitch, volume, lang });
      }
      LogApp('speaking...');
    }
  };

  LogApp('Speak', String(speaking));

  const bookContent = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onShowSpeakOptions = () => {
    setShowSpeakOptions(true);
  };

  const onHideSpeakOptions = () => {
    setShowSpeakOptions(false);
  };

  const getBookImageUrlPageOne = useCallback(
    (imageBooks: any) => {
      if (imageBooks?.[0]) {
        // imageBooks?.[0]?.getAttribute('style', 'width: 65%; height: 65%');
        const coverUrl = imageBooks?.[0]?.getAttribute('xlink:href');
        coverUrl && setBookCoverImage(coverUrl);
      }
    },
    [bookCoverImage],
  );

  const getBookImageUrlPageTwo = useCallback(
    (imageBooks: any) => {
      if (imageBooks?.[0]) {
        const coverUrl = imageBooks?.[0]?.getAttribute('xlink:href');
        coverUrl && setBookCoverImageTwo(coverUrl);
      }
    },
    [bookCoverImageTwo],
  );

  useEffect(() => {
    if (bookReadableId) {
      // getBookData(bookReadableId);
      getData(bookReadableId);
    }
    return () => {
      setPageOne(undefined);
      setPageTwo(undefined);
    };
  }, [pageIndex]);

  useEffect(() => {
    /* reset default scroll for vertical text book */
    if (bookDefaultContent) {
      const textInnerBox = document.getElementsByClassName('text-default')?.[0] as HTMLDivElement;
      if (textInnerBox) {
        textInnerBox.scrollLeft = 0;
      }
    }
  }, [bookReadableId, pageIndex]);

  useEffect(() => {
    const elements = document.getElementById('second-page')?.getElementsByTagName('text');
    const paths = document.getElementById('second-page')?.getElementsByTagName('path');
    if (!elements?.length && !paths?.length) {
      const imageBooks = document.getElementById('second-page')?.getElementsByTagName('image');
      if (imageBooks?.length === 1) getBookImageUrlPageTwo(imageBooks);
    }
    return () => {
      setBookCoverImageTwo('');
    };
  }, [bookContent?.current, pageTwo]);

  useEffect(() => {
    const elements = document.getElementById('first-page')?.getElementsByTagName('text');
    const paths = document.getElementById('first-page')?.getElementsByTagName('path');
    if (!elements?.length && !paths?.length) {
      const imageBooks = document.getElementById('first-page')?.getElementsByTagName('image');
      if (imageBooks?.length === 1) getBookImageUrlPageOne(imageBooks);
    }
    return () => {
      setBookCoverImage('');
    };
  }, [bookContent?.current, pageOne]);

  useEffect(() => {
    // if (bookContent?.current) {
    const bookPreview = document.getElementById('preview-book');
    const text = (bookPreview?.textContent || bookPreview?.innerText)?.replaceAll('/ /g', '');
    // ?.replaceAll('/,|./g', '');
    LogApp('innerText', text);

    const elements = document.getElementById('preview-book')?.children;
    LogApp('check221', elements);
    const page1 = elements?.[0];
    const page2 = elements?.[1];
    if (page1) {
      // lay tat ca the text trong page 1
      const innerElements = page1?.children?.[0]?.children;
      const innerBookSvg = innerElements?.[2]?.children;
      innerElements[2]?.setAttribute('class', `svgPage`); //set className for page svg
      const bookG = innerElements?.[2]?.getElementsByTagName('g')?.[0]?.children;
      bookG?.[0]?.setAttribute('id', 'bookSVG_G');
      const bookTexts = bookG?.[0]?.getElementsByTagName('text');

      if (isMobileAndIpad) {
        const bookContentG = bookG?.[0];
        const parentBookContentSVG = innerElements[2];
        const scale = !isMobile ? G_SVG_BOOK_DEFAULT_SCALE : 1;
        if (bookContentG) {
          centerBookContent(bookContentG, parentBookContentSVG, scale);
        }
      } else {
        centerBookContentInDesktop(bookG?.[0], page1, 0.9);
      }

      // set Id cho tat ca cac text, get plainText for web
      const textIds = [];
      let textIndex = 0;
      let plainText = '';
      if (bookTexts && bookTexts?.length > 0) {
        for (let i = 0; i < bookTexts.length; i++) {
          const textLgPrev = bookTexts[i - 1]?.textContent?.length || 0;
          LogApp('check21', textLgPrev);
          plainText += bookTexts[i]?.textContent;
          textIndex += textLgPrev;
          bookTexts[i]?.setAttribute('id', `bookText_${textIndex}`);
          bookTexts[i]?.setAttribute('class', `svgText`);
          textIds.push(textIndex);
        }
      } else {
        LogApp('check22');
        // if (isMobileAndIpad) {
        const textElements = document.getElementById('first-page')?.getElementsByTagName('text');
        const paths = document.getElementById('first-page')?.getElementsByTagName('path');
        const imageBooks = document.getElementById('first-page')?.getElementsByTagName('image');
        if (!textElements?.length && !paths?.length) {
          getBookImageUrlPageTwo(imageBooks);
        }
        // }
      }

      LogApp('plainText1', plainText);

      //handle read page2
      if (page2) {
        const page2InnerElements = page2?.children?.[0]?.children;
        const page2InnerBookSvg = page2InnerElements?.[2]?.children;
        page2InnerElements[2]?.setAttribute('class', `svgPage`); //set className for page svg
        const page2BookG = page2InnerElements?.[2]?.getElementsByTagName('g')?.[0]?.children;
        page2BookG?.[0]?.setAttribute('id', 'bookSVG_G2');
        const bookTextsPage2 = page2BookG?.[0].getElementsByTagName('text');
        if (bookTextsPage2 && bookTextsPage2?.length > 0) {
          for (let i = 0; i < bookTextsPage2.length; i++) {
            const textLgPrev = bookTextsPage2[i - 1]?.textContent?.length || 0;
            LogApp('check21', textLgPrev);
            plainText += bookTextsPage2[i]?.textContent;
            textIndex += textLgPrev;
            bookTextsPage2[i]?.setAttribute('id', `bookText_${textIndex}`);
            bookTextsPage2[i]?.setAttribute('class', `svgText`);
            textIds.push(textIndex);
          }
        } else {
          const textElements = document.getElementById('second-page')?.getElementsByTagName('text');
          const paths = document.getElementById('second-page')?.getElementsByTagName('path');
          const imageBooks = document.getElementById('second-page')?.getElementsByTagName('image');
          if (!textElements?.length && !paths?.length) {
            getBookImageUrlPageTwo(imageBooks);
          }
        }

        if (!isMobileAndIpad) {
          centerBookContentInDesktop(page2BookG?.[0], page2, 0.9);
          page2BookG?.[0]?.setAttribute('clip-path', 'none');
        }
      }
      LogApp('plainText2', plainText);
      if (plainText) {
        setBookPlainText(plainText);
        setTextSpeak(plainText);
      }
      setTextIds(textIds);
    }
    // }
    return () => {
      setBookCoverImage('');
      setBookCoverImageTwo('');
    };
  }, [bookContent?.current, pageOne, pageTwo]);

  useEffect(() => {
    // pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    // setOptions({ workerSrc: '/pdf.worker.min.js', });
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return () => {
      cancel();
      dispatch(setLoading(false));
    };
  }, []);

  useEffect(() => {
    //center when scale
    const gBook = document.getElementById('bookSVG_G');
    const gBook2 = document.getElementById('bookSVG_G2');
    const pageOne = document.getElementById('first-page');
    const pageTwo = document.getElementById('second-page');
    if (!isMobileAndIpad) {
      if (scaleRatio === 1) {
        centerBookContentInDesktop(gBook, pageOne);
        centerBookContentInDesktop(gBook2, pageTwo);
      }
    }
  }, [scaleRatio]);

  const handleResetScale = () => {
    setScaleRatio(INITIAL_SCALE);
    setPositionX(INITIAL_POSITION_X);
    setPositionY(INITIAL_POSITION_Y);
  };

  const handleClickPrev = () => {
    cancel();
    if (pageIndex === 0) return;

    if (bookReadableId) {
      if (currentPage > 0) {
        setCurrentPage(currentPage - (isMobileAndIpad || isTextBooks ? 1 : 2));
        setPageIndex((prev) => prev - 1);
      }
    }
  };

  const handleClickNext = () => {
    cancel();
    // if (pageIndex === getLastPage(totalPage)) return;
    if (currentPage >= totalPage) return;
    if (bookReadableId) {
      setCurrentPage(currentPage + (isMobileAndIpad || isTextBooks ? 1 : 2));
      setPageIndex((prev) => prev + 1);
    }
  };

  const handleChangePage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const enterNumber = Number(value);
    //update page ngay khi nhap vao
    if (enterNumber > 0 && enterNumber <= totalPage) {
      handleUpdatePage(enterNumber);
    }
  };

  const handleUpdatePage = debounce((enterPage: number) => {
    if (isMobileAndIpad || isTextBooks) {
      if (bookReadableId) {
        enterPage > 0 && enterPage <= totalPage && setCurrentPage(enterPage);
      }

      setPageIndex(enterPage - 1);
      if (pageIndex === getLastPage(totalPage)) return;
      if (bookReadableId) {
        cancel();
      }
    }
    // if (bookReadableId) {
    //   enterNumber > 0 && enterNumber <= totalPage && setCurrentPage(enterNumber);
    // }
  }, 1000);

  const handlePressEnter = (e: any) => {
    const { value } = e.target;
    const enterNumber = Number(value);
    if (enterNumber > totalPage) return;
    if (bookReadableId) {
      if (enterNumber > 0) {
        enterNumber % 2 === 0 ? setCurrentPage(enterNumber - 1) : setCurrentPage(enterNumber);
      }
      if (enterNumber === 1) {
        setPageIndex(0);
      } else {
        if (isMobileAndIpad || isTextBooks) {
          setPageIndex(enterNumber);
        } else {
          const currentIndex = enterNumber % 2 === 0 ? enterNumber / 2 - 1 : (enterNumber - 1) / 2;
          setPageIndex(currentIndex);
        }
      }
      cancel();
    }
  };

  const calculateRatioPosition = (scaleRatio: number) => {
    if (scaleRatio === 1) return 0;
    return scaleRatio < 2.6
      ? 50 - 50 * (scaleRatio - Math.floor(scaleRatio))
      : -1 + 1 * (scaleRatio - Math.floor(scaleRatio));
  };
  const handleZoomIn = () => {
    if (scaleRatio >= 5) return;
    // Handle scale
    setScaleRatio((prevState) => prevState + 0.2);
    setPositionX((prevState) => prevState + calculateRatioPosition(scaleRatio));
    setPositionY((prevState) => prevState + 50);
    // reset center g content
    const gBook = document.getElementById('bookSVG_G');
    const gBook2 = document.getElementById('bookSVG_G2');
    if (!isMobileAndIpad) {
      gBook?.setAttribute('style', ``);
      gBook2?.setAttribute('style', ``);
    }
  };
  const handleZoomOut = () => {
    if (!isTextBooks && scaleRatio <= 1) return;
    if (isTextBooks && scaleRatio < 0.35) return;

    setScaleRatio((prevState) => prevState - 0.2);
    setPositionX((prevState) => prevState - calculateRatioPosition(scaleRatio));
    setPositionY((prevState) => prevState - 50);
  };

  const handleTouchStart = (e: any) => {
    // setTouchStart(e?.targetTouches[0]?.clientX);
    swipeBook.current.touchStart = e?.targetTouches[0]?.clientX;
  };

  const handleTouchMove = (e: any) => {
    // setTouchEnd(e?.targetTouches[0]?.clientX);
    swipeBook.current.touchEnd = e?.targetTouches[0]?.clientX;
  };

  const handleTouchEnd = () => {
    // if (isTextBooks) return;
    const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
    const touchRange = isTextBooks ? deviceWidth + 20 : SWIPE_BOOK_TOUCH_RANGE;
    // const touchRange = SWIPE_BOOK_TOUCH_RANGE;
    if (swipeBook.current.touchStart - swipeBook.current.touchEnd > touchRange) {
      // swipe right to left
      if (orientationId === enumReadBookOrientation.Vertical || isTextBooks) handleClickPrev();
      else handleClickNext();
    }

    if (swipeBook.current.touchStart - swipeBook.current.touchEnd < -touchRange) {
      // swipe left to right
      if (orientationId === enumReadBookOrientation.Vertical || isTextBooks) handleClickNext();
      else handleClickPrev();
    }
  };

  // alert(orientationId + 'c' + enumReadBookOrientation.Vertical);

  LogApp(bookPlainText?.length, bookPlainText, 'bookPlainText');
  LogApp(mobileTextSpeak?.length, mobileTextSpeak, 'mobileTextSpeak');
  LogApp({ scaleRatio, positionX, positionY, currentPage, totalPage });

  useEffect(() => {
    // Handle font size = original book size to fit the screen
    LogApp('handle resize txt books');
    if (!isMobile && bookDefaultContent && !resizeCompleted) {
      const deviceWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
      const txtBookContent = document.getElementById('txt-book-content');
      const contentWidth = txtBookContent?.offsetWidth || 0;
      const bookPadding = isMobileAndIpad ? 32 * 2 : (134 + 26 + 56) * 2;
      LogApp('handle resize txt books 1', deviceWidth, contentWidth);
      if (contentWidth > deviceWidth - bookPadding) {
        LogApp('handle resize txt books 2', contentWidth, loading);
        setScaleRatio((prevState) => prevState - 0.08);
      } else {
        setResizeCompleted(true);
      }
    }
  }, [bookDefaultContent, scaleRatio]);

  return (
    <>
      <StyledSuggestModal
        className="abc"
        modalClassName="sub-modal"
        popupPosition="top"
        open={showSuggestBuySubcription}
        borderRadius={0}
      >
        <StyledSuggestModalContent>
          <span className="popup__title">読書を続けますか？</span>
          <div className="popup__actions">
            <SharedButton
              className="sg-btn by-btn"
              textColor="red"
              onClick={() => navigate(PATH_SUBSCRIPTION)}
              text={'サブスクを購入'}
            />
            <SharedButton
              className="sg-btn back-btn"
              textColor="red"
              onClick={() => navigate(-1)}
              text={'本の詳細ページへ'}
            />
            <SharedButton
              className="sg-btn cancel-btn"
              textColor="cyan"
              onClick={() => setShowSuggestBuySubcription(false)}
              text={'キャンセル'}
            />
          </div>
        </StyledSuggestModalContent>
      </StyledSuggestModal>
      {isMobileAndIpad ? (
        <MobileReadBook
          title={title}
          fileUrl={fileUrl}
          // onDocumentLoadSuccess={onDocumentLoadSuccess}
          pageOne={pageOne}
          pageTwo={pageTwo}
          bookDefaultContent={bookDefaultContent}
          bookCoverImage={bookCoverImage}
          // ref={bookContent}
          speakCharIndex={speakCharIndex}
          speakCharLength={speakCharLength}
          isSpeaking={speaking}
          onChangePage={handleChangePage}
          onClickPrev={handleClickPrev}
          onClickNext={handleClickNext}
          disablePrevButton={pageIndex === 0}
          disabledNextButton={pageIndex === getLastPage(totalPage)}
          currentPage={currentPage}
          totalPage={totalPage}
          onPressEnter={handlePressEnter}
          onCancelReadBook={onCancelReadBook}
          openSettingSpeak={openSettingSpeak}
          onCloseSpeakSetting={onCloseSpeakSetting}
          onSwipeStart={handleTouchStart}
          onSwipeMove={handleTouchMove}
          onSwipeEnd={handleTouchEnd}
          isTextBooks={isTextBooks}
          scaleInner={scaleRatio}
          pageIndex={pageIndex}
          topActions={
            <ReadActions
              isPause={isPause}
              showSpeakOptions={showSpeakOptions}
              speaking={speaking}
              onSpeak={handleSpeak}
              onPause={pause}
              onResume={resume}
              onCancel={cancel}
              onShowSpeakOptions={onShowSpeakOptions}
              onHideSpeakOptions={onHideSpeakOptions}
              onOpenSpeakSetting={onOpenSpeakSetting}
              supported={supported}
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              currentZoomNumber={scaleRatio}
              isTextBooks={isTextBooks}
            />
          }
          settingContent={
            <ReadBookSetting
              rate={rate}
              pitch={pitch}
              availableVoices={availableVoices}
              voiceIndex={voiceIndex}
              setVoiceIndex={setVoiceIndex}
              setRate={setRate}
              setPitch={setPitch}
              onClose={onCloseSpeakSetting}
            />
          }
        />
      ) : (
        <DesktopReadBook
          title={title}
          orientation={orientationId}
          fileUrl={fileUrl}
          isVisible={visibleScroll}
          // onDocumentLoadSuccess={onDocumentLoadSuccess}
          bookDefaultContent={bookDefaultContent}
          pageOne={pageOne}
          pageTwo={pageTwo}
          bookCoverImage={bookCoverImage}
          bookCoverImageTwo={bookCoverImageTwo}
          ref={bookContent}
          speakCharIndex={speakCharIndex}
          speakCharLength={speakCharLength}
          isSpeaking={speaking}
          onChangePage={handleChangePage}
          onClickPrev={handleClickPrev}
          onClickNext={handleClickNext}
          currentPage={currentPage}
          totalPage={totalPage}
          onPressEnter={handlePressEnter}
          onCancelReadBook={onCancelReadBook}
          openSettingSpeak={openSettingSpeak}
          onCloseSpeakSetting={onCloseSpeakSetting}
          scaleInner={scaleRatio}
          positionY={positionY}
          positionX={positionX}
          isTextBooks={isTextBooks}
          pageIndex={pageIndex}
          topActions={
            <ReadActions
              isPause={isPause}
              showSpeakOptions={showSpeakOptions}
              speaking={speaking}
              onSpeak={handleSpeak}
              onPause={pause}
              onResume={resume}
              onCancel={cancel}
              onShowSpeakOptions={onShowSpeakOptions}
              onHideSpeakOptions={onHideSpeakOptions}
              onOpenSpeakSetting={onOpenSpeakSetting}
              supported={supported}
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              currentZoomNumber={scaleRatio}
              isTextBooks={isTextBooks}
            />
          }
          settingContent={
            <ReadBookSetting
              rate={rate}
              pitch={pitch}
              availableVoices={availableVoices}
              voiceIndex={voiceIndex}
              setVoiceIndex={setVoiceIndex}
              setRate={setRate}
              setPitch={setPitch}
              onClose={onCloseSpeakSetting}
            />
          }
        />
      )}
    </>
  );
});

const StyledSuggestModal = styled((props) => <AppModal {...props} />)<{
  $open?: boolean;
}>`
  .modal__title {
    margin: 0 2rem;
  }
  .content {
  }
`;

const StyledSuggestModalContent = styled.div<{
  $open?: boolean;
}>`
  padding: 0;
  .popup__title {
    color: ${(p) => p.theme.colors.textColor};
    text-align: center;
    font-weight: 500;
    text-align: center;
    font-size: 2rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
  .popup__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    .by-btn {
    }
    .cancel-btn {
      /* margin-left: 1.2rem; */
    }
    .sg-btn {
      padding: 0.6rem 0;
      min-width: 28rem;
      max-width: 32rem;
      width: 100%;
      border-radius: 0rem;
      border-top: 1px solid ${(p) => p.theme.colors.textColor};
    }
  }
`;
