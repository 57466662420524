import { subscriptionRankApi } from '@api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const url = 'Product';
export const fetchSubscriptionRank = createAsyncThunk<any>(
  `${url}/subscriptionRank`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await subscriptionRankApi.getSubscriptionRankApi();
      return res.data;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
