import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, selectCustomer, selectSubscription, useAppSelector } from '@redux';
import { UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Account,
  DataWrapper,
  SubDataWrapper,
  Text,
  UserAvatar,
  UserAvatarLoading,
  UserSideBarWrapper,
} from './style';

import { PATH_SUBSCRIPTION } from '@configs';
import { IconChevronDown, IconChevronRight } from '@components';

interface SubTitleType {
  id: number;
  title: string;
  path: string;
}

interface DataType {
  id: number;
  icon: any;
  title: string;
  path?: string;
  subTitles?: SubTitleType[];
  isLogout?: boolean;
  isUserInfo?: boolean;
}

interface UserSideBarProps {
  dataList: DataType[];
}

export const UserSideBar: React.FC<UserSideBarProps> = ({ dataList }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSelector((state: RootState) => state);
  const { userInfo, accessToken } = auth;
  const isLogin = accessToken;
  const [itemSelected, setItemSelected] = useState<DataType | null>(null);
  const {
    data: { userProfile },
  } = useAppSelector(selectCustomer);
  const { subState } = useSelector(selectSubscription);
  const handleOpenSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleSelectData = (data: DataType) => {
    if (data.subTitles?.length) {
      setOpenSubMenu(!openSubMenu);
    }
    setItemSelected(data);
    /*if (data.path) {
      if(subState.data && subState.data.success){
        navigate(data.path);
      }else {
        navigate(PATH_SUBSCRIPTION);
      }
    }*/
  };
  useLayoutEffect(() => {
    if (itemSelected?.path) {
      if (itemSelected.id === 2) {
        subState.data && subState.data.success
          ? navigate(itemSelected.path)
          : navigate(PATH_SUBSCRIPTION);
      } else {
        navigate(itemSelected.path);
      }
    }
  }, [itemSelected]);
  const handleSelectSubData = (data: SubTitleType) => {
    if (data.path) navigate(data.path);
  };

  return (
    <UserSideBarWrapper>
      <Account className="p-item">
        <div className="avatar-icon__wrapper">
          {isLogin ? (
            userProfile?.data.avatarUrl ? (
              <UserAvatar
                className="image-detector"
                src={userProfile?.data.avatarUrl}
                alt="user-avatar"
              />
            ) : (
              <UserAvatarLoading />
            )
          ) : (
            <UserOutlined className="icon" />
          )}
        </div>
        <div className="account-info">
          <Text textWeight="500">
            {userProfile?.data.firstName} {userProfile?.data.lastName}
          </Text>
          {/* <Text>{userProfile?.data.email}</Text> */}
        </div>
      </Account>
      <div className="p-item">
        {dataList.map((data) => {
          const isSubTitleLength = data.subTitles?.length;
          const IconSideBar = data.icon;
          const isLogout = data.isLogout;
          const isUserInfo = data.isUserInfo;
          return (
            <>
              <DataWrapper key={data.id} onClick={() => handleSelectData(data)}>
                <div className="data-item__wrapper">
                  <span className="icon-wrapper">
                    {isLogout || isUserInfo ? null : <IconSideBar width={15} height={21} />}
                  </span>
                  <Text
                    textWeight="400"
                    className="data-title"
                    textColor={location.pathname === data.path ? '#D42A1C' : 'inherit'}
                  >
                    {isUserInfo || isLogout ? null : data.title}
                  </Text>
                </div>

                <span>
                  {isSubTitleLength &&
                    (openSubMenu ? (
                      <IconChevronDown
                        className="icon-active"
                        width={16}
                        height={16}
                        color="#6C757D"
                        onClick={handleOpenSubMenu}
                      />
                    ) : (
                      <IconChevronRight
                        className="icon-active"
                        width={16}
                        height={16}
                        color="#6C757D"
                        onClick={handleOpenSubMenu}
                      />
                    ))}
                </span>
              </DataWrapper>

              {openSubMenu && (
                <SubDataWrapper>
                  {data.subTitles?.map((item) => (
                    <div
                      key={item.id}
                      className="df-item"
                      onClick={() => handleSelectSubData(item)}
                    >
                      <Text
                        textSize="1.4rem"
                        textWeight="400"
                        className="mb-item df-item"
                        isBefore
                        textColor={location.pathname === item.path ? '#D42A1C' : 'inherit'}
                      >
                        {item.title}
                      </Text>
                    </div>
                  ))}
                </SubDataWrapper>
              )}
            </>
          );
        })}
      </div>
    </UserSideBarWrapper>
  );
};
