import { FC } from 'react';
import styled from 'styled-components';
import { Button, Rate } from 'antd';
import { ProductReviewsModel } from '@interfaces';
import { IconRecycle } from '../Icon/RecycleBin';

interface CommentItemProps {
  data: ProductReviewsModel;
  index: number;
  isSelf: boolean;
  onDeleteClicked: (currentId: number) => void;
}

export const CommentItem: FC<CommentItemProps> = ({
  index = 1,
  isSelf,
  onDeleteClicked,
  data: { rating, customerName, customerAvatarUrl, writtenOnStr, reviewText, id },
}) => {
  return (
    <StyledCommentItem>
      <div className="header">{index + 1} 件</div>
      <div className="info">
        <div className="fa-center">
          <div className="avatar">
            <img src={customerAvatarUrl} alt="" title={customerName} />
          </div>
          <div className="detail">
            <div className="username">{customerName}</div>
            <div className="date">{writtenOnStr}</div>
          </div>
        </div>
        <Rate disabled className="rating-box" allowHalf defaultValue={rating || 0} />
        {isSelf ? (
          <Button
            onClick={() => onDeleteClicked(id)}
            shape="circle"
            className="delete-btn"
            icon={<IconRecycle />}
          />
        ) : null}
      </div>
      <div className="cmt-text">{reviewText}</div>
    </StyledCommentItem>
  );
};

const StyledCommentItem = styled.div`
  margin-bottom: 2.4rem;
  * {
    font-family: 'Noto Sans JP';
  }
  .fa-center {
    display: flex;
    align-items: center;
  }
  &:hover .delete-btn {
    opacity: 1;
    visibility: visible;
    right: 1rem;
  }
  .delete-btn {
    transition: 0.25;
    opacity: 0;
    visibility: hidden;
    width: 3.2rem;
    height: 3.2rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border: none !important;
  }
  .header {
    font-weight: ${(p) => p.theme.weight['700']};
    font-size: ${(p) => p.theme.size.l};
    line-height: 23px;
    color: ${(p) => p.theme.colors.text};
  }
  .cmt-text {
    font-weight: ${(p) => p.theme.weight['400']};
    font-size: ${(p) => p.theme.size.xs};
    line-height: 23px;
    color: ${(p) => p.theme.colors.text};
  }
  .info {
    position: relative;
    margin-top: 2.4rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    justify-content: space-between;
    min-width: 50%;
    @media (min-width: 768px) {
      padding-right: 71px;
    }
    .detail {
      margin-left: 0.8rem;
    }
    .rating-box {
      margin-left: 4.8rem;
    }
    .username {
      font-weight: ${(p) => p.theme.weight['700']};
      font-size: ${(p) => p.theme.size.s};
      line-height: 20px;
      color: ${(p) => p.theme.colors.text};
    }
    .date {
      font-weight: ${(p) => p.theme.weight['400']};
      font-size: ${(p) => p.theme.size.s};
      line-height: 20px;
      color: ${(p) => p.theme.colors.text2};
    }
    .avatar {
      min-width: 4rem;
      max-width: 4rem;
      width: 4rem;
      height: 4rem;
      min-height: 4rem;
      max-height: 4rem;
      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
