import React, { FC } from 'react';
import { UserOutlined } from '@ant-design/icons';

import { StyledUserBookRequest } from './style';
import { BookRequestProps } from '@interfaces';

interface IUserBookRequest {
  data: BookRequestProps;
}

export const UserBookRequest: FC<IUserBookRequest> = ({ data }) => {
  return (
    <>
      <StyledUserBookRequest className="user-request-container">
        <h6 className="book-of-title force-wrap">書籍名： {data.name}</h6>
        <div className="item-author force-wrap">
          <span className="item-author-name">
            著者: <span>{data.authorName}</span>
          </span>
          <span className="item-author-date no-wrap">{data.createdOnUtcFormated}</span>
        </div>
        <div className="user-request-line"></div>
        <div className="user-request-info">
          {data.customer.avatarUrl ? (
            <div className="user-request-info-avt">
              <img src={data.customer.avatarUrl} alt="not" />
            </div>
          ) : (
            <>
              <UserOutlined className="user-icon__default" />
            </>
          )}

          <div className="user-request-info-content">
            <div className="user-request-info-content-name">{data.customer.nameFormated}</div>
            <div className="user-request-info-content-email">{data.customer.email}</div>
            <div className="user-request-info-content-require force-wrap">{data.body}</div>
            {data.pictures.length > 0 && (
              <div className="user-request-info-content-image">
                {data.pictures.map((e, i) => (
                  <img src={e.imageUrl} key={i} alt="image" />
                ))}
              </div>
            )}
          </div>
        </div>
      </StyledUserBookRequest>
    </>
  );
};
