import React, { useCallback, useEffect, useState } from 'react';
import { StyleContainer } from './style';
import _ from 'lodash';
import { selectCart, useAppSelector } from '@redux';
import { ShareInput } from '@components';

interface IHeaderCustom {
  title?: string;
  content?: string;
  variant?: 'search';
  rootClassName?: string;
  onSearch: (...args: any) => any;
  debounceEmitValue?: number;
}
export const HeaderCustomer = (props: IHeaderCustom) => {
  const [value, setValue] = useState('');
  const {
    data: { cartRemoveOne },
  } = useAppSelector(selectCart);
  const { title, content, variant, rootClassName, onSearch, debounceEmitValue = 300 } = props;
  // const isMobile = useMediaQuery('max-width: 640px');

  const handleDebouncedChange = useCallback(_.debounce(onSearch, debounceEmitValue), [onSearch]);

  const handleValueChange = useCallback(
    (event) => {
      setValue(event.target.value);
      handleDebouncedChange(event.target.value);
    },
    [setValue, handleDebouncedChange],
  );

  useEffect(() => {
    setValue(value);
  }, [value]);
  useEffect(() => {
    setValue('');
  }, [cartRemoveOne]);

  return (
    <StyleContainer
      className={rootClassName ? `${rootClassName} header-customer` : 'header-customer'}
    >
      <div className="header-customer-main">
        <div className="header-customer-left">
          <h5>{title}</h5>
          <p className="header-customer-content">{content}</p>
        </div>
        {variant === 'search' && (
          <div className="header-input__wrapper">
            <ShareInput
              containerClassName="header-customer-search"
              inputType="search"
              sizeSearch="small"
              placeholder="書籍名で検索"
              name="search"
              value={value}
              onChange={handleValueChange}
            />
          </div>
        )}
      </div>
      <div className="header-customer-line" />
    </StyleContainer>
  );
};
