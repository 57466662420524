import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';

import { enumBookType, enumNameOrder, enumSortByOtherCategories, enumSortByPrice } from '@configs';
import { SharedCheckbox, ShareInput } from '../shared';

interface IProps {
  bookType: enumBookType;
  sortByPrice: enumSortByPrice;
  nameOrder: enumNameOrder;
  sortByOtherCategories: enumSortByOtherCategories;
  handleBookTypeChange?: (value: RadioChangeEvent) => void;
  handleSortByPriceChange: (value: RadioChangeEvent) => void;
  handleNameOrderChange: (value: RadioChangeEvent) => void;
  handleSortByOtherCategoriesChange: (value: RadioChangeEvent) => void;
}

export const SelectFilterOptions = (props: IProps) => {
  const {
    bookType,
    sortByPrice,
    nameOrder,
    sortByOtherCategories,
    handleBookTypeChange,
    handleSortByPriceChange,
    handleNameOrderChange,
    handleSortByOtherCategoriesChange,
  } = props;
  return (
    <StyledFilterOptions>
      {/* Book type */}
      <StyledOption>
        <h2 className="heading">書籍の種類</h2>

        <Radio.Group value={bookType} onChange={handleBookTypeChange}>
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumBookType.ALL}
            type="radio"
            text="全て"
            // checked={bookType === enumBookType.ALL}
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumBookType.EBOOK}
            type="radio"
            text="電子書籍"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumBookType.READ_ALOUD}
            type="radio"
            text="音声読み上げ"
          />
        </Radio.Group>
      </StyledOption>
      {/* Sort by price */}
      <StyledOption>
        <h2 className="heading">価格で並べ替え</h2>
        <Radio.Group value={sortByPrice} onChange={handleSortByPriceChange}>
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByPrice.ALL}
            type="radio"
            text="全て"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByPrice.HIGH_PRICE}
            type="radio"
            text="価格の高い順"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByPrice.LOWEST_PRICE}
            type="radio"
            text="価格の低い順"
          />
        </Radio.Group>
      </StyledOption>
      {/* Name order */}
      <StyledOption>
        <h2 className="heading">名前順</h2>
        <Radio.Group value={nameOrder} onChange={handleNameOrderChange}>
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumNameOrder.ALL}
            type="radio"
            text="全て"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumNameOrder.TITLE_ASCENDING_ORDER}
            type="radio"
            text="タイトル昇順"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumNameOrder.TITLE_DESCENDING_ORDER}
            type="radio"
            text="タイトル降順"
          />
        </Radio.Group>
      </StyledOption>
      {/* Sort by other categories */}
      <StyledOption>
        <h2 className="heading">他のカテゴリで並べ替え</h2>
        <Radio.Group value={sortByOtherCategories} onChange={handleSortByOtherCategoriesChange}>
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByOtherCategories.ALL}
            type="radio"
            text="全て"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByOtherCategories.OLDEST_RELEASE_FIRST}
            type="radio"
            text="リリースの古い順"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByOtherCategories.ORDER_OF_POPULARITY}
            type="radio"
            text="レビューの評価順"
          />
          <SharedCheckbox
            className="filter-options__cb"
            size={'2rem'}
            value={enumSortByOtherCategories.RATING_ORDER_OF_REVIEWS}
            type="radio"
            text="人気順"
          />
        </Radio.Group>
      </StyledOption>
    </StyledFilterOptions>
  );
};

const StyledFilterOptions = styled.div``;

const StyledOption = styled.div`
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 23px;
    color: #303030;
    text-align: left;
    margin-bottom: 2rem;
  }
  .filter-options__cb {
    margin-bottom: 1.6rem;
    @media (max-width: 640px) {
      /*.ant-radio {
        display: none !important;
      }*/
      .radio-label {
        padding-left: 8px;
      }
    }
  }
`;
