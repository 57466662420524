import styled from 'styled-components';

export const StyledUserBookRequest = styled.div`
  width: 100%;
  .force-wrap {
    width: 100%;
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }
  .no-wrap {
    white-space: nowrap;
    padding-left: 2.4rem;
  }
  & ~ & {
    margin-top: 4.8rem;
  }
  .book-of-title {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: ${(p) => p?.theme.colors?.text};
  }
  .item-author {
    width: 100%;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.8rem;
    &-name {
      color: #6c757d;
      span {
        color: #0dbed2;
      }
    }
    &-date {
      color: #6c757d;
    }
  }
  .user-request-line {
    margin: 1.6rem 0;
    width: 100%;
    height: 0.1rem;
    background-color: #d9d9d9;
  }
  .user-request-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &-avt {
      width: 7.6rem;
      height: 7.6rem;
      min-width: 7.6rem;
      min-height: 7.6rem;
      img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
    &-content {
      margin-left: 2.4rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      &-name {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.9rem;
        color: #303030;
      }
      &-email,
      &-require {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #6c757d;
        margin-top: 0.8rem;
      }
      &-require {
        margin-top: 2.4rem;
      }
      &-image {
        margin-top: 2.5rem;
        height: 12rem;
        img {
          //width: 100%;
          height: 100%;
        }
      }
    }
    @media screen and (max-width: 768px) {
      &-avt {
        width: 6rem;
        height: 6rem;
      }
      &-content {
        margin-left: 5rem;
      }
    }

    .user-icon__default {
      background-color: #b6b6b6;
      color: ${(props) => props.theme.colors.body};
      border-radius: 50%;
      width: 7.6rem;
      height: 7.6rem;

      svg {
        width: 100%;
        height: 100%;
        padding: 0.5rem;
      }
    }
  }
`;
