import styled from 'styled-components';
import React, { FC, useEffect } from 'react';
import { LoginFacebookIcon, SharedButton } from '@components';
import { LogApp } from '@utils';
import { useDispatch } from 'react-redux';
import {
  getUserProfileDefault,
  selectSocialAuth,
  setAccessToken,
  setLoading,
  setLoadingCustomer,
  setRefreshToken,
  setUserInfo,
  setUserInfoFacebook,
  useAppSelector,
} from '@redux';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '@api';
import { PATH_HOME } from '@configs';

interface FacebookLoginModuleProps {
  onClick?: () => void;
}

export const FacebookLoginModule: FC<FacebookLoginModuleProps> = ({ onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { facebook } = useAppSelector(selectSocialAuth);
  const loginFacebookSDK = () => {
    FB.login(
      function (response) {
        LogApp('response : ', response);
        if (response.status === 'connected' && response.authResponse) {
          const body = {
            AccessToken: response.authResponse.accessToken,
            ClientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID + '',
            ClientSecret: process.env.REACT_APP_FACEBOOK_CLIENT_SECRET + '',
          };
          authAPI.loginByFacebook(body).then((res) => {
            dispatch(setUserInfoFacebook(res));
            dispatch(setUserInfo(res?.data.customer));
            dispatch(setAccessToken(res?.data.accessToken.tokenString));
            dispatch(setRefreshToken(res?.data.refreshToken.tokenString));
            dispatch(setLoadingCustomer(false));
          });
          LogApp('Welcome!  Fetching your information.... ');
          FB.api('/me', function (response: any) {
            LogApp('res ', response);
            LogApp('Good to see you, ' + response.name + '.' + response.email);
          });
        } else {
          LogApp('User cancelled login or did not fully authorize.');
        }
      },
      {
        scope: 'email,public_profile',
        return_scopes: true,
      },
    );
  };

  useEffect(() => {
    if (facebook && facebook.data && facebook.data.success) {
      dispatch(getUserProfileDefault());
      navigate(PATH_HOME);
    }
  }, [facebook]);

  return (
    <StyledFacebookBtn
      text="Facebookでサインイン"
      prevIcon={<LoginFacebookIcon width={30} height={30} />}
      textColor="#3467A6"
      borderColor="#3467A6"
      btnStyle="social"
      textClassName="text-hover-color"
      onClick={() => {
        loginFacebookSDK();
      }}
    />
  );
};

const StyledFacebookBtn = styled((props) => <SharedButton {...props} />)`
  width: 76%;
  margin-bottom: 1rem;
  border-radius: 0.8rem;

  .text-hover-color {
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`;
