import axiosClient from './axiosClient';
import { AddToCartProps, PaymentProps } from '@interfaces';
import { DeleteAllType } from '@configs';

const url = 'api/ShoppingCart';

export const shoppingCartApi = {
  getWishList: () => {
    return axiosClient.get(`${url}/wishlist`);
  },
  searchWishList: (searchParam: string) => {
    return axiosClient.get(`${url}/wishlist?BookOrAuthorName=${searchParam}`);
  },
  getWishListWithPagination: (queryParams: string) => {
    return axiosClient.get(`${url}/wishlist?${queryParams}`);
  },
  getBooksInCart: () => {
    return axiosClient.get(`${url}`);
  },
  addToCart: (cartParams: AddToCartProps) => {
    return axiosClient.post(`${url}`, cartParams);
  },
  removeOne: (cartId: number) => {
    return axiosClient.delete(`${url}/${cartId}`);
  },
  removeAll: (type: DeleteAllType) => {
    return axiosClient.delete(`${url}/deleteAllCartOrWishList/${type}`);
  },
  removeMultiple: (body: number[]) => {
    return axiosClient.post(`${url}/RemoveItemsInWishList`, body);
  },
  checkoutAll: (body: PaymentProps) => {
    const url = `api/Checkout/Payment`;
    return axiosClient.post(url, body);
  },
};
