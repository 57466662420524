import styled from 'styled-components';
import { LoginModule, RegisterModule } from '@modules';
import { IMAGES } from '@assets';
import { SocialLogin } from '@components';

export const RegisterPage = () => {
  return (
    <StyledRegister background={IMAGES.backgroundLogin} className="register-page">
      <div className="header__logo logo app-container">
        <img title="" src={IMAGES.headerLogo} />
      </div>
      <div className="auth__section app-container">
        <div className="auth__frame">
          <RegisterModule />
          <SocialLogin haveDividerDes />
        </div>
      </div>
    </StyledRegister>
  );
};

const StyledRegister = styled.div<{ background?: string }>`
  //height: 100vh;
  min-height: 100vh;
  //height: calc(100vh + 250px);
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  background-image: ${(p) => `url(${p?.background})`};
  @media (max-width: 640px) {
    background-position-x: 50%;
    background-size: 200% 100%;
  }
  .header__logo {
    padding-top: calc(17 / 360 * 100%);
    height: fit-content;
    @media (max-width: 640px) {
      padding-top: 4.6rem;
      margin: 0 auto 2rem;
      width: 15rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .auth__section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    @media (max-width: 640px) {
      width: 100%;
      padding-bottom: 0%;
    }
    .auth__frame {
      background-color: ${(props) => props?.theme.colors.body};
      width: 49%;
      height: 100%;
      border-radius: 0.8rem;
      padding: 4rem 4rem 6.4rem;

      @media (max-width: 1024px) {
        width: 68%;
      }

      @media (max-width: 640px) {
        border-radius: 1.2rem 1.2rem 0 0;
        padding: 3.6rem 2rem 9.4rem;
        width: 100%;
      }
    }
  }
`;
