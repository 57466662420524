import { Layout } from 'antd';
import styled from 'styled-components';

import { ILayout } from '@interfaces';
import { HeaderModule, InnerAppModule } from '@modules';
import { Footer, HeaderSubscription } from '@components';

export const AppLayout = (props: ILayout) => {
  const { children } = props;
  return (
    <Layout className="layout">
      <HeaderModule />
      <HeaderSubscription />
      <StyledPageInner>
        <InnerAppModule {...props}>{children}</InnerAppModule>
      </StyledPageInner>
      <Footer />
    </Layout>
  );
};

const StyledPageInner = styled.div<{ backgroundColor?: string }>`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  margin-top: 8rem;

  /* 
  @media (min-width: ${(p) => 200}) {
    width: 100%;
    margin: 0 auto;
  } */
`;
