import styled from 'styled-components';

export const SpecialCommerStyled = styled.div`
  .title-text {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: ${(props) => props.theme?.colors.text};
    @media (max-width: 640px) {
      font-size: 2rem;
    }
  }
  .header-customer-line {
    margin: 2.4rem;
    border: 1px solid #ececec;
  }
`;

export const PageTitle = styled.div`
  @media (max-width: 640px) {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 4.1rem;
  }
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5.8rem;
  text-align: center;
  margin: 4rem 0 10rem 0;
  color: ${(props) => props.theme?.colors.text};
`;

export const PageContent = styled.div`
  .element-text {
    margin: 1.6rem 0;
    .name {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text};
      margin-bottom: 0.8rem;
    }
    .description {
      margin-left: 0.8rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text};
    }

    &:nth-child(6) {
      .description {
        color: ${(props) => props.theme?.colors.text4} !important;
      }
    }
  }
`;
