import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumBookType, enumNameOrder, enumSortByOtherCategories, enumSortByPrice } from '@configs';

import { RootState } from '.';
import { Price } from '@interfaces';

interface ISidebar {
  bookType: enumBookType;
  sortByPrice: enumSortByPrice;
  nameOrder: enumNameOrder;
  sortByOtherCategories: enumSortByOtherCategories;
  showCategorySidebar: boolean;
  category: string;
  price: Price;
  currentActive: number | null;
}

const initialState: ISidebar = {
  bookType: enumBookType.ALL,
  sortByPrice: enumSortByPrice.ALL,
  nameOrder: enumNameOrder.ALL,
  sortByOtherCategories: enumSortByOtherCategories.ALL,
  showCategorySidebar: false,
  category: '',
  price: {
    priceMin: null,
    priceMax: null,
  },
  currentActive: null,
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: initialState,
  reducers: {
    setBookType: (state, action: PayloadAction<any>) => {
      state.bookType = action.payload;
    },
    setSortByPrice: (state, action: PayloadAction<any>) => {
      state.sortByPrice = action.payload;
    },
    setNameOrder: (state, action: PayloadAction<any>) => {
      state.nameOrder = action.payload;
    },
    setSortByOtherCategories: (state, action: PayloadAction<any>) => {
      state.sortByOtherCategories = action.payload;
    },
    setShowCategorySidebar: (state, action: PayloadAction<boolean>) => {
      state.showCategorySidebar = action.payload;
    },
    setAppCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
    setPrice: (state, action: PayloadAction<Price>) => {
      state.price = action.payload;
    },
    resetSidebar: () => {
      return initialState;
    },
    setCurrentActive: (state, action) => {
      state.currentActive = action.payload;
    },
  },
});

export const {
  setBookType,
  setSortByPrice,
  setNameOrder,
  setSortByOtherCategories,
  setShowCategorySidebar,
  setAppCategory,
  setPrice,
  setCurrentActive,
} = sidebarSlice.actions;

export const selectSidebar = (state: RootState) => state.sidebar;
export const selectShowCategorySidebar = (state: RootState) => state.sidebar.showCategorySidebar;
export default sidebarSlice.reducer;
