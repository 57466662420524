import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { removeFromLS, setToLS, useMediaQuery } from '@utils';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { resetCurrentPage } from '@redux';

interface IProps {
  minimum?: number;
  maximum?: number;
  handleChangeMinimum: (value: any) => void;
  handleChangeMaximum: (value: any) => void;
  onPressEnter?: () => void;
  onFilterPress: () => void;
  errorText?: string;
}

export const SelectPriceRange = (props: IProps) => {
  const {
    minimum,
    maximum,
    handleChangeMinimum,
    handleChangeMaximum,
    onPressEnter,
    onFilterPress,
    errorText,
  } = props;
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [error, setError] = useState<string>();
  const inpMaxRef = useRef<any>(null);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const dispatch = useDispatch();
  const resetInputIfZero = () => {
    if (+max === 0) {
      setMax('0');
    }
    if (+min === 0) {
      setMin('0');
    }
  };

  const replaceTruncate = () => {
    setMax((e) => e.replace(/^0+/, ''));
    setMin((e) => e.replace(/^0+/, ''));
  };

  useEffect(() => {
    handleChangeMinimum(+min);
  }, [min]);

  useEffect(() => {
    handleChangeMaximum(+max);
  }, [max]);

  return (
    <StyledSelectPriceRange>
      <h2 className="price_heading">価格</h2>
      <div className="range-contain">
        <Input
          value={min}
          name="minimum-number"
          onChange={(e: any) => {
            const regexOnlyNumber = /^[0-9\b]+$/;
            if (
              e.target.value === '' ||
              (regexOnlyNumber.test(e.target.value) && e.target.value.length <= 10)
            ) {
              setMin(e.target.value);
            }
          }}
          placeholder="円（最小)"
          className={`inner-input${isMobile ? ' fx-input' : ''}`}
        />
        {/*<ShareInput
          value={minimum}
          type="text"
          pattern="[0-9]*"
          name="minimum-number"
          onChange={handleChangeMinimum}
          placeholder="円（最小)"
          containerClassName={`price-range__input${isMobile ? ' fx-input': ''}`}
          className="inner-input"
        />*/}
        <span className="line">-</span>
        <Input
          ref={inpMaxRef}
          value={max}
          name="maximum-number"
          onChange={(e: any) => {
            const regexOnlyNumber = /^[0-9\b]+$/;
            if (
              e.target.value === '' ||
              (regexOnlyNumber.test(e.target.value) && e.target.value.length <= 10)
            ) {
              setMax(e.target.value);
            }
          }}
          onPressEnter={(event) => {
            event.preventDefault();
            if (+max < +min) {
              setError('最小数は最大数を超えることはできません');
            } else {
              setError(undefined);
              onPressEnter && onPressEnter();
              removeFromLS('subActive');
              inpMaxRef.current.blur();
            }
            resetInputIfZero();
            replaceTruncate();
            dispatch(resetCurrentPage(1));
          }}
          placeholder="円（最小)"
          className={`inner-input${isMobile ? ' fx-input' : ''}`}
        />
        {/*<ShareInput
          ref={inpMaxRef}
          value={maximum}
          type="text"
          name="maximum-number"
          placeholder="円（最大)"
          onChange={handleChangeMaximum}
          containerClassName={`price-range__input${isMobile ? ' fx-input': ''}`}
          className="inner-input"
          onPressEnter={(event) => {
            event.preventDefault();
            onPressEnter && onPressEnter();
            inpMaxRef.current.blur();
          }}
        />*/}
        <button
          className="search-by-price"
          onClick={() => {
            removeFromLS('subActive');
            onFilterPress();
            resetInputIfZero();
            replaceTruncate();
            dispatch(resetCurrentPage(1));
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.75 12H20.25"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 5.25L20.25 12L13.5 18.75"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      {error && <div className="input-error">{error}</div>}
      {errorText && <div className="input-error">{errorText}</div>}
    </StyledSelectPriceRange>
  );
};

const StyledSelectPriceRange = styled.div`
  margin-bottom: 3rem;

  .search-by-price {
    width: 5.6rem;
    height: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: ${(p) => p.theme.colors.primary};
    border: none;
    outline: none;
    margin-left: auto;
    cursor: pointer;
    @media (min-width: 640px) {
      display: none;
    }
  }

  .fx-input {
    flex-grow: unset !important;
    width: calc((100% - 3.2rem + 5px - 5.6rem - 3.6rem) / 2) !important;
  }

  .price_heading {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: #303030;
    margin-bottom: 1.6rem;
  }

  .range-contain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.6rem;

    .line {
      margin: 0 1.6rem;
    }

    input {
      text-align: center;
    }

    .inner-input {
      flex-grow: 1;
      height: 4rem;
      padding: 0.6rem 1.2rem;
      border: 1px solid #b6b6b6;
      color: #495057;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 1.9rem;
      border-radius: 0.4rem;
    }
  }

  @media (max-width: 640px) {
    // margin-right: 3.2rem;
    .range-contain {
      margin-bottom: 0.6rem;
    }
  }
`;
