import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { GlobalStyles, useTheme } from './theme';
import { store, persistor } from 'src/redux';
import { ModuleMain } from 'src/modules';
import '@translations/i18n';
import { getMessaging, getToken } from 'firebase/messaging';
import { setToLS } from '@utils';

function App() {
  const { theme, themeLoaded, getFonts } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    setSelectedTheme(theme);
    document.title = 'ブックスKOZUCHI';
    const href = window.location.href.split('/');
    setToLS('redirect', `https://${href[2]}/login/`);
  }, [themeLoaded]);

  if (process.env.NODE_ENV !== 'development') {
    useEffect(() => {
      // WebFont.load({
      //   google: {
      //     families: getFonts(),
      //   },
      // });
      const messaging = getMessaging();
      getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            // ...
          }
        })
        .catch((err) => {
          // ...
        });
    });
  }

  return (
    <Provider store={store}>
      {themeLoaded && selectedTheme && (
        <ThemeProvider theme={selectedTheme}>
          <GlobalStyles />
          <PersistGate loading={null} persistor={persistor}>
            <ModuleMain setTheme={setSelectedTheme} />
          </PersistGate>
        </ThemeProvider>
      )}
    </Provider>
  );
}

export default App;
