import React, { memo } from 'react';

interface DecreaseIconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

export const DecreaseIcon: React.FC<DecreaseIconProps> = memo(
  ({ color = '#000', width = 24, height = 24 }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.75 12h16.5"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);
