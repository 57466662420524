import { createAsyncThunk } from '@reduxjs/toolkit';
import { productApi, customerApi } from '@api';
import { RegisterSubscriptionProps } from '@interfaces';

interface ProductParams {
  [x: string]: any;
}
const url = 'Product';
export const fetchProductList = createAsyncThunk(
  `${url}`,
  async (params: ProductParams, { rejectWithValue }) => {
    try {
      const res: any = await productApi.getProductList(params);
      return res;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
export const fetchSuggestedData = createAsyncThunk(
  `${url}/product-data`,
  async (params: ProductParams, { rejectWithValue }) => {
    try {
      const res: any = await productApi.getProductList(params);
      return res;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchBestSellerProduct = createAsyncThunk(
  `${url}/best-seller`,
  async (params: ProductParams, { rejectWithValue }) => {
    try {
      const res: any = await productApi.getBestSellerProducts(params);
      return res;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const subscribeSubscriptionProduct = createAsyncThunk<any, RegisterSubscriptionProps>(
  `${url}/subscribeSubscriptionProduct`,
  async (params, { rejectWithValue }) => {
    try {
      return await customerApi.subscribeSubscription(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const cancelSubscriptionProduct = createAsyncThunk<any>(
  `${url}/cancelSubscriptionProduct`,
  async (_, { rejectWithValue }) => {
    try {
      return await customerApi.cancelSubscription();
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const checkSubscriptionProduct = createAsyncThunk<any>(
  `${url}/checkSubscriptionProduct`,
  async (_, { rejectWithValue }) => {
    try {
      return await customerApi.getSubscriptionStatus();
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
