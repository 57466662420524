import React from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';

interface SharedSkeletonProps {
  type: 'image' | 'input';
  active?: boolean;
  imageClassName?: string;
  inputClassName?: string;
}

export const SharedSkeleton: React.FC<SharedSkeletonProps> = ({
  type,
  active,
  imageClassName,
  inputClassName,
}) => {
  return (
    <SkeletonWrapper>
      {type === 'image' ? (
        <CustomSkeleton.Image active={active} className={imageClassName} />
      ) : (
        <CustomSkeleton.Input active={active} className={inputClassName} />
      )}
    </SkeletonWrapper>
  );
};

const SkeletonWrapper = styled.div`
  width: 100%;
`;

const CustomSkeleton = styled(Skeleton)``;
