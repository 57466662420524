import { Col, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { KEY_PATH } from '@configs';
import { IBookDataItem } from '@interfaces';
import { hasKeyIn, parseString, setToLS, useMediaQuery } from '@utils';
import { FilterMenuIcon } from '../Icon';
import { Pagination } from '../Pagination';
import { CardItem, SharedButton } from '../shared';
import { SkeletonCardItem } from '../shared/Skeleton/skeletonCardItem';
import { StyledListingPage, StyledRow } from './style';
import { changePathName, selectProduct, useAppSelector } from '@redux';

interface IProps {
  handleShowSidebar: () => void;
  dataSearch: IBookDataItem[];
  onChangePage: (value: number) => void;
  total: number;
  pageSize: number;
  bookLists: IBookDataItem[];
  isSearching?: boolean;
  isLoading?: boolean;
  categoryName: string;
  itemStart?: number;
  itemEnd?: number;
  recommendedBooksTotal: number;
  bestSellerBooksTotal: number;
  lastedBooksTotal: number;
}

export const ListingComponent = (props: IProps) => {
  const {
    handleShowSidebar,
    dataSearch,
    onChangePage,
    total,
    pageSize,
    bookLists,
    isSearching,
    isLoading,
    categoryName,
    itemStart,
    itemEnd,
    bestSellerBooksTotal,
    recommendedBooksTotal,
    lastedBooksTotal,
  } = props;
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(max-width: 992px) and (min-width: 640px)');
  const skeletonNum = new Array(8).fill(1);
  const dispatch = useDispatch();
  const params = useSearchParams();
  const locationPath = useLocation();
  const paramsPath = useParams();
  const productSelector = useAppSelector(selectProduct);
  const { currentPage } = productSelector;

  useEffect(() => {
    return () => {
      const path = locationPath.pathname.split('/')[0];
      if (path === 'category') {
        dispatch(changePathName(null));
      }
    };
  }, []);
  useEffect(() => {
    if (params[0].get('isLatestBooks') && params[0].get('isLatestBooks') === 'true') {
      dispatch(changePathName('最新書籍'));
      setToLS('subActive', '999999999');
    }
    if (params[0].get('isHighlight')) {
      dispatch(changePathName('おすすめの本'));
      setToLS('subActive', '999999999');
    }
    if (params[0].get('bestSeller')) {
      dispatch(changePathName('ベストセラー'));
      setToLS('subActive', '999999999');
    }

    if (
      !params[0].get('isLatestBooks') &&
      !params[0].get('isHighlight') &&
      !params[0].get('bestSeller')
    ) {
      dispatch(changePathName(null));
    }
  }, [locationPath]);
  const renderData = (data: IBookDataItem[]) => {
    if (isLoading) {
      return skeletonNum.map((item, index) => (
        <Col key={index} xs={12} sm={8} md={8} lg={6} xl={6} xxl={6}>
          <SkeletonCardItem />
        </Col>
      ));
    } else {
      return data?.map((item) => (
        <Col key={item.id} className="gutter-row" xs={12} sm={8} md={8} lg={6} xl={6} xxl={6}>
          <CardItem dataCard={item} />
        </Col>
      ));
    }
  };
  const renderTitle = (path: string) => {
    const newPath = parseString(path);
    switch (true) {
      case hasKeyIn(newPath, KEY_PATH.isHighlight):
        return 'おすすめの本';
      case hasKeyIn(newPath, KEY_PATH.isBestSeller):
        return 'ベストセラー';
      case hasKeyIn(newPath, KEY_PATH.isLatestBooks):
        return '最新書籍';
      case hasKeyIn(paramsPath, 'categoryId'):
        return categoryName;
      case hasKeyIn(newPath, KEY_PATH.isFilterNone):
        return `${itemStart}-${itemEnd}の${total}結果`;
      case hasKeyIn(newPath, KEY_PATH.isSearch):
        return `${itemStart}-${itemEnd}の${total}結果`;
      default:
        return '';
    }
  };
  const getTotalPage = (path: string) => {
    const newPath = parseString(path);
    switch (true) {
      case hasKeyIn(newPath, KEY_PATH.isHighlight):
        return recommendedBooksTotal;
      case hasKeyIn(newPath, KEY_PATH.isBestSeller):
        return bestSellerBooksTotal;
      case hasKeyIn(newPath, KEY_PATH.isLatestBooks):
        return lastedBooksTotal;
      default:
        return total;
    }
  };
  return (
    <StyledListingPage className="listing-section">
      <div className="header">
        {bookLists?.length && !isSearching ? (
          <h1 className="header-text">{renderTitle(locationPath.search)}</h1>
        ) : (
          <h1 className="header-text">
            {!dataSearch?.length ? (
              <>
                <h1 className="header-text">{renderTitle(locationPath.search)}</h1>
                <p>探している書籍が見つかりません。</p>
              </>
            ) : (
              renderTitle(locationPath.search)
            )}
          </h1>
        )}

        {(isMobile || isTablet) && (
          <SharedButton
            onClick={(e) => {
              e.stopPropagation();
              handleShowSidebar();
            }}
            text="フィルター"
            textColor="#303030;"
            borderColor="#D9D9D9"
            bgColor="transparent"
            prevIcon={<FilterMenuIcon />}
            className="filter-btn ignore-onClickOutside"
          />
        )}
      </div>
      <Divider />
      <StyledRow gutter={[30, 16]} className="books-list">
        {/* {dataSearch?.map((item) => (
          <Col key={item.id} className="gutter-row" xs={12} sm={8} md={8} lg={6} xl={6} xxl={6}>
            <CardItem dataCard={item} />
          </Col>
        ))} */}

        {renderData(isSearching ? dataSearch : bookLists)}
      </StyledRow>
      {(dataSearch?.length > 0 || bookLists.length > 0) && (
        <>
          <Divider className="sub-divider" />
          <Pagination
            current={currentPage}
            defaultCurrent={1}
            total={getTotalPage(locationPath.search)}
            onChangePage={onChangePage}
            pageSize={pageSize}
          />
        </>
      )}
    </StyledListingPage>
  );
};
