import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { queryParamsParser } from '@utils';
import { NotificationPage, SignupSuccessIcon } from '@components';
import { PATH_HOME, PATH_REGISTER_SUCCESS } from '@configs';

export const UserAccountActivationModule = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(PATH_HOME);
  };
  useEffect(() => {
    const path = queryParamsParser(window.location.href.split('/').at(-1));
    if (path.activationmessage === 'あなたのアカウントはすでに有効になっています。') {
      navigate(`${PATH_REGISTER_SUCCESS}`);
    }
  }, []);

  return (
    <NotificationPage
      icon={<SignupSuccessIcon />}
      buttonText="ホーム画面へ"
      onButtonClick={handleClick}
      headerText="ユーザアカウントのアクティベーション"
      description="あなたのアカウントはすでに有効になっています。"
      descriptionAlignment="center"
      headerAlignment="center"
    />
  );
};
