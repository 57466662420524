import { createAsyncThunk } from '@reduxjs/toolkit';
import { bookReviewApi } from '@api';
import { CommentRatingRequestProps } from '@interfaces';

const prefix = 'Review';

export const fetchReviewBooksList = createAsyncThunk<any, string>(
  `${prefix}/fetchReviewBooksList`,
  async (params, { rejectWithValue }) => {
    try {
      return await bookReviewApi.getReviewList(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const deleteAComment = createAsyncThunk<any, number>(
  `${prefix}/deleteAComment`,
  async (params, { rejectWithValue }) => {
    try {
      return await bookReviewApi.deleteComment(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const postAComment = createAsyncThunk<any, CommentRatingRequestProps>(
  `${prefix}/postAComment`,
  async (params, { rejectWithValue }) => {
    try {
      return await bookReviewApi.sendComment(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
