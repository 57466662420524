import { ICon } from '@interfaces';

const LoginFacebookIcon = (props: ICon) => {
  const { onClick, width = 24, height = 24, className } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
      role="img"
      onClick={onClick}
      className={className}
    >
      <path
        fill="#3a569c"
        d="M42 37a5 5 0 0 1-5 5H11a5 5 0 0 1-5-5V11a5 5 0 0 1 5-5h26a5 5 0 0 1 5 5v26z"
      />
      <path
        fill="#FFF"
        d="M34.368 25H31v13h-5V25h-3v-4h3v-2.41c.002-3.508 1.459-5.59 5.592-5.59H35v4h-2.287C31.104 17 31 17.6 31 18.723V21h4l-.632 4z"
      />
    </svg>
  );
};

const LoginLineIcon = (props: ICon) => {
  const { onClick, width = 24, height = 24, className, color = '#4ac953' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 320 320"
      role="img"
      onClick={onClick}
      className={className}
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="LINE_LOGO" data-name="LINE LOGO">
          <rect fill={color} width={320} height={320} rx={72.14} />
          <path
            d="M266.66 144.92c0-47.74-47.86-86.58-106.69-86.58S53.28 97.18 53.28 144.92c0 42.8 38 78.65 89.22 85.42 3.48.75 8.21 2.29 9.4 5.26 1.08 2.7.71 6.93.35 9.65 0 0-1.25 7.53-1.52 9.13-.47 2.7-2.15 10.55 9.24 5.76s61.44-36.18 83.82-61.95c15.46-16.95 22.87-34.19 22.87-53.27Z"
            style={{
              fill: '#fff',
            }}
          />
          <path
            fill={color}
            d="M231.16 172.49h-30a2 2 0 0 1-2-2v-46.55a2 2 0 0 1 2-2h30a2 2 0 0 1 2 2v7.57a2 2 0 0 1-2 2h-20.37v7.85h20.37a2 2 0 0 1 2 2V151a2 2 0 0 1-2 2h-20.37v7.86h20.37a2 2 0 0 1 2 2v7.56a2 2 0 0 1-2 2.07Zm-110.87 0a2 2 0 0 0 2-2v-7.56a2 2 0 0 0-2-2H99.92v-37a2 2 0 0 0-2-2h-7.6a2 2 0 0 0-2 2v46.53a2 2 0 0 0 2 2h30Z"
          />
          <rect fill={color} x={128.73} y={121.85} width={11.64} height={50.64} rx={2.04} />
          <path
            fill={color}
            d="M189.84 121.85h-7.56a2 2 0 0 0-2 2v27.66l-21.3-28.77a1.2 1.2 0 0 0-.17-.21l-.12-.12-.11-.09h-.06l-.11-.08h-.06l-.11-.06h-8.47a2 2 0 0 0-2 2v46.56a2 2 0 0 0 2 2h7.57a2 2 0 0 0 2-2v-27.93l21.33 28.8a2 2 0 0 0 .52.52l.12.08h.06l.1.05h.31a2.42 2.42 0 0 0 .54.07h7.52a2 2 0 0 0 2-2v-46.44a2 2 0 0 0-2-2.04Z"
          />
        </g>
      </g>
    </svg>
  );
};

const LoginAppleIcon = (props: ICon) => {
  const { onClick, width = 24, height = 24, className, color = '#000' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
      role="img"
      onClick={onClick}
      className={className}
    >
      <path
        fill={color}
        d="M44.529 34.75c-1.081 2.393-1.598 3.464-2.986 5.579-1.94 2.954-4.678 6.64-8.064 6.665-3.012.025-3.789-1.965-7.876-1.932-4.087.02-4.939 1.969-7.954 1.938-3.386-.031-5.978-3.352-7.92-6.3C4.3 32.429 3.727 22.736 7.082 17.579c2.374-3.657 6.13-5.805 9.657-5.805 3.592 0 5.85 1.974 8.82 1.974 2.882 0 4.637-1.979 8.791-1.979 3.142 0 6.464 1.712 8.838 4.666-7.766 4.255-6.504 15.347 1.341 18.315zM31.197 8.468c1.511-1.94 2.657-4.677 2.242-7.468-2.466.168-5.349 1.743-7.034 3.782-1.526 1.857-2.791 4.615-2.298 7.283 2.69.087 5.474-1.517 7.09-3.597z"
      />
    </svg>
  );
};

export { LoginFacebookIcon, LoginLineIcon, LoginAppleIcon };
