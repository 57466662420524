import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

interface OptionType {
  id?: string | number;
  title: string;
  value: string;
}

interface SelectInputProps {
  options: OptionType[];
  value?: string;
  onChange?: (value: string) => void;
  showSearch?: boolean;
  disabled?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  [params: string]: any;
}

const { Option } = Select;

export const SelectInput: React.FC<SelectInputProps> = ({
  options,
  value,
  onChange,
  showSearch,
  disabled,
  placeholder,
  placeholderColor,
  params,
}) => {
  return (
    <SelectInputWrapper
      value={value}
      onChange={onChange}
      showSearch={showSearch}
      disabled={disabled}
      placeholder={placeholder}
      placeholderColor={placeholderColor}
      {...params}
    >
      {options.map((option) => {
        return (
          <Option key={option?.id || option.title} value={option.value}>
            {option.title}
          </Option>
        );
      })}
    </SelectInputWrapper>
  );
};

const SelectInputWrapper = styled(Select)<{ placeholderColor?: string }>`
  width: 100%;

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #55759d;
    border-right-width: 1px;
    box-shadow: none;
  }
  &.ant-select-selector {
    border-color: #5879a1;
  }
  &.ant-select-focused:not(ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #597daa;
    box-shadow: none;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 2px;
  }

  .ant-select-selection-placeholder {
    color: ${(props) => props.placeholderColor || props.theme.colors.text2};
    font-size: 1.3rem;
    font-weight: ${(props) => props.theme.weight['400']};
  }
  .ant-select-arrow {
    color: ${(props) => props.placeholderColor || props.theme.colors.text2};
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 4rem;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.gray['400']};
    font-size: 1.3rem;
  }
`;
