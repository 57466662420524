import axiosClient from './axiosClient';

const prefixUrl = 'api/BookRequestTopic';

export const bookRequestApi = {
  createANewBookRequest: (formData: FormData) => {
    return axiosClient.post(`${prefixUrl}`, formData);
  },
  getBookRequest: () => {
    return axiosClient.get(`${prefixUrl}`);
  },
  getBookRequestSearchList: (searchTerm = '') => {
    return axiosClient.get(`${prefixUrl}?KeySearch=${searchTerm}`);
  },
  getBookRequestTopicsLibrary: () => {
    return axiosClient.get(`${prefixUrl}/getBookRequestTopicsLibrary`);
  },
  getPaginationBookRequest: (queryString: string) => {
    return axiosClient.get(`${prefixUrl}?${queryString}`);
  },
};
