import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseResponseProps, BaseState } from '@interfaces';
import { RootState } from '@redux';
import { paymentAll } from '../action';
import { toast } from 'react-toastify';

const initialState: BaseState = {
  loading: false,
  data: null,
};

const checkoutSlice = createSlice({
  name: 'checkoutSlice',
  initialState,
  reducers: {
    resetDataCheckout: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(paymentAll.pending, (state) => {
      state.loading = true;
    })
      .addCase(paymentAll.fulfilled, (state, { payload }: PayloadAction<BaseResponseProps>) => {
        state.loading = false;
        state.data = payload;
        if (payload.success) {
          toast.success(
            payload.messages === 'Payment Success' ? 'ペイロード.メッセージ' : payload.messages,
          );
        } else {
          toast.error(payload.messages);
        }
      })
      .addCase(paymentAll.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { resetDataCheckout } = checkoutSlice.actions;
export const checkoutReducer = checkoutSlice.reducer;
export const selectCheckout = (s: RootState) => s.checkout;
