import React from 'react';
import { StyledContentPolicy, StyledPolicy } from './style';
import { PURPOSE } from '@configs';
import { useMediaQuery } from '@utils';

export const Policy = () => {
  const isMobile = useMediaQuery('(max-width:640px)');
  const purpose = PURPOSE;
  return (
    <StyledPolicy>
      <div className="header-policy">個人情報保護方針 </div>
      <StyledContentPolicy isMobile={isMobile}>
        <div className="title">個人情報保護方針</div>
        <p>
          三和書籍有限会社（以下、「弊社」といいます）では、個人情報の保護に関する法律及び適用されるガイドライン等を遵守する他、下記の個人情報保護方針に基づき、お客様（以下、「ユーザー」といいます）の個人情報を取り扱います。ユーザーが、「KOZUCHI」、その他の弊社が運営するサービス（以下あわせて「本サービス」といいます）のご利用をされるためには、下記の個人情報保護方針を熟読のうえ、内容に同意していただく必要があります。同意いただけない場合は、本サービスへのアクセス及び本サービスのご利用はできません。
        </p>
        <p>
          &emsp;&emsp;&emsp;弊社は、以下のとおり個人情報の利用目的を明確にした上で、利用目的の範囲内に限り、個人情報を収集及び利用いたします。ここに個人情報とは、ユーザー個人に関する情報のことを指し、氏名、住所、電話番号、メールアドレス、勤務先、性別、生年月日その他の記述等によりユーザー個人を識別できるものを指します。また、その情報のみでは識別できない場合でも、他の情報と容易に照合することができ、結果的にユーザー個人を識別できるものも個人情報に含まれます。
        </p>
        <div className="container">
          <h4> ＜利用目的＞</h4>
          <ul className="purpose">
            {purpose.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
        <div className="container">
          <h4>（１） 委託について</h4>
          <p>
            弊社では業務の全部又は一部を外部委託先に委託するに際し、当該外部委託先に対して必要最低限の範囲でユーザーの個人情報の取り扱いを委託する場合があります。この場合には、弊社が定めた基準を満たす外部委託先のみを利用することとし、個人情報の取り扱いに関する適切な契約締結及び監督を行います。
          </p>
          <p>（使用権の許諾）</p>
        </div>
        <div className="container">
          <h4>（２） 第三者提供に関して</h4>
          <p>
            弊社は、上記「委託について」に記載した外部委託先へ個人情報の取り扱いを委託する場合の他、弊社グループ会社に対し、上記（１）の利用目的の範囲内でユーザーの個人情報を開示・提供することがあります。この場合を除き、弊社は、ユーザーの個人情報を第三者へ開示・提供する場合には、開示・提供先及び開示・提供情報を特定したうえでユーザー本人に通知し、その事前の同意を得て行うものといたします。
          </p>
          <p>
            だし、以下の場合には、ユーザーからの事前の同意を得ずに個人情報を提供する場合があります。
          </p>
          <p>
            {' '}
            ① 法令に基づく場合
            <br />
            ②
            人の生命、身体、財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />
            ③
            公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
            <br />④
            国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </p>
        </div>
        <div className="container">
          <h4>（３） 匿名加工情報</h4>
          <p>
            弊社は、個人情報の属性の集計、分析を行い、個人が識別・特定できないよう加工したものを作成し、弊社によるサービス開発・提供等のために利用又は処理することがあります。
          </p>
        </div>
        <div className="container">
          <h4>（４） 「クッキー」などの使用について</h4>
          <p>
            {' '}
            •
            クッキー情報に関して、単独では特定の個人を識別することはできません。個人情報が一体となって使用される場合には個人情報とみなします。
            <br />
            • クッキーの使用を許可するかどうかはユーザーが設定できます。
            多くのWebブラウザでは、自動的にクッキーの使用が許可されますが、クッキーの使用を禁止するようにブラウザの設定を変更することも可能です。クッキーの使用を禁止した場合、本サービスを正常にご利用できない、あるいはクッキーを必要とする広告設定を反映できなくなる可能性があります。
            <br />•
            弊社は、ユーザーのクッキー情報や、閲覧された広告ページ、ユーザーのご利用環境等の情報を自動的にユーザーのブラウザから受け取り、サーバーに記録し、本サービスログイン時の電子メールアドレス自動入力等の利便性向上のため、セッションの維持及び保護などセキュリティのため、また、新しいサービスを検討するため、サービスや広告の内容をよりユーザーに適したものとするために利用いたします。
          </p>
        </div>
        <div className="container">
          <h4>（５） 「ログ」の使用などについて</h4>
          <p>
            弊社は、ユーザーが本サービス利用時に自動で生成される、IPアドレス、ブラウザ種類、ブラウザ言語などを収集します。これらの情報はユーザー環境を分析し、より良いサービス提供のため、また正常なサービス提供を妨害する不正行為防止のために利用いたします。検索に関する記録は個人を特定することができない形態で保存及び管理され、統計データ作成などの用途で利用いたします。
          </p>
        </div>
        <div className="container">
          <h4>（６） 「機器情報」の使用等について</h4>
          <p>
            個人情報の管理は厳重に行うこととし、不正アクセス、紛失、破壊、改ざん、漏洩を防ぐための適切な安全対策を講じます。また、個人情報の取り扱いに関する監査、教育を定期的に行い、継続的改善に努めます。{' '}
          </p>
        </div>
        <div className="container">
          <h4>（７） 個人情報の管理と保</h4>
          <p>
            個人情報の管理は厳重に行うこととし、不正アクセス、紛失、破壊、改ざん、漏洩を防ぐための適切な安全対策を講じます。また、個人情報の取り扱いに関する監査、教育を定期的に行い、継続的改善に努めます。
          </p>
        </div>
        <div className="container">
          <h4>（８） 個人情報保護方針の外部伝達</h4>
          <p>
            弊社は、本方針を全社員に周知徹底させるとともに、弊社ホームページ上に公表いたします。{' '}
          </p>
        </div>
        <div className="container">
          <h4>（９） 個人情報の取り扱いに関する問い合わせ窓口</h4>
          <p>
            個人情報に関する苦情、相談、開示、訂正、削除等に関するお問い合わせは、下記までご連絡ください。
          </p>
        </div>
        <div className="contact">
          <p>
            Eメール：info@sanwa-co.com
            <br />
            宛先：個人情報保護管理責任者
          </p>
          <p className="date">制定日：2021年4月1日</p>
        </div>
      </StyledContentPolicy>
    </StyledPolicy>
  );
};
