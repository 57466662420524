import styled from 'styled-components';

export const CompanyProfileStyled = styled.div`
  .title-text {
    margin-bottom: 4rem;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: ${(props) => props.theme?.colors.text};
    @media (max-width: 640px) {
      font-size: 2rem;
    }
  }
  .header-customer-line {
    margin: 2.4rem;
    border: 1px solid #ececec;
  }
  .name-text {
    margin: 1.6rem 0;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: ${(props) => props.theme?.colors.text};
    @media (max-width: 640px) {
      font-size: 2rem;
    }
  }
  .detail-text {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${(props) => props.theme?.colors.text};
  }
  .title-list-book {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${(props) => props.theme?.colors.text};
  }
  .page-information {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${(props) => props.theme?.colors.text};
  }
`;

export const AboutUs = styled.div`
  .about-information {
    margin: 2.4rem 0;
    .name {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text};
      margin-bottom: 0.8rem;
    }
    .des {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text};
    }
    .tel {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text};
    }
    .fax {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text};
    }
  }
`;

export const PageTitle = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  line-height: 5.8rem;
  color: ${(props) => props.theme?.colors.text};
  @media (max-width: 640px) {
    font-size: 2.8rem;
    font-weight: 500;
  }
`;

export const ListBook = styled.div`
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  @media (max-width: 640px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .list-book {
    margin: 0.8rem 1.2rem 2.4rem 0;
    .des {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 350;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text2};
    }
    .time {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 350;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.text2};
    }
  }
`;

export const ListInformation = styled.div`
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${(props) => props.theme?.colors.text};
  padding-left: 2rem;
  margin-top: 0.8rem;
`;

export const PageImage = styled.div`
  width: 50%;
  img {
    width: 100%;
  }
  @media (max-width: 640px) {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;
