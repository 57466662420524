import styled from 'styled-components';

export const StyledForgotForm = styled.div`
  width: 100%;
  height: 100%;
  .heading_title {
    margin-bottom: 8px;
    color: #303030;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 29px;
  }
  .desc {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 28px;

    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    color: #303030;
  }
  .forgot-form {
    .shared-input {
      margin-bottom: 2.8rem;
    }
    .actions {
      display: flex;
      width: 100%;
      .submit_btn {
        margin-left: 0.8rem;
        width: calc((100% - 0.8rem) / 2);
      }
      .cancel_btn {
        width: calc((100% - 0.8rem) / 2);
      }
    }
  }

  @media (max-width: 640px) {
    .heading_title {
      font-weight: 700;
    }
    .desc {
      font-size: 1.2rem;
    }
  }
`;
