import axiosClient from './axiosClient';
import { ChangePasswordProps, CustomerRqBodyProps, RegisterSubscriptionProps } from '@interfaces';
const url = 'api/Customer';
interface CustomerParam {
  [key: string]: any;
}
export const customerApi = {
  updateProfile: (profile: CustomerRqBodyProps) => {
    return axiosClient.post(url, profile);
  },
  getProfile: () => {
    return axiosClient.get(url);
  },
  updateAvatar: (formData: FormData) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axiosClient.post(`${url}/uploadAvatar`, formData, config);
  },
  changePassword: (body: ChangePasswordProps) => {
    return axiosClient.post(`${url}/changePassword`, body);
  },
  changeSettingNotification: (isTurnOff: boolean) => {
    return axiosClient.put(
      `${url}?IsEmailNotificationEnable=${isTurnOff}&IsMobileAppNotificationEnable=${isTurnOff}`,
    );
  },
  getNotification: (params?: CustomerParam) => {
    return axiosClient.get(`${url}/getNotificationConfig`, { params });
  },
  subscribeSubscription: (params: RegisterSubscriptionProps) => {
    return axiosClient.post(`${url}/RegisterSubscription`, { params });
  },
  getSubscriptionStatus: () => {
    return axiosClient.get(`${url}/CheckSubscription`);
  },
  cancelSubscription: () => {
    return axiosClient.get(`${url}/CancelSubscription`);
  },
};
