import { Payment } from '@components';
import { PATH_HOME } from '@configs';
import { selectSubscription, useAppSelector } from '@redux';
import { Navigate } from 'react-router-dom';

export const SubscriptionOutlet = () => {
  const { subState } = useAppSelector(selectSubscription);

  return subState?.data?.success ? (
    <Navigate to={PATH_HOME} />
  ) : (
    <Payment paymentType="subscription" />
  );
};
