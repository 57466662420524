import styled from 'styled-components';

export const CategoriesStyled = styled.div`
  .title {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: ${(props) => props.theme.colors.text};
    margin-bottom: 6rem;
    @media (max-width: 640px) {
      margin-bottom: 0;
    }
  }

  .header-customer-line {
    margin: 2.4rem 0;
    width: 100%;
    height: 0.1rem;
    background-color: ${(props) => props.theme?.colors.gray['200']};
  }
`;

export const ListCategories = styled.div`
  @media (max-width: 640px) {
    display: block;
    margin-left: 9rem;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 11.7rem;
`;

export const ItemCategories = styled.div`
  @media (max-width: 640px) {
    width: 100%;
    margin-bottom: 2.4rem;
  }
  width: 25%;
  margin-bottom: 6rem;
  .name-categories {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: ${(props) => props.theme.colors.text};
    &:hover {
      color: ${(props) => props.theme.colors.primary};
      cursor: pointer;
    }
  }
  ul {
    list-style: none;
    padding-left: 3rem;
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      height: calc(100% - 1.1rem);
      border-right: 0.1rem solid ${(props) => props.theme?.colors.gray['300']};
    }
    li {
      position: relative;
      margin-top: 2rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      transition: 0.3s;
      color: ${(props) => props.theme.colors.text2};
      &:before {
        position: absolute;
        top: 50%;
        left: -3rem;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 0.1rem;
        width: 1rem;
        background-color: ${(props) => props.theme?.colors.gray['300']};
      }
      &: hover {
        color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
        padding-left: 1rem;
      }
    }
  }
`;
