import { ICon } from '@interfaces';

export const IconChevronRight = (props: ICon) => {
  const { onClick, width = 24, height = 24, className, color = '#000', strokeWidth = 2 } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <polyline
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        onClick={onClick}
        points="9 6 15 12 9 18"
      />
    </svg>
  );
};
