import { ForgotPasswordComponent } from '@components';
import { PATH_FORGOT_PASSWORD_SUCCESS, PATH_LOGIN } from '@configs';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { IForgotPassword, BaseResponseProps } from '@interfaces';
import { setLoading, useAppDispatch } from '@redux';
import { authAPI } from '@api';
import { toast } from 'react-toastify';

export const ForgotPasswordModule = () => {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email('入力した電子メールアドレスは無効です。もう一度やり直してください。')
        .required('メールアドレスが必要です'),
    }),
    onSubmit: (val) => {
      void handleRedirectToForgot(val);
    },
  });

  const navigate = useNavigate();

  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleRedirectToForgot = async (forgotForm: IForgotPassword) => {
    const body = {
      Email: encodeURI(forgotForm.email),
    };
    try {
      dispatch(setLoading(true));
      const rs = (await authAPI.forgotPassword(body)) as unknown as BaseResponseProps;
      if (!rs.success) {
        formik.setErrors({ email: rs.messages || '' });
      } else {
        navigate(PATH_FORGOT_PASSWORD_SUCCESS);
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      toast.error(
        <div className="success-message">
          {'いくつか問題が発生しました。もう一度お試しください'}
        </div>,
        {},
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  return <ForgotPasswordComponent formik={formik} onCancel={handleRedirectToLogin} />;
};
