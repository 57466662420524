import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HomeNodule } from '@modules';
import { CategoriesDataType, CategorySelected } from '@interfaces';
import {
  categorySelected,
  getCategoryId,
  resetCategoryId,
  RootState,
  fetchProductList,
  fetchBestSellerProduct,
  fetchCategories,
} from '@redux';

import { useNavigateParams } from '@hooks';
import { BOOK_LIST_PARAMS, CATEGORY_PARAMS, PATH_LISTING, PATH_PARENT_CATEGORY } from '@configs';
import { mapData } from '@utils';

interface NavigateParams {
  [key: string]: any;
}

export const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigateParams();
  const { product, category, auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const { recommendedBooks, bestSellerBooks, lastedBooks } = product.data;
  const [isLoadingImg, setIsLoadingImg] = useState(false);

  useEffect(() => {
    dispatch(fetchProductList({ isHighlightBooks: BOOK_LIST_PARAMS.isHighlightBooks }));
    dispatch(fetchProductList({ isLastestBooks: BOOK_LIST_PARAMS.isLastestBooks }));
    dispatch(fetchCategories());
    dispatch(fetchBestSellerProduct({ start: 0, length: 10 }));
  }, []);

  const onPageLoad = () => {
    setIsLoadingImg(false);
  };

  useEffect(() => {
    setIsLoadingImg(true);

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const newRecommendedBooks = mapData(recommendedBooks || []);
  const newBestSellerBooks = mapData(bestSellerBooks);
  const newLastestBooks = mapData(lastedBooks || []);

  const categories = category?.data?.categories?.map((data: CategoriesDataType, index) => {
    return {
      category_id: data.id,
      category_name: data.name,
      img_url: data.iconUrl,
      subCategories: data.subCategories,
      navigateTo: `${PATH_PARENT_CATEGORY}/${data.name}`,
    };
  });

  const handleNavigate = (params: NavigateParams) => {
    dispatch(resetCategoryId(null));
    navigate(PATH_LISTING, params);
  };

  const handleSelectSubMenu = (category: CategoriesDataType, selectedValue: any) => {
    const selectCategory: CategorySelected = {
      categories: category,
      idSubcategory: selectedValue.key,
    };
    dispatch(getCategoryId(Number(selectedValue.key)));
    dispatch(categorySelected(selectCategory));
    const subData = categories.find((item) =>
      item.subCategories.find((subData) => subData.id === Number(selectedValue.key)),
    );
    const subName = subData?.subCategories.find((item) => item.id === Number(selectedValue.key));
    dispatch(fetchProductList({ ...CATEGORY_PARAMS, categoryIds: Number(selectedValue.key) }));
    navigate(`${PATH_LISTING}/${subName?.seName}`);
  };

  return (
    <HomeNodule
      recommendedBooks={newRecommendedBooks}
      bestSellerBooks={newBestSellerBooks}
      lastestBooks={newLastestBooks}
      categories={categories}
      isLoadingImg={isLoadingImg}
      isLogin={accessToken || ''}
      onNavigateBestSeller={() => handleNavigate({ bestSeller: BOOK_LIST_PARAMS.isBestSeller })}
      onNavigateLatestBook={() =>
        handleNavigate({ isLatestBooks: BOOK_LIST_PARAMS.isLastestBooks })
      }
      onNavigateRecommendBook={() =>
        handleNavigate({ isHighlight: BOOK_LIST_PARAMS.isHighlightBooks })
      }
      onSelectSubMenu={handleSelectSubMenu}
    />
  );
};
