import styled from 'styled-components';
import { ICONS } from '@assets';
import { Link } from 'react-router-dom';
import { PATH_MY_BOOKS } from '@configs';

export const CheckoutComplete = () => {
  return (
    <StyledCheckoutComplete>
      <img src={ICONS.CheckoutSuccessIcon} alt="Checkout Successfully" />
      <div className="header">お支払い完了！</div>
      <div className="desc subtitle">ありがとうございました。</div>
      <div className="desc detail">
        電子領収書はご登録いただいたメールアドレスに送信されました。購入した書籍はライブラリでご確認ください。
      </div>
      <Link className="navigate-btn" to={PATH_MY_BOOKS}>
        ライブラリに移動
      </Link>
    </StyledCheckoutComplete>
  );
};

const StyledCheckoutComplete = styled.div`
  * {
    font-family: 'Noto Sans JP';
  }
  padding: 3.6rem 5rem 5.5rem;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 2rem;
  }
  .desc {
    font-weight: ${(p) => p.theme.weight['500']};
    font-size: 1.5rem;

    &.subtitle {
      margin-bottom: 1.25rem;
    }
    &.detail {
      color: #999999;
      max-width: 560px;
      text-align: center;
    }
  }
  .navigate-btn {
    margin-top: 2rem;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: ${(p) => p.theme.colors.primary};
    color: ${(p) => p.theme.colors.body};
    font-weight: bold;
  }
`;
