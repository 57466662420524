import { Breadcrumbs } from '@components';
import { useLocation } from 'react-router-dom';
import { RootState, useAppSelector } from '@redux';
import { PATH_DETAILS, PATH_HOME, PATH_LISTING, PATH_PARENT_CATEGORY } from '@configs';

export const BreadcrumbsModule = () => {
  const categorySelected = useAppSelector(
    (state: RootState) => state.category.data.categorySelected,
  );
  const location = useLocation();
  /*LogApp('location', location);*/
  const currentPath = location?.pathname;
  const listPaths = currentPath.slice(1).split('/');
  const path = window.location.href.split('/').at(-1);
  let pathCheck: string | null = null;
  if (currentPath?.startsWith(`${PATH_LISTING}/`)) {
    pathCheck = 'category';
  }
  if (currentPath?.startsWith(`${PATH_DETAILS}`)) {
    pathCheck = 'detail';
  }
  if (
    path?.startsWith(`${PATH_LISTING.replace('/', '')}?search`) ||
    path?.startsWith(PATH_LISTING.replace('/', ''))
  ) {
    pathCheck = 'search';
  }
  if (currentPath?.startsWith(PATH_PARENT_CATEGORY)) {
    pathCheck = 'parent-category';
  }
  const breadcrumbData = listPaths.reduce((prev: any, value: string, index: number) => {
    /*LogApp('check', prev, prev[index - 1]);*/
    const path = prev[index - 1]?.path
      ? prev[index - 1].path.concat('/').concat(value)
      : '/' + value;
    if (pathCheck === 'detail') {
      return [
        { label: 'bookstore', path: PATH_HOME },
        { label: 'details', path: path },
      ];
    }
    if (pathCheck === 'search') {
      return [{ label: 'search', path: null }];
    }
    if (pathCheck === 'category') {
      const subCategory = categorySelected?.categories?.subCategories.find(
        (value) => Number(categorySelected.idSubcategory) === value.id,
      );
      return [
        {
          label:
            categorySelected?.categories?.category_name || categorySelected?.categories?.name || '',
          path:
            `${PATH_PARENT_CATEGORY}/${categorySelected?.categories?.name}` ||
            `${PATH_PARENT_CATEGORY}/${categorySelected?.categories?.category_name}`,
        },
        { label: subCategory?.name, path: path },
      ];
    } else {
      return [...prev, { label: pathCheck ? pathCheck : value, path: path }];
    }
  }, []);
  /*LogApp('location2', breadcrumbData);*/
  return <Breadcrumbs data={breadcrumbData} />;
};
