// import { Document, Page } from 'react-pdf';
//@ts-ignore
// import pdf from './test.pdf';
import React, { forwardRef, useState } from 'react';

import { IConArrowLeft, IConArrowRight, IConCancel } from '../Icon';
import {
  ContentBook,
  ContentBookWrapper,
  FooterBook,
  InnerContent,
  NameBook,
  NumberPage,
  StyledReadBook,
  ToolBarDesktop,
} from './style';
import { CustomDrawer } from '../Drawer';
import { SharedButton, ShareInput, TextContentViewer } from '../shared';
import { selectApp, useAppSelector } from '@redux';

interface IProps {
  title?: string;
  orientation?: number;
  fileUrl?: string;
  pageOne?: any;
  pageTwo?: any;
  bookDefaultContent?: string;
  speakCharIndex?: number;
  speakCharLength?: number;
  topActions?: JSX.Element | JSX.Element[];
  settingContent?: JSX.Element | JSX.Element[];
  currentPage: number;
  openSettingSpeak: boolean;
  totalPage: number;
  scaleInner?: number;
  positionX?: string | number;
  positionY?: string | number;
  isVisible?: boolean;
  bookCoverImage?: string;
  bookCoverImageTwo?: string;
  isSpeaking?: boolean;
  isTextBooks?: boolean;
  pageIndex: number;
  onPressEnter?: (e: any) => void;
  onCancelReadBook: () => void;
  onCloseSpeakSetting: () => void;
  onChangePage: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onClickPrev: () => void;
  onClickNext: () => void;
  onDocumentLoadSuccess?: (value: any) => void;
}

export const DesktopReadBook = forwardRef((props: IProps, ref: React.Ref<any>) => {
  const {
    title,
    orientation,
    pageOne,
    pageTwo,
    bookCoverImage,
    bookCoverImageTwo,
    topActions,
    speakCharIndex,
    speakCharLength,
    isSpeaking,
    onChangePage,
    onClickPrev,
    onClickNext,
    currentPage,
    totalPage,
    bookDefaultContent,
    onPressEnter,
    onCancelReadBook,
    scaleInner,
    positionX,
    positionY,
    isVisible,
    isTextBooks,
    openSettingSpeak,
    settingContent,
    pageIndex,
    onCloseSpeakSetting,
  } = props;

  const { textBookFont } = useAppSelector(selectApp);

  return (
    <StyledReadBook
      $orientation={orientation}
      $scaleInner={scaleInner}
      $firstPage={pageIndex === 0}
      $textBookFont={textBookFont}
    >
      <div className="icon-style" onClick={onCancelReadBook}>
        <IConCancel />
      </div>
      <ContentBookWrapper $orientation={orientation}>
        {currentPage > 1 && (
          <SharedButton
            sufIcon={<IConArrowLeft color="#ffffff" width={14} height={14} />}
            onClick={onClickPrev}
            className="read-book-btn pre-btn"
            textClassName="btn-text__color"
          />
        )}

        {(isTextBooks
          ? currentPage < totalPage
          : (currentPage % 2 === 0 && currentPage < totalPage) ||
            (currentPage % 2 === 1 && currentPage + 1 < totalPage)) && (
          <SharedButton
            sufIcon={<IConArrowRight color="#ffffff" width={14} height={14} />}
            onClick={onClickNext}
            className="read-book-btn next-btn"
            textClassName="btn-text__color"
          />
        )}
        <ContentBook>
          <ToolBarDesktop>{topActions}</ToolBarDesktop>
          <div className="preview-book" id="preview-book">
            {!bookDefaultContent ? (
              <>
                <div className="content first-content" id="first-page">
                  <InnerContent
                    ref={ref}
                    id="inner-first-page"
                    className="inner"
                    dangerouslySetInnerHTML={{ __html: pageOne }}
                    scaleInner={scaleInner}
                    positionX={`${positionX}px`}
                    positionY={`${positionY}px`}
                    $haveBookCoverImage={!!bookCoverImage}
                  />
                  {bookCoverImage && (
                    <div className="cover-section">
                      <img className="cover-img" src={bookCoverImage} alt="" />
                    </div>
                  )}
                </div>
                <div className="content second-content" id="second-page">
                  <InnerContent
                    id="inner-second-page"
                    className="inner"
                    dangerouslySetInnerHTML={{ __html: pageTwo }}
                    scaleInner={scaleInner}
                    positionX={`${positionX}px`}
                    positionY={`${positionY}px`}
                    $haveBookCoverImage={!!bookCoverImageTwo}
                    //   style={{
                    //     scale: `${scaleInner}`,
                    //     transform: `translateX(${translationInner || '6rem'})`,
                    //   }}
                  />
                  {bookCoverImageTwo && (
                    <div className="cover-section">
                      <img className="cover-img" src={bookCoverImageTwo} alt="" />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className="content text-book__content">
                <div className="inner text-default" id="txt-book-box">
                  {/* {bookDefaultContent} */}
                  {/* @ts-ignore */}

                  <TextContentViewer
                    text={bookDefaultContent}
                    highLightIndex={speakCharIndex}
                    highLightLength={speakCharLength}
                    isSpeaking={isSpeaking}
                  />
                </div>
              </div>
            )}
          </div>

          <FooterBook $ísTextBookDefault={!!bookDefaultContent}>
            <NumberPage>
              <div className="current-page">
                <ShareInput
                  className="current-page-search"
                  onChange={onChangePage}
                  defaultValue={currentPage}
                  key={`currentPage-${currentPage}`}
                  type="number"
                  max={totalPage}
                  min={1}
                  onPressEnter={onPressEnter}
                />
              </div>
              <div className="total-page">の{totalPage}</div>
            </NumberPage>
            <NameBook>{title}</NameBook>
          </FooterBook>
        </ContentBook>
      </ContentBookWrapper>

      <CustomDrawer
        className="drawer-read-book-desktop"
        open={openSettingSpeak}
        placement={'left'}
        onClose={onCloseSpeakSetting}
      >
        {settingContent}
        {/* <SettingVoice closeDrawer={changeClose} /> */}
      </CustomDrawer>
    </StyledReadBook>
  );
});
