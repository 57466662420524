import { createAsyncThunk } from '@reduxjs/toolkit';
import { commonApi, productApi } from '@api';

const prefix = 'my-books';

export const fetchCategoriesSelect = createAsyncThunk<any>(
  `${prefix}/fetchCategoriesSelect`,
  async (params, { rejectWithValue }) => {
    try {
      return await commonApi.getCategorySelect();
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchAuthorSelect = createAsyncThunk<any>(
  `${prefix}/fetchAuthorSelect`,
  async (params, { rejectWithValue }) => {
    try {
      return await commonApi.getAuthorSelect();
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
