import { IGetBookData, IGetContentBookData } from '@interfaces';
import axiosClient from './axiosClient';

const url = 'api/Download/sample';
const bookUrl = 'Book/GetProductBinaries';
const readBookContentUrl = 'api/Product/readcontentbook';

export const readBookApi = {
  getReadBookSample: (productId: number | string) => {
    return axiosClient.get(`${url}/${productId}`);
  },

  getReadBook: (productId: number | string) => {
    return axiosClient.get(`${url}/${productId}`);
  },

  getBookData: (params: IGetBookData) => {
    return axiosClient.get(bookUrl, { params });
  },

  getReadBookContent: (params: IGetContentBookData) => {
    return axiosClient.get(readBookContentUrl, { params });
  },
};
