import {
  enumLeftSidebar,
  PATH_CART_NOT_EMPTY,
  PATH_CHECKOUT,
  PATH_CHECKOUT_COMPLETE,
  PATH_DETAIL,
  PATH_FORGOT_PASSWORD_SUCCESS,
  PATH_HOME_READY,
  PATH_ITEM,
  PATH_PARENT_CATEGORY,
  PATH_POLICY,
  PATH_REGISTER_SUCCESS,
  PATH_SET_PASSWORD,
  PATH_SIGN_UP_SUCCESS,
  PATH_TERM,
} from '@configs';
import { AppLayout, LayoutError } from '@layouts';
import { IRoute } from '@interfaces';
import {
  BookDetailsPage,
  BookRequest,
  Cart,
  ChangePasswordPage,
  ChangeProfilePage,
  Customer,
  ForgotPasswordPage,
  ForgotPasswordSuccess,
  HomePage,
  ListingPage,
  LoginPage,
  MyPreview,
  NotificationSettingPage,
  PageError404,
  PageIntroduction,
  PurchaseHistory,
  ReadBookPage,
  ReadPage,
  RegisterPage,
  SetPassWord,
  SignupSuccess,
  SpecialCommer,
  SubscribeRank,
  Subscription,
  UserAccountActivation,
  WishList,
  SubscriptionOutlet,
  CheckoutComplete,
  CompanyProfile,
  Categories,
  ParentCategory,
} from '@pages';
import {
  PATH_404,
  PATH_BOOK_REQUEST,
  PATH_CART,
  PATH_CATEGORIES,
  PATH_CHANGE_PASSWORD,
  PATH_COMPANY_PROFILE,
  PATH_DETAIL_READ_BOOK,
  PATH_DETAILS,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_INTRODUCTION,
  PATH_LISTING,
  PATH_LOGIN,
  PATH_MAIN,
  PATH_MY_BOOKS,
  PATH_MY_REVIEW,
  PATH_NOTIFY_SETTINGS,
  PATH_PARENT_CATEGORY_DETAIL,
  PATH_PROFILE,
  PATH_PURCHASE_HISTORY,
  PATH_READ,
  PATH_SIGN_UP,
  PATH_SPECIAL_COMMER,
  PATH_SUBSCRIBER_RANK,
  PATH_SUBSCRIPTION,
  PATH_WISH_LIST,
} from './navigation';
import { Policy, Term } from '@modules';

export const routes: Array<IRoute> = [
  //home
  { path: PATH_HOME, page: HomePage, layout: AppLayout },
  { path: PATH_INTRODUCTION, page: PageIntroduction },
  { path: PATH_LOGIN, page: LoginPage },
  { path: PATH_READ, page: ReadPage },
  {
    path: PATH_LISTING,
    page: ListingPage,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.CATEGORY,
    breadcrumbs: true,
  },
  {
    path: PATH_ITEM,
    page: ListingPage,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.CATEGORY,
    breadcrumbs: true,
  },
  {
    path: PATH_DETAILS,
    page: BookDetailsPage,
    layout: AppLayout,
    breadcrumbs: true,
  },
  {
    path: PATH_DETAIL,
    page: BookDetailsPage,
    layout: AppLayout,
    breadcrumbs: true,
  },
  { path: PATH_SIGN_UP, page: RegisterPage },
  { path: PATH_FORGOT_PASSWORD, page: ForgotPasswordPage },
  { path: PATH_SUBSCRIBER_RANK, page: SubscribeRank, layout: AppLayout, breadcrumbs: true },
  { path: PATH_CART, page: Cart, layout: AppLayout, breadcrumbs: true },
  { path: PATH_SUBSCRIPTION, page: Subscription, layout: AppLayout },
  { path: PATH_SPECIAL_COMMER, page: SpecialCommer, layout: AppLayout, breadcrumbs: true },
  { path: PATH_COMPANY_PROFILE, page: CompanyProfile, layout: AppLayout, breadcrumbs: true },
  { path: PATH_POLICY, page: Policy, layout: AppLayout, breadcrumbs: true },
  { path: PATH_TERM, page: Term, layout: AppLayout, breadcrumbs: true },
  { path: PATH_CATEGORIES, page: Categories, layout: AppLayout, breadcrumbs: true },
  { path: PATH_DETAIL_READ_BOOK, page: ReadBookPage },
  { path: PATH_CHECKOUT, page: SubscriptionOutlet, layout: AppLayout },
  { path: PATH_CHECKOUT_COMPLETE, page: CheckoutComplete, layout: AppLayout, breadcrumbs: true },
  {
    path: PATH_BOOK_REQUEST,
    page: BookRequest,
    layout: AppLayout,
    breadcrumbs: true,
    leftSidebar: enumLeftSidebar.USER_MENU,
  },
  {
    path: PATH_WISH_LIST,
    page: WishList,
    layout: AppLayout,
    breadcrumbs: true,
    leftSidebar: enumLeftSidebar.USER_MENU,
  },
  {
    path: PATH_MY_REVIEW,
    page: MyPreview,
    layout: AppLayout,
    breadcrumbs: true,
    leftSidebar: enumLeftSidebar.USER_MENU,
  },
  {
    path: PATH_PURCHASE_HISTORY,
    page: PurchaseHistory,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.USER_MENU,
    breadcrumbs: true,
  },
  {
    path: PATH_PROFILE,
    page: ChangeProfilePage,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.USER_MENU,
    breadcrumbs: true,
  },
  {
    path: PATH_CHANGE_PASSWORD,
    page: ChangePasswordPage,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.USER_MENU,
    breadcrumbs: true,
  },
  {
    path: PATH_NOTIFY_SETTINGS,
    page: NotificationSettingPage,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.USER_MENU,
    breadcrumbs: true,
  },
  {
    path: PATH_MY_BOOKS,
    page: Customer,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.USER_MENU,
    breadcrumbs: true,
  },
  {
    path: PATH_PARENT_CATEGORY,
    page: ParentCategory,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.CATEGORY,
    breadcrumbs: true,
  },
  {
    path: PATH_PARENT_CATEGORY_DETAIL,
    page: ParentCategory,
    layout: AppLayout,
    leftSidebar: enumLeftSidebar.CATEGORY,
    breadcrumbs: true,
  },
  { path: PATH_MAIN, page: HomePage, layout: AppLayout },
  { path: PATH_SIGN_UP_SUCCESS, page: SignupSuccess },
  { path: PATH_FORGOT_PASSWORD_SUCCESS, page: ForgotPasswordSuccess },
  { path: PATH_HOME_READY, page: UserAccountActivation },
  { path: PATH_REGISTER_SUCCESS, page: UserAccountActivation },
  { path: PATH_SET_PASSWORD, page: SetPassWord },
  { path: PATH_404, page: PageError404, layout: LayoutError },
  { page: PageError404, layout: LayoutError },
];
export const normalRoutes = [PATH_HOME];

export const authRoutes = [];
