import { combineReducers } from 'redux';
import { AnyAction, Reducer } from '@reduxjs/toolkit';

import toastReducer from './toast';
import appReducer from './app';
import authReducer from './authSlice';
import sidebarReducer from './sidebarSlice';
import productsReducer from './productSlice';
import categoryReducer from './categorySlice';
import { customerReducer } from './customerSlice';
import stepReducer from './stepSlice';
import { cartReducer } from './cartSlice';
import { bookReducer } from './bookSlice';
import { bookRequestReducer } from './bookRequestSlice';
import { breadcrumbReducer } from './breadcrumbSlice';
import { subscriptionReducer } from './subscriptionRankSlice';
import { bookReviewReducer } from './bookReviewSlice';
import { commonReducer } from './commonSlice';
import { socialAuthReducer } from './socialAuthSlice';
import { purchaseHistoryReducer } from './purchaseHistorySlice';
import { deleteProductReducer } from './deleteProductSlice';
import { productSubscriptionReducer } from './subscriptionSlice';
import { checkoutReducer } from './checkoutSlice';

export * from './toast';
export * from './app';
export * from './authSlice';
export * from './sidebarSlice';
export * from './customerSlice';
export * from './stepSlice';
export * from './cartSlice';
export * from './bookSlice';
export * from './categorySlice';
export * from './bookRequestSlice';
export * from './productSlice';
export * from './subscriptionRankSlice';
export * from './breadcrumbSlice';
export * from './bookReviewSlice';
export * from './commonSlice';
export * from './purchaseHistorySlice';
export * from './deleteProductSlice';
export * from './subscriptionSlice';
export * from './checkoutSlice';
export * from './socialAuthSlice';
const productReducer = combineReducers({
  toast: toastReducer,
  app: appReducer,
  auth: authReducer,
  sidebar: sidebarReducer,
  product: productsReducer,
  category: categoryReducer,
  step: stepReducer,
  customer: customerReducer,
  cart: cartReducer,
  book: bookReducer,
  bookRequest: bookRequestReducer,
  subscriptionRank: subscriptionReducer,
  breadcrumb: breadcrumbReducer,
  bookReview: bookReviewReducer,
  common: commonReducer,
  purchaseHistory: purchaseHistoryReducer,
  deleteCart: deleteProductReducer,
  checkout: checkoutReducer,
  subscription: productSubscriptionReducer,
  socialAuth: socialAuthReducer,
});

export type RootState = ReturnType<typeof productReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'RESET') {
    // reset state
    state = {} as RootState;
    // reset local storage
    localStorage.clear();
  }
  return productReducer(state, action);
};
export default rootReducer;
