import { TEXT_HIGHLIGHT_CLASS_NAME, TEXT_HIGHLIGHT_COLOR } from '@configs';
import { decodeEntities } from './convertHTML';
import { LogApp } from './utilities';

export const insertHighlightTextSpeaking = (speakingText: any) => {
  const svgns = 'http://www.w3.org/2000/svg';
  // const bounds = speakingText?.getBoundingClientRect();
  const bounds = speakingText?.getBBox();
  const bg = document.createElementNS(svgns, 'rect');
  const style: any = getComputedStyle(speakingText);
  LogApp('SVG_BOUNDS', bounds);
  const padding_top = !isNaN(style['padding-top']) ? Number(style['padding-top']) : 0;
  const padding_left = !isNaN(style['padding-left']) ? Number(style['padding-top']) : 0;
  const padding_right = !isNaN(style['padding-right']) ? Number(style['padding-top']) : 0;
  const padding_bottom = !isNaN(style['padding-bottom']) ? Number(style['padding-top']) : 0;
  LogApp('SVG_CHECK', { padding_top, padding_left });
  bg.setAttribute('x', String(bounds.x - padding_left));
  bg.setAttribute('y', String(bounds.y - padding_top));
  bg.setAttribute('width', String(bounds.width + padding_left + padding_right));
  bg.setAttribute('height', String(bounds.height + padding_top + padding_bottom));
  bg.setAttribute('fill', TEXT_HIGHLIGHT_COLOR);
  bg.setAttribute('rx', style['border-radius']);
  bg.setAttribute('stroke-width', style['border-top-width']);
  bg.setAttribute('stroke', style['border-top-color']);
  if (speakingText.hasAttribute('transform')) {
    bg.setAttribute('transform', speakingText.getAttribute('transform') || '');
  }
  bg.setAttribute('class', TEXT_HIGHLIGHT_CLASS_NAME);
  speakingText?.parentNode?.insertBefore(bg, speakingText);
};

export const getPlainText = (pageOneData: string, pageTwoData?: string) => {
  /* Remove all attributes from <text> tags */
  const textRemovedAttrPageOne = pageOneData?.replace(/<\s*text.*?>/g, '<text>');
  /* Get all text from <text> tag */
  const pageOneTextsList = textRemovedAttrPageOne
    ?.match(/<text>(.*?)<\/text>/g)
    ?.map((val) => val.replace(/<\/?text>/g, ''));
  const textPlainPageOne = pageOneTextsList?.join('') || '';
  let textPlainPageTwo = '';
  if (pageTwoData) {
    const textRemovedAttrPageTwo = pageOneData?.replace(/<\s*text.*?>/g, '<text>');
    const pageTwoTextsList = textRemovedAttrPageTwo
      ?.match(/<text>(.*?)<\/text>/g)
      ?.map((val) => val.replace(/<\/?text>/g, ''));
    textPlainPageTwo = pageTwoTextsList?.join('') || '';
  }
  const allPlainText = decodeEntities(textPlainPageOne) + decodeEntities(textPlainPageTwo);
  return allPlainText;
};

export const centerBookContent = (element: any, parElement?: any, scale?: number) => {
  const PADDING_HOZ = 32;
  const PADDING_VER = 56;
  const screenWidth = screen.width;
  const bounds = element?.getBoundingClientRect();
  const SVGBounds = element && element?.getBBox();
  const elementX = bounds?.x || 0;
  const elementWidth = bounds?.width;
  const elementHeight = bounds?.height;
  const valueCenterX = (screenWidth - elementWidth) / 2;
  // element?.setAttribute(
  //   'style',
  //   `transform: scale(${
  //     (screenWidth - PADDING_HOZ) / elementWidth
  //   }) translateX(${valueCenterX - elementX}px);`
  // );
  const elementInSvgTop = SVGBounds?.x;
  LogApp('element poi', { elementInSvgTop, elementHeight });
  element?.setAttribute(
    'style',
    `transform: translateX(${(valueCenterX - elementX) / (scale || 1)}px);`,
  );
  parElement?.setAttribute(
    'style',
    `min-height: ${elementHeight + elementInSvgTop + PADDING_VER}px;`,
  );
};

export const centerBookContentInDesktop = (element: any, parElement?: any, scale?: number) => {
  const eleBounds = element?.getBoundingClientRect();
  const parElementBounds = parElement?.getBoundingClientRect();
  /* 
    Note: 
    Case: element 1 nam trong parElement
     -> expect X: X = Xpar + (Wpar - We)/2
        translateX: X - Xe 
  */
  // const xSpace =
  //   parElementBounds?.width > eleBounds?.width && (parElementBounds?.width - eleBounds?.width) / 2;
  const xSpace =
    parElementBounds?.width > eleBounds?.width &&
    parElementBounds?.x - eleBounds?.x + (parElementBounds?.width - eleBounds?.width) / 2;
  const ySpace =
    parElementBounds?.height > eleBounds?.height &&
    (parElementBounds?.height - eleBounds?.height) / 2;
  if (eleBounds && parElementBounds) {
    LogApp('svg book content', { eleBounds, parElementBounds });
    element?.setAttribute(
      'style',
      `transform: translate(${(xSpace || 0) / (scale || 1)}px, ${(ySpace || 0) / (scale || 1)}px);`,
    );
  }
  // parElement?.setAttribute(
  //   'style',
  //   `min-height: ${elementHeight + elementInSvgTop + PADDING_VER}px;`,
  // );
};
