import { SelectProps } from '@interfaces';

export const monthSelect: SelectProps[] = [
  {
    label: '1',
    value: '1',
    id: '1',
  },
  {
    label: '2',
    value: '2',
    id: '2',
  },
  {
    label: '3',
    value: '3',
    id: '3',
  },
  {
    label: '4',
    value: '4',
    id: '4',
  },
  {
    label: '5',
    value: '5',
    id: '5',
  },
  {
    label: '6',
    value: '6',
    id: '6',
  },
  {
    label: '7',
    value: '7',
    id: '7',
  },
  {
    label: '8',
    value: '8',
    id: '8',
  },
  {
    label: '9',
    value: '9',
    id: '9',
  },
  {
    label: '10',
    value: '10',
    id: '10',
  },
  {
    label: '11',
    value: '11',
    id: '11',
  },
  {
    label: '12',
    value: '12',
    id: '12',
  },
];

const currentYear = Number(new Date().getFullYear());

export const yearSelect: SelectProps[] = Array.from(Array(10).keys()).map((e, i) => ({
  label: e + currentYear + '',
  id: e + currentYear + '',
  value: e + currentYear + '',
}));
