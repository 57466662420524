import styled from 'styled-components';
import { Button, Divider } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { SharedCheckbox } from '@components';
import { changeSettingNotification, getSettingNotification, selectCustomer } from '@redux';
import { useDispatch, useSelector } from 'react-redux';

export const NotificationSettingPage = () => {
  const [notification, setNotification] = useState<boolean>(true);
  const dispatch = useDispatch();
  const customerSelector = useSelector(selectCustomer);
  const { isLoading, notificationCustomer } = customerSelector;
  useEffect(() => {
    dispatch(getSettingNotification({}));
  }, []);
  useEffect(() => {
    setNotification(notificationCustomer.isMobileAppNotificationEnable);
  }, [notificationCustomer]);

  const handleToggleNotification = debounce(() => {
    setNotification(!notification);
  }, 300);
  const handleSubmitSetting = () => {
    dispatch(changeSettingNotification(notification));
  };
  return (
    <StyledChangeProfileSection className="rg-sec notify-section">
      <h1 className="title">通知設定</h1>
      <Divider />
      <div onSubmit={() => {}} className="content notify-setting">
        <code>{notification}</code>
        <SharedCheckbox
          className="notification-cb"
          size="2.4rem"
          id="agreePrivacy"
          name="agreePrivacy"
          type="checkbox"
          onChange={handleToggleNotification}
          text="プッシュ通知を有効にする"
          checked={notification}
        />
        {/* <SharedButton
          typeHtml="submit"
          text="更新"
          className="submit_btn"
          btnStyle="basic"
          bgColor="#D42A1C"
          onClick={handleSubmitSetting}
        />*/}
        <Button
          className="button-with-loading submit_btn"
          loading={isLoading}
          onClick={handleSubmitSetting}
        >
          更新
        </Button>
      </div>
    </StyledChangeProfileSection>
  );
};

const StyledChangeProfileSection = styled.div`
  margin-top: -5.4rem;
  width: 100%;
  .title {
    @media (max-width: 640px) {
      font-size: 2rem;
    }
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: #303030;
  }
  .content {
    width: 100%;
    margin-bottom: 10rem;
    .notification-cb {
      @media (max-width: 640px) {
        margin-bottom: 4rem;
      }
      margin-bottom: 4.8rem;

      .ant-checkbox-checked .ant-checkbox-inner::after {
        margin: 0 2px;
      }

      .checkbox-label {
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        line-height: 2.9rem;
        color: #303030;
      }
    }
    .submit_btn {
      background-color: ${(props) => props.theme.colors.primary};
      * {
        font-weight: ${(props) => props.theme.weight['700']};
        color: ${(props) => props.theme.colors.body};
        @media (max-width: 640px) {
          font-weight: ${(props) => props.theme.weight['500']};
          font-size: 1.2rem;
        }
      }
      max-width: 15rem;
    }
  }
`;
