import { SettingIcon } from 'src/components/Icon/NewSetting';
import { IMAGES } from '@assets';
import {
  BookRequestIcon,
  HeartIcon,
  MyBookIcon,
  OrderIcon,
  ReviewIcon,
  SettingBookIcon,
} from '../components/Icon';
import {
  PATH_CHANGE_PASSWORD,
  PATH_NOTIFY_SETTINGS,
  PATH_PROFILE,
  PATH_PURCHASE_HISTORY,
  PATH_MY_BOOKS,
  PATH_LOGIN,
} from '../routes/navigation';
import {
  PATH_BOOK_REQUEST,
  PATH_MY_REVIEW,
  PATH_SUBSCRIBER_RANK,
  PATH_WISH_LIST,
} from './../routes/navigation';
import { enumTextBookFont } from './enum';

export const DEFAULT_LANGUAGE = process.env.REACT_APP_LANGUAGE || 'jp';
export const LINE_AUTH_BASE_URL = 'https://access.line.me/oauth2/v2.1/authorize?';
export const LINE_GET_TOKEN_URL = 'https://api.line.me/oauth2/v2.1/token';
export const LINE_REDIRECT_URI = 'https://bookskozuchi.com/login';
export const LINE_MAX_AGE_SECONDS = 120;
export const ALL_THEMES = 'themes';
export const CURRENT_THEME = 'theme';
export const DEFAULT_SPEECH_LANG = 'jp';
export const DEFAULT_CATEGORY_ITEM_HEIGH = '3rem';
export const DEFAULT_MOBILE_CATEGORY_ITEM_HEIGH = '2.2rem';
export const PAGE_LENGTH = 12;
export const STYLE_STEP = {
  maxWidth: '447px',
  fontsize: '14px!important',
};
export const STYLE_BTN_CONTINUE = {
  width: '175px',
  height: '41px',
  background: '#D42A1C',
  borderRadius: '4px',
  lineHeight: '17px',
  fontWeight: '500',
  borderColor: '#D42A1C',
};
export const STYLE_BTN_CONTINUE_PAYMENT = {
  width: '253PX',
  height: '41px',
  background: '#D42A1C',
  borderRadius: '4px',
  lineHeight: '17px',
  fontWeight: '500',
  borderColor: '#D42A1C',
};
export const OPTIONS_SELECT_INPUT = [
  {
    id: 1,
    title: 'option1',
    value: 'option1',
  },
  {
    id: 2,
    title: 'option2',
    value: 'option2',
  },
  {
    id: 3,
    title: 'option3',
    value: 'option3',
  },
];
export const ITEMS_STEP = [
  {
    title: 'ステップ１：購入',
  },
  {
    title: 'ステップ２：確認',
  },
  {
    title: 'ステップ３：支払い',
  },
];

export const USER_MENU_SIDE_BAR = [
  {
    id: 0,
    avatar: IMAGES.imageTest,
    userName: 'user_name',
    userMail: 'user_mail@gmail.com',
    path: PATH_PROFILE,
    title: '',
    icon: MyBookIcon,
    isUserInfo: true,
  },
  {
    id: 1,
    icon: MyBookIcon,
    title: 'マイブック',
    path: PATH_MY_BOOKS,
    userName: '',
  },
  {
    id: 2,
    icon: SettingBookIcon,
    title: 'サブスクリプション',
    path: PATH_SUBSCRIBER_RANK,
  },
  {
    id: 3,
    icon: HeartIcon,
    title: 'ウィッシュリスト',
    path: PATH_WISH_LIST,
  },
  {
    id: 4,
    icon: OrderIcon,
    title: '購入履歴',
    path: PATH_PURCHASE_HISTORY,
  },
  {
    id: 5,
    icon: ReviewIcon,
    title: 'マイレビュー',
    path: PATH_MY_REVIEW,
  },
  {
    id: 6,
    icon: BookRequestIcon,
    title: 'マイリクエスト',
    path: PATH_BOOK_REQUEST,
  },
  {
    id: 7,
    icon: SettingBookIcon,
    title: '一般設定',
    subTitles: [
      {
        id: 8,
        title: '個人情報',
        path: PATH_PROFILE,
      },
      {
        id: 9,
        title: 'パスワードの変更',
        path: PATH_CHANGE_PASSWORD,
      },
      {
        id: 10,
        title: '通知設定',
        path: PATH_NOTIFY_SETTINGS,
      },
    ],
  },
  {
    id: 11,
    icon: BookRequestIcon,
    title: 'ログアウト',
    path: PATH_LOGIN,
    isLogout: true,
  },
];

export const categories = [
  {
    category_id: 1,
    img_url: IMAGES.imageCategoryItem,
    category_name: '文豪KOZUCHI',
    lists: [
      {
        list_id: 1,
        list_text: '文豪KOZUCHI',
      },
      {
        list_id: 2,
        list_text: '文豪KOZUCHI',
      },
    ],
  },
  {
    category_id: 2,
    img_url: IMAGES.humanSocial,
    category_name: '人文社会',
    lists: [],
  },
  {
    category_id: 3,
    img_url: IMAGES.hobbies,
    category_name: '趣味・生活全般',
    lists: [],
  },
  {
    category_id: 4,
    img_url: IMAGES.pratical,
    category_name: '実用・ビジネス',
    lists: [],
  },
  {
    category_id: 5,
    img_url: IMAGES.science,
    category_name: '理工・環境',
    lists: [],
  },
  {
    category_id: 6,
    img_url: IMAGES.health,
    category_name: '健康・医療',
    lists: [],
  },
  {
    category_id: 7,
    img_url: IMAGES.scale,
    category_name: '政治・経済・法律',
    lists: [],
  },
  {
    category_id: 8,
    img_url: IMAGES.bookshelf,
    category_name: 'アート・建築・デザイン',
    lists: [],
  },
];

export const FOOTER_PRIVACY_LABELS = ['会社概要', '特商法・表記', '利用規約', '個人情報保護方針'];
export const PATH_FOOTER_PRIVACY = ['/company-profile', '/special-commer', '/term', '/policy'];

export const BREAK_POINTS = {
  320: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 5,
  },
  // when window width is >= 480px
  480: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 10,
  },
  // when window width is >= 640px
  640: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 10,
  },
  768: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 12,
  },
  1024: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
  },
};

export const PHONE_NUMBER_LENGTH_MAX = 15;
export const PHONE_NUMBER_LENGTH_MIN = 10;

export const BOOKS_AND_AUTHORS_PARAMS = {
  bookOrAuthorName: null,
  start: 0,
  length: 12,
};

export const BOOK_LIST_PARAMS = {
  isHighlightBooks: true,
  isLastestBooks: true,
  isBestSeller: true,
};

export const ALL_BOOKS_PARAMS = {
  start: 0,
  length: 12,
};

export const SEARCH_PATH = {
  // isHighlightPath: '?isHighlight=true',
  // isLatestPath: '?isLatestBooks=true',
  // isBestSellerPath: '?bestSeller=true',
  typeSearch: '?search',
  // emptySearchPath: '',
  // isFilterNone: '?filter=none',
  // allTerm: '?term=all',
};

export const KEY_PATH = {
  isHighlight: 'isHighlight',
  isBestSeller: 'bestSeller',
  isLatestBooks: 'isLatestBooks',
  isFilterNone: 'filter',
  priceRange: {
    priceMin: 'priceMin',
    priceMax: 'priceMax',
  },
  isAudioReading: 'isAudioReading',
  sortByPrice: 'sortByPrice',
  orderByName: 'orderByName',
  sortByOtherCategories: 'sortByOtherCategories',
  isSearch: 'search',
};

export const DISPATCH_BOOK_PARAMS = {
  highLightBook: {
    isHighlightBooks: BOOK_LIST_PARAMS.isHighlightBooks,
    start: 0,
    length: 12,
  },
  latestBook: {
    isLastestBooks: BOOK_LIST_PARAMS.isLastestBooks,
    start: 0,
    length: 12,
  },
  bestSellerBooks: {
    isBestSeller: BOOK_LIST_PARAMS.isBestSeller,
    start: 0,
    length: 12,
  },
};

export const DATA_SPECIAL_COMMER_1 = [
  {
    name: '店舗情報店舗運営者',
    des: '三和書籍有限会社',
  },
  {
    name: '店舗運営責任者',
    des: '高橋 考',
  },
  {
    name: '店舗セキュリティ責任者',
    des: '高橋 考',
  },
  {
    name: '店舗運営者住所',
    des: '〒112-0013 東京都文京区音羽二丁目2番2号',
  },
  {
    name: 'TEL',
    des: '03-5395-4630',
  },
  {
    name: 'メールアドレス',
    des: 'info@sanwa-co.com',
  },
];

export const DATA_SPECIAL_COMMER_2 = [
  {
    name: '販売価格',
    des: '商品ごとに表示された税込み価格に基づきます。※キャンペーン等により、価格が変更になることがあります。',
  },
  {
    name: '商品代金以外の費用',
    des: 'インターネットへの接続に必要な費用及び通信料金はお客様にご負担いただきます（送料がかかりません）。',
  },
  {
    name: 'お支払い方法',
    des: 'クレジットカード（VISA・MASTER・JCB・DINERS・AMEX）',
  },
  {
    name: 'お支払い時期',
    des: 'クレジットカードを利用した場合、カード会社の締め日に準じます。',
  },
  {
    name: '提供時期',
    des: 'お支払手続の完了後、電子コンテンツのダウンロードが可能になります。',
  },
  {
    name: 'ご利用条件',
    des: 'ダウンロードの前に、必ず各商品の詳細ページで事前に対応端末・ご利用環境をご確認ください。詳細はこちら。',
  },
  {
    name: '返品',
    des: 'ご購入後の返品は、電子コンテンツの性質上、お受けいたしかねます。',
  },
  {
    name: '欠陥・不具合等の取扱い',
    des: '電子コンテンツの品質上の問題により、再ダウンロードによってもご利用できない場合、返金など、当社が適切と判断する対応をいたします。',
  },
];

export const DATA_COMPANY_PROFILE_1 = [
  {
    name: '商号',
    des: '三和書籍',
  },
  {
    name: '代表取締役',
    des: '高橋 考',
  },
  {
    name: '所在地',
    des: '〒112-0013東京都文京区音羽2-2-2アベニュー音羽 2F',
    tel: 'TEL.03-5395-4630',
    fax: 'FAX.03-5395-4632',
  },
];

export const DATA_COMPANY_PROFILE_2 = [
  {
    name: '資本金',
    des: '1,000万円',
  },
  {
    name: '事業内容',
    des: '出版',
  },
  {
    name: '主要書籍',
    des: '1944年8月創業の三和書房東京支店を1994年に独立させサンワコーポレーションを設立。2001年 3月、東京都文京区音羽に移転を機に現在の三和書籍に社名変更。2005年に特定非営利活動法人北東アジア交流協会を立ち上げ、地球温暖化による砂漠化防止のための植林活動を、日中緑化基金を得て行っている。',
  },
  {
    name: '取次',
    des: 'トーハン、日本出版販売、大阪屋、栗田出版販売、太洋社、鍬谷書店、明文図書、大学図書、全官報ほか',
  },
];

export const DATA_COMPANY_PROFILE_3 = [
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_s25%E5%B0%8F.jpg',
    des: '図書目録',
    time: '昭和25年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_s27%E5%B0%8F.jpg',
    des: '図書目録',
    time: '昭和27年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_s29%E5%B0%8F.jpg',
    des: '図書目録',
    time: '昭和29年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_s30%E5%B0%8F.jpg',
    des: '図書目録',
    time: '昭和30年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_s35%E5%B0%8F.jpg',
    des: '図書目録',
    time: '昭和35年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_h19%E5%B0%8F.jpg',
    des: '図書目録',
    time: '平成19年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_h25%E5%B0%8F.jpg',
    des: '図書目録',
    time: '平成25年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_h28_1%E5%B0%8F.jpg',
    des: '図書目録',
    time: '平成28年',
  },
  {
    img: 'https://sanwa-co.com/wordpress/wp-content/uploads/2017/05/mokuroku_h28_2%E5%B0%8F.jpg',
    des: '図書目録',
    time: '平成28年',
  },
];

export const OPTIONS_SELECT_FONT = [
  {
    title: 'Shin Go Pr6',
    value: enumTextBookFont.SHIN_GO_PR6,
  },
  {
    title: 'Ryumin Pro',
    value: enumTextBookFont.RYUMIN_PRO,
  },
];

export const DATA_COMPANY_PROFILE_4 = [
  '1995年『家族／看護／医療の社会学』山岸健著、その他1点発行',
  '1996年『アメリカ政治学の展開』ジェームズ・ファ／レイモンド・セイデルマン編著 本田弘〔ほか〕訳、その他1点発行',
  '1997年『人間理解と看護の心理学』寺内 礼著、その他5点発行',
  '1998年『住宅と健康』スウェーデン建築研究評議会 著 スウェーデン国立住宅・建築計画委員会著 早川潤一訳、その他3点発行',
  '1999年『180年間戦争をしてこなかった国』早川潤一著、『ストックヤード飛行場特集』サンワコーポレーション、その他2点発行',
  '2000年『実例でわかる福祉住環境 2001年度版』、『ストックヤード駅特集』サンワコーポレーション、『森林は誰のもの』日置幸雄著、その他2点発行',
  '2001年『バリアフリーデザインガイドブック2002年度版』サンワコーポレーション、『ストックヤードサッカー場特集』、『世界テロ事典』浦野起央著。その他6点発行',
  '2002年『バリアフリーデザインガイドブック2003年度版』、『日中関係の管見と見証 』張香山著 鈴木栄司訳、その他10点発行',
  '2003年『バリアフリーデザインガイドブック2004年度版』、『宇宙飛行士はイビキをかかない』 秋広良昭著、その他6点発行',
  '2004年『バリアフリーデザインガイドブック2005年度版』、『自律神経と免疫の法則』 安保徹著、その他10点発行',
  '2005年『バリアフリーデザインガイドブック2006年度版』、『鍼灸大成』楊継州著 浅野周訳、『「完全図解」知的財産検定「2級」』知財検定研究会著、その他22点発行',
  '2006年『バリアフリーデザインガイドブック2007-8年度版』、『知って得する年金・税金・雇用・健康保険の基礎知識』 榎本恵一,渡辺峰男,吉田幸司著、その他16点発行',
  '2007年2008年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『レオナルド・ダ・ヴィンチへの誘い』『改正建築士法 Ｑ＆Ａ』『自律神経免疫療法入門』『図解特許用語事典』『知的財産一問一答』『知的財産キ－ワ－ド２００』他',
  '2008年2009年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『耐震規定と構造動力学』『ビジネス実務法務3級問題集』『知財管理技能検定２・3級テキスト』『水を燃やす技術』他',
  '2009年2010年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『ハイク・ガイ』『生物遺伝資源のゆくえ』『オバマのアメリカ・どうする日本』『僕の神経細胞』『住宅改修アセスメントのすべて』『バリアフリ－住宅読本 新版』他',
  '2010年2011年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『NPO・新公益法人Q&A』『倫理学原理（ムーア）』『労働法』『ケ-スで考える情報社会 第二版』『世界を魅了するチベット』他',
  '2011年2012年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『3・11震災法務Q&A 』『行政書士記述式問題集』『無血刺絡の臨床』『写真で見るアジアの少数民族①東アジア編』シリーズ開始、『ダライ・ラマの般若心経』『はやぶさパワ－スッポト50』他',
  '2012年2013年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『憲法入門』『災害と住民保護』『事例で学ぶ認定NPO法人の申請実務』『中南海の100日』『司法アクセスの理念と現状』『漢方治療の診断と実践』他',
  '2013年2014年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『新しい医療への挑戦』『もうひとつのチャイナリスク』『働く女性のためのリ－ダ－シップ講義』『安保徹の原著論文を読む』『希望の社会学』他',
  '2014年2015年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『日の丸ベンチャー』『若杉文吉』『15-16 バリアフリ－デザインガイドブック』『自分でできるチクチク療法』『人類はこの危機をいかに克服するか』他',
  '2015年2016年度版『知って得する年金・税金・雇用・健康保険の基礎知識』『新筆跡鑑定』『これからの環境エネルギー』『病気にならない生き方』『島根核発電所 原発 その光と影』『周恩来たちの日本留学』『女性が輝く時代 「働く」とはどういうことか』他',
  '2016年『バリアフリ－住宅読本[改訂新版]』『腱移行術による麻痺手の再建とその応用』『フリーメイソンの歴史と思想「陰謀論」批判の本格的研究』『立憲主義と安全保障法制』『「一強多弱」政党制の分析 得票の動きからみる過去・現在』他',
];
export const PURPOSE = [
  '本サービスに関する各種事項の連絡や情報提供を行うため',
  '本サービスを提供するため',
  '本サービスにユーザー登録する際に必要な情報入力を簡略化するため',
  '電子メール配信サービスのお申し込みの確認や電子メールを配信するため',
  '本サービスにデータ登録する際に必要な情報入力を簡略化するため',
  '本サービスに関する請求に対するお支払いの確認をするため',
  'ご協力いただいた調査などに対する謝礼等をお送りするため',
  'ご応募いただいた懸賞などに対する景品等をお送りするため',
  '本サービスの利用状況等を調査・分析するため',
  '本サービスの内容をよりご満足いただけるよう改良・改善するため又は新サービス開発のため',
  '本サービスの内容をユーザーに合わせてカスタマイズするため',
  '本サービスに関する満足度を調査するため',
  '取得情報を統計的に処理した情報を集約し、調査結果として利用するため',
  '本サービス、新サービスその他の各種サービスのご案内やお知らせをお届けするため',
  '調査のご協力依頼や各種イベントへのご参加をお願いし、またその結果などをご報告するため',
  '本サービス運営上のトラブルの解決のため',
];

export const CATEGORY_PARAMS = {
  categoryIds: null,
  start: 0,
  length: 12,
};

export const PRICE_PARAMS = {
  priceMin: null,
  priceMax: null,
  start: 0,
  length: 12,
};

export const BOOK_TYPE_PARAMS = {
  isAudioReading: false,
  start: 0,
  length: 12,
};

export const PRODUCT_SORTING_PARAMS = {
  start: 0,
  length: 12,
  productSorting: null,
};

export const FILTER_NONE_PATH = {
  filter: 'none',
};

export const READ_BOOK_PARAMS = {
  productId: null,
  start: 2,
  length: 2,
};

export enum DeleteAllType {
  Cart = 1,
  WishlistProps = 2,
}

export const TEXT_HIGHLIGHT_CLASS_NAME = 'rect-highlight';
export const TEXT_HIGHLIGHT_COLOR = '#FFFF00';

export const LOCAL_STORAGE_KEY = {
  CheckoutProducts: 'CheckoutProduct',
} as const;

export const G_SVG_BOOK_DEFAULT_SCALE = 1.3333334;
export const SWIPE_BOOK_TOUCH_RANGE = 130;
export const SWIPE_TEXT_BOOK_TOUCH_RANGE = 650;
