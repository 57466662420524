import { useNavigate, createSearchParams } from 'react-router-dom';

interface NavigateParams {
  [key: string]: any;
}

export const useNavigateParams = () => {
  const navigate = useNavigate();

  return (pathname: string, params?: NavigateParams) => {
    const path = {
      pathname,
      search: createSearchParams(params).toString(),
    };
    navigate(path);
  };
};
