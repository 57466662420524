export const PATH_MAIN = '/';
export const PATH_HOME = '/home';
//auth
export const PATH_LOGIN = '/login';
export const PATH_INTRODUCTION = '/intro';
export const PATH_SIGN_UP = '/register';
export const PATH_FORGOT_PASSWORD = '/forgot-password';
export const PATH_SIGN_UP_SUCCESS = '/signup-success';
export const PATH_SET_PASSWORD = '/set-password';
export const PATH_HOME_READY = '/activation';
export const PATH_REGISTER_SUCCESS =
  '/activation?message=あなたのアカウントはすでに有効になっています。';
export const PATH_FORGOT_PASSWORD_SUCCESS = '/forgot-password-success';
//
export const PATH_CART = '/cart';
export const PATH_CART_NOT_EMPTY = '/cart-not-empty';
export const PATH_DETAILS = '/details';
export const PATH_DETAIL = `${PATH_DETAILS}/:bookId`;
export const PATH_READ = '/read';
export const PATH_WISH_LIST = '/wish-list';
export const PATH_MY_REVIEW = '/my-review';
export const PATH_READ_BOOK = '/read-book';
export const PATH_DETAIL_READ_BOOK = `${PATH_READ_BOOK}/:bookReadableId`;
export const PATH_SPECIAL_COMMER = '/special-commer';
export const PATH_COMPANY_PROFILE = '/company-profile';
export const PATH_POLICY = '/policy';
export const PATH_TERM = '/term';
//subscribe
export const PATH_SUBSCRIBER_RANK = '/subscription-rank';
export const PATH_SETTINGS = '/settings';
export const PATH_MY_BOOKS = '/my-books';
export const PATH_PURCHASE_HISTORY = '/purchase-history';
export const PATH_SUBSCRIPTION = '/subscription';
export const PATH_PARENT_CATEGORY = '/parent-category';
export const PATH_PARENT_CATEGORY_DETAIL = `${PATH_PARENT_CATEGORY}/:pathParentId`;

//customer
export const PATH_BOOK_REQUEST = '/book-request';
export const PATH_LISTING = '/category';
export const PATH_ITEM = `${PATH_LISTING}/:categoryId`;
export const PATH_CATEGORIES = '/categories';
export const PATH_CHECKOUT_COMPLETE = '/checkout-complete';
export const PATH_PROFILE = '/profile';
export const PATH_CHANGE_PASSWORD = '/change-password';
export const PATH_NOTIFY_SETTINGS = '/notification-setting';
export const PATH_CHECKOUT = '/checkout';
//error
export const PATH_404 = '/*';
