import styled from 'styled-components';

export const UserFilterWrapper = styled.div`
  .input-width {
    height: 4rem;
    .ant-select {
      width: 100%;
      height: 100% !important;
      cursor: pointer;
      input {
        height: 100% !important;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        display: flex !important;
        align-items: center !important;
      }
      // padding: 3px 0;
      .ant-select-selector {
        border-radius: 50px;
        height: 100% !important;
      }
    }
    &.search-book {
      border-radius: 5rem;
      border: 1px solid #d9d9d9;
      .shared-input .group-input {
        border: none;
        background: none;
        input {
          background: transparent !important;
        }
        // height: 3.8rem !important;
      }
    }
  }
  @media (max-width: 767px) {
    .filter-group__wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    .mobile {
      width: calc(50% - 1.4rem);
      margin-bottom: 1.6rem;
      &:nth-child(2) {
        margin-left: 2.8rem;
      }
    }

    .user-filter-btn__bg {
      margin-left: 1.6rem;
      background-color: ${(props) => props.theme.colors.primary};
      padding: 1rem 1.5rem;
      font-size: ${(props) => props.theme.size.s};
      font-weight: ${(props) => props.theme.weight['500']};
      border-radius: 0.8rem;
      width: 7.4rem;
      height: 4.4rem;
    }

    .user-filter-btn__text {
      color: ${(props) => props.theme.colors.button.text};
    }

    .search-book {
      width: calc(100% - 9rem);
      margin-bottom: 2.4rem;
    }
  }
  @media (min-width: 768px) {
    margin-bottom: 2.4rem;
    .input-width {
      width: 100%;
      margin-right: 2.4rem;
    }

    .filter-group__wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 2.4rem;
      width: 100%;
    }

    .user-filter-btn__bg {
      background-color: ${(props) => props.theme.colors.primary};
      padding: 1rem 1.5rem;
      font-size: ${(props) => props.theme.size.s};
      font-weight: ${(props) => props.theme.weight['500']};
      border-radius: 0.8rem;
      // width: 7.6rem;
    }

    .user-filter-btn__text {
      white-space: nowrap;
      color: ${(props) => props.theme.colors.button.text};
    }
  }
`;

export const SelectStyle = styled.div`
  @media (min-width: 375px) and (max-width: 640px) {
    width: 100%;
    display: flex;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    &.input {
      .input-width:first-child {
        width: calc((100% - 1.6rem) / 2);
      }
      .input-width:nth-child(2) {
        margin-left: 1.6rem;
        width: calc((100% - 1.6rem) / 2);
      }
    }
  }
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
  }
`;

export const SearchStyle = styled.div`
  @media (min-width: 375px) and (max-width: 640px) {
    width: 100%;
    display: flex;
    margin-bottom: 2.4rem;
    justify-content: space-between;
    .user-filter-btn__bg {
      margin-left: 1.6rem;
      background-color: ${(props) => props.theme.colors.primary};
      padding: 1rem 1.5rem;
      font-size: ${(props) => props.theme.size.s};
      font-weight: ${(props) => props.theme.weight['500']};
      border-radius: 0.8rem;
    }
    .user-filter-btn__text {
      color: ${(props) => props.theme.colors.button.text};
    }
    .input-width {
      width: 100%;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    width: 100%;
  }
`;
