import Rating from 'react-rating';
import styled from 'styled-components';

import { StarEmptyIcon, StarFullIcon } from './Icon';

interface IProps {
  rate?: number;
  reviews?: number;
  readonly?: boolean;
  size?: number;
}

export const AppRating = (props: IProps) => {
  const { reviews, readonly = false, size, rate } = props;
  return (
    <StyledRating>
      <Rating
        emptySymbol={<StarEmptyIcon width={size} height={size} />}
        fullSymbol={<StarFullIcon width={size} height={size} />}
        fractions={2}
        start={0}
        stop={5}
        readonly={readonly}
      />
      {!!reviews && <span className="reviews">(0 レビュー)</span>}
    </StyledRating>
  );
};

const StyledRating = styled.div`
  display: flex;
  align-items: center;
  .reviews {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #6c757d;
    margin-left: 1.6rem;
  }
`;
