export const themes = {
  theme: {
    light: {
      id: 'T01',
      name: 'Light',
      colors: {
        divider: '#f0f0f0',
        dividerBolder: '#ececec',
        body: '#FFFFFF',
        primary: '#D42A1C',
        secondary: '#359F08',
        information: '#21A9FF',
        text: '#303030',
        text2: '#6C757D',
        text3: '#0BDED2',
        text4: '#0038FF',
        scrollbarThumb: '#888',
        scrollbarTrack: '#f1f1f1',
        textBreadcrumbNotAllow: '#000000',
        badge: {
          text: '#FFFFFF',
          background: '#0BDED2',
        },
        button: {
          text: '#FFFFFF',
          background: '#000000',
          facebook: '#3467A6',
          line: '#00B900',
        },
        link: {
          text: 'teal',
          opacity: 1,
        },
        bgColor: '#D9D9D9',
        bgHeader: '#FFFFFF',
        bgSection: '#FFFFFF',
        bgFooter: '#F5F5F5',
        gray: {
          '100': '#F9F9F9',
          '200': '#ECECEC',
          '300': '#B6B6B6',
          '400': '#6C757D',
          '600': '#303030',
          '700': '#000000',
        },
      },
      size: {
        xxs: '1rem',
        xs: '1.2rem',
        s: '1.4rem',
        l: '1.6rem',
        xl: '2rem',
        xxl: '2.4rem',
      },
      weight: {
        '300': 300,
        '400': 400,
        '500': 500,
        '700': 700,
      },
    },
    dark: {
      id: 'T02',
      name: 'Dark',
      colors: {
        divider: '#f0f0f0',
        dividerBolder: '#ececec',
        body: '#706f6d',
        primary: '#D42A1C',
        secondary: '#359F08',
        information: '#21A9FF',
        text: '#303030',
        text2: '#6C757D',
        text3: '#0BDED2',
        textBreadcrumbNotAllow: '#000000',
        badge: {
          text: '#FFFFFF',
          background: '#0BDED2',
        },
        button: {
          text: '#ffffff',
          background: '#0d47a1',
          facebook: '#3467A6',
          line: '#00B900',
        },
        link: {
          text: '#0d47a1',
          opacity: 0.8,
        },
        bgColor: '#D9D9D9',
        bgHeader: '#ffffff',
        bgSection: '#000000',
        gray: {
          '100': '#F9F9F9',
          '200': '#ECECEC',
          '300': '#B6B6B6',
          '400': '#6C757D',
          '600': '#303030',
          '700': '#000000',
        },
      },

      size: {
        xxs: '10px',
        xs: '13px',
        s: '14px',
        l: '16px',
        xl: '20px',
        xxl: '24px',
      },
      weight: {
        '300': 300,
        '400': 400,
        '500': 500,
        '700': 700,
      },
    },
  },
  breakPoints: {
    breakSmall: '320px',
    breakMobile: '375px',
    breakMobileLarge: '414px',
    breakMobileMax: '480px',
    breakMobileMedium: '575px',
    breakTablet: '767px',
    breakIPadPro: '1024px',
    breakDesktop: '1280px',
    breakBig: '2048px',
    breakBigMax: '3840px',
  },
};
