import { NotificationPage } from '@components';
import { PATH_LOGIN } from '@configs';
import { useNavigate } from 'react-router-dom';

export const SignupSuccess = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate(PATH_LOGIN);
  return (
    <NotificationPage
      headerText="アカウント有効化"
      buttonText="ログイン"
      description="アカウントの作成、ありがとうございます。 確認メールがご登録いただいたメールアドレスに送信されました。 受信トレイを確認し、確認リンクをクリックすると、アカウントが「有効」になります。"
      onButtonClick={handleClick}
    />
  );
};
