import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux';
import {
  cancelSubscriptionProduct,
  checkSubscriptionProduct,
  subscribeSubscriptionProduct,
} from '../action';
import { BaseResponseProps, BaseState } from '@interfaces';
import { toast } from 'react-toastify';

interface SubscriptionSliceProps {
  subState: BaseState;
  subscribe: BaseState;
  cancel: BaseState;
}

const initialState: SubscriptionSliceProps = {
  subState: {
    data: null,
    loading: false,
  },
  subscribe: {
    data: null,
    loading: false,
  },
  cancel: {
    data: null,
    loading: false,
  },
};

const subscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(subscribeSubscriptionProduct.pending, (state) => {
      state.subscribe.loading = true;
    })
      .addCase(subscribeSubscriptionProduct.fulfilled, (state, { payload }) => {
        state.subscribe.loading = false;
        state.subscribe.data = payload;
        if (payload.success) {
          toast.success(payload.messages || payload.message || 'Subscribe Successfully');
        } else {
          toast.error(payload.messages || payload.message || 'Something went wrong');
        }
      })
      .addCase(subscribeSubscriptionProduct.rejected, (state) => {
        state.subscribe.loading = false;
      })

      .addCase(cancelSubscriptionProduct.pending, (state) => {
        state.cancel.loading = true;
      })
      .addCase(
        cancelSubscriptionProduct.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps>) => {
          state.cancel.loading = false;
          state.cancel.data = payload;
          if (payload.success) {
            toast.success(payload.messages || payload.message || 'Cancel Successfully');
          } else {
            toast.error(payload.messages || payload.message || 'Something went wrong');
          }
        },
      )
      .addCase(cancelSubscriptionProduct.rejected, (state) => {
        state.cancel.loading = false;
      })

      .addCase(checkSubscriptionProduct.pending, (state) => {
        state.subState.loading = true;
      })
      .addCase(checkSubscriptionProduct.fulfilled, (state, { payload }) => {
        state.subState.loading = false;
        state.subState.data = payload;
      })
      .addCase(checkSubscriptionProduct.rejected, (state) => {
        state.subState.loading = false;
      });
  },
});

export const productSubscriptionReducer = subscriptionSlice.reducer;
export const selectSubscription = (s: RootState) => s.subscription;
