import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@redux';

interface Breadcrumb {
  path: string | null;
}

const initialState: Breadcrumb = {
  path: null,
};
const breadcrumbSlice = createSlice({
  name: 'breadcrumbSlice',
  initialState,
  reducers: {
    changePathName: (state, action) => {
      state.path = action.payload;
    },
  },
});
export const { changePathName } = breadcrumbSlice.actions;
export const selectBreadcrumb = (state: RootState) => state.breadcrumb;
export const breadcrumbReducer = breadcrumbSlice.reducer;
