import { ICon } from '@interfaces';

export const ArrowToTop = (props: ICon) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M7.99998 5.33337H24V8.00004H7.99998V5.33337ZM8.94264 20.9427L14.6666 15.2187V26.6667H17.3333V15.2187L23.0573 20.9427L24.9426 19.0574L16 10.1147L7.05731 19.0574L8.94264 20.9427Z"
      fill="white"
    />
  </svg>
);
