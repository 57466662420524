import { Routes } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { IRoute } from '@interfaces';
import { ComponentToast } from 'src/components/Toast/Toast';
import { routes } from 'src/routes';
import { DefaultLayout } from '@layouts';
import {
  getUserProfileDefault,
  selectAppLoading,
  selectThemeMode,
  setLoading,
  useAppDispatch,
  useAppSelector,
} from 'src/redux';
import { useEffect } from 'react';
import { enumThemeMode } from '@configs';
import { themes, useTheme } from '@theme';
import { ButtonScrollToTop } from '@components';
import { ResetScrollWhenPageChange } from '@components';
import { useDispatch } from 'react-redux';

interface IProps {
  setTheme: (theme: any) => void;
}

export const ModuleMain = (props: IProps) => {
  const { setTheme } = props;
  const themeMode = useAppSelector(selectThemeMode);
  const appLoading = useAppSelector(selectAppLoading);
  const { setCurrentTheme } = useTheme();
  const dispatch = useAppDispatch();
  const defaultDispatch = useDispatch();

  useEffect(() => {
    updateTheme();
  }, [themeMode]);

  // DEV
  if (process.env.NODE_ENV === 'development') {
    useEffect(() => {
      if (themeMode === enumThemeMode.LIGHT) {
        setTheme(themes.theme.light);
        setCurrentTheme(themes.theme.light);
      } else {
        setTheme(themes.theme.dark);
        setCurrentTheme(themes.theme.dark);
      }
      dispatch(setLoading(false));
    }, []);
  }

  const updateTheme = () => {
    if (themeMode === enumThemeMode.LIGHT) {
      setTheme(themes.theme.light);
    } else {
      setTheme(themes.theme.dark);
    }
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 60, color: themes.theme.light.colors.primary }} spin />
  );
  return (
    <Spin indicator={antIcon} spinning={appLoading} size="large">
      <BrowserRouter>
        <ResetScrollWhenPageChange />
        <Routes>
          {routes.map((route: IRoute, index: number) => {
            const Page = route.page;
            const Layout = route.layout || DefaultLayout;
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout {...route}>
                    <Page />
                  </Layout>
                }
              />
            );
          })}
        </Routes>
        <ComponentToast />
        <ButtonScrollToTop />
      </BrowserRouter>
    </Spin>
  );
};
