import { SharedSkeleton, SkeletonCategory, SkeletonListCategory } from '@components';
import { CATEGORY_PARAMS, PATH_LISTING, PATH_PARENT_CATEGORY } from '@configs';
import { useNavigateParams } from '@hooks';
import { CategoriesDataType, CategorySelected, SubCategoryType } from '@interfaces';
import {
  selectCategories,
  useAppSelector,
  fetchCategories,
  fetchProductList,
  categorySelected,
  getCategoryId,
  setCurrentActive,
  getListBookByCategoryId,
  changePathName,
} from '@redux';
import { renderSkeletonItem, setToLS, useMediaQuery } from '@utils';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CategoriesStyled, ItemCategories, ListCategories } from './style';

export const CategoriesModule = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const dispatch = useDispatch();
  const {
    data: { categories },
    isLoading,
  } = useAppSelector(selectCategories);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);
  const customNavigate = useNavigateParams();
  const navigate = useNavigate();
  const handleNavigateSubCategories = (itemSubCategory: SubCategoryType) => {
    dispatch(setCurrentActive(itemSubCategory.id));
    setToLS('subActive', itemSubCategory.id);
    dispatch(getCategoryId(itemSubCategory.id));
    dispatch(fetchProductList({ ...CATEGORY_PARAMS, categoryIds: itemSubCategory.id }));
    customNavigate(`${PATH_LISTING}/${itemSubCategory.seName}`);
  };
  const handleNavigateCategories = (itemCategory: CategoriesDataType) => {
    setToLS('subActive', itemCategory.id);
    navigate(`${PATH_PARENT_CATEGORY}/${itemCategory.name}`);
    dispatch(changePathName(itemCategory.name));
    setToLS('categoryId', itemCategory.id);
  };
  const changeBreadcrumb = (item: CategoriesDataType, idSubCategory: number) => {
    const selectCategory: CategorySelected = {
      categories: item,
      idSubcategory: idSubCategory,
    };
    dispatch(categorySelected(selectCategory));
  };
  return (
    <CategoriesStyled>
      <div className="title">全てのカテゴリ</div>
      {isMobile && <div className="header-customer-line" />}
      <ListCategories>
        {isLoading && (
          <>
            {renderSkeletonItem(8).map((data, index) => (
              <SkeletonListCategory />
            ))}
          </>
        )}
        {categories?.map((item) => (
          <ItemCategories key={item.id}>
            <div className="name-categories" onClick={() => handleNavigateCategories(item)}>
              {item.name}
            </div>
            <ul>
              {item.subCategories.map((subItem) => (
                <li
                  onClick={() => {
                    handleNavigateSubCategories(subItem);
                    changeBreadcrumb(item, subItem.id);
                  }}
                  key={subItem.id}
                >
                  {subItem.name}
                </li>
              ))}
            </ul>
          </ItemCategories>
        ))}
      </ListCategories>
    </CategoriesStyled>
  );
};
