import { DATA_SPECIAL_COMMER_1, DATA_SPECIAL_COMMER_2 } from '@configs';
import { useMediaQuery } from '@utils';
import { SpecialCommerStyled, PageTitle, PageContent } from './style';

export const SpecialCommerModule = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <SpecialCommerStyled>
      <div className="title-text">特商法・表記</div>
      {isMobile && <div className="header-customer-line" />}
      <PageTitle>特定商取引法に基づく表記</PageTitle>
      <PageContent>
        {DATA_SPECIAL_COMMER_1.map((item) => (
          <div className="element-text">
            <div className="name">
              {item.name}: <span className="description">{item.des}</span>
            </div>
          </div>
        ))}
        {DATA_SPECIAL_COMMER_2.map((item) => (
          <div className="element-text">
            <div className="name">{item.name}</div>
            <div>{item.des}</div>
          </div>
        ))}
      </PageContent>
    </SpecialCommerStyled>
  );
};
