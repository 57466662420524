import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ICategory } from '@interfaces';
import { StyledCategory } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_PARAMS } from '@configs';
import {
  changePathName,
  fetchProductList,
  getListBookByCategoryId,
  selectProduct,
  useAppDispatch,
} from '@redux';
import { setToLS } from '@utils';
import { SkeletonCategory } from '../shared';

export const Category: React.FC<ICategory> = ({
  category_id,
  img_url,
  category_name,
  subCategories,
  navigateTo = '',
}) => {
  const dispatch = useAppDispatch();
  const productSelector = useSelector(selectProduct);
  const { isLoading } = productSelector;
  const handleNavigateCategoryId = (category_name: string, category_id: string | number) => {
    setToLS('subActive', category_id);
    dispatch(changePathName(category_name));
    setToLS('categoryId', category_id);
  };
  return (
    <StyledCategory data-id={category_id}>
      <Link
        className="category-item"
        to={isLoading ? '#' : `${navigateTo}`}
        onClick={() => handleNavigateCategoryId(category_name, category_id)}
      >
        {isLoading ? (
          <SkeletonCategory />
        ) : (
          <>
            <img className="category-item-img" src={img_url} alt="not" />
            <span className="category-item-text">{category_name}</span>
          </>
        )}
      </Link>
    </StyledCategory>
  );
};
