import { enumLeftSidebar } from '@configs';
import { Col, Row } from 'antd';
import styled, { css } from 'styled-components';

export const StyledInnerPage = styled.div<{
  leftSidebar?: enumLeftSidebar;
}>`
  ${(p) =>
    p.leftSidebar &&
    css`
      @media (max-width: 768px) and (min-width: 640px) {
        .app-left_col {
          display: none;
        }
        .rg-sec {
          margin-top: 0;
        }
      }

      @media (max-width: 640px) {
        padding: 0 1.6rem;
        /* .app-left_sidebar, */
        .app-left_col {
          display: none;
        }
        .rg-sec {
          margin-top: 0;
        }
      }

      @media (max-width: 930px) and (min-height: 820px) {
        .app-left_col {
          display: none;
        }
        .rg-sec {
          margin-top: 0;
        }
      }

      // for all generations of ipad mini
      @media (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
        /*.app-left_col ,
        .app-breadcrumbs{
          display: none;
        }*/
        .app-left_col {
          display: none;
        }
        .rg-sec {
          margin-top: 0;
        }
      }

      // for all generations of ipad air
      @media (max-width: 1200px) and (min-height: 820px) and (orientation: landscape) {
        .app-left_col {
          display: none;
        }
        .rg-sec {
          margin-top: 0;
        }
      }
    `};

  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    margin-top: 3rem;
  }
`;
export const StyledRow = styled((props) => <Row {...props} />)`
  padding: 2.4rem 0;
  @media (max-width: 374px) {
    padding: 4.4rem 0;
  }
  flex-wrap: nowrap !important; //this line for fix break layout
  @media (max-width: 1200px) {
    flex-flow: row;
  }

  //@media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
  //  /* hide scrollbar for IE, Edge and Firefox */
  //  -ms-overflow-style: none;
  //  scrollbar-width: none;
  //
  //  /* hide scrollbar for chrome, safari and opera */
  //  &::-webkit-scrollbar {
  //    display: none;
  //  }
  //}
`;

export const StyledCol = styled((props) => <Col {...props} />)<{
  left?: boolean;
  right?: boolean;
}>`
  ${(p) =>
    p.left &&
    css`
      padding-right: 4rem;
    `}
  ${(p) =>
    p.right &&
    css`
      padding-left: 4rem;
    `}
`;

export const AppBreadCrumbsWrapper = styled.div`
  @media (min-width: 768px) and (min-height: 813px) and (orientation: portrait) {
  }

  //@media (min-width: 1024px) and (max-width: 1180px) and (orientation: landscape) {
  //  display: none;
  //}
`;
