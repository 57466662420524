import styled from 'styled-components';
import { useState } from 'react';
import { onAuthStateChanged, signInWithPopup } from 'firebase/auth';

import { LoginModule } from '@modules';
import { useTheme } from '@theme';
import { setThemeMode, useAppDispatch } from '@redux';
import { enumThemeMode } from '@configs';
import { IMAGES } from '@assets';
import { SocialLogin } from '@components';
import { appleProvider, auth, fbProvider } from '@service';
import { LogApp } from '@utils';

export const LoginPage = () => {
  const [payload, setPayload] = useState<string>('');
  const [idToken, setIdToken] = useState<string>('');
  const { themes, setCurrentTheme } = useTheme();
  const dispatch = useAppDispatch();

  const handleChangeTheme = () => {
    setCurrentTheme(themes.theme.dark);
    dispatch(setThemeMode(enumThemeMode.DARK));
  };

  const handleChangeLight = () => {
    setCurrentTheme(themes.theme.light);
    dispatch(setThemeMode(enumThemeMode.LIGHT));
  };

  return (
    <StyledLogin background={IMAGES.backgroundLogin} className="login-page">
      {/* 
      <div>
        <h1>Change theme</h1>
        <button onClick={handleChangeLight}>ChangeLight</button>
        <button onClick={handleChangeTheme}>ChangeDark</button>
      </div> */}
      <div className="header__logo logo app-container">
        <img title="" src={IMAGES.headerLogo} />
      </div>
      <div className="auth__section app-container">
        <div className="auth__frame">
          <LoginModule />
          <SocialLogin haveDividerDes />
        </div>
      </div>
    </StyledLogin>
  );
};

const StyledLogin = styled.div<{ background?: string }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  background-image: ${(p) => `url(${p?.background})`};
  @media (max-width: 640px) {
    background-position-x: 50%;
    background-size: 200% 100%;
  }
  .header__logo {
    padding-top: calc(17 / 360 * 100%);
    height: fit-content;
    @media (max-width: 640px) {
      padding-top: 4.6rem;
      margin: 0 auto 2rem;
      width: 15rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .auth__section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 5%;
    @media (max-width: 640px) {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 0.8rem 0.8rem 0 0;
    }
    .auth__frame {
      background-color: ${(props) => props?.theme.colors.body};
      width: 49%;
      padding: 4rem 4rem 6.4rem;
      border-radius: 0.8rem;
      @media (max-width: 1024px) {
        width: 68%;
      }

      @media (max-width: 640px) {
        border-radius: 1.2rem 1.2rem 0 0;
        padding: 3.6rem 2rem 9.4rem;
        width: 100%;
      }
    }
  }

  @media (max-width: 640px) {
    height: 100vh;
  }
`;
