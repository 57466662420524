import styled from 'styled-components';

import {
  Check,
  IconRecycle,
  ItemCart,
  ItemCheckout,
  SharedSkeleton,
  StyleBtnRecycle,
} from '@components';
import { useMediaQuery } from '@utils';

export const SkeletonCartList = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(min-width:640px) and (max-width:992px)');
  return (
    <ItemCart>
      <Check />
      <div className="image-item">
        <SharedSkeleton type={'image'} imageClassName="skeleton-img" active />
      </div>
      <div className="des-item">
        <Text className="book-item-text book-item-title">
          <SharedSkeleton type={'input'} active />
        </Text>
        <p className="book-item-text book-item-author">
          <SharedSkeleton type={'input'} active />
        </p>
        {isMobile && (
          <>
            <p className="book-item-price">
              <SharedSkeleton type={'input'} inputClassName="skeleton-price" active />
            </p>
            <StyleBtnRecycle>
              <IconRecycle />
              <p>削除</p>
            </StyleBtnRecycle>
          </>
        )}
      </div>
      {!isMobile && (
        <div className="book-price-delete">
          {!isTablet && (
            <Text className="book-item-price">
              <SharedSkeleton type={'input'} inputClassName="skeleton-price" active />
            </Text>
          )}
          <StyleBtnRecycle>
            <IconRecycle />
            <p>削除</p>
          </StyleBtnRecycle>
        </div>
      )}
    </ItemCart>
  );
};

export const SkeletonCheckout = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <ItemCheckout>
      <div className="image-item">
        <SharedSkeleton type={'image'} imageClassName="skeleton-image" />
      </div>
      <div className="des-item ">
        <Text className="book-item-text book-item-title">
          <SharedSkeleton type={'input'} />
        </Text>
        <p className="book-item-text book-item-author">
          <SharedSkeleton type={'input'} />
        </p>
        <p className="book-item-text book-item-publisher">
          <SharedSkeleton type={'input'} />
        </p>
        {isMobile && (
          <>
            <p className="book-item-price">
              <SharedSkeleton type={'input'} inputClassName="skeleton-price" />
            </p>
          </>
        )}
      </div>
      {!isMobile && (
        <div className="book-price">
          <Text className="book-item-price">
            <SharedSkeleton type={'input'} inputClassName="skeleton-price" />
          </Text>
        </div>
      )}
    </ItemCheckout>
  );
};

const Text = styled.p<{
  fontSize?: string;
  fontWeight?: string;
}>`
  color: ${(props) => props.theme.colors.button.background};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
`;
