// Import the functions you need from the SDKs you need
//@ts-nocheck
import { initializeApp } from 'firebase/app';
import { getAuth, FacebookAuthProvider, OAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDHm_GxIWLNp6cXyAzsjJSumkhg7w1P8EU',
  authDomain: 'kozuchi-web.firebaseapp.com',
  projectId: 'kozuchi-web',
  storageBucket: 'kozuchi-web.appspot.com',
  messagingSenderId: '831379783454',
  appId: '1:831379783454:web:e67a4904e1ae2e1fb4130f',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

// firebase provider
const fbProvider = new FacebookAuthProvider('12321321321321');
const appleProvider = new OAuthProvider('apple.com');

export { auth, db, fbProvider, appleProvider };
