interface IProps {
  reference?: any;
  quote?: any;
}

export const BlockQuote = (props: IProps) => {
  const { reference, quote } = props;
  const blockQuote = '"' + quote + '"';

  return (
    <div className="border-start border-danger border-3 px-3 py-3">
      <div className="h2 mb-3">
        {blockQuote.split('\\br').map((line: any) => (
          <div key={line}>{line}</div>
        ))}
      </div>
      <div className="h5">- {reference}</div>
    </div>
  );
};
