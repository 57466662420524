import { LogApp } from '@utils';
import { useEffect, useState } from 'react';

interface IProps {
  onEnd?: () => void;
  onPause?: (e: any) => void;
  onResume?: (e: any) => void;
  onBoundaryHandler?: (e: SpeechSynthesisEvent) => void;
}

export const useSpeechSynthesis = (props: IProps) => {
  const { onEnd, onResume, onPause, onBoundaryHandler } = props;
  const [voices, setVoices] = useState([]);
  const [speaking, setSpeaking] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [supported, setSupported] = useState(false);

  useEffect(() => {
    //remove when have another voice
    setSpeaking(false);
    window.speechSynthesis.cancel();
    return () => {
      // disable
      window.speechSynthesis.cancel();
      setSpeaking(false);
    };
  }, []);

  let utterance: SpeechSynthesisUtterance = new SpeechSynthesisUtterance();

  const processVoices = (voiceOptions: any) => {
    setVoices(voiceOptions);
  };

  const getVoices = () => {
    let voiceOptions = window.speechSynthesis.getVoices();
    if (voiceOptions.length > 0) {
      processVoices(voiceOptions);
      return;
    }

    window.speechSynthesis.onvoiceschanged = (event: any) => {
      voiceOptions = event.target.getVoices();
      processVoices(voiceOptions);
    };
  };

  const handleEnd = () => {
    setSpeaking(false);
    onEnd && onEnd();
  };

  const handleResume = (e: any) => {
    // setSpeaking(true);
    setIsPause(false);
    onResume && onResume(e);
  };

  const handlePause = (e: any) => {
    setIsPause(true);
    onPause && onPause(e);
  };

  const handleBoundaryHandler = (event: SpeechSynthesisEvent) => {
    onBoundaryHandler && onBoundaryHandler(event);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.speechSynthesis) {
      setSupported(true);
      getVoices();
    }
  }, []);

  const speak = (args: any = {}) => {
    const { voice = null, text, rate = 1, pitch = 1, volume = 1, lang = 'ja' } = args;
    if (!supported) return;
    setSpeaking(true);
    utterance = new window.SpeechSynthesisUtterance();
    LogApp('textVoice23', text);
    utterance.text = text;
    utterance.voice = voice;
    utterance.onend = handleEnd;
    utterance.rate = rate;
    utterance.pitch = pitch;
    utterance.volume = volume;
    utterance.lang = lang;
    utterance.onresume = handleResume;
    utterance.onpause = handlePause;
    utterance.onboundary = handleBoundaryHandler;
    window.speechSynthesis.speak(utterance);
  };

  const cancel = () => {
    if (!supported) return;
    setSpeaking(false);
    setIsPause(false);
    window.speechSynthesis.cancel();
  };

  const pause = () => {
    if (!supported) return;
    setIsPause(true);
    window.speechSynthesis.pause();
  };

  const resume = () => {
    if (!supported) return;
    setIsPause(false);
    window.speechSynthesis.resume();
  };

  return {
    supported,
    speak,
    speaking,
    isPause,
    cancel,
    voices,
    pause,
    resume,
  };
};

export default useSpeechSynthesis;
