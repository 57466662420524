import { createAsyncThunk } from '@reduxjs/toolkit';
import { authAPI } from '@api';
import { LoginByFacebookProps, LoginWithLineProps } from '@interfaces';

const prefix = 'SocialAuth';

export const loginWithLineAction = createAsyncThunk<any, LoginWithLineProps>(
  `${prefix}/Line`,
  async (params, { rejectWithValue }) => {
    try {
      return await authAPI.loginWithLine(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
/*export const loginWithFacebookAction = createAsyncThunk<any, LoginByFacebookProps>(
  `${prefix}/Facebook`,
  async (params, { rejectWithValue }) => {
    try {
      return await authAPI.loginByFacebook(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);*/
