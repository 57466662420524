import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { PATH_HOME, PATH_LOGIN, PATH_SIGN_UP_SUCCESS } from '@configs';
import { RegisterComponent } from '@components';
import { IRegister, BaseResponseProps } from '@interfaces';
import { setLoading, useAppDispatch } from '@redux';
import { authAPI } from '@api';
import { toast } from 'react-toastify';

export const RegisterModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRedirectToSignIn = () => {
    navigate(PATH_LOGIN);
  };

  const handleNavigateHome = () => {
    navigate(PATH_HOME);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreePolicy: true,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required('苗字が必要です'),
      lastName: Yup.string().trim().required('名前が必要です'),
      email: Yup.string()
        .trim()
        .email('入力した電子メールアドレスは無効です。もう一度やり直してください。')
        .required('メールアドレスが必要です'),
      password: Yup.string()
        .trim()
        .min(8, 'パスワードは8文字～60文字で入力して下さい。')
        .max(60, 'パスワードは8文字～60文字で入力して下さい。')
        .required('パスワードが必要です'),
      confirmPassword: Yup.string()
        .trim()
        .oneOf(
          //confirmPass not match with password
          [Yup.ref('password'), null],
          '新しいパスワードが再入力パスワードと一致しません。もう一度やり直してください。',
        )
        .required('パスワードが必要です'),
      agreePolicy: Yup.boolean()
        .oneOf([true], '利用規約に同意する必要があります。')
        .required('利用規約に同意する必要があります。'),
    }),
    onSubmit: (values: IRegister) => {
      handleRegister(values);
    },
  });

  // LogApp('submit', String(formik?.isSubmitting));

  const handleRegister = async (values: IRegister) => {
    const body = {
      FirstName: values.firstName,
      LastName: values.lastName,
      Email: values.email,
      Password: values.password,
      ConfirmPassword: values.confirmPassword,
    };
    try {
      dispatch(setLoading(true));
      const res = (await authAPI.register(body)) as unknown as BaseResponseProps;
      if (res.success) {
        navigate(PATH_SIGN_UP_SUCCESS);
      } else {
        toast.error(res.messages);
        // formik.setErrors({ confirmPassword: res.messages || '' });
      }
    } catch (err: any) {
      dispatch(setLoading(false));
      formik.setErrors({ confirmPassword: err?.message || '' });
      return { slotId: '', studentId: '' };
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <RegisterComponent
      formik={formik}
      redirectToSignIn={handleRedirectToSignIn}
      backToHome={handleNavigateHome}
    />
  );
};
