import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { jumpTo, selectCart, useAppSelector } from '@redux';
import { BookInCartProps, CartProps } from '@interfaces';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SharedButton } from '@components';
import { useDispatch } from 'react-redux';
import { PATH_CART, PATH_DETAILS } from '@configs';

interface CartProp {
  lengthCart?: number;
  changePopover?: (isOpen: boolean) => void;
  isLogin?: string;
}
export const CartShortcut = (props: CartProp) => {
  const {
    data: { cart },
  } = useAppSelector(selectCart);
  const { pathname } = useLocation();
  const [cartList, setCartList] = useState<CartProps>();
  const [cartListLessThanFive, setListLessThanFive] = useState<BookInCartProps[]>();
  const [isCartRoute, setIsCartRoute] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currency = (n = 0): string => {
    return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(n);
  };

  useEffect(() => {
    setIsCartRoute(pathname === PATH_CART);
  }, [pathname]);

  useEffect(() => {
    if (cart) {
      setCartList(cart.data);
      if (cart.data.items.length > 5) {
        const nr = [...cart.data.items].slice(0, 5);
        setListLessThanFive(nr);
      }
    }
  }, [cart]);

  return (
    <StyledCartShortcut>
      <div className="header">カートの中に {props.lengthCart || 0} アイテム が入っています</div>
      {!props.isLogin && <p className="empty">商品がない</p>}
      {cartList && (
        <>
          {!cartList.items.length ? <p className="empty">商品がない</p> : null}
          {cartList.items.length > 5 ? (
            <>
              {cartListLessThanFive?.map((e) => (
                <div className="item" key={e.productId}>
                  <img
                    src={e.pictureMobileModel.imageUrl}
                    alt="short cut of cart item"
                    className="thumb"
                  />
                  <div className="info">
                    <Link className="name" to={`${PATH_DETAILS}/${e.productId}`}>
                      {e.bookName}
                    </Link>
                    <div className="price">{currency(e.price)}</div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            cartList.items.map((e) => (
              <div className="item" key={e.productId}>
                <img
                  src={e.pictureMobileModel.imageUrl}
                  alt="short cut of cart item"
                  className="thumb"
                />
                <div className="info">
                  <Link
                    className="name"
                    to={`${PATH_DETAILS}/${e.productId}`}
                    onClick={() => props?.changePopover && props.changePopover(false)}
                  >
                    {e.bookName}
                  </Link>
                  <div className="price">{currency(e.price)}</div>
                </div>
              </div>
            ))
          )}
        </>
      )}
      <div className="price-root">
        小計:
        <span>{currency(cartList?.totalPrice)}</span>
        {cartList && cartList.items.length > 5 && !isCartRoute && (
          <Link className="see-more" to={PATH_CART}>
            リストに行く
          </Link>
        )}
      </div>
      <SharedButton
        onClick={() => {
          dispatch(jumpTo(2));
          navigate(PATH_CART);
        }}
        className={`checkout-btn ${!cartList?.items.length ? 'disabled' : ''}`}
        text="チェックアウト"
        bgColor="#21A9FF"
        textColor="#fff"
      />
    </StyledCartShortcut>
  );
};

const StyledCartShortcut = styled.div`
  max-width: 280px;
  .disabled {
    user-select: none;
    opacity: 0.7;
    pointer-events: none;
  }
  .empty {
    padding-top: 1.2rem;
    text-align: center;
  }
  .header {
    border-bottom: 1px solid ${(props) => props.theme.colors.dividerBolder};
    padding-bottom: 1.2rem;
  }
  .see-more {
    margin-left: auto;
    color: ${(props) => props.theme.colors.information};
    font-weight: ${(props) => props.theme.weight['400']};
    font-size: ${(props) => props.theme.size.xs};
  }
  .price-root {
    margin-top: 1.2rem;
    display: flex;
    width: 100%;
    padding: 12px 0;
    border-top: 1px solid ${(props) => props.theme.colors.dividerBolder};
    font-weight: ${(props) => props.theme.weight['400']};
    font-size: ${(props) => props.theme.size.s};
    color: ${(props) => props.theme.colors.text};
    span {
      font-weight: ${(props) => props.theme.weight['700']};
    }
  }
  .checkout-btn {
    padding: 0.6rem 1.8rem;
    margin-left: auto;
  }
  .item {
    display: flex;
    margin-top: 1.2rem;
    .thumb {
      width: 4.6rem;
      height: 6.5rem;
    }

    .info {
      margin-left: 1.4rem;

      .name {
        font-size: ${(props) => props.theme.size.xs};
        font-weight: ${(props) => props.theme.weight['400']};
        color: ${(props) => props.theme.colors.information};
      }

      .price {
        color: ${(props) => props.theme.colors.primary};
        font-size: ${(props) => props.theme.size.xxs};
        font-weight: ${(props) => props.theme.weight['500']};
      }
    }
  }
`;
