import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux';
import { BaseResponseProps, CartProps, WishlistProps } from '@interfaces';
import {
  removeAllProduct,
  fetchWishList,
  searchWishList,
  addToCart,
  getAllBooksInCart,
  removeOneCart,
  fetchWishlistWithPagination,
} from '../action';
import { toast } from 'react-toastify';

export interface ShoppingCartProps {
  isLoading: boolean;
  data: {
    wishlist: BaseResponseProps<WishlistProps> | null;
    searchWishlist: BaseResponseProps<WishlistProps> | null;
    addCart: BaseResponseProps | null;
    cart: BaseResponseProps<CartProps> | null;
    cartRemoveOne: BaseResponseProps | null;
  };
}

const initialState: ShoppingCartProps = {
  isLoading: false,
  data: { wishlist: null, addCart: null, cart: null, cartRemoveOne: null, searchWishlist: null },
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetSearchList: (state) => {
      state.data.searchWishlist = null;
    },
    resetCartList: (state, action) => {
      state.data.cart = action.payload;
    },
    resetWishList: (state, action) => {
      state.data.wishlist = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWishList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchWishList.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<WishlistProps>>) => {
          state.isLoading = false;
          state.data.wishlist = payload;
        },
      )
      .addCase(fetchWishList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchWishlistWithPagination.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchWishlistWithPagination.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<WishlistProps>>) => {
          state.isLoading = false;
          state.data.wishlist = payload;
        },
      )
      .addCase(fetchWishlistWithPagination.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(searchWishList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        searchWishList.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<WishlistProps>>) => {
          state.isLoading = false;
          state.data.searchWishlist = payload;
        },
      )
      .addCase(searchWishList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addToCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToCart.fulfilled, (state, { payload }: PayloadAction<BaseResponseProps<any>>) => {
        state.isLoading = false;
        state.data.addCart = payload;
        if (payload.success) {
          toast.success(payload.messages);
        } else {
          toast.error(payload.messages || payload.message);
        }
      })
      .addCase(addToCart.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllBooksInCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAllBooksInCart.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<CartProps>>) => {
          state.isLoading = false;
          const newState = payload;
          newState.data?.items?.forEach((e) => {
            if (!e.checked) {
              e.checked = false;
            }
          });
          state.data.cart = newState;
        },
      )
      .addCase(getAllBooksInCart.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(removeOneCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        removeOneCart.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<any>>) => {
          state.isLoading = false;
          state.data.cartRemoveOne = payload;
          if (payload.success) {
            toast.success(payload.messages);
          } else {
            toast.error(payload.messages);
          }
        },
      )
      .addCase(removeOneCart.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
export const { resetSearchList, resetCartList, resetWishList } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
export const selectCart = (state: RootState) => state.cart;
