import { CATEGORY_PARAMS, PATH_DETAIL, PATH_DETAILS, PATH_LISTING } from '@configs';
import { useNavigateParams } from '@hooks';
import { SubCategories } from '@interfaces';
import {
  categorySelected,
  changePathName,
  fetchProductList,
  getCategoryId,
  getListBookByCategoryId,
  selectCategories,
  selectShowCategorySidebar,
  setCurrentActive,
  setShowCategorySidebar,
  useAppSelector,
} from '@redux';
import { getFromLS, renderSkeletonItem, scrollToTop, setToLS, useMediaQuery } from '@utils';
import { use } from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { FilterMenuIcon, IconChevronRight } from '../Icon';
import { SharedButton, SkeletonCardItem } from '../shared';
import { ParentCategoryStyled, BookList, ItemBook } from './style';

export const ParentCategoryComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { categoriesSelected, isLoading } = useAppSelector(selectCategories);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(max-width: 992px) and (min-width: 640px)');
  const showCategorySidebar = useAppSelector(selectShowCategorySidebar);
  const params = useSearchParams();
  const path = window.location.href.split('/').at(-1);
  useEffect(() => {
    const categoryId = getFromLS('categoryId');
    dispatch(getListBookByCategoryId(`CategoryId=${categoryId}&Start=0&Length=10`));
    scrollToTop();
  }, [location]);
  const handleShowSidebar = () => {
    if (!showCategorySidebar) dispatch(setShowCategorySidebar(true));
  };
  useEffect(() => {
    return () => {
      dispatch(changePathName(null));
    };
  }, []);

  useEffect(() => {
    dispatch(changePathName(decodeURIComponent(path || '')));
    if (path === 'parent-category') {
      dispatch(changePathName('古典文学'));
      dispatch(getListBookByCategoryId(`CategoryId=43&Start=0&Length=10`));
      setToLS('subActive', 43);
    }
  }, []);

  const navigate = useNavigateParams();
  const handleNavigateDetailCategory = (item: SubCategories) => {
    dispatch(getCategoryId(item.id));
    navigate(`${PATH_LISTING}/${item.name}`);
    dispatch(fetchProductList({ ...CATEGORY_PARAMS, categoryIds: item.id }));

    dispatch(setCurrentActive(item.id));
    setToLS('subActive', item.id);
    const selectCategory: any = {
      categories: categoriesSelected,
      idSubcategory: item.id,
    };
    dispatch(categorySelected(selectCategory));
  };

  return (
    <ParentCategoryStyled>
      <div className="header-title">
        <div className="title">{categoriesSelected?.name}</div>
        {(isMobile || isTablet) && (
          <SharedButton
            onClick={(e) => {
              e.stopPropagation();
              handleShowSidebar();
            }}
            text="フィルター"
            textColor="#303030;"
            borderColor="#D9D9D9"
            bgColor="transparent"
            prevIcon={<FilterMenuIcon />}
            className="filter-btn ignore-onClickOutside"
          />
        )}
      </div>
      <div className="header-customer-line" />
      <div className="header">
        {categoriesSelected?.subCategories.map((item) => (
          <>
            <div className="header-selected">
              <div className="header-text">{item.name}</div>
              {item.products.length > 5 && (
                <div className="detail-category" onClick={() => handleNavigateDetailCategory(item)}>
                  <p>もっと見る</p>
                  <IconChevronRight width={16} height={16} color="#359F08" />
                </div>
              )}
            </div>
            {item.products.length === 0 && <div className="no-item">本はありません</div>}
            {isLoading ? (
              <BookList>
                {renderSkeletonItem(5).map((data, index) => (
                  <ItemBook>
                    <SkeletonCardItem type={'vertical'} />
                  </ItemBook>
                ))}
              </BookList>
            ) : (
              <BookList>
                {item.products.slice(0, 5).map((bookItem) => (
                  <ItemBook>
                    <Link to={`${PATH_DETAILS}/${bookItem.id}`} className="card-item-img">
                      <img src={bookItem.defaultPictureModel?.imageUrl} alt="not" />
                    </Link>
                    <div className="card-item-content font-noto">
                      <Link
                        to={`${PATH_DETAILS}/${bookItem.id}`}
                        className="card-item-content-desc"
                      >
                        {bookItem.productName}
                      </Link>
                      <p className="card-item-content-author">{bookItem.authorName}</p>
                      <div className="pr-info">
                        <span className="card-item-content-price">{bookItem.price}</span>
                      </div>
                    </div>
                  </ItemBook>
                ))}
              </BookList>
            )}
          </>
        ))}
      </div>
    </ParentCategoryStyled>
  );
};
