import { Link } from 'react-router-dom';

import { AppRating } from '@components';
import { IBookDataItem } from '@interfaces';
import { StyledCardItem } from './style';
import { IMAGES } from '@assets';
import { useMediaQuery } from '@utils';
import { SkeletonCardItem } from '../Skeleton/skeletonCardItem';
import { Rate } from 'antd';

interface ICardItem {
  visibleNumber?: boolean;
  amount?: number;
  dataCard: IBookDataItem;
  haveRating?: boolean;
  badge?: string;
  isLoading?: boolean;
}

export const CardItem = ({
  visibleNumber,
  amount,
  dataCard,
  haveRating,
  badge,
  isLoading,
}: ICardItem) => {
  const isMobile = useMediaQuery('(max-width:640px)');

  return (
    <>
      {isLoading ? (
        <SkeletonCardItem></SkeletonCardItem>
      ) : (
        <StyledCardItem className="book-item">
          <Link to={dataCard.navigateUrl || '#'} className="card-item-img">
            <img src={dataCard.img_url} alt="not" />
            {dataCard.isAudioReading ? (
              <img className="card-speaker" src={IMAGES.Speaker} alt="not" />
            ) : null}
            {visibleNumber && (
              <span className="card-number font-noto">
                <span>{amount}</span>
              </span>
            )}
            {badge && <div className="card-item-badge">{badge}</div>}
          </Link>
          <div className="card-item-content font-noto">
            <Link to={dataCard.navigateUrl || '#'} className="card-item-content-desc">
              {dataCard.title}
            </Link>
            <p className="card-item-content-author">{dataCard.author}</p>
            <div className="pr-info">
              <Rate disabled allowHalf defaultValue={dataCard.rating || 0} />
              <span className="card-item-content-price">{dataCard.price}</span>
              {/*{haveRating && <AppRating readonly size={isMobile ? 16 : 20} />}*/}
            </div>
          </div>
        </StyledCardItem>
      )}
    </>
  );
};
