import React, { memo } from 'react';

interface IncreaseIconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
}

export const IncreaseIcon: React.FC<IncreaseIconProps> = memo(
  ({ color = '#000', width = 24, height = 24 }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path d="M3.75 12h16.5M12 3.75v16.5" />
        </g>
      </svg>
    );
  },
);
