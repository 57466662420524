import React, { useEffect, useState } from 'react';
import { Header, PurchaseModuleWrapper, Text } from './style';
import {
  Pagination,
  PurchaseItem,
  SearchEmptyBox,
  ShareInput,
  SkeletonCardItem,
} from '@components';
import { useDispatch } from 'react-redux';
import { fetchPurchaseHistory, selectPurchase, useAppSelector } from '@redux';
import { Simulate } from 'react-dom/test-utils';
import { IBookDataItem } from '@interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { deleteSearchParams, scrollToTop } from '@utils';

let timer: ReturnType<typeof setTimeout>;

export const PurchaseHistoryModule = () => {
  const [searchValue, setSearchValue] = useState<string>();
  const bookSelector = useAppSelector(selectPurchase);
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const {
    isLoading,
    data: { purchaseHistory },
    purchaseHistoryTotalItem,
  } = bookSelector;
  const dispatch = useDispatch();
  const [bookList, setBookList] = useState<IBookDataItem[]>([]);
  const paramsStart = searchParams[0].get('Start');
  useEffect(() => {
    if (paramsStart) {
      dispatch(fetchPurchaseHistory({ PageNumber: paramsStart }));
    } else {
      dispatch(fetchPurchaseHistory({}));
    }
  }, []);
  useEffect(() => {
    if (paramsStart) dispatch(fetchPurchaseHistory({ PageNumber: searchParams[0].get('Start') }));
  }, [paramsStart]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value.trim());
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(fetchPurchaseHistory({ TextSearch: value.trim() }));
    }, 500);
    deleteSearchParams(searchParams, ['Start', 'Length']);
  };

  const handleChangePage = (page: number) => {
    dispatch(fetchPurchaseHistory({ PageNumber: page }));
    navigate(`?Start=${page}&Length=10`);
    scrollToTop();
  };
  return (
    <PurchaseModuleWrapper className="rg-sec">
      <Header>
        <Text textSize="2.4rem" textWeight={700} className="purchase-title">
          購入履歴
        </Text>
        <div className="purchase-input__wrapper">
          <ShareInput
            value={searchValue}
            onChange={(e: InputEvent) => handleSearchValue((e.target as HTMLInputElement).value)}
            sizeSearch="small"
            inputType="search"
            placeholder="書籍名で検索"
          />
        </div>
      </Header>

      <div className="purchase-border_b" />
      {isLoading ? (
        <>
          <SkeletonCardItem type={'horizontal'} size={'regular'} />
          <SkeletonCardItem type={'horizontal'} size={'regular'} />
          <SkeletonCardItem type={'horizontal'} size={'regular'} />
        </>
      ) : (
        <>
          <div className="mb-6">
            {purchaseHistory?.map((data, index) => (
              <PurchaseItem data={data} key={index} />
            ))}
          </div>
          {purchaseHistory.length > 0 && (
            <Pagination
              current={Number(searchParams[0].get('Start')) || 0}
              defaultCurrent={1}
              total={purchaseHistoryTotalItem}
              onChangePage={handleChangePage}
              pageSize={10}
            />
          )}
          {!purchaseHistory?.length ? (
            <SearchEmptyBox text="このアカウントは書籍を購入していません" hasNavBtn={false} />
          ) : null}
        </>
      )}
    </PurchaseModuleWrapper>
  );
};
