import { SubscriptionBlock1, Item } from './style';
import { ICONS } from '@assets';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PATH_SUBSCRIPTION } from '@configs';

export const HeaderSubscription = () => {
  const [isSubscription, setIsSubscription] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === PATH_SUBSCRIPTION) {
      setIsSubscription(true);
    } else {
      setIsSubscription(false);
    }
  }, [pathname]);

  return isSubscription ? (
    <SubscriptionBlock1>
      <div className="title">文豪KOZUCHIのサブスク</div>
      <div className="list-item">
        <Item className="item">
          <div className="information-item">
            約１万点の
            <br />
            古典名作が読み放題
          </div>
          <img className="image-item" src={ICONS.teaLeafIcon} alt="" />
          <div className="price-item">
            10.000p
            <span className="time-item">/mo</span>
          </div>
        </Item>
        <Item className="item">
          <div className="information-item">
            月額４８０円
            <br />
            （税込）
          </div>
          <img className="image-item" src={ICONS.teaLeafIcon2} alt="" />
          <div className="price-item">
            ４８０円
            <span className="time-item">/mo</span>
          </div>
        </Item>
        <Item className="item">
          <div className="information-item">
            1カ月の
            <br />
            無料トライアル
          </div>
          <img className="image-item" src={ICONS.teaLeafIcon3} alt="" />
          <div className="price-item">
            ０円
            <span className="time-item">/mo</span>
          </div>
        </Item>
      </div>
    </SubscriptionBlock1>
  ) : (
    <></>
  );
};
