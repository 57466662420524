import styled from 'styled-components';
import { Row } from 'antd';

export const StyledCategory = styled.div`
  /* width: calc(100% / 5); */
  width: 100%;
  /* position: relative; */

  .category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.4rem;
    width: 100%;
    height: 13.2rem;

    border: 1px solid #b6b6b6;
    border-radius: 4px;

    &-img {
      width: 4.8rem;
      height: 4.8rem;
    }

    &-text {
      margin-top: 1rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #303030;
      text-align: center;
    }
  }

  /* & ~ & {
    margin-left: 1.6rem;
  } */

  .category-modal {
    margin: 0;
    width: calc((100% - 6.4rem) / 5);
    height: fit-content;
    position: absolute;
    background-color: #fff;
    top: 12rem;
    left: 0;
    border: 1px solid #b6b6b6;
    border-radius: 4px;
    box-shadow: 0 0 10px 1px #ccc;
    display: none;

    a {
      display: block;
      padding: 0.5rem 1rem;
      font-family: 'Noto Sans JP';
      line-height: 2rem;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #303030;

      &:hover {
        color: #d42a1c;
      }
    }
  }

  &:hover {
    cursor: pointer;
    transition: 0.5s ease-out;
    .category-item {
      &-text {
        color: #d42a1c;
      }
    }

    .category-modal {
      display: block;
      position: absolute;
    }
  }
`;

export const StyledListingPage = styled.div`
  max-width: 85rem;
  position: relative;
  overflow: hidden;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-text {
      font-style: normal;
      font-weight: ${(props) => props.theme.weight['700']};
      font-size: ${(props) => props.theme.size.xxl};
      line-height: 35px;
      color: ${(props) => props.theme.colors.text};
    }
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.8rem;
    .heading__title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 23px;
      color: #303030;
    }
    .right-btn {
      .text-btn {
        font-weight: 400;
        font-size: 1.4rem;
      }
    }
  }
  .books-list {
    margin-bottom: 3.2rem;
    .book-item {
      width: 100%;
    }
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    max-width: 66.8rem;
  }

  @media (max-width: 992px) and (min-width: 640px) {
    max-width: 99.2rem;
    .header {
      .header-text {
        font-size: 2rem;
      }
      .filter-btn {
        padding: 0.8rem;
      }
    }
  }

  @media (max-width: 640px) {
    .header {
      .header-text {
        font-size: 2rem;
      }
      .filter-btn {
        padding: 0.8rem;
        font-size: 1.2rem;
      }
    }
    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.8rem;
      .heading__title {
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 23px;
        color: ${(props) => props.theme.colors.text};
      }
    }
    .books-list {
      margin-bottom: 2.4rem;
      .book-item {
        width: 100%;
      }
    }
    .sub-divider {
      margin: 2.4rem 0;
    }
  }
`;

export const StyledRow = styled(Row)``;
