import { TEXT_HIGHLIGHT_COLOR } from '@configs';
import { LogApp } from '@utils';
import { Index } from 'firebase/firestore';
import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BlockQuote } from './Quote';

interface IProps {
  text?: string;
  markdown?: any;
  highLightIndex?: number;
  highLightLength?: number;
  isSpeaking?: boolean;
}

export const TextContentViewer = (props: IProps) => {
  const { text, markdown, highLightIndex = 0, highLightLength, isSpeaking } = props;

  const headlineRegex = /^\s*##(.*)/;
  const markdownRegex = (terms: any) => new RegExp('\\[([^\\]]*)\\]\\(' + terms + ':([^)]*)\\)');
  const autoMarkdownRegex = (terms: any) => new RegExp(terms, 'i');

  if (!text) return <></>;

  let key = 0;
  let textIndex = 0;

  const replaceInString =
    (regex: string | RegExp, componentFactory: any, firstOnly?: boolean) =>
    (obj: any): any[] => {
      if (typeof obj !== 'string') return [obj];

      const results = [];
      let fragment = obj || '';

      while (!!fragment && fragment !== '') {
        const match = fragment.match(regex);

        if (!!match && !!match?.length) {
          results.push(fragment);
          break;
        }

        const idx = fragment.indexOf(match?.[0] || '');
        const prefix = fragment.substring(0, idx);
        const postfix = fragment.substring(idx + Number(match?.[0]?.length), fragment?.length);

        results.push(prefix, componentFactory(match));

        if (firstOnly) {
          results.push(postfix);
          break;
        }

        fragment = postfix;
      }

      return results;
    };

  const mediaFactory = (match: any) => (
    <img className="img-fluid mt-5" key={key++} title={match?.[1]} id={match?.[2]} />
  );
  const replaceMediaTags = replaceInString(markdownRegex('media'), mediaFactory);

  const rubyFactory = (match: any) => <ruby className="rbText">{match}</ruby>;

  const replaceRubyTags = replaceInString(markdownRegex('ruby'), rubyFactory);

  const blockQuoteFactory = (match: any) => (
    <BlockQuote key={key++} reference={match[2]} quote={match[1]} />
  );
  const replaceBlockQuotes = replaceInString(markdownRegex('quote'), blockQuoteFactory);

  const headlineFactory = (match: any) => {
    if (match) {
      return (
        <p key={key++} className="h3 lead bolder">
          {match?.[1]}
        </p>
      );
    } else return <></>;
  };
  const replaceHeadlines = replaceInString(headlineRegex, headlineFactory);

  const linkFactory = (thePageFor: any) => (match: any) =>
    (
      <Link key={key++} to={thePageFor(match?.[2])}>
        {match?.[1]}
      </Link>
    );

  const xlinkFactory = (theUrlFor: any) => (match: any) =>
    (
      <a
        className="link-lighter"
        key={key++}
        href={theUrlFor(match?.length >= 3 ? match?.[2] : match?.[1])}
        rel="noreferrer"
        target="_kozuchi"
      >
        {match?.[1]}
      </a>
    );
  const replaceLinks = replaceInString(
    markdownRegex('link'),
    xlinkFactory((x: any) => x),
  );

  const autoMarkdownLinkFactory = (thePageFor: any, substitute: any) => (match: any) =>
    (
      <Link key={key++} to={thePageFor(substitute)}>
        {match}
      </Link>
    );
  const autoMarkdownXLinkFactory = (substitute: any) => (match: any) =>
    (
      <a key={key++} href={substitute} rel="noreferrer" target="_kozuchi">
        {match}
      </a>
    );

  const autoMarkdown = (obj: any) => {
    if (typeof obj !== 'string') return [obj];

    let results = [obj];
    for (const md of markdown) {
      let replaced: any = [];
      for (const result of results) {
        const regex = autoMarkdownRegex(md.markdownRegex);
        const replacement = md.markdownReplacement;
        const type = md.markdownType;

        const factory = autoMarkdownXLinkFactory(replacement);
        // let factory = autoMarkdownXLinkFactory(replacement);
        switch (type) {
          case 'book':
            // factory = autoMarkdownLinkFactory(bookDetailPage, replacement);
            break;
        }

        replaced = replaced.concat(replaceInString(regex, factory)(result));
      }

      results = replaced;
    }
    return results;
  };

  const replaceInlineElements = (lineText: any, index: number) => {
    if (typeof lineText !== 'string') return [lineText];
    let speakingResults = '';
    let firstTexts = '';
    let endTexts = '';

    const results = lineText?.replace(/(\r\n|\n|\r|\t)/gm, '');
    const hasMiniText = results?.includes('');

    const textLgPrev =
      text
        ?.split('\n')
        ?.[index - 1]?.replace(/(\r\n|\n|\r|\t)/gm, '')
        ?.replace(/ /g, '+')?.length || 0;
    textIndex += textLgPrev;

    const highlightLg = highLightLength || 1;
    const highlightPosition = highLightIndex - textIndex;

    if (isSpeaking && highLightIndex >= textIndex && highLightIndex < textIndex + results?.length) {
      //slice ky tu dang doc
      speakingResults = results?.slice(highlightPosition, highlightPosition + highlightLg);
      firstTexts = results?.slice(0, highlightPosition);
      endTexts = results?.slice(highlightPosition + highlightLg);
    }

    LogApp('resultsText', text?.length, text);
    LogApp('resultsText2', results?.length, results);
    LogApp('speaking dt', {
      highLightIndex,
      highLightLength,
      highlightPosition,
      firstTexts,
      speakingResults,
      endTexts,
    });

    return (
      <p className={`content-text my-3 bookText_${textIndex}`} key={++key}>
        {isSpeaking &&
        !hasMiniText &&
        highlightPosition >= 0 &&
        !!highLightLength &&
        highLightIndex < textIndex + results.length ? (
          <>
            {firstTexts}
            <span className="hightLight-text">{speakingResults}</span>
            {endTexts}
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: results }} />
        )}
      </p>
    );
  };

  return (
    <StyledWord id="txt-book-content">
      {text
        ?.split('\n')
        // ?.flatMap(replaceRubyTags)
        ?.flatMap(replaceInlineElements)}
    </StyledWord>
  );
};

const StyledWord = styled.div`
  color: '#000';
  font-size: 12px;
  .hightLight-text {
    background-color: ${TEXT_HIGHLIGHT_COLOR};
  }
`;
