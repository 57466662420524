import { UserOutlined } from '@ant-design/icons';
import { Menu, MenuProps, Popover } from 'antd';
import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ICONS, IMAGES } from '@assets';
import { CustomDrawer, SearchInput } from '@components';
import {
  BookRequestIcon,
  CartIcon,
  HeartIcon,
  Search,
  SettingBookIcon,
  SubscriptionIcon,
  UserIcon,
} from '../Icon';
import { SharedButton } from '../shared';
import { MobileNavBar } from './MobileNavBar';
import {
  CustomDropdown,
  getItem,
  IconWrapper,
  Label,
  MobileHeaderAvatar,
  MobileInputContainer,
  MobileWrapper,
  SearchIconWrapper,
  StyledHeader,
  UserInfoWrapper,
} from './styles';

import {
  PATH_BOOK_REQUEST,
  PATH_CART,
  PATH_CHANGE_PASSWORD,
  PATH_HOME,
  PATH_LOGIN,
  PATH_MY_BOOKS,
  PATH_NOTIFY_SETTINGS,
  PATH_PROFILE,
  PATH_SUBSCRIBER_RANK,
  PATH_SUBSCRIPTION,
  PATH_WISH_LIST,
  USER_MENU_SIDE_BAR,
} from '@configs';
import { OptionProductsType } from '@interfaces';
import { RootState, selectAuth, selectCart, selectCustomer, selectSubscription, useAppSelector } from '@redux';
import { LogApp, scrollToTop, swapElement, useMediaQuery } from '@utils';
import { MobileUserSetting } from './MobileUserSetting';
import { CartShortcut } from '../CartShortcut/CartShortcut';
import { useSelector } from 'react-redux';
import { themes } from '@theme';

interface IProps {
  role?: any;
  redirectToLogin?: () => void;
  value: string;
  isLogin?: string;
  onLogout: () => void;
  onSearch: (value: string) => void;
  onSelect: (data: string) => void;
  options: OptionProductsType[];
  onPressEnter?: () => void;
  onFetchWishList: () => void;
  openSearchDrawer: boolean;
  setOpenSearchDrawer: (value: boolean) => void;
  onSubmit: () => void;
  onClickIcon?: () => void;
  onChange?: (value: string) => void;
}

export const Header = memo(
  forwardRef((props: IProps, ref?: React.Ref<any>) => {
    const {
      redirectToLogin,
      value,
      isLogin,
      onLogout,
      onSelect,
      onSearch,
      options,
      onPressEnter,
      onFetchWishList,
      openSearchDrawer,
      setOpenSearchDrawer,
      onSubmit,
      onClickIcon,
      onChange,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const {
      data: { wishlist, cart },
    } = useAppSelector(selectCart);

    const isMobile = useMediaQuery('(max-width: 640px)');
    const {
      auth: { userInfo, role },
    } = useSelector((state: RootState) => state);
    const [openDropdownMenu, setOpenDropdownMenu] = useState<boolean>(false);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const {
      data,
      isLoading,
    } = useAppSelector(selectCustomer);
    const userProfile = data?.userProfile || {data: userInfo};
    const { subState } = useAppSelector(selectSubscription);
    const [open, setOpen] = useState(true);
    const isOpenPopover = (isOpen: boolean) => {
      setOpen(isOpen);
      const x = setTimeout(() => {
        setOpen(true);
        clearTimeout(x);
      }, 1000);
    };
    const showAdminLink =
      role?.includes('Administrator') ||
      role?.includes('Vendors') ||
      role?.includes('SuperAdministrators');

    useEffect(() => {
      if (!isMobile) {
        setOpenDrawer(false);
        setOpenSearchDrawer(false);
      }
    }, [isMobile]);

    useEffect(() => {
      setOpenDropdownMenu(false);
      setOpenDrawer(false);
      setOpen(!location.pathname.includes(PATH_CART));
    }, [location]);

    const handleNavigate = (path: string, isLogout?: boolean, isLoginAdmin?: boolean) => {
      LogApp('cek', path, isLogout, isLoginAdmin);
      if (isLoginAdmin) {
        const adminUrl = process.env.REACT_APP_ADMIN_LINK;
        window?.open(adminUrl, '_blank', 'noopener,noreferrer');
        return;
      }
      if (!isLoginAdmin && isLogout) {
        onLogout();
        navigate(path);
      }
      scrollToTop();
      navigate(path);
    };

    const handleOpenDropdown = (isOpen: boolean) => {
      setOpenDropdownMenu(isOpen);
    };
    const setTextColorFocus = (path: string) => {
      return location.pathname.startsWith(path) ? themes.theme.light.colors.primary : '#000';
    };
    const handleSubmenuItemClick = (item: any) => {
      const key = Number(item?.key) || 0;
      switch (key) {
        case 8: {
          navigate(PATH_PROFILE);
          break;
        }
        case 9: {
          navigate(PATH_CHANGE_PASSWORD);
          break;
        }
        case 10: {
          navigate(PATH_NOTIFY_SETTINGS);
          break;
        }
        default:
          navigate(PATH_HOME);
          break;
      }
      setOpenDropdownMenu(false);
    };

    
    // console.log({userProfile});
    

    const getUserMenuData = useMemo(() => {
      const startIndexRemove = 2;
      const removeCount = 1;
      const startIndexAdd = -1;
      const addCount = 0;
      const userProfile = [
        ...USER_MENU_SIDE_BAR,
        ...(showAdminLink
          ? [
              {
                id: 12,
                icon: BookRequestIcon,
                title: '管理画面',
                path: 'path_admin',
                isLoginAdmin: true,
              },
            ]
          : []),
      ];
      userProfile.splice(startIndexRemove, removeCount);
      userProfile.splice(startIndexAdd, addCount, USER_MENU_SIDE_BAR[2]);
      LogApp('prev', userProfile);
      // const newSidebar = userProfile.move(7,8)
      if (showAdminLink) {
        swapElement(userProfile, 7, 8);
        LogApp('prev 2', JSON.stringify(userProfile));
        swapElement(userProfile, 8, 9);
        LogApp('prev 3', JSON.stringify(userProfile));
      }
      return userProfile;
    }, []);

    const handleOpenDrawer = () => {
      setOpenDrawer(!openDrawer);
    };

    const handleSearchDrawer = () => {
      setOpenSearchDrawer(!openSearchDrawer);
    };

    useEffect(() => {
      if (isLogin) onFetchWishList();
    }, []);

    const renderItem: MenuProps['items'] = getUserMenuData.map((item: any, i) => {
      const Icon = item?.icon;
      return {
        key: item?.id,
        label: item?.isUserInfo ? (
          <Link to={PATH_PROFILE}>
            <UserInfoWrapper>
              {userProfile && userProfile?.data && userProfile?.data?.avatarUrl ? (
                <img
                  src={userProfile?.data?.avatarUrl}
                  alt="header-user-info"
                  className="user-info__img"
                />
              ) : (
                <img className="user-info__img" src={ICONS.defaultAvatar} alt="avatar" />
              )}
              <div className="df-col">
                <p className="text-ellipsis">
                  {`${userProfile?.data?.firstName || ''} ${userProfile?.data?.lastName || ''}` ||
                    item?.userName}
                </p>
                <p className="text-ellipsis">{userProfile?.data?.email || '' || item?.userMail}</p>
              </div>
            </UserInfoWrapper>
          </Link>
        ) : item?.subTitles ? (
          <div>
            <Menu
              mode="inline"
              onClick={handleSubmenuItemClick}
              items={[
                getItem(
                  item?.title,
                  'submenu',
                  <SettingBookIcon />,
                  item?.subTitles.map((e: any) => getItem(e.title, e.id)),
                ),
              ]}
            />
          </div>
        ) : (
          <Label
            onClick={() =>
              item?.path === PATH_SUBSCRIBER_RANK
                ? handleNavigate(PATH_SUBSCRIPTION)
                : handleNavigate(item?.path || '', item?.isLogout, item?.isLoginAdmin)
            }
          >
            {item?.title}
          </Label>
        ),
        icon: (
          <IconWrapper>
            {!item?.isUserInfo && !item?.isLogout && !item?.isLoginAdmin && Icon && <Icon />}
          </IconWrapper>
        ),
      };
    });

    return (
      <StyledHeader isLogin={isLogin} ref={ref}>
        <div className="app-container">
          <Link to="/" className="header__logo logo">
            <img title="KOZUCHI" alt="KOZUCHI" src={IMAGES.headerLogo} />
          </Link>
          <div className="header__right">
            <div className="form-search">
              <form id="search" action="/search">
                <SearchInput
                  value={value}
                  onSelect={onSelect}
                  onSearch={onSearch}
                  onChange={onChange}
                  options={options}
                  placeholder="書籍名や著者で検索"
                  allowClear
                  onPressEnter={onPressEnter}
                  onClickIcon={onClickIcon}
                  maxlength={10}
                />
              </form>
            </div>
            {isMobile && (
              <MobileWrapper>
                <SearchIconWrapper onClick={handleSearchDrawer}>
                  <Search width={20} height={20} color="#fff" className="mobile-search-icon" />
                </SearchIconWrapper>

                <div className="mobile-header-user">
                  {isLogin ? (
                    <MobileHeaderAvatar
                      src={userProfile?.data?.avatarUrl || ''}
                      onClick={handleOpenDrawer}
                    />
                  ) : (
                    <UserOutlined
                      className="mobile-header-icon"
                      onClick={() => handleNavigate(PATH_LOGIN)}
                    />
                  )}
                  <span
                    className="mobile-user-name"
                    onClick={isLogin ? handleOpenDrawer : () => handleNavigate(PATH_LOGIN)}
                  >
                    {isLogin
                      ? `${userProfile?.data?.firstName || ''} ${userProfile?.data?.lastName || ''}`
                      : 'ログイン'}
                  </span>
                </div>
              </MobileWrapper>
            )}

            <div className="ctr header-actions">
              {isLogin && (
                // <SharedButton
                //   text="サブスクリプション"
                //   prevIcon={<SubscriptionIcon />}
                //   textClassName="heb__text"
                //   className="header-btn"
                //   textColor={setTextColorFocus(PATH_MY_BOOKS || PATH_SUBSCRIPTION)}
                //   onClick={() =>
                //     handleNavigate(subState.data?.success ? PATH_MY_BOOKS : PATH_SUBSCRIPTION)
                //   }
                // />
                <></>
              )}
              <SharedButton
                text="本の広場"
                prevIcon={<BookRequestIcon />}
                textClassName="heb__text"
                className="header-btn"
                textColor={setTextColorFocus(PATH_BOOK_REQUEST)}
                onClick={() => handleNavigate(PATH_BOOK_REQUEST)}
              />
              <SharedButton
                text={
                  <React.Fragment>
                    <span className="ctr__text">ウィッシュリスト</span>
                    <span className="wishlist-qty">({isLogin ? wishlist?.total : 0})</span>
                  </React.Fragment>
                }
                prevIcon={<HeartIcon />}
                textColor={setTextColorFocus(PATH_WISH_LIST)}
                textClassName="heb__text"
                className="header-btn"
                onClick={() => handleNavigate(PATH_WISH_LIST)}
              />
              {!isMobile ? (
                <>
                  {open ? (
                    <div className="btn-popover">
                      <Popover
                        overlayClassName="cart-sc"
                        content={
                          <CartShortcut
                            lengthCart={cart?.data?.items?.length}
                            changePopover={isOpenPopover}
                            isLogin={isLogin}
                          />
                        }
                        trigger="hover"
                      >
                        <SharedButton
                          text={
                            <React.Fragment>
                              <span className="ctr__text">カート</span>
                              <span className="wishlist-qty">
                                ({isLogin ? cart?.data?.items?.length : 0})
                              </span>
                            </React.Fragment>
                          }
                          prevIcon={<CartIcon />}
                          textClassName="heb__text"
                          className="header-btn"
                          textColor={setTextColorFocus(PATH_CART)}
                          onClick={() => handleNavigate(PATH_CART)}
                        />
                      </Popover>
                    </div>
                  ) : (
                    <SharedButton
                      text={
                        <React.Fragment>
                          <span className="ctr__text">カート</span>
                          <span className="wishlist-qty">
                            ({isLogin ? cart?.data?.items?.length : 0})
                          </span>
                        </React.Fragment>
                      }
                      prevIcon={<CartIcon />}
                      textClassName="heb__text"
                      className="header-btn"
                      textColor={setTextColorFocus(PATH_CART)}
                      onClick={() => handleNavigate(PATH_CART)}
                    />
                  )}
                </>
              ) : (
                <SharedButton
                  text={
                    <React.Fragment>
                      <span className="ctr__text">カート</span>
                      <span className="wishlist-qty">({cart?.data?.items?.length || 0})</span>
                    </React.Fragment>
                  }
                  prevIcon={<CartIcon />}
                  textClassName="heb__text"
                  className="header-btn"
                  textColor={setTextColorFocus(PATH_CART)}
                  onClick={() => handleNavigate(PATH_CART)}
                />
              )}
              {isLogin ? (
                <CustomDropdown
                  onOpenChange={handleOpenDropdown}
                  trigger={['click']}
                  menu={{ items: renderItem }}
                  overlayClassName="user-profile-overlay"
                  open={openDropdownMenu}
                  overlayStyle={{
                    minWidth: 250,
                    maxWidth: 250,
                    left: '970px',
                    top: '70px',
                    borderRadius: 10,
                  }}
                >
                  <SharedButton
                    onClick={handleOpenDropdown}
                    text={`${userProfile?.data?.firstName || ''} ${
                      userProfile?.data?.lastName || ''
                    }`}
                    prevIcon={
                      isLoading ? (
                        <img src={ICONS.defaultAvatar} alt="avatar" />
                      ) : userProfile?.data?.avatarUrl ? (
                        <img src={userProfile?.data?.avatarUrl} alt="" />
                      ) : (
                        <UserIcon />
                      )
                    }
                    textClassName="heb__text"
                    className="header-btn"
                  />
                </CustomDropdown>
              ) : (
                <SharedButton
                  text="ログイン"
                  prevIcon={<UserIcon />}
                  textClassName="heb__text"
                  className="header-btn"
                  onClick={redirectToLogin}
                />
              )}
            </div>
          </div>
        </div>

        {isMobile && <MobileNavBar />}
        <CustomDrawer placement="left" onClose={() => setOpenDrawer(false)} open={openDrawer}>
          <MobileUserSetting
            avatar={userProfile?.data?.avatarUrl || ''}
            userName={`${userProfile?.data?.firstName || ''} ${userProfile?.data?.lastName || ''}`}
            userMail={userProfile?.data?.email || ''}
            onClose={() => setOpenDrawer(false)}
            onLogout={onLogout}
          />
        </CustomDrawer>

        <CustomDrawer
          placement="left"
          onClose={() => setOpenSearchDrawer(false)}
          open={openSearchDrawer}
        >
          <MobileInputContainer>
            <SearchInput
              value={value}
              onSelect={onSelect}
              onSearch={onSearch}
              options={options}
              placeholder="書籍名や著者で検索"
              allowClear
              onPressEnter={onPressEnter}
              onClickIcon={onClickIcon}
              maxlength={10}
            />
            <SharedButton
              text="探す"
              textClassName="heb__text"
              className="header-btn"
              onClick={onSubmit}
            />
          </MobileInputContainer>
        </CustomDrawer>
      </StyledHeader>
    );
  }),
);
