import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledFooter = styled.div`
  width: 100%;
  .footer-top {
    width: 100%;
    background-color: ${(p: any) => p?.theme.colors?.bgFooter};
    padding: 7.7rem 0;
    &-container {
      display: flex;
      justify-content: space-between;

      @media (max-width: 640px) and (orientation: portrait) {
        display: block;
        padding: 0 1.6rem;
      }
    }
    &-payment {
      &-content {
        .text-credit-card {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
          /* text-align: center; */
        }
        .list-card-method {
          margin-top: 3.2rem;
          width: 26rem;
          display: flex;
          justify-content: flex-start;
          gap: 1.6rem;
          flex-wrap: wrap;
          img {
            width: 7.6rem;
            height: 4.2rem;
            border-radius: 4px;
          }
        }

        @media (max-width: 640px) and (orientation: portrait) {
          margin-bottom: 3.2rem;
        }
      }
    }
    &-supported {
      &-content {
        ol {
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          li {
            list-style-position: inside;
            font-family: 'Noto Sans JP';
            font-style: normal;
            ul {
              li {
                margin-left: 1rem;
                list-style-type: disc;
                list-style-position: inside;

                @media (max-width: 640px) and (orientation: portrait) {
                  margin-left: 0.3rem;
                }
              }
            }
          }
        }
      }
    }
    &-freedownload {
      &-content {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        @media (max-width: 640px) and (orientation: portrait) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    &-header {
      h5 {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.3rem;
        /* text-align: center; */
        color: #303030;
      }
    }
    &-line {
      width: 7.6rem;
      height: 2px;
      margin-top: 1.6rem;
      background-color: #fd7e14;
    }
    &-content {
      margin-top: 2.4rem;
    }

    @media (max-width: 640px) and (orientation: portrait) {
      padding-top: 3.2rem;
    }
  }

  .footer-bottom {
    width: 100%;
    height: 10.4rem;
    background-color: #fff;

    .footer-privacy__wrapper {
      display: flex;
      justify-content: flex-end;
      padding: 2rem 0 2rem 2rem;
    }

    @media (max-width: 640px) and (orientation: portrait) {
      height: 100%;

      .footer-privacy__wrapper {
        justify-content: space-between;
      }
    }
  }
`;

export const ButtonDownLoad = styled(Link)`
  img {
    width: 17.5rem;
    height: 5rem;
  }

  @media (max-width: 640px) and (orientation: portrait) {
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PrivacyButton = styled.div`
  border-right: 1px solid ${(props) => props.theme.colors.gray['200']};

  &:last-child {
    .footer-btn__label {
      margin-right: 0;
    }
    border-right: none;
  }

  .footer-btn__label {
    color: ${(props) => props.theme.colors.text};
    margin: 0 1.6rem;
    white-space: nowrap;
    cursor: pointer;
  }

  @media (max-width: 640px) and (orientation: portrait) {
    .footer-btn__label {
      font-size: ${(props) => props.theme.size.xs};
      font-weight: ${(props) => props.theme.weight['500']};
      margin: 0 1.6rem 0 0;
    }

    &:last-child > p {
      margin-right: 0;
    }
  }
`;
