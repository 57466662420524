import { useMediaQuery } from '@utils';
import { Slider } from 'antd';
import { SliderMarks } from 'antd/lib/slider';
import React, { Dispatch } from 'react';
import { CloseIcon, IconSubcribe } from '../Icon';
import { IconRabit } from '../Icon/Rabit';
import { SelectInput } from '../SelectInput';
import { StyleContentSetting, StyledHeaderSetting, StyledSetting } from './ReadBookSettingStyle';

interface IProps {
  rate: any;
  pitch: any;
  availableVoices: any;
  voiceIndex: any;
  setVoiceIndex: any;
  setRate: any;
  setPitch: Dispatch<any>;
  onClose: () => void;
}

export const ReadBookSetting = (props: IProps) => {
  const { availableVoices, rate, pitch, voiceIndex, setVoiceIndex, setRate, setPitch, onClose } =
    props;
  const isMobile = useMediaQuery('(max-width:1024px)');

  const speedMarks: SliderMarks = {
    0.5: '0.5',
    0.75: '0.75',
    1: '1',
    1.25: '1.25',
    1.5: '1.5',
    1.75: '1.75',
    2: '2',
    2.25: '2.25',
    2.5: '2.5',
    2.75: '2.75',
    3: '3',
  };

  const pitchMarks: SliderMarks = {
    0.2: '0.2',
    0.4: '0.4',
    0.6: '0.6',
    0.8: '0.8',
    1: '1',
    1.2: '1.2',
    1.4: '1.4',
    1.6: '1.6',
    1.8: '1.8',
    2: '2',
  };

  return (
    <StyledSetting>
      <StyledHeaderSetting fontSize={isMobile ? '16px' : '24px'}>
        <div className="header-write">
          <p>音声調整</p>
        </div>
        <div className="btn-close" onClick={onClose}>
          <CloseIcon />
        </div>
      </StyledHeaderSetting>
      <StyleContentSetting>
        <div className="setting-speed">
          <p>音声速度</p>
          <div className="ic-speed">
            <IconSubcribe />
            <IconRabit />
          </div>
          <Slider
            min={0.5}
            max={3}
            defaultValue={1}
            step={0.25}
            id="rate"
            onChange={(newValue) => {
              setRate(newValue);
            }}
            className="custom-slider"
            tooltip={{ open: false, placement: 'top' }}
            marks={speedMarks}
          />
        </div>
        <div className="voice-type">
          <p>音声タイプ</p>
          <select
            className="voice-select"
            id="voice"
            name="voice"
            value={voiceIndex || ''}
            onChange={(event) => {
              setVoiceIndex(Number(event.target.value));
            }}
          >
            {availableVoices.map((option: any, index: number) => (
              <option key={option.voiceURI} value={index}>
                {`${option.lang} - ${option.name}`}
              </option>
            ))}
          </select>
        </div>
        <div className="pitch">
          <p>ピッチ</p>
          <div className="level">
            <p>低</p>
            <p>高</p>
          </div>
          <Slider
            min={0.2}
            max={2}
            defaultValue={1}
            step={0.2}
            id="pitch"
            onChange={(newValue) => {
              setPitch(newValue);
            }}
            tooltip={{ open: false, placement: 'top' }}
            marks={pitchMarks}
          />
        </div>
      </StyleContentSetting>
    </StyledSetting>
    // <div>
    //   <label htmlFor="voice">Voice</label>
    //   <div
    //     style={{
    //       display: 'flex',
    //       marginBottom: 12,
    //     }}
    //   >
    //     <div style={{ display: 'flex', flexDirection: 'row' }}>
    //       <label htmlFor="rate">Speed: </label>
    //       <div className="rate-value">{rate}</div>
    //     </div>
    //     <input
    //       type="range"
    //       min="0.5"
    //       max="2"
    //       defaultValue="1"
    //       step="0.1"
    //       id="rate"
    //       onChange={(event) => {
    //         setRate(event.target.value);
    //       }}
    //     />
    //   </div>
    //   <div
    //     style={{
    //       display: 'flex',
    //       marginBottom: 12,
    //     }}
    //   >
    //     <div style={{ display: 'flex', flexDirection: 'row' }}>
    //       <label htmlFor="pitch">Pitch: </label>
    //       <div className="pitch-value">{pitch}</div>
    //     </div>
    //     <input
    //       type="range"
    //       min="0"
    //       max="2"
    //       defaultValue="1"
    //       step="0.1"
    //       id="pitch"
    //       onChange={(event) => {
    //         setPitch(event.target.value);
    //       }}
    //     />
    //   </div>
    //   <label htmlFor="message">Message</label>
    // </div>
  );
};
