import { ICon } from '@interfaces';

export const IconSubcribe = (props: ICon) => {
  const { width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_806_6627)">
        <path
          d="M20.2844 9.02197H16.6703C15.9976 7.28339 14.751 5.82843 13.164 4.89056C13.1444 4.87628 13.1237 4.86273 13.1023 4.85028C13.0818 4.83856 13.0609 4.82776 13.0397 4.81824C11.8425 4.13873 10.4597 3.75 8.98755 3.75C7.5152 3.75 6.13202 4.13892 4.93469 4.8186C4.91382 4.82812 4.89313 4.83856 4.8728 4.85028C4.85138 4.86273 4.83087 4.8761 4.81128 4.89038C2.38715 6.323 0.756775 8.96173 0.753296 11.9747V11.9773V11.9788V11.9844V15.0469H0.703125C0.314758 15.0469 0 15.3616 0 15.75C0 16.1384 0.314758 16.4531 0.703125 16.4531H0.753113V19.5157C0.753113 19.9039 1.06787 20.2189 1.45624 20.2189H3.33911C4.76495 20.2189 5.92511 19.0587 5.92511 17.6329V16.4531H12.05V19.5157C12.05 19.9039 12.3647 20.2189 12.7531 20.2189H16.5189C16.907 20.2189 17.222 19.9039 17.222 19.5157V17.6329C17.222 17.2081 17.1183 16.8071 16.936 16.4531H20.2844C22.3332 16.4531 24 14.7863 24 12.7375C24 10.6888 22.3332 9.02197 20.2844 9.02197ZM5.22198 15.0469H2.15936V12.6804H5.22198C5.47321 12.6804 5.7052 12.5464 5.83081 12.3289L7.51062 9.41913H10.4645L12.1443 12.3289C12.2699 12.5464 12.5021 12.6804 12.7531 12.6804H15.8157V15.0469H5.22198ZM15.7789 11.2742H13.1591L11.6823 8.716L12.988 6.45447C14.5192 7.56537 15.5733 9.29535 15.7789 11.2742ZM11.7709 5.74988L10.4645 8.01288H7.51062L6.20416 5.74988C7.05469 5.36865 7.99677 5.15625 8.98755 5.15625C9.97833 5.15625 10.9204 5.36865 11.7709 5.74988ZM4.98706 6.45447L6.29279 8.716L4.81604 11.274H2.19617C2.40161 9.29535 3.45593 7.56519 4.98706 6.45447ZM4.51886 17.6329C4.51886 18.2833 3.9895 18.8126 3.33911 18.8126H2.15936V16.4531H4.51886V17.6329ZM15.8157 18.8126H13.4562V16.4531H14.636C15.2864 16.4531 15.8157 16.9823 15.8157 17.6329V18.8126ZM20.2844 15.0469H17.222V11.9844C17.222 11.9826 17.2218 11.9806 17.2218 11.9788C17.2218 11.9782 17.222 11.9777 17.222 11.9773C17.222 11.9764 17.2218 11.9756 17.2218 11.9747C17.2213 11.4461 17.17 10.9292 17.0739 10.4282H20.2844C21.5577 10.4282 22.5938 11.4642 22.5938 12.7375C22.5938 14.0109 21.5577 15.0469 20.2844 15.0469Z"
          fill="#303030"
        />
        <path
          d="M20.2842 11.2812C20.0991 11.2812 19.9182 11.3561 19.7872 11.4871C19.6559 11.6182 19.5811 11.7993 19.5811 11.9844C19.5811 12.1695 19.6559 12.351 19.7872 12.4817C19.9182 12.6124 20.0994 12.6875 20.2842 12.6875C20.4693 12.6875 20.6508 12.6124 20.7815 12.4817C20.9122 12.351 20.9873 12.1695 20.9873 11.9844C20.9873 11.7993 20.9122 11.6182 20.7815 11.4871C20.6508 11.3561 20.4693 11.2812 20.2842 11.2812Z"
          fill="#303030"
        />
      </g>
      <defs>
        <clipPath id="clip0_806_6627">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
