import axiosClient from './axiosClient';

const url = 'api/Category';
export const categoryApi = {
  getCategories: (params?: any) => {
    return axiosClient.get(url, params);
  },
  getCategoryByCategoryId: (params: string) => {
    return axiosClient.get(`${url}/listProductByCategory?${params}`);
  },
};
