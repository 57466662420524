import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  changeSettingNotification,
  changeUserPassword,
  getSettingNotification,
  getUserProfileDefault,
  updateUserProfile,
  uploadAvatar,
} from '../action';
import { RootState } from '@redux';
import { BaseResponseProps, CustomerProps, SettingNotification } from '@interfaces';
import { toast } from 'react-toastify';

export interface CustomerSliceProps {
  isLoading: boolean;
  data: {
    userProfile: BaseResponseProps<CustomerProps>;
    userProfileUpdate: BaseResponseProps<CustomerProps> | null;
    changeAvatar: BaseResponseProps | null;
    changePassword: BaseResponseProps | null;
    changeSettingNotification: BaseResponseProps | null;
  };
  notificationCustomer: SettingNotification;
  message: string;
}

const initialState: CustomerSliceProps = {
  isLoading: false,
  data: {
    userProfile: {
      data: {
        lastName: '',
        firstName: '',
        phoneNumber: '',
        address: '',
        gender: '',
        birthday: '',
        active: true,
        avatarUrl: undefined,
        email: '',
        nameFormated: null,
      },
      messages: '',
      success: true,
      code: 200,
      errors: null,
      total: 0,
    },
    userProfileUpdate: null,
    changeAvatar: null,
    changePassword: null,
    changeSettingNotification: null,
  },
  notificationCustomer: {
    isMobileAppNotificationEnable: false,
    isEmailNotificationEnable: false,
  },
  message: '',
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetUpdateUserState: (state) => {
      state.data.userProfileUpdate = null;
    },
    getUserLoginFacebook: (state, action) => {
      state.data.userProfile = action.payload;
    },
    setLoadingCustomer: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateUserProfile.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<CustomerProps>>) => {
          state.isLoading = false;
          state.data.userProfileUpdate = payload;
          if (payload.success) {
            toast.success('プロファイルの変更成功');
          } else {
            toast.error('プロファイルの変更の失敗');
          }
        },
      )
      .addCase(updateUserProfile.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserProfileDefault.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserProfileDefault.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.userProfile = payload as any;
      })
      .addCase(getUserProfileDefault.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadAvatar.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAvatar.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.changeAvatar = payload;
      })
      .addCase(uploadAvatar.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(changeUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeUserPassword.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.changePassword = payload;
      })
      .addCase(changeUserPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(changeSettingNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeSettingNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.changeSettingNotification = action.payload;
        if (action.payload.success) {
          toast.success('更新に成功');
        } else {
          toast.error('アップデートに失敗しました');
        }
      })
      .addCase(changeSettingNotification.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSettingNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSettingNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notificationCustomer = action.payload?.data;
      })
      .addCase(getSettingNotification.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { resetUpdateUserState, getUserLoginFacebook, setLoadingCustomer } =
  customerSlice.actions;
export const customerReducer = customerSlice.reducer;
export const selectCustomer = (state: RootState) => state.customer;
