import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Rate } from 'antd';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';

import { IMAGES } from '@assets';
import { CommentRatingRequestProps, IBookItem } from '@interfaces';
import { BookOpenIcon, StarIcon, StarItemIcon, WishListIcon } from '../Icon';
import { SharedButton } from '../shared';
import { StyleContainer } from './style';
import { useMediaQuery } from '@utils';
import { useNavigateParams } from '@hooks';
import { postAComment } from '@redux';
import { PATH_DETAILS, PATH_READ_BOOK } from '@configs';

const { TextArea } = Input;
export const BookItem = ({ data, type, isPurchasedBook, removeFromWishlist }: IBookItem) => {
  const maxLengthTextArea = 1000;
  const [rating, setRating] = useState<any[]>([]);
  const [ratingReview, setRatingReview] = useState(0);
  const [isLiked, setIsLiked] = useState(true);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const navigateParam = useNavigateParams();
  const navigate = useNavigate();
  const [openReview, setOpenReview] = useState(false);
  const [valueTextArea, setValueTextArea] = useState<string>('');
  const dispatch = useDispatch();
  const onPostComment = () => {
    if (ratingReview > 0) {
      const rqBody: CommentRatingRequestProps = {
        ProductId: data?.productId || 0,
        ReviewText: valueTextArea,
        Rating: ratingReview,
      };
      dispatch(postAComment(rqBody));
      setRatingReview(0);
      setOpenReview(false);
    }
  };
  useEffect(() => {
    const genArrayBaseOnNumber = new Array(data.ratingSum).fill(1);
    setRating(genArrayBaseOnNumber);
  }, []);
  const toggleLike = () => {
    setIsLiked(!isLiked);
  };
  return (
    <>
      <StyleContainer className="book-item-container">
        <Link to={`${PATH_DETAILS}/${data.productId}`} className="book-item__left">
          <img src={data.pictureMobileModel?.imageUrl} alt="not" title="" />
          {data.audioReading && <img src={IMAGES.Speaker} alt="not" />}
        </Link>
        <div className="book-item__right">
          <div className="book-item__right-top">
            {type === 'wish-list' && (
              <>
                <Link to={`${PATH_DETAILS}/${data.productId}`}>
                  <h5 className="book-item-title">{data.bookName}</h5>
                </Link>
                <p className="book-item-text book-item-author">著者 : {data.authorName}</p>
                {/* <p className="book-item-text book-item-publisher">
                  出版社 : <span className="book-item-color">{data.publisherName}</span>
                </p>*/}
              </>
            )}
            {type === 'purchase' && (
              <>
                <Link to={`${PATH_DETAILS}/${data.productId}`}>
                  <h5 className="book-item-title">{data.bookName}</h5>
                </Link>
                <p className="book-item-text book-item-color">{data.authorName}</p>
                <p className="book-item-text book-item-price">{data.price}</p>
                {/* <p className="book-item-text">{data.publisherName}</p>*/}
                {!isMobile && (
                  <div className="book-item-list-btn">
                    {isPurchasedBook && (
                      <>
                        <SharedButton
                          className="book-read-now has-responsive"
                          prevIcon={<BookOpenIcon />}
                          text={'今すぐ読む'}
                          onClick={() =>
                            navigate(`${PATH_READ_BOOK}/${data.productId}`, { replace: true })
                          }
                        />
                        <SharedButton
                          className="book-writing-review has-responsive"
                          prevIcon={<StarIcon />}
                          text={'レビューを書く'}
                          onClick={() => setOpenReview(!openReview)}
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            {type === 'preview' && (
              <>
                <Link to={`${PATH_DETAILS}/${data.productId}`}>
                  <h5 className="book-item-title">{data.bookName}</h5>
                </Link>
                <div className="book-item-text book-item-rate-date">
                  <div className="list-star">
                    {rating.map((_, i) => (
                      <StarItemIcon key={i} />
                    ))}
                  </div>
                  <p className="book-item-date">{data.publishDate}</p>
                </div>
                <p className="book-item-text">{data.published}</p>
                <p className="book-item-text book-item-preview-title">
                  {data.pictureMobileModel?.title}
                </p>
                <p className="book-item-text book-item-preview-content">
                  {data.pictureMobileModel?.alternateText}
                </p>
              </>
            )}
          </div>
          {type === 'wish-list' && (
            <div className="book-item__right-bottom">
              <SharedButton
                onClick={() => removeFromWishlist(data.shoppingCartItemId)}
                textColor={isLiked ? '#D42A1C' : '#303030'}
                className="book-item-btn-wish-list"
                prevIcon={
                  <WishListIcon fill={isLiked ? '#D42A1C' : '#303030'} width={16} height={16} />
                }
                text="ウィッシュリストから削除"
              />
            </div>
          )}
        </div>
      </StyleContainer>
      {isMobile && (
        <div className="book-item-list-btn">
          {isPurchasedBook && (
            <>
              <SharedButton
                className="book-read-now has-responsive"
                prevIcon={<BookOpenIcon />}
                text={'今すぐ読む'}
                onClick={() => navigate(`${PATH_READ_BOOK}/${data.productId}`, { replace: true })}
              />
              <SharedButton
                className="book-writing-review has-responsive"
                prevIcon={<StarIcon />}
                text={'レビューを書く'}
                onClick={() => setOpenReview(!openReview)}
              />
            </>
          )}
        </div>
      )}
      <Modal
        className="modal-cart hide-close modal-review"
        style={{ fontSize: isMobile ? '12px' : '14px' }}
        title="書籍レビュー"
        okText={<div className="flex-center-all">レビュー送信</div>}
        open={openReview}
        onOk={onPostComment}
        onCancel={() => {
          setOpenReview(false);
          setRatingReview(0);
        }}
        okButtonProps={{ disabled: ratingReview === 0 }}
      >
        <div className="modal-review-container">
          <div className="modal-review-container-img">
            <Link to={`${PATH_DETAILS}/${data.productId}`} className="book-item__left">
              <img src={data.pictureMobileModel?.imageUrl} alt="not" title="" />
              {data.audioReading && <img src={IMAGES.Speaker} alt="not" className="icon-audio" />}
            </Link>
          </div>
          <div className="modal-review-container-info">
            <h2 className="book-item-title">{data.bookName}</h2>
            <p className="book-item-text book-item-color">{data.authorName}</p>
            <p className="book-item-text book-item-price">{data.vendorName}</p>
          </div>
        </div>
        <div className="review">
          <Rate
            className="review-rate"
            defaultValue={5}
            value={ratingReview}
            onChange={setRatingReview}
          />
          <TextArea
            rows={4}
            placeholder={'この書籍についてご感想を自由にご記入ください'}
            maxLength={maxLengthTextArea}
            value={valueTextArea}
            onChange={(value) => setValueTextArea(value.target.value)}
          />
          <div className="length-textarea">
            {valueTextArea.length}/{maxLengthTextArea}
          </div>
        </div>
      </Modal>
    </>
  );
};
