import styled from 'styled-components';
import { ReactElement } from 'react';

export type Alignment = 'center' | 'left' | 'right';

export interface NotificationPageProps {
  buttonText: string;
  buttonColor?: string;
  onButtonClick: () => void;
  headerText: string;
  headerAlignment?: Alignment;
  descriptionAlignment?: Exclude<Alignment, 'right'>;
  description: string;
  buttonType?: 'rounded' | 'basic' | 'social';
  icon?: ReactElement;
}

export const StyledHeader = styled.div<Pick<NotificationPageProps, 'headerAlignment'>>`
  text-align: ${(props) => (props.headerAlignment ? props.headerAlignment : 'left')};
`;

export const StyledDescription = styled.div<Pick<NotificationPageProps, 'descriptionAlignment'>>`
  text-align: ${(props) => (props.descriptionAlignment ? props.descriptionAlignment : 'left')};
`;

export const StyleContainer = styled.div<{ background?: string }>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  overflow: hidden;
  background-image: ${(p) => `url(${p?.background})`};
  @media screen and (max-width: 640px) {
    background-position-x: 50%;
    background-size: 200% 100%;
  }

  .overlay-inset-all {
    position: fixed;
    inset: 0;
    z-index: 1;
  }

  .header__logo {
    padding-top: calc(17 / 360 * 100%);
    height: fit-content;
    @media (max-width: 640px) {
      padding-top: 4.6rem;
      margin: 0 auto 2rem;
      width: 15rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .redirect-notify {
    z-index: 2;
    background-color: ${(props) => props?.theme.colors.body};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    padding: 4rem;
    border-radius: 8px;
    @media (min-width: 641px) {
      width: 547px;
    }
  }

  .icon-header {
    display: flex;
    justify-content: center;
    margin-bottom: 2.8rem;
  }

  .desc-spacing {
    margin: 0.8rem 0 2.8rem 0;
  }
`;
