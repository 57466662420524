import styled, { css } from 'styled-components';
import { Dropdown, MenuProps } from 'antd';
import React from 'react';

interface TextStyleProps {
  fontSize?: string;
  fontWeight?: number;
  colorText?: string;
  isBefore?: boolean;
  mt?: string;
}

export const StyledHeader = styled.div<{
  isLogin?: boolean | string;
}>`
  position: fixed;
  width: 100%;
  /* background: ${({ theme }: any) => theme?.colors?.bgHeader}; */
  background: ${(p: any) => p?.theme.colors?.bgHeader};
  padding: 2rem 0;
  box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
  transition: 0.2s;
  z-index: 999;

  .app-container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;

    @media (max-width: 640px) and (orientation: portrait) {
      padding: 0 1.6rem;
    }
  }

  .header__logo {
    @media (max-width: 640px) and (orientation: portrait) {
      img {
        width: 13.5rem;
      }
    }
  }

  .header__right {
    display: flex;
    height: 100%;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 5.125rem;
    .form-search {
      width: ${(p) => (p.isLogin ? '12rem' : '37.5rem')};
      flex: ${(p) => p.isLogin && 1};
      /* max-width: 25.625rem; */
      margin-right: 3.6rem;
      /* width: 100%; */
      position: relative;
      /* @media (max-width: 1200px) {
        max-width: 12.625rem;
      } */
      @media (min-width: 1560px) {
        width: ${(p) => !p.isLogin && '41rem'};
      }

      @media (max-width: 640px) and (orientation: portrait) {
        display: none;
      }
    }
    .header-actions {
      display: flex;
      align-items: center;
      .header-btn {
        width: fit-content;
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-left: 2rem;
        }
        .prev-icon {
          img {
            width: 2.8rem;
            height: 2.8rem;
            border-radius: 50%;
          }
        }
      }

      .btn-popover {
        margin-left: 2rem;
      }

      @media (max-width: 640px) and (orientation: portrait) {
        display: none;
      }
    }
    .flyout-cart {
      display: none;
      position: absolute;
      top: 100%;
      right: 40px;
      z-index: 100;
      width: 300px;
      box-shadow: 0 0 2px rgb(0 0 0 / 25%);
      background-color: #fff;
    }
    .heb__text {
      /* font-family: 'Noto Sans JP'; */
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
      margin-left: 8px;
      @media (max-width: 989px) {
        display: none;
      }
      @media (max-width: 1280px) {
        font-size: 1.2rem;
      }

      @media (min-width: 1024px) and (max-width: 1200px) {
        display: none;
      }
    }
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      margin-left: 4.125rem;
      .form-search {
        .header__search {
          width: 25rem;
        }
      }
    }
    /* iPads (portrait) ----------- */
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: 4.125rem;
      .form-search {
        .header__search {
          width: 30rem;
        }
      }
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
      margin-left: 4.125rem;
      .form-search {
        .header__search {
          width: 25rem;
        }
      }
    }

    /* mobile (portrait) ----------- */

    @media (max-width: 640px) and (orientation: portrait) {
      margin-left: 0;
      display: flex;
      justify-content: flex-end;
    }
  }
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mobile-header-user {
    margin-left: 1.6rem;

    .mobile-header-icon {
      color: ${(props) => props.theme.colors.text};
      margin-right: 0.8rem;

      & > svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .mobile-user-name {
      font-size: 1.3rem;
      font-weight: ${(props) => props.theme.weight['500']};
      color: ${(props) => props.theme.colors.text};
    }
  }
`;

export const SearchIconWrapper = styled.div`
  @media (max-width: 640px) and (orientation: portrait) {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 6rem;
    padding: 0.6rem;
  }
`;

export const MobileHeaderAvatar = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.8rem;
`;

export const Label = styled.div`
  font-size: ${(props) => props.theme.size.s};
  font-weight: ${(props) => props.theme.weight['400']};
  line-height: 1.5rem;
  margin-left: 0.5rem;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const CustomDropdown = styled(Dropdown)``;

export const IconWrapper = styled.div`
  margin: 0;
`;

export const UserInfoWrapper = styled.div`
  border-bottom: 1px solid #cccccc;
  display: flex;

  .user-info__img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  .df-col {
    width: calc(100% - 50px);
  }
`;
export type MenuItem = Required<MenuProps>['items'][number];

export function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const UserSettingWrapper = styled.div`
  .setting-data__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .setting-wrapper {
    margin-right: 0.8rem;

    svg {
      width: 1.5rem;
      height: 2rem;
    }
  }

  .px-24 {
    padding: 2.4rem;
  }

  .d-f-item {
    display: flex;
    align-items: center;
  }
`;

export const DataContainer = styled.div<{ isTopBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.8rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.isTopBorder &&
    css`
      border-top: 0.1rem solid ${(props) => props.theme.colors.gray['200']};
      margin-bottom: 0.8rem;
    `}
`;

export const UserSettingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.4rem 1rem rgba(0, 0, 0, 0.05);

  .d-f {
    display: flex;
  }

  .setting-avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    margin-right: 0.8rem;
    img {
      @media (max-width: 640px) {
        border-radius: 50%;
      }
      width: 100%;
      height: 100%;
    }
  }
`;

export const Text = styled.p<TextStyleProps>`
  color: ${(props) => props.colorText || props.theme.colors.text};
  font-weight: ${(props) => props.fontWeight || props.theme.weight['700']};
  font-size: ${(props) => props.fontSize || props.theme.size.s};
  margin-top: ${(props) => props.mt || 'none'};

  &.sub-data-wrapper {
    position: relative;
    padding-left: 3rem;

    &:before {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      display: block;
      content: '';
      width: 1.6rem;
      height: 0.1rem;
      position: absolute;
      z-index: 5;
      background-color: ${(props) => props.theme?.colors?.gray['300']};
      margin: 0 0.8rem;
    }
  }

  ${(props) =>
    props.isBefore &&
    css`
      &::before {
        display: block;
        content: '';
        width: 1.6rem;
        height: 0.03rem;
        background: #b6b6b6;
        margin: 0 0.8rem;
      }
    `}
`;

export const SubMenuContainer = styled.div`
  .sub-data-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
`;

export const MobileInputContainer = styled.div`
  padding: 2.4rem;
  display: flex;
  align-items: center;
  .header-btn {
    background: ${(props) => props.theme?.colors.primary};
    padding: 1rem;
    margin-left: 2rem;
    width: 6rem;
    .text-btn {
      color: ${(props) => props.theme?.colors.body};
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2rem;
      font-family: 'Noto Sans JP';
    }
  }
`;
