import React, { FC, useCallback, useEffect, useState } from 'react';
import { UserFilterWrapper } from './style';
import { SharedButton, ShareInput } from '../shared';
import _ from 'lodash';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchAuthorSelect, fetchCategoriesSelect, selectCommon, useAppSelector } from '@redux';

const options = [
  {
    id: 1,
    title: 'option1',
    value: 'option1',
  },
  {
    id: 2,
    title: 'option2',
    value: 'option2',
  },
  {
    id: 3,
    title: 'option3',
    value: 'option3',
  },
];

interface UserFilterProps {
  onSearch: (value: string) => void;
  onAuthorChanged: (value: string) => void;
  onCategoryChanged: (value: string) => void;
  onFilterClick: () => void;
}

export const UserFilter: FC<UserFilterProps> = ({
  onSearch,
  onCategoryChanged,
  onAuthorChanged,
  onFilterClick,
}) => {
  const [category, setCategory] = useState('');
  const [author, setAuthor] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedVendorId, setSelectedVendorId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const { selectCategories, selectAuthor } = useAppSelector(selectCommon);

  const handleDebouncedChange = useCallback(_.debounce(onSearch, 500), [onSearch]);

  const handleSearchValueChange = useCallback(
    (event) => {
      setSearchValue(event.target.value);
      handleDebouncedChange(event.target.value);
    },
    [setSearchValue, handleDebouncedChange],
  );

  useEffect(() => {
    setSearchValue(searchValue);
  }, [searchValue]);

  useEffect(() => {
    dispatch(fetchCategoriesSelect());
    dispatch(fetchAuthorSelect());
  }, []);

  return (
    <UserFilterWrapper>
      <div className="filter-group__wrapper">
        <div className="input-width mobile">
          {/*<SelectInput
            className="input"
            value={category}
            onChange={setCategory}
            options={options}
            placeholder="書籍名や著者で検索"
          />*/}
          <Select
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={selectedCategoryId}
            popupClassName="custom-option-list"
            showSearch
            placeholder="全て"
            onChange={(e) => {
              onCategoryChanged(e);
              setSelectedCategoryId(e);
            }}
            options={selectCategories.data}
          />
        </div>
        <div className="input-width mobile">
          {/*<SelectInput
            className="input"
            value={author}
            onChange={setAuthor}
            options={options}
            placeholder="書籍名や著者で検索"
          />*/}
          <Select
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            popupClassName="custom-option-list"
            showSearch
            placeholder="全て"
            onChange={(e) => {
              onAuthorChanged(e);
              setSelectedVendorId(e);
            }}
            value={selectedVendorId}
            options={selectAuthor.data}
          />
        </div>
        <div className="input-width search-book">
          <ShareInput
            value={searchValue}
            onChange={(e: any) => {
              handleSearchValueChange(e);
              setSelectedVendorId(null);
              setSelectedCategoryId(null);
            }}
            inputType="search"
            sizeSearch="small"
            className="user-filter-input__container"
            placeholder="書籍名で検索"
          />
        </div>

        <div>
          <SharedButton
            onClick={() => {
              onFilterClick();
              setSearchValue('');
            }}
            text="フィルター"
            textClassName="user-filter-btn__text"
            className="user-filter-btn__bg"
          />
        </div>
      </div>
    </UserFilterWrapper>
  );
};
