import React from 'react';

import { ButtonDownLoad, PrivacyButton, StyledFooter } from './style';
import { IMAGES } from '@assets';
import { FOOTER_PRIVACY_LABELS, PATH_FOOTER_PRIVACY } from '@configs';
import { useNavigateParams } from '@hooks';

export const Footer = () => {
  const navigate = useNavigateParams();
  return (
    <StyledFooter>
      <div className="footer-top font-noto">
        <div className="app-container footer-top-container">
          <div className="footer-top-payment">
            <div className="footer-top-header">
              <h5>ご利用可能なお支払い方法</h5>
              <div className="footer-top-line"></div>
            </div>
            <div className="footer-top-content footer-top-payment-content">
              <p className="text-credit-card">クレジットカード</p>
              <div className="list-card-method">
                <img src={IMAGES.cardVisa} alt="not" />
                <img src={IMAGES.cardMaster} alt="not" />
                <img src={IMAGES.cardDinersClub} alt="not" />
                <img src={IMAGES.cardJCB} alt="not" />
                <img src={IMAGES.cardAmerica} alt="not" />
              </div>
            </div>
          </div>
          <div className="footer-top-supported">
            <div className="footer-top-header">
              <h5>対応OS / 推奨ブラウザ</h5>
              <div className="footer-top-line"></div>
            </div>
            <div className="footer-top-content footer-top-supported-content font-noto">
              <ol>
                <li>
                  パソコン
                  <ul>
                    <li>Microsoft Edge最新バージョン</li>
                    <li>Google Chrome最新バージョン</li>
                    <li>Safari最新バージョン</li>
                  </ul>
                </li>
                <li>
                  スマートフォン
                  <ul>
                    <li>Android最新バージョン（Google Chrome最新バージョン）</li>
                    <li>iOS最新バージョン（Safari最新バージョン）</li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
          {/*<div className="footer-top-freedownload">
            <div className="footer-top-header">
              <h5>無料ダウンロードアプリ</h5>
              <div className="footer-top-line"></div>
            </div>
            <div className="footer-top-content footer-top-freedownload-content">
              <ButtonDownLoad to={'#'}>
                <img src={IMAGES.appStore} alt="not" />
              </ButtonDownLoad>
              <ButtonDownLoad to={'#'}>
                <img src={IMAGES.googlePlay} alt="not" />
              </ButtonDownLoad>
            </div>
          </div>*/}
        </div>
      </div>
      <div className="footer-bottom">
        <div className="app-container footer-privacy__wrapper">
          {FOOTER_PRIVACY_LABELS.map((label, index) => {
            return (
              <PrivacyButton key={index}>
                <p
                  className="footer-btn__label"
                  onClick={() => navigate(PATH_FOOTER_PRIVACY[index])}
                >
                  {label}
                </p>
              </PrivacyButton>
            );
          })}
        </div>
      </div>
    </StyledFooter>
  );
};
