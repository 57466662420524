import styled from 'styled-components';

export const StyledTerm = styled.div`
  .header-term {
    padding-bottom: 2.4rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border-bottom: 1px solid #ececec;
    font-weight: ${(props) => props.theme.weight['700']};
    font-size: ${(props) => props.theme.size.xl};
    line-height: 2.9rem;
    font-family: 'Noto Sans JP';
    font-style: normal;
  }
`;
export const StyledContentTerm = styled.div<{
  isMobile?: boolean;
}>`
  .title {
    font-weight: ${(props) =>
      props.isMobile ? props.theme.weight['500'] : props.theme.weight['700']};
    font-size: 2.8rem;
    line-height: 4.1rem;
    margin: 2.4rem 0 2rem 0;
    font-family: 'Noto Sans JP';
    font-style: normal;
    @media (min-width: 1024px) {
      font-weight: ${(props) => props.theme.weight['700']};
      font-size: ${(props) => props.theme.size.xl};
    }
  }
  .contract {
    margin-top: 2rem;
    p {
      font-weight: ${(props) => props.theme.weight['400']};
      font-size: ${(props) => props.theme.size.s};
      line-height: 2rem;
      margin-top: 0.8rem;
      text-align: justify;
    }
  }
  .issued {
    margin-top: 2.4rem;
    display: flex;
    .date {
      font-weight: ${(props) => props.theme.weight['700']};
    }
  }
`;
