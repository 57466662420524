import styled from 'styled-components';

export const StyledSubscription = styled.div`
  width: 100%;
`;

export const SubscriptionBlock2 = styled.div`
  @media (min-width: 641px) {
    width: 100%;
    .action {
      margin-top: 4rem;
      margin-bottom: 8rem;
      width: 100%;
      display: flex;
      justify-content: center;
      .button-link {
        background: #d42a1c;
        height: 4.4rem;
        border-radius: 4px;
        gap: 8px;
        padding: 1.2rem 1.6rem;
        .text-btn {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2rem;
          text-align: center;
          color: #ffffff;
        }
      }
      .button-forward {
        background: #d42a1c;
        height: 4.4rem;
        border-radius: 4px;
        gap: 8px;
        padding: 1.2rem 1.6rem;
        margin-left: 2rem;
        .text-btn {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2rem;
          text-align: center;
          color: #ffffff;
        }
      }
    }
    .desc {
      width: 100%;
    }
  }

  @media (min-width: 375px) and (max-width: 640px) {
    width: 100%;
    .action {
      margin: 4rem 0 6rem 0;
      width: 100%;
      display: flex;
      .button-link {
        width: calc((100% - 2rem) / 2);
        background: #d42a1c;
        margin-left: 1.7rem;
        gap: 8px;
        border-radius: 4px;
        height: 4.1rem;
        .text-btn {
          color: #fff;
          text-align: center;
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }
      .button-forward {
        margin-left: 2rem;
        width: calc((100% - 2rem) / 2);
        background: #d42a1c;
        margin-right: 1.7rem;
        padding: 1.2rem 1.6rem;
        gap: 8px;
        border-radius: 4px;
        height: 4.1rem;
        .text-btn {
          color: #fff;
          text-align: center;
          font-size: 1.2rem;
          line-height: 1.7rem;
        }
      }
    }
    .desc {
      margin: 0 1.6rem;
    }
  }
`;

export const DescItem = styled.div`
  width: 100%;
  max-width: 77.4rem;
  .ticker-icon {
    min-width: 20px;
    width: 20px;
    max-width: 20px;
    height: 20px;
  }
  .desc-img {
    height: 2.4rem;
    min-width: 2.4rem;
    max-width: 2.4rem;
    min-height: 2.4rem;
    max-height: 2.4rem;
    @media (min-width: 640px) {
      height: 2rem;
      min-width: 2rem;
      max-width: 2rem;
      min-height: 2rem;
      max-height: 2rem;
    }
  }
  @media (min-width: 641px) {
    display: flex;
    align-items: center;
    margin: 4rem auto;
    .detail {
      margin-left: 1.6rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 2.3rem;
      color: #303030;
    }
  }
  @media (min-width: 375px) and (max-width: 640px) {
    width: 100%;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    .desc-img {
      width: 2rem;
      height: 2rem;
    }
    .detail {
      margin-left: 1.6rem;
      height: 6.8rem;
      font-size: 1.2rem;
      line-height: 1.7rem;
      font-weight: 400;
      font-style: normal;
      font-family: 'Noto Sans JP';
      color: #303030;
      display: flex;
      align-items: center;
    }
  }
`;
