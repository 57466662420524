import styled from 'styled-components';
import { Checkbox } from 'antd';
import { themes } from '@theme';
export const StyledCart = styled.div<{
  isMobile?: boolean;
}>`
  .content {
    width: 100%;
    border: ${(props) => (props.isMobile ? 'none' : '1px solid #ECECEC')};
    border-radius: ${(props) => (props.isMobile ? '0' : '16px')};
    padding: ${(props) => (props.isMobile ? '0' : '60px 60px 60px 60px')};
  }
  .step {
    display: flex;
    justify-content: center;
  }
  .ant-steps-finish-icon {
    display: none;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background: ${(props) => props.theme?.colors?.primary};
    border-color: ${(props) => props.theme?.colors?.primary};
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
    display: none;
  }
  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 15px;
    left: 64px;
    width: 100%;
    height: 2px;
    background: ${(p) => p.theme?.colors?.gray['300']};
    padding: 0;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${(props) => props.theme?.colors?.primary};
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: ${(props) => props.theme?.colors?.primary};
  }
  .ant-steps-item-container {
    display: inline-grid;
    justify-items: center;
    text-align: center;
  }
  .ant-steps-item-title::after {
    display: none;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${(props) => props.theme?.colors?.text2};
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d9d9d9;
  }
  .ant-steps-vertical {
    display: flex;
    flex-direction: row;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme?.colors?.primary};
  }
  .ant-checkbox-wrapper:hover {
    border-color: ${(props) => props.theme?.colors?.primary};
  }
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(props) => props.theme?.colors?.primary};
  }
  @media screen and (min-width: 640px) {
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      left: 80px;
    }
  }
  @media screen and (max-width: 640px) {
    .ant-steps-item-container {
      display: inline-grid;
      justify-items: center;
      text-align: center;
    }
    .ant-steps-vertical {
      flex-direction: row;
    }

    .ant-steps-item-finish {
      display: none;
    }

    .ant-steps-item-title {
      font-size: 12px;
    }

    .ant-steps-item-tail::after {
      display: none;
    }
  }
  .cart-container {
    width: 100%;
    margin-top: 0.8rem;
    .cart-empty {
      width: 100%;
      height: 31.9rem;
      border: 1px solid ${(props) => props.theme?.colors?.gray['200']};
      border-radius: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &-btn {
        margin-top: 2.4rem;
        width: 28.4rem;
        height: 4.4rem;
        background: ${(p) => p?.theme?.colors?.primary};
        border-radius: 4px;
        span {
          color: #fff;
        }
      }
      h5 {
        margin-top: 2.4rem;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.5rem;
        color: ${(props) => props.theme?.colors?.gray['600' + '']};
      }

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        padding: 0 1.6rem;
      }
    }
  }
  .message {
    width: 100%;
    height: 23px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #000000;
    padding-top: 24px;
    padding-bottom: 46px;
  }
  .check-all {
    display: flex;
    gap: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    padding-bottom: 6.4rem;
  }

  @media screen and (min-width: 640px) {
    .label-checkbox {
      width: 70%;
    }
    .title-action {
      flex: 1;
    }
    .title {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
    .book-price-delete {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }
`;

export const StepsCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 80px;
  .step {
    width: 32px;
    height: 32px;
    background: #d9d9d9;
    border-radius: 50px;
    font-weight: 400;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    padding-top: 7.5px;
    z-index: 2;
    color: ${(props) => props.theme?.colors?.text2};
    .des-step {
      position: absolute;
      width: 96px;
      height: 17px;
      left: 28px;
      top: 270px;
    }
    .ant-steps-item-title {
      font-size: 14px;
    }
  }
  .line {
    position: absolute;
    width: 200px;
    height: 0;
    border: 1px solid #d9d9d9;
  }
  .process {
    background: ${(props) => props.theme?.colors?.primary};
    color: ${(props) => props.theme?.colors?.gray['200']};
  }
`;

export const Check = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme?.colors?.primary};
  }
  .ant-checkbox-checked:after {
    border: unset;
  }
  /*.ant-checkbox {
    border: 2px ${(props) => props.theme?.colors?.primary} solid;
    border-radius: 5px;
  }*/
`;

export const ItemCart = styled.div`
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid ${(props) => props.theme?.colors?.gray['200']};
  .image-item {
    width: inherit;
    .skeleton-img {
      width: 12.8rem;
      height: 18.2rem;
    }
  }
  img {
    width: 128px;
    height: 182px;
  }
  .des-item {
    width: 54%;
  }
  .des-item1 {
    width: calc(54% + 160px);
  }
  .book-item-text {
    margin-bottom: 8px;
  }
  .book-item-title {
    font-weight: 500;
    line-height: 20px;
    color: ${(props) => props.theme?.colors?.gray['600' + '']};
  }
  .book-item-author {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${(props) => props.theme?.colors?.text3};
  }
  .book-item-publisher {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: ${(props) => props.theme?.colors?.text2};
  }
  .book-item-color {
    color: ${(props) => props.theme?.colors?.text3};
  }
  .book-item-price {
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: ${(props) => props.theme?.colors?.primary};
    .skeleton-price {
      min-width: 8rem;
      width: 8rem;
    }
  }
`;

export const TotalCart = styled.div<{
  isMobile?: string;
}>`
  display: flex;
  justify-content: flex-end;
  gap: 70px;
  margin-top: 24px;
  .row {
    flex-direction: column;
    p {
      line-height: 20px;
    }
    .margin-text {
      margin-bottom: 12px;
      margin-top: 12px;
      color: ${(props) => props.theme?.colors?.text};
    }
    .total {
      font-weight: 700;
      color: ${(props) => props.theme?.colors?.primary};
    }
    .amount {
      font-weight: 700;
      color: ${(props) => props.theme?.colors?.gray['600' + '']};
    }
  }
  .text-right {
    text-align: right;
  }
`;

export const ButtonCart = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  gap: 20px;
  .disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

export const StyleBtnRecycle = styled.div`
  display: flex;
  justify-content: end;
  gap: 7px;
  margin-top: 40px;
  //padding: 1rem;
  height: fit-content;
  cursor: pointer;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${(p) => p.theme?.colors?.gray['300']};
  }
  @media screen and (min-width: 640px) {
    margin-top: 0;
  }
`;

export const Text = styled.p<{
  fontSize?: string;
  fontWeight?: string;
}>`
  color: ${(props) => props.theme.colors.button.background};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
`;
export const StyledModal = styled.div``;
