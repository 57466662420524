import { IButton } from '@interfaces';
import { useRef } from 'react';
import styled, { css } from 'styled-components';

export const SharedButton = (props: IButton) => {
  const {
    className,
    children,
    style,
    text = '',
    prevIcon,
    sufIcon,
    textClassName,
    value,
    onClick,
    typeHtml,
    onMouseEnter,
    onMouseLeave,
    disabled,
    bgColor,
    borderColor,
    btnStyle,
    textColor,
  } = props;

  const submitBtn = useRef<HTMLButtonElement>(null);

  return (
    <StyledButton
      style={style}
      onClick={(action: any) => {
        onClick && onClick(action);
        submitBtn && submitBtn?.current?.click();
      }}
      tabIndex={value}
      className={!className ? 'btn' : `btn ${className}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      bgColor={bgColor}
      borderColor={borderColor}
      textColor={textColor}
      btnStyle={btnStyle}
    >
      <div className={prevIcon ? 'prev-icon spacing' : 'prev-icon'}>{!!prevIcon && prevIcon}</div>
      {!!text && (
        <span
          className={!textClassName ? `text-btn` : `text-btn ${textClassName}`}
          tabIndex={value}
        >
          {text}
        </span>
      )}
      <div className="suf-icon"> {!!sufIcon && sufIcon}</div>
      {typeHtml === 'submit' && (
        <button ref={submitBtn} type={typeHtml} className="hidden-btn">
          Submit
        </button>
      )}
      {children}
    </StyledButton>
  );
};

export const StyledButton = styled.div<{
  disabled?: boolean;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  btnStyle?: 'basic' | 'rounded' | 'social';
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
  height: ${(p) => (p.btnStyle ? 'fit-content' : '100%')};
  /* gap: 0.5rem; */
  border-radius: ${(p) => (p.btnStyle === 'rounded' ? '200px' : '0.4rem')};
  &:hover {
    opacity: 0.85;
  }
  .spacing {
    margin-right: 0.5rem;
  }
  .hidden-btn {
    /* position: absolute;
    opacity: 0;
    visibility: hidden; */
    /* width: 100%;
    height: 100%; */
    display: none;
  }
  .text-btn {
    display: inline-block;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    color: ${(p) => (p.btnStyle === 'rounded' ? '#fff' : '#000')};
  }
  .prev-icon {
    //margin-right: 0.5rem;
  }
  ${(p) =>
    p?.btnStyle &&
    css`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1.2rem 0;
      .text-btn {
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 20px;
        color: #fff;
      }
    `}

  ${(p) =>
    p?.btnStyle === 'social' &&
    css`
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1.4rem 1.6rem;
      .text-btn {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 20px;
        text-align: center;
      }
      .prev-icon {
        position: absolute;
        left: 5%;
        margin-top: auto;
        margin-bottom: auto;
      }
      .suf-icon {
        position: absolute;
        right: 5%;
        margin-top: auto;
        margin-bottom: auto;
      }
    `}

  .text-btn {
    ${(p) => p.textColor && `color: ${p.textColor} !important`};
  }
  ${(p) => p.borderColor && `border: 1px solid ${p.borderColor}`};
  background: ${(p) => (p.bgColor ? p.bgColor : 'transparent')};
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.3;
    `};
`;
