import styled, { css } from 'styled-components';

interface TextStyledProps {
  textColor?: string;
  textSize?: string;
  textWeight?: number;
  mb?: string;
  isTitle?: boolean;
  isCompleted?: boolean;
}
export const PurchaseItemWrapper = styled.div`
  margin-top: 2.4rem;

  .df-wrapper {
    display: flex;
    margin-top: 1.6rem;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  .w-50 {
    width: 40%;
    margin-top: 1.6rem;
    margin-bottom: 2.4rem;
    padding: 0 2rem;
  }

  .mr-l-4 {
    margin-right: 4rem;
  }

  .px-16 {
    padding: 0 1.6rem;
  }
`;

export const PurchaseTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.gray['100']};
  padding: 1.6rem;
`;

export const Text = styled.p<TextStyledProps>`
  color: ${(props) => props.textColor || props.theme.colors.text};
  font-size: ${(props) => props.textSize || props.theme.size.xs};
  font-weight: ${(props) => props.textWeight || props.theme.weight['400']};
  @media (min-width: 768px) {
    font-size: ${(props) => props.textSize || props.theme.size.s};
  }
  &.no-responsive {
    font-size: ${(props) => props.textSize || props.theme.size.s};
  }
  ${(props) =>
    props.isTitle &&
    css`
      color: ${(props) => props.theme.colors.text};
      font-weight: ${(props) => props.theme.weight['500']};
    `};
  &.detail {
    text-align: right;
    font-size: ${(props) => props.textSize || props.theme.size.xs};
    line-height: 2.2rem;
    @media (min-width: 768px) {
      font-size: ${(props) => props.textSize || props.theme.size.s};
    }
  }
`;

export const ItemDetail = styled.div`
  margin-top: 2.4rem;

  .purchase-book-item {
    margin-bottom: 2.4rem;
  }
`;
