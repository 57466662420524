import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseResponseProps, BaseState } from '@interfaces';
import { RootState } from '@redux';
import { toast } from 'react-toastify';
import { removeAllProduct, removeMultipleProduct } from '../action';

interface DeleteProductSliceProps {
  loading: boolean;
  data: BaseResponseProps | null;
  deleteMultiple: BaseState;
}

const initialState: DeleteProductSliceProps = {
  loading: false,
  data: null,
  deleteMultiple: {
    loading: false,
    data: null,
  },
};

const deleteProductSlice = createSlice({
  name: 'deleteProductSlice',
  initialState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(removeAllProduct.pending, (state) => {
      state.loading = true;
    })
      .addCase(
        removeAllProduct.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<any>>) => {
          state.loading = false;
          state.data = payload;
          if (payload.success) {
            toast.success(payload.messages);
          } else {
            toast.error(payload.messages);
          }
        },
      )
      .addCase(removeAllProduct.rejected, (state) => {
        state.loading = false;
      })

      .addCase(removeMultipleProduct.pending, (state) => {
        state.deleteMultiple.loading = true;
      })
      .addCase(
        removeMultipleProduct.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps>) => {
          state.deleteMultiple.loading = false;
          state.deleteMultiple.data = payload;
          if (payload.success) {
            toast.success(payload.messages);
          } else {
            toast.error(payload.messages);
          }
        },
      )
      .addCase(removeMultipleProduct.rejected, (state) => {
        state.deleteMultiple.loading = false;
      });
  },
});

export const deleteProductReducer = deleteProductSlice.reducer;
export const selectDeleteProduct = (s: RootState) => s.deleteCart;
