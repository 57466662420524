import page from './page';
import message from './message';
import validation from './validation';
import path from './path';

export const TRANSLATIONS_EN = {
  page,
  message,
  validation,
  path,
};
