import { memo } from 'react';

import { SharedButton, ShareInput } from '@components';
import { StyledForgotForm } from './forgotStyle';
import { FormikProps } from 'formik';
import { IForgotPassword, IRegister } from '@interfaces';

interface IProps {
  onSubmit?: () => void;
  onCancel?: () => void;
  formik: FormikProps<IForgotPassword>;
}

export const ForgotPasswordComponent = memo((props: IProps) => {
  const { formik, onCancel } = props;

  return (
    <StyledForgotForm>
      <h1 className="heading_title">パスワードを忘れた方</h1>
      <span className="desc">
        以下にメールアドレスを入力してください。パスワードをリセットするためのリンクがメールにて送信されます。
      </span>
      <form onSubmit={formik.handleSubmit} className="forgot-form">
        <div className="forgot-wrap">
          <ShareInput
            label="メールアドレス"
            placeholder=""
            name="email"
            value={formik.values.email}
            errors={formik.errors.email}
            touched={formik.touched.email}
            onChange={formik.handleChange}
            className="input"
            inputMode="email"
            required
            type="text"
          />
          <div className="actions">
            <SharedButton
              onClick={onCancel}
              text="キャンセル"
              className="cancel_btn"
              btnStyle="rounded"
              bgColor="#CECECE"
            />
            <SharedButton
              typeHtml="submit"
              text="リクエスト"
              className="submit_btn"
              btnStyle="rounded"
              bgColor="#D42A1C"
            />
          </div>
        </div>
      </form>
    </StyledForgotForm>
  );
});
