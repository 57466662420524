import React, { useEffect } from 'react';

import axios from 'axios';
import url from 'url';
import qs from 'qs';
import queryString from 'query-string';
import styled from 'styled-components';

import { LogApp, generateRandomId, getFromLS } from '@utils';
import {
  LINE_AUTH_BASE_URL,
  LINE_GET_TOKEN_URL,
  LINE_MAX_AGE_SECONDS,
  LINE_REDIRECT_URI,
  PATH_HOME,
} from '@configs';
import {
  getUserProfileDefault,
  selectAppStateId,
  selectSocialAuth,
  setAccessToken,
  setAppStateId,
  setLoading,
  setLoadingCustomer,
  setRefreshToken,
  setUserInfo,
  setUserInfoLine,
  useAppDispatch,
  useAppSelector,
} from '@redux';
import { LoginLineIcon, SharedButton } from '@components';
import { useDispatch } from 'react-redux';
import { LoginWithLineProps } from '@interfaces';
import { LoginLoading } from './Loading';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '@api';
import { toast } from 'react-toastify';

const redirectURI = process.env.REACT_APP_LINE_REDIRECT_URI || LINE_REDIRECT_URI;
const AlternativeAuthInfo = {
  ClientId: process.env.REACT_APP_LINE_CLIENT_ID,
  ClientSecret: process.env.REACT_APP_LINE_CLIENT_SECRET,
};

export const LineLoginModule = () => {
  const dispatch = useAppDispatch();
  const dispatchDefault = useDispatch();
  const defaultDispatch = useDispatch();
  const navigate = useNavigate();
  const appStateId = useAppSelector(selectAppStateId);
  const {
    line: { loading, data },
  } = useAppSelector(selectSocialAuth);
  useEffect(() => {
    if (data && data.success) {
      dispatchDefault(getUserProfileDefault());
      navigate(`${PATH_HOME}`);
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (!appStateId) genAppStateIdLineLogin();
      getAccessToken(window.location.href);
    }, 100);
  }, []);

  const handleLoginLine = async ({
    AccessToken,
    IdToken,
  }: {
    AccessToken: string;
    IdToken: string;
  }) => {
    const loginWithLINEParams: LoginWithLineProps = {
      AccessToken,
      IdToken,
      ClientId: AlternativeAuthInfo.ClientId || '',
      ClientSecret: AlternativeAuthInfo.ClientSecret || '',
    };

    try {
      dispatch(setLoading(true));
      const res: any = await authAPI.loginWithLine(loginWithLINEParams);
      LogApp('login res', res);
      if (res?.success) {
        dispatch(setUserInfoLine(res));
        dispatch(setUserInfo(res?.data.customer));
        dispatch(setAccessToken(res?.data.accessToken.tokenString));
        dispatch(setRefreshToken(res?.data.refreshToken.tokenString));
        dispatch(setLoadingCustomer(false));
        const memoPath = getFromLS('memoPath');
        if (memoPath) {
          navigate(memoPath);
          localStorage.removeItem('memoPath');
          dispatch(setLoading(false));
        } else {
          navigate(PATH_HOME);
          dispatch(setLoading(false));
        }
      }
    } catch (err: any) {
      toast.error('エラー！エラーが発生しました。後でもう一度試してください');
    } finally {
      dispatch(setLoading(false));
    }
  };

  LogApp(
    'ClientID',
    process.env.REACT_APP_ENV,
    process.env.REACT_APP_LINE_CLIENT_ID,
    process.env.REACT_APP_LINE_CLIENT_SECRET,
  );

  const genAppStateIdLineLogin = () => {
    const genAppStateId = generateRandomId();
    dispatch(setAppStateId(genAppStateId));
  };

  const lineLogin = () => {
    // Build query string.
    const query = queryString.stringify({
      response_type: 'code',
      client_id: AlternativeAuthInfo.ClientId,
      state: appStateId,
      scope: 'profile openid email',
      // nonce: process.env.REACT_APP_LINE_NONCE,
      prompt: 'consent',
      max_age: LINE_MAX_AGE_SECONDS,
      bot_prompt: 'normal',
    });
    // Build the Line authorise URL.
    const lineAuthorizeURL = LINE_AUTH_BASE_URL + query + '&redirect_uri=' + redirectURI;
    LogApp(lineAuthorizeURL);
    // Redirect to external URL.
    window.location.href = lineAuthorizeURL;
  };

  const getAccessToken = (callbackURL: string) => {
    LogApp({ callbackURL });
    const urlParts = url.parse(callbackURL, true);
    const query = urlParts.query;
    const hasCodeProperty = Object.prototype.hasOwnProperty.call(query, 'code');
    // const appStateId = store.getState()?.app?.appStateId;
    //LogApp(store.getState());
    //LogApp('ClientID', process.env.REACT_APP_LINE_CLIENT_ID, process.env.REACT_APP_ENV);
    LogApp(query?.state, { appStateId });
    if (query?.state === appStateId) {
      LogApp({ hasCodeProperty });
      if (hasCodeProperty) {
        const reqBody = {
          grant_type: 'authorization_code',
          code: query.code,
          redirect_uri: redirectURI,
          // redirect_uri: redirectLocalURI,
          client_id: AlternativeAuthInfo.ClientId,
          client_secret: AlternativeAuthInfo.ClientSecret,
        };
        const reqConfig = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        dispatch(setLoading(true));
        axios
          .post(LINE_GET_TOKEN_URL, qs.stringify(reqBody), reqConfig)
          .then((res: any) => {
            // if (setPayload) setPayload(res?.data);
            const idToken = res?.data?.id_token;
            const lineAuthInfo = res.data;
            try {
              if (lineAuthInfo) {
                handleLoginLine({
                  AccessToken: lineAuthInfo.access_token,
                  IdToken: lineAuthInfo.id_token,
                });
              }
              LogApp('tokenLoginLine', idToken);
              // const decodedIdToken = jwt.verify(res.data.id_token, String(process.env.REACT_APP_LINE_CLIENT_SECRET), {
              //   algorithms: ['HS256'],
              //   audience: String(process.env.REACT_APP_LINE_CLIENT_ID),
              //   issuer: 'https://access.line.me',
              //   nonce: process.env.REACT_APP_LINE_NONCE,
              // });
              // if (setIdToken) setIdToken(String(decodedIdToken));
              // if (setIdToken) setIdToken(idToken);
              dispatch(setAppStateId(''));
            } catch (err) {
              // If token is invalid.
              // LogApp(err);
            }
          })
          .catch((err) => {
            //LogApp(err);
          });
        dispatch(setLoading(false));
      }
    } // else LogApp("App state id isn't correct", appStateId + '-' + query?.state);
  };

  return (
    <>
      <StyledLineBtn
        text="LINEでサインイン"
        textColor="#00B900"
        onClick={lineLogin}
        prevIcon={<LoginLineIcon />}
        borderColor="#00C01A"
        btnStyle="social"
        textClassName="text-hover-color"
      />
      {loading ? (
        <LoginLoading>
          {' '}
          <LoadingChild>
            Logging with LINE &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="loading-indicator"></div>
          </LoadingChild>
        </LoginLoading>
      ) : null}
    </>
  );
};

const LoadingChild = styled.div`
  width: 100%;
  justify-content: center;
  color: #fff;
  margin-top: 25rem;
  display: flex;
  align-items: center;
  @keyframes il {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }

  .loading-indicator {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    border-bottom: 2px solid transparent;
    animation: il 2s linear infinite;
  }
`;

const StyledLineBtn = styled((props) => <SharedButton {...props} />)<{
  color?: string;
}>`
  width: 76%;
  margin-bottom: 1rem;
  border-radius: 0.8rem;

  .text-hover-color {
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`;
