import styled from 'styled-components';

interface TextStyledProps {
  textColor?: string;
  textSize?: string;
  textWeight?: number;
}

export const PurchaseModuleWrapper = styled.div`
  //margin-top: -5.5rem;
  .purchase-border_b {
    border-bottom: 1px solid #d9d9d9;
    margin-top: 2.9rem;
  }

  .mb-6 {
    margin-bottom: 6rem;
  }
  .ant-pagination .ant-pagination-item a {
    font-size: ${(props) => props.theme.size.s};
    font-weight: ${(props) => props.theme.weight['500']};
  }
  @media screen and (min-width: 1024px) {
    margin-top: -5.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .purchase-title {
    font-size: 2rem;
  }
  .purchase-input__wrapper {
    width: 30%;
  }

  @media (max-width: 768px) {
    display: block;
    .purchase-input__wrapper {
      width: 100%;
      margin-top: 1.6rem;
    }

    .purchase-title {
      font-size: ${(props) => props.theme.size.xl};
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .purchase-input__wrapper {
      width: 30%;
      margin-top: 1rem;
    }

    .purchase-title {
      font-size: ${(props) => props.theme.size.xxl};
    }
  }
`;

export const Text = styled.p<TextStyledProps>`
  color: ${(props) => props.textColor || props.theme.colors.text};
  font-size: ${(props) => props.textSize || props.theme.size.s};
  font-weight: ${(props) => props.textWeight || props.theme.weight['400']};
`;
