import { SelectDateOfBirthModule } from '@modules';
import { Button, Divider, Radio } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SharedButton, SharedCheckbox, ShareInput } from '../shared';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { CustomerRqBodyProps } from '@interfaces';
import * as Yup from 'yup';
import {
  getUserProfileDefault,
  resetUpdateUserState,
  selectCustomer,
  updateUserProfile,
  useAppSelector,
} from '@redux';
import { toast } from 'react-toastify';
import { PATH_HOME, PHONE_NUMBER_LENGTH_MAX, PHONE_NUMBER_LENGTH_MIN } from '@configs';
import { useNavigateParams } from '@hooks';
import { timeConverter, useMediaQuery } from '@utils';

interface IProps {
  uploadBtnRef: any;
  previewUrl?: string;
  onUpload: () => void;
  onChangeImage: (e: ChangeEvent<any>) => void;
  onSubmit: () => void;
}

export const ChangeProfile = (props: IProps) => {
  const { uploadBtnRef, previewUrl, onUpload, onChangeImage, onSubmit } = props;
  const [defaultAvatar, setDefaultAvatar] = useState<string>();
  const [mail, setMail] = useState<string>('');
  const navigate = useNavigateParams();
  const isMobile = useMediaQuery('(max-width:640px)');
  const {
    data: { userProfile, userProfileUpdate },
    isLoading,
  } = useAppSelector(selectCustomer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfile) {
      formik.setValues({
        LastName: userProfile?.data.lastName || '',
        FirstName: userProfile?.data.firstName || '',
        PhoneNumber: userProfile?.data.phoneNumber || '',
        Address: userProfile?.data.address || '',
        Gender: userProfile?.data.gender || '',
        Birthday: new Date(userProfile.data.birthday),
      });
      setDefaultAvatar(userProfile.data.avatarUrl);
      setMail(userProfile.data.email);
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfileUpdate && userProfileUpdate.success) {
      dispatch(getUserProfileDefault());
      navigate(PATH_HOME);
    }
    if (userProfileUpdate) {
      dispatch(resetUpdateUserState());
    }
  }, [userProfileUpdate]);

  const formik = useFormik<CustomerRqBodyProps>({
    initialValues: {
      LastName: '',
      FirstName: '',
      PhoneNumber: '',
      Address: '',
      Gender: '',
      Birthday: null,
    },
    validationSchema: Yup.object({
      LastName: Yup.string()
        .trim()
        .max(50, '姓を 50 文字以内で入力してください')
        .required(' 姓は必須フィールドです'),
      FirstName: Yup.string()
        .trim()
        .max(50, '名を 50 文字以内で入力してください')
        .required('名は必須フィールドです'),
      PhoneNumber: Yup.number()
        .typeError('電話番号は数字でなければなりません')
        .required('メールアドレスが必要です')
        .test(
          'len',
          '電話番号は 10 ～ 15 文字である必要があります',
          (val) =>
            (val || 0).toString().length >= PHONE_NUMBER_LENGTH_MIN - 1 &&
            (val || 0).toString().length <= PHONE_NUMBER_LENGTH_MAX - 1,
        ),
      Address: Yup.string()
        .trim()
        .max(100, '住所を入力してください 100 文字未満にする必要があります'),
      Gender: Yup.string().trim().required('性別は必須のオプションです'),
      Birthday: Yup.string().trim().required('メールアドレスが必要です'),
    }),
    onSubmit: (val: CustomerRqBodyProps) => {
      const insertZero = (t: number) => ('0' + t).slice(-2);
      const today = new Date();
      const crDate = new Date(val.Birthday || '');
      const todayStr = `${today.getFullYear()}${insertZero(today.getMonth())}${insertZero(
        today.getDate(),
      )}`;
      const selectDayStr = `${crDate.getFullYear()}${insertZero(crDate.getMonth())}${insertZero(
        crDate.getDate(),
      )}`;
      const todayNum = Number(todayStr);
      const selectDayNum = Number(selectDayStr);
      if (todayNum <= selectDayNum) {
        formik.setErrors({ Birthday: '生年月日は未来の日付を選択することはできません' });
      } else {
        formik.setErrors({ Birthday: undefined });
        onSubmit();
        dispatch(updateUserProfile(val));
      }
    },
  });

  return (
    <StyledChangeProfileSection className="rg-sec profile-section" isMobile={isMobile}>
      <h1 className="title">プロフィール</h1>
      <Divider />
      <form onSubmit={formik.handleSubmit} className="content change-profile-form">
        <div className="avatar">
          {defaultAvatar && (
            <img onClick={onUpload} src={previewUrl || defaultAvatar} alt="" className="avatar" />
          )}
          <StyledUploadButton>
            <input
              ref={uploadBtnRef}
              type="file"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              onChange={onChangeImage}
            />
          </StyledUploadButton>
        </div>
        <div className="form-profile__info">
          <ShareInput
            label="名"
            name="LastName"
            onChange={formik.handleChange}
            value={formik.values.LastName}
            errors={formik.errors.LastName}
            touched={formik.touched.LastName}
            className="input"
            required
          />
          <ShareInput
            label="姓"
            name="FirstName"
            onChange={formik.handleChange}
            value={formik.values.FirstName}
            errors={formik.errors.FirstName}
            touched={formik.touched.FirstName}
            className="input"
            required
          />
          <ShareInput
            label="メールアドレス"
            name="email"
            onChange={() => {}}
            value={mail}
            className="input inp-not-allow"
            disabled
            required
          />
          <ShareInput
            label="電話番号"
            name="PhoneNumber"
            onChange={formik.handleChange}
            value={formik.values.PhoneNumber}
            errors={formik.errors.PhoneNumber}
            touched={formik.touched.PhoneNumber}
            className="input"
            type="text"
            required
            inputMode="numeric"
          />
          <ShareInput
            label="住所"
            name="Address"
            onChange={formik.handleChange}
            value={formik.values.Address}
            errors={formik.errors.Address}
            touched={formik.touched.Address}
            className="input"
          />
          <div className="sex-field field-input">
            <label className="field-label">
              性別
              <span className="field-require">*</span>:
            </label>

            <Radio.Group
              onChange={(e) => formik.setFieldValue('Gender', e.target.value)}
              value={formik.values.Gender}
            >
              <SharedCheckbox
                className="options__cb"
                size={'2.4rem'}
                name="Gender"
                value="F"
                type="radio"
                text="男性"
              />
              <SharedCheckbox
                className="options__cb"
                size={'2.4rem'}
                name="Gender"
                value="M"
                type="radio"
                text="女性"
              />
            </Radio.Group>
          </div>
          {formik.errors.Gender && formik.touched.Gender && (
            <div className="sex-field field-input align-top">
              <div></div>
              <div className="input-error">{formik.errors.Gender}</div>
            </div>
          )}
          <div className="birth-field field-input">
            <span className="field-label">生年月日:</span>
            <div className="select-date__group">
              <SelectDateOfBirthModule
                onDateChange={(e) => formik.setFieldValue('Birthday', e)}
                currentDate={formik.values.Birthday}
              />
            </div>
          </div>
          {formik.errors.Birthday && formik.touched.Birthday && (
            <div className="sex-field field-input align-top">
              <div></div>
              <div className="input-error select-date__group">{formik.errors.Birthday}</div>
            </div>
          )}
          <p className="external-auth">外部認証</p>
          {/*<SharedButton
            typeHtml="submit"
            text="更新"
            className="submit_btn change-profile-btn"
            btnStyle="basic"
            bgColor="#D42A1C"
          />*/}
          <Button
            type="primary"
            htmlType="submit"
            className="button-with-loading form-submit-button"
            loading={isLoading}
          >
            更新
          </Button>
        </div>
      </form>
    </StyledChangeProfileSection>
  );
};

const StyledChangeProfileSection = styled.div<{
  isMobile?: boolean;
}>`
  margin-top: -5.4rem;
  width: 100%;
  .form-submit-button {
    background: ${(props) => props.theme.colors.primary};
    width: 24.7rem;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: #303030;
  }

  .content {
    display: flex;
    width: 100%;
    margin-bottom: 10rem;

    .avatar {
      width: 12.8rem;
      height: 12.8rem;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      margin-bottom: 2.4rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }

    .form-profile__info {
      .shared-input {
        margin-bottom: 1.6rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .group-input {
          width: 100%;
          @media (min-width: 768px) {
            width: 69%;
          }

          .ant-input {
            background: #f9f9f9;
            padding: 1.2rem 1.6rem;
            border: none;
            border-radius: 0;

            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            color: #303030;
          }
        }

        .input__label {
          font-style: normal;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #000000;
          margin-bottom: 0;
        }
      }

      .field-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1.6rem;
        min-height: 4rem;

        .ant-radio-group,
        .select-date__group {
          width: 69%;
          max-width: 47.4rem;
          display: flex;
          align-items: center;
        }

        .field-label {
          font-style: normal;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #000000;
        }

        .field-require {
          color: ${(props) => props.theme.colors.primary};
        }

        .ant-radio-group {
          max-width: 47.4rem;

          .options__cb {
            &:first-child {
              margin-right: 6.4rem;
            }
          }

          .ant-radio-checked .ant-radio-inner {
            &:after {
              transform: scale(0.8);
            }
          }
        }

        .date__group {
        }
      }

      .external-auth {
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #000000;
        margin-bottom: 2.6rem;
        @media (max-width: 640px) {
          margin-bottom: 4rem;
        }
      }

      .submit_btn {
        max-width: 24.7rem;
        &.change-profile-btn {
          @media (max-width: 640px) {
            width: 100%;
            max-width: unset !important;
          }
        }
      }
    }
  }

  .change-profile-form {
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }

    .form-profile__info {
      width: 100%;
      margin-left: 0;
      @media (min-width: 768px) {
        margin-left: 7.2rem;
        width: calc(100% - 12.8rem);
      }

      .shared-input,
      .field-input {
        align-items: flex-start;
        flex-direction: column;
        @media (min-width: 768px) {
          flex-direction: row;
          align-items: center;
          height: 4.4rem;
        }

        .field-label {
          margin-bottom: 0.8rem;
          @media (min-width: 768px) {
            margin-bottom: 0;
          }
        }

        .input__label {
          margin-bottom: 0.8rem;
          @media (min-width: 768px) {
            margin-bottom: 0;
          }
        }

        .ant-radio-group,
        .select-date__group {
          width: ${(props) => (props.isMobile ? '100%' : '69%')};
          max-width: unset !important;
        }
      }
    }
  }
`;

const StyledUploadButton = styled.div`
  display: none;

  input {
    display: none;
  }
`;
