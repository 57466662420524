import styled from 'styled-components';
import { AutoComplete } from 'antd';

export const CustomAutoComplete = styled(AutoComplete)`
  width: 100%;

  .ant-input-affix-wrapper {
    padding: 0.4rem 0.6rem;
    border-radius: 20rem;
  }

  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    font-size: 16px;
    padding: 5px;
  }
`;

export const IconWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 6rem 6rem 0 6rem;
`;
