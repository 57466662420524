import { createSlice } from '@reduxjs/toolkit';
import { BaseState } from '@interfaces';
import { loginWithLineAction } from '../action';
import { RootState } from '@redux';

interface SocialAuthSliceProps {
  line: BaseState;
  facebook: BaseState;
}

const initialState: SocialAuthSliceProps = {
  line: {
    loading: false,
    data: null,
  },
  facebook: {
    loading: false,
    data: null,
  },
};

export const socialAuthSlice = createSlice({
  name: 'socialAuthSlice',
  initialState,
  reducers: {
    setUserInfoLine: (state, action) => {
      state.line.data = action.payload;
    },
    setUserInfoFacebook: (state, { payload }) => {
      state.facebook.data = payload;
    },
    resetDataLoginSocial: (state) => {
      state.facebook.data = null;
      state.line.data = null;
    },
  },
  extraReducers: (b) => {
    b.addCase(loginWithLineAction.pending, (state) => {
      state.line.loading = true;
    })
      .addCase(loginWithLineAction.fulfilled, (state, { payload }) => {
        state.line.loading = false;
        state.line.data = payload;
      })
      .addCase(loginWithLineAction.rejected, (state) => {
        state.line.loading = false;
      });
  },
});
export const { setUserInfoFacebook, resetDataLoginSocial, setUserInfoLine } =
  socialAuthSlice.actions;
export const socialAuthReducer = socialAuthSlice.reducer;
export const selectSocialAuth = (s: RootState) => s.socialAuth;
