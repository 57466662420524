import styled from 'styled-components';
import { Checkbox } from 'antd';

export const StyledCheckout = styled.div<{
  isMobile?: boolean;
}>`
  .content {
    width: 100%;
    border: ${(props) => (props.isMobile ? 'none' : '1px solid #ECECEC')};
    border-radius: ${(props) => (props.isMobile ? '0' : '16px')};
    padding: ${(props) => (props.isMobile ? '0' : '60px 60px 60px 60px')};
  }
  .message {
    font-family: 'Noto Sans JP';
    width: 100%;
    height: 23px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: ${(props) => props.theme?.colors?.button?.background};
    padding-top: 24px;
    padding-bottom: 46px;
  }
  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    padding-bottom: 6.4rem;
  }

  @media screen and (min-width: 640px) {
    .label-checkbox {
      width: 70%;
    }
    title-action {
      flex: 1;
    }
    .title {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
    .book-price {
      display: flex;
      justify-content: end;
      flex: 1;
      font-size: 1.4rem;
      font-weight: 400;
      font-family: 'Noto Sans JP';
      color: ${(props) => props.theme?.colors?.button?.background};
    }
  }
`;

export const StepsCheckout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 80px;
  .step {
    width: 32px;
    height: 32px;
    background: #d9d9d9;
    border-radius: 50px;
    font-weight: 400;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    padding-top: 7.5px;
    z-index: 2;
    color: ${(props) => props.theme?.colors?.gray['400']};
    .ant-steps-item.ant-steps-item-finish {
      &:nth-child(2) {
        .ant-steps-item-container .ant-steps-item-icon:after {
          content: '2';
          color: ${(props) => props.theme?.colors?.body};
          font-weight: 500;
        }
      }
    }
    .des-step {
      position: absolute;
      width: 96px;
      height: 17px;
      left: 28px;
      top: 270px;
    }
    .ant-steps-item-title {
      font-size: 14px;
    }
  }
  .line {
    position: absolute;
    width: 200px;
    height: 0px;
    border: 1px solid #d9d9d9;
  }
  .process {
    background: ${(props) => props.theme?.colors?.primary};
    color: ${(props) => props.theme?.colors?.gray['200']};
  }
`;

export const ItemCheckout = styled.div`
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-bottom: 1px solid ${(props) => props.theme?.colors?.gray['200']};
  .image-item {
    .skeleton-image {
      width: 12.8rem;
      height: 18.2rem;
    }
  }
  img {
    width: 128px;
    height: 182px;
  }
  .book-price {
    display: flex;
    justify-content: end;
    flex: 1;
  }
  .des-item {
    width: 54%;
  }
  .des-item1 {
    width: calc(54% + 160px);
  }
  .book-item-text {
    margin-bottom: 8px;
  }
  .book-item-title {
    font-weight: 500;
    line-height: 20px;
    color: ${(props) => props.theme?.colors?.['600']};
  }
  .book-item-author {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 17px;
    color: ${(props) => props.theme?.colors?.text2};
  }
  .book-item-publisher {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 17px;
    color: ${(props) => props.theme?.colors?.primary};
  }
  .book-item-color {
    color: ${(props) => props.theme?.colors?.text2};
  }
  .book-item-price {
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: ${(props) => props.theme?.colors?.primary};
    .skeleton-price {
      min-width: 8rem;
      width: 8rem;
    }
  }
`;

export const TotalCheckout = styled.div<{
  isMobile?: string;
}>`
  display: flex;
  justify-content: flex-end;
  gap: 70px;
  margin-top: 24px;
  .row {
    flex-direction: column;
    p {
      line-height: 20px;
    }
    .margin-text {
      margin-bottom: 12px;
      margin-top: 12px;
      color: ${(props) => props.theme?.colors?.text};
    }
    .total {
      font-weight: 700;
      color: ${(props) => props.theme?.colors?.primary};
    }
    .amount {
      font-weight: 700;
      color: ${(props) => props.theme?.colors?.['600']};
    }
  }
  text-right {
    text-align: right;
  }
`;

export const ButtonCheckout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

export const StyleCheckoutBtnRecycle = styled.div`
  display: flex;
  justify-content: end;
  gap: 7px;
  margin-top: 40px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b6b6b6;
  }
  @media screen and (min-width: 640px) {
    margin-top: 0;
  }
`;
export const StyledCheckoutText = styled.p<{
  fontSize?: string;
  fontWeight?: string;
}>`
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
`;
