import React from 'react';
import { SharedSkeleton } from '@components';
import { StyledSkeletonDetail, StyleSkeletonCardItem } from './style';
interface SkeletonCardItemProp {
  type?: 'horizontal' | 'vertical';
  size?: 'large' | 'regular';
  className?: string;
}
export const SkeletonCardItem = ({
  type = 'vertical',
  size = 'regular',
  className,
}: SkeletonCardItemProp) => {
  const render = () => {
    switch (type) {
      case 'horizontal':
        return (
          <StyledSkeletonDetail className={className}>
            <div className={`skeleton-image ${size}`}>
              <SharedSkeleton
                type={'image'}
                active={true}
                imageClassName={'skeleton-img'}
              ></SharedSkeleton>
            </div>
            <div className="skeleton-input">
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'skeleton-input-title'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-first'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-second'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-third'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-fourth'}
              ></SharedSkeleton>
            </div>
          </StyledSkeletonDetail>
        );
      case 'vertical':
        return (
          <StyleSkeletonCardItem>
            <SharedSkeleton
              type={'image'}
              active={true}
              imageClassName={'skeleton-img'}
            ></SharedSkeleton>
            <SharedSkeleton
              type={'input'}
              active={true}
              inputClassName={'skeleton-input-title'}
            ></SharedSkeleton>
            <SharedSkeleton
              type={'input'}
              active={true}
              inputClassName={'skeleton-input-subtitle'}
            ></SharedSkeleton>
          </StyleSkeletonCardItem>
        );

      default:
        return (
          <StyledSkeletonDetail>
            <div className="skeleton-image">
              <SharedSkeleton
                type={'image'}
                active={true}
                imageClassName={'skeleton-img'}
              ></SharedSkeleton>
            </div>
            <div className="skeleton-input">
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'skeleton-input-title'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-first'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-second'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-third'}
              ></SharedSkeleton>
              <SharedSkeleton
                type={'input'}
                active={true}
                inputClassName={'margin skeleton-input-subtitle-fourth'}
              ></SharedSkeleton>
            </div>
          </StyledSkeletonDetail>
        );
    }
  };
  return render();
};
