import { Row } from 'antd';
import styled from 'styled-components';

export const ParentCategoryStyled = styled.div`
  .header-title {
    display: flex;
    justify-content: space-between;
    .filter-btn {
      padding: 0.8rem;
      @media (max-width: 640px) {
        font-size: 1.2rem;
      }
    }
  }
  .title {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: ${(props) => props.theme?.colors.gray['600']};
  }
  .header-customer-line {
    margin: 2.4rem 0;
    width: 100%;
    height: 0.1rem;
    background-color: ${(props) => props.theme?.colors.gray['200']};
  }
  .header {
    .header-selected {
      display: flex;
      justify-content: space-between;
      .header-text {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.3rem;
        color: ${(props) => props.theme?.colors.gray['600']};
      }
      .detail-category {
        &:hover {
          cursor: pointer;
          color: ${(props) => props.theme?.colors.primary};
        }
        display: flex;
        align-items: center;
        p {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #359f08;
        }
      }
    }
    .no-item {
      text-align: center;
    }
  }
`;

export const BookList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1.2rem -1.2rem;
`;

export const ItemBook = styled.div`
  @media (max-width: 768px) {
    width: calc(50% - 2.4rem);
  }
  @media (min-width: 768px) and (max-width: 820px) {
    width: calc(33% - 2.4rem);
  }
  margin: 1.2rem;
  width: calc(25% - 2.4rem);
  height: auto;
  .card-item-img {
    width: 100%;
    height: 24rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: center bottom;
      transition: 0.2s;
      &:hover {
        transform: scale(1.04);
      }
    }
  }
  .card-item-content {
    margin-top: 1.6rem;
    .card-item-content-desc {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(props) => props.theme?.colors.gray['600']};
    }
    .card-item-content-author {
      margin-bottom: 0.8rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.4rem;
      color: #0bded2;
    }
    .pr-info {
      .card-item-content-price {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2rem;
        color: ${(props) => props.theme?.colors.primary};
      }
    }
  }
`;
