import React from 'react';
import { selectBreadcrumb, useAppSelector } from '@redux';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export const CustomBreadcrumb = () => {
  const { path } = useAppSelector(selectBreadcrumb);

  return (
    <div>
      {path?.split('/').map((item) => (
        <>
          <Breadcrumb.Separator>|</Breadcrumb.Separator>
          <StyledText>{item}</StyledText>
        </>
      ))}
    </div>
  );
};

const StyledText = styled.span`
  color: ${(props) => props.theme.colors.textBreadcrumbNotAllow};
`;
