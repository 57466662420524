import styled from 'styled-components';

export const StyledSubscribeRank = styled.div`
  width: 100%;
  margin-bottom: 7.6rem;
  @media (max-width: 640px) {
    margin-bottom: 4rem;
  }
  .subscribe-rank {
    &-banner {
      width: 100%;
      @media (max-width: 640px) {
        padding-top: 4px;
        img {
          height: 7.6rem !important;
        }
      }
      img {
        width: 100%;
        height: 26.2rem;
      }
    }
    &-container {
      margin-top: 3.2rem;
      display: flex;
      @media (max-width: 640px) {
        display: block;
        margin-right: 8.4rem;
        margin-top: 2.4rem;
      }
      gap: 2.8rem;
      ol {
        li ~ li {
          margin-top: 1.8rem;
        }
        li {
          display: flex;
          justify-content: space-between;
          gap: 3.2rem;
          p {
            &:first-child {
              flex: 5;
              a {
                display: inline-block;
                width: fit-content;
                color: ${(p) => p?.theme.colors?.text};
                font-family: 'Noto Sans JP';
                font-style: normal;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2rem;

                &:hover {
                  color: ${(p) => p.theme?.colors?.primary};
                }
              }
            }
            &:last-child {
              flex: 2;
              width: fit-content;
              text-align: left;
            }
          }
        }
      }
      &-left {
        flex: 1;
      }
      &-right {
        flex: 1;
      }
    }
    &-line {
      margin-top: 2.4rem;
      width: 100%;
      height: 1px;
      background-color: #b6b6b6;
      @media (max-width: 640px) {
        background-color: ${(p) => p.theme?.colors?.gray['300']};
      }
    }
    &-text-bottom {
      margin-top: 2.4rem;
      @media (max-width: 640px) {
        margin: 1.6rem 2.8rem 0 2.8rem;
        font-size: 1.2rem;
      }
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: ${(p) => p.theme?.colors?.gray['600']};
      text-align: center;
    }
  }
`;
