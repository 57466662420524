import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ListingComponent } from '@components';
import {
  ALL_BOOKS_PARAMS,
  BOOK_TYPE_PARAMS,
  BOOKS_AND_AUTHORS_PARAMS,
  CATEGORY_PARAMS,
  DISPATCH_BOOK_PARAMS,
  KEY_PATH,
  PAGE_LENGTH,
  PRICE_PARAMS,
  PRODUCT_SORTING_PARAMS,
  SEARCH_PATH,
} from '@configs';
import { IBookDataItem } from '@interfaces';
import {
  fetchBestSellerProduct,
  fetchProductList,
  selectCategories,
  selectProduct,
  selectShowCategorySidebar,
  selectSidebar,
  setCurrentPage,
  setShowCategorySidebar,
  useAppSelector,
} from '@redux';
import {
  getAudioReadingValue,
  hasKeyIn,
  hasValue,
  mapData,
  parseString,
  scrollToTop,
} from '@utils';

type PageChangeParams = {
  [key: string]: any;
};

export const ListingModule = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const parsedValue = parseString(location.search);

  const showCategorySidebar = useAppSelector(selectShowCategorySidebar);
  const productSelector = useAppSelector(selectProduct);
  const categoriesSelector = useAppSelector(selectCategories);
  const filterSelector = useAppSelector(selectSidebar);
  const { price, bookType, sortByPrice, nameOrder, sortByOtherCategories } = filterSelector;
  const { categories } = categoriesSelector.data;
  const {
    bestSellerBooks,
    lastedBooks,
    recommendedBooks,
    productSearchResults,
    productTypeList,
    allBooks,
  } = productSelector.data;
  const {
    isLoading,
    categoryId,
    booksAndAuthorsParams,
    total,
    recommendedBooksTotal,
    lastedBooksTotal,
    bestSellerBooksTotal,
    currentPage,
    selectedBooksAndAuthorsParams,
  } = productSelector;
  const totalProduct = total || 0;
  const [bookList, setBookList] = useState<IBookDataItem[]>([]);
  const [dataSearch, setDataSearch] = useState<IBookDataItem[]>([]);

  // const isSearchType = !categoryId && location.search.includes(SEARCH_PATH.typeSearch);
  const newProductSearchResults = mapData(productSearchResults);
  const newBestsellerBooks = mapData(bestSellerBooks);
  const newRecommendBooks = mapData(recommendedBooks);
  const newLatestBooks = mapData(lastedBooks);
  const newProductTypeList = mapData(productTypeList);
  const newAllBooks = mapData(allBooks);

  const [itemStart, setItemStart] = useState<number>(1);
  const [itemEnd, setItemEnd] = useState<number>(12);
  const newSubCategories = categories.find((data) =>
    data.subCategories.find((subData) => subData.id === categoryId),
  );
  const isSearching = location.search.includes('search');
  const subData = newSubCategories?.subCategories.find((data) => data.id === categoryId);

  useEffect(() => {
    if ((!isSearching && !isLoading) || categoryId) {
      handleDispatchData(location.search);
      setDataSearch([]);
    } else {
      setDataSearch(newProductSearchResults);
      setBookList([]);
    }
  }, [location.search, isLoading, categoryId]);

  const handleDispatchData = (path: string) => {
    const newPath = parseString(path);

    switch (true) {
      case hasKeyIn(newPath, KEY_PATH.isHighlight):
        setBookList(newRecommendBooks);
        break;
      case hasKeyIn(newPath, KEY_PATH.isBestSeller):
        setBookList(newBestsellerBooks);
        break;
      case hasKeyIn(newPath, KEY_PATH.isLatestBooks):
        setBookList(newLatestBooks);
        break;
      case hasKeyIn(newPath, KEY_PATH.isFilterNone):
        setBookList(newAllBooks);
        break;
      case hasValue(categoryId):
        setBookList(newProductTypeList);
        break;
      case hasKeyIn(newPath, KEY_PATH.priceRange.priceMin || KEY_PATH.priceRange.priceMax):
        setBookList(newProductTypeList);
        break;
      case hasKeyIn(newPath, KEY_PATH.isAudioReading):
        setBookList(newProductTypeList);
        break;
      case hasKeyIn(newPath, KEY_PATH.sortByPrice):
        setBookList(newProductTypeList);
        break;
      case hasKeyIn(newPath, KEY_PATH.orderByName):
        setBookList(newProductTypeList);
        break;
      case hasKeyIn(newPath, KEY_PATH.sortByOtherCategories):
        setBookList(newProductTypeList);
        break;
      default:
        setBookList(newAllBooks);
    }
  };

  // console.log(
  //   {
  //     bookList,
  //     newProductSearchResults,
  //     parsedValue,
  //     categoryId,
  //     newProductTypeList,
  //     bookType,
  //     sortByPrice,
  //     nameOrder,
  //     allBooks,
  //     totalProduct,
  //   },
  //   hasValue(categoryId)
  // );
  const handleShowSidebar = () => {
    if (!showCategorySidebar) dispatch(setShowCategorySidebar(true));
  };

  const handleDispatchChangePage = (params: PageChangeParams) => {
    dispatch(fetchProductList(params));
  };

  useEffect(() => {
    handleCalculateResult(currentPage);
  }, [totalProduct]);

  const handleCalculateResult = (page: number) => {
    let start;
    let end;
    const lengthPage = Math.floor(totalProduct / PAGE_LENGTH);
    const surplus = totalProduct % PAGE_LENGTH;
    if (surplus > 0) {
      if (page <= lengthPage) {
        end = page * 12;
        start = end - 11;
        if (start === 0) {
          start = 1;
        }
        setItemStart(start);
        setItemEnd(end);
      }
      if (page === lengthPage + 1) {
        end = totalProduct;
        start = totalProduct - surplus + 1;
        setItemStart(start);
        setItemEnd(end);
      }
    }
    if (surplus === 0) {
      end = page * 12;
      start = end - 11;
      if (start === 0) {
        start = 1;
      }
      setItemStart(start);
      setItemEnd(end);
    }
    if (totalProduct === 0) {
      setItemStart(0);
      setItemEnd(0);
    }
  };

  const handleChangePage = (page: number) => {
    handleCalculateResult(page);
    scrollToTop();
    dispatch(setCurrentPage(page));
    switch (true) {
      case hasKeyIn(parsedValue, KEY_PATH.isHighlight):
        handleDispatchChangePage({ ...DISPATCH_BOOK_PARAMS.highLightBook, start: page - 1 });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.isBestSeller):
        dispatch(fetchBestSellerProduct({ start: page - 1, length: 12 }));
        break;
      case hasKeyIn(parsedValue, KEY_PATH.isLatestBooks):
        handleDispatchChangePage({ ...DISPATCH_BOOK_PARAMS.latestBook, start: page - 1 });
        break;
      case hasValue(categoryId):
        handleDispatchChangePage({ ...CATEGORY_PARAMS, categoryIds: categoryId, start: page - 1 });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.priceRange.priceMin || KEY_PATH.priceRange.priceMax):
        handleDispatchChangePage({
          ...PRICE_PARAMS,
          priceMin: price.priceMin,
          priceMax: price.priceMax,
          start: page - 1,
        });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.isAudioReading):
        handleDispatchChangePage({
          ...BOOK_TYPE_PARAMS,
          isAudioReading: getAudioReadingValue(bookType),
          start: page - 1,
        });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.sortByPrice):
        handleDispatchChangePage({
          ...PRODUCT_SORTING_PARAMS,
          productSorting: sortByPrice,
          start: page - 1,
        });
        break;

      case hasKeyIn(parsedValue, KEY_PATH.orderByName):
        handleDispatchChangePage({
          ...PRODUCT_SORTING_PARAMS,
          productSorting: nameOrder,
          start: page - 1,
        });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.sortByOtherCategories):
        handleDispatchChangePage({
          ...PRODUCT_SORTING_PARAMS,
          productSorting: sortByOtherCategories,
          start: page - 1,
        });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.isFilterNone):
        handleDispatchChangePage({ ...ALL_BOOKS_PARAMS, start: page - 1 });
        break;
      case hasKeyIn(parsedValue, KEY_PATH.isSearch):
        handleDispatchChangePage({
          ...BOOKS_AND_AUTHORS_PARAMS,
          bookOrAuthorName: selectedBooksAndAuthorsParams,
          start: page - 1,
        });
        break;
      default:
        handleDispatchChangePage({ ...ALL_BOOKS_PARAMS, start: page - 1 });
    }
  };

  return (
    <ListingComponent
      handleShowSidebar={handleShowSidebar}
      dataSearch={dataSearch}
      onChangePage={handleChangePage}
      total={totalProduct}
      pageSize={12}
      bookLists={bookList}
      isSearching={isSearching}
      isLoading={isLoading}
      categoryName={subData?.name || ''}
      itemStart={itemStart}
      itemEnd={itemEnd}
      recommendedBooksTotal={recommendedBooksTotal}
      bestSellerBooksTotal={bestSellerBooksTotal}
      lastedBooksTotal={lastedBooksTotal}
    />
  );
};
