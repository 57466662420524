import React, { useEffect, useState } from 'react';
import {
  BookItem,
  HeaderCustomer,
  IconRecycle,
  Pagination,
  SearchEmptyBox,
  SkeletonCardItem,
} from '@components';
import { StyleContainer } from './style';
import {
  fetchWishList,
  fetchWishlistWithPagination,
  removeOneCart,
  resetSearchList,
  resetWishList,
  RootState,
  searchWishList,
  selectCart,
  useAppSelector,
} from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { deleteSearchParams, scrollToTop, useMediaQuery } from '@utils';
import { Modal } from 'antd';
import { PATH_WISH_LIST } from '@configs';

export const WishListModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const isLogin = accessToken;
  const searchParams = useSearchParams();
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [totalItem, setTotalItem] = useState<number>(0);
  const [unlike, setUnlike] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const {
    data: { wishlist, searchWishlist, cartRemoveOne },
    isLoading,
  } = useAppSelector(selectCart);
  const [currentIdItem, setCurrentIdItem] = useState<number>();
  const handleSearch = (e: string) => {
    setCurrentSearchText(e);
    dispatch(searchWishList(e));
    deleteSearchParams(searchParams, ['start', 'length']);
  };

  const handleRemoveFromWishList = (shoppingCartItemId: number) => {
    setUnlike(true);
    setCurrentIdItem(shoppingCartItemId);
    //dispatch(removeOneCart(shoppingCartItemId));
  };
  useEffect(() => {
    searchWishlist && setTotalItem(searchWishlist.total);
    isLogin && dispatch(fetchWishList());
  }, [searchWishlist]);

  useEffect(() => {
    wishlist && setTotalItem(wishlist.total);
  }, [wishlist]);
  useEffect(() => {
    return () => {
      isLogin && dispatch(searchWishList(''));
    };
  }, []);

  useEffect(() => {
    if (!isLogin) dispatch(resetWishList(null));
  }, [isLogin]);

  useEffect(() => {
    isLogin && dispatch(fetchWishList());
    setCurrentSearchText('');
  }, [cartRemoveOne]);

  /*  useEffect(() => {
      dispatch(setLoading(isLoading));
    }, [isLoading]);*/

  const onPageChange = (pageNumber: number) => {
    navigate(`${PATH_WISH_LIST}?start=${pageNumber - 1}&length=10`);
  };

  useEffect(() => {
    const start = searchParams[0].get('start') || 0;
    isLogin && dispatch(fetchWishlistWithPagination(`start=${start}&length=10`));
    scrollToTop();
  }, [location]);

  const onConfirmUnlike = () => {
    currentIdItem && dispatch(removeOneCart(currentIdItem));
    setUnlike(false);
    dispatch(resetSearchList());
  };
  return (
    <StyleContainer className="wish-list">
      <div className="wish-list-wrapper">
        <div className="wish-list-wrapper-right">
          {wishlist && (
            <div className="wish-list-wrapper-right__header">
              <HeaderCustomer
                debounceEmitValue={500}
                onSearch={handleSearch}
                title="ウィッシュリスト"
                variant="search"
              />
            </div>
          )}
          <div className="wish-list-content">
            {isLoading && isLogin ? (
              <>
                <SkeletonCardItem
                  className={'skeleton-wishlist'}
                  type={'horizontal'}
                  size={'regular'}
                />
                <SkeletonCardItem
                  className={'skeleton-wishlist'}
                  type={'horizontal'}
                  size={'regular'}
                />
                <SkeletonCardItem
                  className={'skeleton-wishlist'}
                  type={'horizontal'}
                  size={'regular'}
                />
              </>
            ) : (
              <>
                <p className="wish-list-content-total">
                  {currentSearchText.length > 0 ? searchWishlist?.total || 0 : wishlist?.total || 0}{' '}
                  件
                </p>
                {currentSearchText.length === 0 &&
                  wishlist &&
                  wishlist.data.items.map((wishItem, index) => (
                    <div className="wish-list-item" key={index}>
                      <BookItem
                        removeFromWishlist={() => {
                          handleRemoveFromWishList(wishItem.shoppingCartItemId);
                        }}
                        data={wishItem}
                        type="wish-list"
                      />
                    </div>
                  ))}
                {currentSearchText.length === 0 && wishlist && wishlist.data.items.length === 0 && (
                  <SearchEmptyBox />
                )}
                {currentSearchText.length > 0 &&
                  searchWishlist &&
                  searchWishlist.data.items.map((wishItem, index) => (
                    <div className="wish-list-item" key={index}>
                      <BookItem
                        removeFromWishlist={() => {
                          handleRemoveFromWishList(wishItem.shoppingCartItemId);
                        }}
                        data={wishItem}
                        type="wish-list"
                      />
                    </div>
                  ))}
                {currentSearchText.length > 0 &&
                  searchWishlist &&
                  searchWishlist.data.items.length === 0 && (
                    <SearchEmptyBox hasNavBtn={false} text={'一致する結果が見つかりません。'} />
                  )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* {totalItem > 0 && (
        <div className="paginator">
          <Pagination
            current={Number(searchParams[0].get('start') || 0) + 1}
            defaultCurrent={1}
            //total={wishlist?.total || 5}
            total={totalItem}
            onChangePage={(e) => {
              onPageChange(e);
            }}
            pageSize={10}
          />
        </div>
      )}*/}
      {currentSearchText.length === 0 && wishlist && wishlist.data.items.length > 0 && (
        <div className="paginator">
          <Pagination
            current={Number(searchParams[0].get('start') || 0) + 1}
            defaultCurrent={1}
            total={wishlist?.total || 5}
            //total={totalItem}
            onChangePage={(e) => {
              onPageChange(e);
            }}
            pageSize={10}
          />
        </div>
      )}
      {currentSearchText.length > 0 && searchWishlist && searchWishlist.data.items.length > 0 && (
        <div className="paginator">
          <Pagination
            current={Number(searchParams[0].get('start') || 0) + 1}
            defaultCurrent={1}
            //total={wishlist?.total || 5}
            total={searchWishlist.total}
            onChangePage={(e) => {
              onPageChange(e);
            }}
            pageSize={10}
          />
        </div>
      )}
      <Modal
        className="modal-cart hide-close"
        style={{ fontSize: isMobile ? '12px' : '14px' }}
        title="確認"
        cancelText="キャンセル"
        okText={
          <div className="flex-center-all">
            <IconRecycle stroke={'#fff'} />
            OK
          </div>
        }
        open={unlike}
        onOk={onConfirmUnlike}
        onCancel={() => setUnlike(false)}
      >
        <p>この本をウィッシュリストから削除してもよろしいですか？</p>
      </Modal>
    </StyleContainer>
  );
};
