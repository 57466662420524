import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  BookRequestIcon,
  CartIcon,
  HeartIcon,
  HomeIcon,
  SharedButton,
  SubscriptionIcon,
} from '@components';
import { RootState } from '@redux';
import { getColorActive } from '@utils';
import { PATH_BOOK_REQUEST, PATH_CART, PATH_HOME, PATH_MY_BOOKS, PATH_WISH_LIST } from '@configs';

export const MobileNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const isLogin = accessToken;

  const handleNavigatePath = (path: string) => {
    if (path) navigate(path);
  };

  return (
    <MobileNavBarWrapper>
      <SharedButton
        prevIcon={
          <HomeIcon width={20} height={20} fill={getColorActive(PATH_HOME, location.pathname)} />
        }
        textClassName="heb__text"
        className="header-btn"
        onClick={() => handleNavigatePath(PATH_HOME)}
      />
      {isLogin && (
        <SharedButton
          prevIcon={
            <SubscriptionIcon
              width={24}
              height={20}
              fill={getColorActive(PATH_MY_BOOKS, location.pathname)}
            />
          }
          textClassName="heb__text"
          className="header-btn"
          onClick={() => navigate(PATH_MY_BOOKS)}
        />
      )}

      <SharedButton
        prevIcon={
          <BookRequestIcon
            width={20}
            height={18}
            fill={getColorActive(PATH_BOOK_REQUEST, location.pathname)}
          />
        }
        textClassName="heb__text"
        onClick={() => navigate(PATH_BOOK_REQUEST)}
      />

      <SharedButton
        prevIcon={
          <HeartIcon
            width={20}
            height={20}
            fill={getColorActive(PATH_WISH_LIST, location.pathname)}
          />
        }
        textClassName="heb__text"
        onClick={() => navigate(PATH_WISH_LIST)}
      />

      <SharedButton
        prevIcon={
          <CartIcon width={20} height={20} fill={getColorActive(PATH_CART, location.pathname)} />
        }
        textClassName="heb__text"
        onClick={() => navigate(PATH_CART)}
      />
    </MobileNavBarWrapper>
  );
};

// @ts-ignore
const MobileNavBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.6rem;
  padding: 0 2.4rem;
`;
