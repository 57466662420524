import { createAsyncThunk } from '@reduxjs/toolkit';
import { bookRequestApi, shoppingCartApi } from '@api';
import { BaseResponseProps } from '@interfaces';

const prefix = 'BookRequestTopic';

export const createBookRequest = createAsyncThunk<any, FormData>(
  `${prefix}/create-new-request`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await bookRequestApi.createANewBookRequest(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchBookRequest = createAsyncThunk<any>(
  `${prefix}/fetchBookRequest`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await bookRequestApi.getBookRequest();
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchSearchBookRequest = createAsyncThunk<any, string>(
  `${prefix}/fetchBookRequest/Search`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await bookRequestApi.getBookRequestSearchList(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchBookRequestTopicsLibrary = createAsyncThunk<any>(
  `${prefix}/fetchBookRequestTopicsLibrary`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await bookRequestApi.getBookRequestTopicsLibrary();
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchPaginationBookRequest = createAsyncThunk<any, string>(
  `${prefix}/fetchPaginationBookRequest`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await bookRequestApi.getPaginationBookRequest(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
