import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseResponseProps, SelectDefaultProps, SelectProps } from '@interfaces';
import { RootState } from '@redux';
import { fetchAuthorSelect, fetchCategoriesSelect } from '../action';

interface CommonSliceProps {
  selectAuthor: {
    loading: boolean;
    data: SelectProps[];
  };
  selectCategories: {
    loading: boolean;
    data: SelectProps[];
  };
}

const initialState: CommonSliceProps = {
  selectCategories: {
    loading: true,
    data: [],
  },
  selectAuthor: {
    loading: true,
    data: [],
  },
};

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategoriesSelect.pending, (state) => {
        state.selectCategories.loading = true;
      })
      .addCase(
        fetchCategoriesSelect.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<SelectDefaultProps[]>>) => {
          state.selectCategories.loading = false;
          const mappedData = (payload.data || []).map((e, i) => ({
            value: e.value,
            id: e.value,
            label: e.text,
          })) as SelectProps[];
          state.selectCategories.data = [...mappedData];
        },
      )
      .addCase(fetchCategoriesSelect.rejected, (state) => {
        state.selectCategories.loading = false;
        state.selectCategories.data = [];
      })

      .addCase(fetchAuthorSelect.pending, (state) => {
        state.selectAuthor.loading = true;
      })
      .addCase(
        fetchAuthorSelect.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<SelectDefaultProps[]>>) => {
          state.selectAuthor.loading = false;
          const mappedData = (payload.data || []).map((e, i) => ({
            value: e.value,
            id: e.value,
            label: e.text,
          })) as SelectProps[];
          state.selectAuthor.data = [...mappedData];
        },
      )
      .addCase(fetchAuthorSelect.rejected, (state) => {
        state.selectAuthor.loading = false;
        state.selectAuthor.data = [];
      });
  },
});

export const commonReducer = commonSlice.reducer;
export const selectCommon = (state: RootState) => state.common;
