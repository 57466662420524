import styled, { css } from 'styled-components';

export const StyledCardItem = styled.div`
  /* width: calc(100% / 6); */
  width: 100%;
  height: auto;

  .card-item-img {
    width: 100%;
    height: 24rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      object-position: center bottom;
      transition: 0.2s;
      &:hover {
        transform: scale(1.04);
      }
    }
    .card-speaker {
      position: absolute;
      bottom: 0.8rem;
      right: 0.8rem;
      width: 2.6rem;
      height: 2.6rem;
    }
    .card-number {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      width: 3.2rem;
      height: 3.2rem;
      background-color: #0bded2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #fff;
      }
    }
    .card-item-badge {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
      line-height: 23.17px;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      font-weight: 500;
      color: ${(props) => props.theme.colors.badge.text};
      background-color: ${(props) => props.theme.colors.badge.background};
    }
    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      //height: 100%;
    }

    @media (min-width: 991px) and (max-width: 1200px) {
      height: 20rem;
    }

    @media (max-width: 767px) {
      height: 36rem;
    }

    @media (max-width: 640px) {
      height: 26rem;
    }
  }
  .card-item-content {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    &-desc {
      width: 100%;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #303030;
      display: -webkit-box;
      overflow: hidden;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      &:hover {
        color: #d42a1c;
      }
    }
    &-author {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.4rem;
      color: #0bded2;
      margin-bottom: 0;
    }
    &-price {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #d42a1c;
      display: block;
    }
    .pr-info {
      display: block;
    }
  }

  @media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
    margin-top: 1.6rem;

    .card-item-content {
      .pr-info {
        display: block;

        .card-item-content-price {
          font-size: ${(props) => props.theme.size.l};
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1180px) and (orientation: landscape) {
    .card-item-content {
      .pr-info {
        display: block;

        .card-item-content-price {
          font-size: ${(props) => props.theme.size.l};
        }
      }
    }
  }
`;
