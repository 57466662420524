import { createSlice } from '@reduxjs/toolkit';
import { fetchCategories, getListBookByCategoryId } from '../action/categoryAction';
import { CategoriesDataType, CategoriesSelected, CategorySelected } from '@interfaces';
import { RootState } from '@redux';

interface StateType {
  isLoading: boolean;
  data: {
    categories: CategoriesDataType[];
    categorySelected: CategorySelected | null;
  };
  categoriesSelected: CategoriesSelected | null;
}

const initialState: StateType = {
  isLoading: true,
  data: {
    categories: [],
    categorySelected: null,
  },
  categoriesSelected: null,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    categorySelected: (state, action) => {
      state.data.categorySelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.categories = payload;
      })
      .addCase(fetchCategories.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getListBookByCategoryId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getListBookByCategoryId.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.categoriesSelected = payload;
      })
      .addCase(getListBookByCategoryId.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const selectCategories = (state: RootState) => state.category;
export const { categorySelected } = categorySlice.actions;
export default categorySlice.reducer;
