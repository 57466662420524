import { ICon } from '@interfaces';

export const IconRecycle = (props: ICon) => {
  const { stroke = '#B6B6B6' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M3.33301 5.83337H16.6663"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33301 9.16663V14.1666"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.667 9.16663V14.1666"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16699 5.83337L5.00033 15.8334C5.00033 16.2754 5.17592 16.6993 5.48848 17.0119C5.80104 17.3244 6.22496 17.5 6.66699 17.5H13.3337C13.7757 17.5 14.1996 17.3244 14.5122 17.0119C14.8247 16.6993 15.0003 16.2754 15.0003 15.8334L15.8337 5.83337"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
