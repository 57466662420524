import axiosClient from './axiosClient';
import { paramsList } from 'swiper/angular/angular/src/utils/params-list';

const url = 'api/Product';

export const productApi = {
  getProductList: (params?: any) => {
    return axiosClient.get(url, { params });
  },
  getBestSellerProducts: (params?: any) => {
    return axiosClient.get(`${url}/best-seller`, { params });
  },
  getDetailBooks: (bookId: string) => {
    return axiosClient.get(`${url}/${bookId}`);
  },
  getSimilarBooks: (bookId: string) => {
    return axiosClient.get(`${url}/similar-book?BookOrAuthorName=&ProductIdSimilar=${bookId}`);
  },
  getMyBooksList: (searchText = '') => {
    return axiosClient.get(`${url}/ListMyBooks?Name=${searchText}`);
  },
  getMyBooksListWithPagination: (searchText: string) => {
    return axiosClient.get(`${url}/ListMyBooks?${searchText}`);
  },
  getPurchaseHistory: (params: any) => {
    return axiosClient.get(`${url}/purchasehistory`, { params });
  },
};
