import { Steps } from 'antd';
import { ITEMS_STEP, PATH_HOME, STYLE_STEP } from '@configs';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@utils';
import { StyledCart } from './style';
import { IMAGES } from '@assets';
import { CartList, Checkout, Payment, SharedButton } from '@components';
import { getAllBooksInCart, resetStep, RootState, selectCart, useAppSelector } from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import { BookInCartProps } from '@interfaces';
import { useNavigate } from 'react-router-dom';

export const CartModule = () => {
  const [listBooks, setListBooks] = useState<BookInCartProps[]>([]);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const dispath = useDispatch();
  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const isLogin = accessToken;
  const step = useAppSelector((state) => state.step.value);
  const navigate = useNavigate();
  const {
    data: { cart, cartRemoveOne },
  } = useAppSelector(selectCart);
  const handleScrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    handleScrollToTop();
  }, [step]);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  useEffect(() => {
    listBooks?.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
    isLogin && dispath(getAllBooksInCart());
  }, []);

  useEffect(() => {
    if (cart && cart.data) {
      setListBooks(cart.data.items);
      cart.data.items.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
    }
  }, [cart, cartRemoveOne]);
  useEffect(() => {
    return () => {
      dispath(resetStep());
    };
  }, []);

  return (
    <StyledCart isMobile={isMobile} isEmptyCart={isEmpty}>
      <div className="cart-container">
        {cart && cart.data.items.length > 0 ? (
          <>
            <div className="content">
              <div className="step">
                <Steps current={step} style={STYLE_STEP} items={ITEMS_STEP} direction="vertical" />
              </div>
              {step === 0 && <CartList data={cart.data.items} />}
              {step === 1 && <Checkout data={cart.data.items} />}
              {step === 2 && <Payment />}
            </div>
          </>
        ) : (
          <div className="cart-empty">
            <div className="cart-empty-img">
              <img src={IMAGES.cart} alt="not" />
            </div>
            <h5>カートに書籍がありません</h5>
            <SharedButton
              text={'カートに入れる'}
              className="cart-empty-btn"
              onClick={() => navigate(PATH_HOME)}
            />
          </div>
        )}
      </div>
    </StyledCart>
  );
};
