import styled from 'styled-components';
import { Swiper, SwiperProps } from 'swiper/react';

export const StyledSwiper = styled(Swiper)<{ props?: SwiperProps }>`
  .swiper-zoom-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      padding: 0 1.6rem;
    }

    .swiper-slide-item {
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        width: 100%;
      }
    }
    .swiper-slide-item ~ .swiper-slide-item {
      margin-left: 2.4rem;
    }
  }

  .card-item__wrapper {
    width: 18rem;
  }
`;
export const StyledCommon = styled.div`
  /* margin-top: 6.4rem; */
  .head-title {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: #303030;
    margin-bottom: 2.6rem;
  }
  .list-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.5rem;

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        font-size: ${(props) => props.theme.size.xl};
      }
    }
    &-line {
      flex: 5;
      margin: 0 3.2rem;
      height: 0.1rem;
      background-color: #ececec;
    }

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      padding: 0 1.6rem;
    }
  }

  .list-card-item {
    margin-top: 3.2rem;
    display: flex;
    gap: 2.4rem;
    position: relative;
    .swiper {
      width: 100%;
    }
    .pagination-btn {
      position: absolute;
      top: 30%;
      cursor: pointer;
      z-index: 10;
      width: 3.5rem;
      height: 3.5rem;
      background-color: #ccc;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
    .next-btn {
      right: -2rem;

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        right: 0rem;
      }
    }
    .prev-btn {
      left: -2rem;

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        left: 0rem;
      }
    }
    .swiper-button-disabled {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
    margin-top: 1.6rem;

    .head-title {
      font-size: ${(props) => props.theme.size.xl};
    }
  }

  @media (min-width: 1024px) and (max-width: 1180px) and (orientation: landscape) {
    margin-top: 1.6rem;

    .head-title {
      font-size: ${(props) => props.theme.size.xl};
    }
  }
`;

export const CardLink = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon-title {
    color: ${(props) => props.theme.colors.secondary};

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  svg {
    margin-left: 0.2rem;
  }
  &:hover {
    color: ${(p) => p.theme.colors.primary};
    svg {
      polyline {
        stroke: ${(p) => p.theme.colors.primary};
      }
    }
  }

  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    font-size: ${(props) => props.theme.size.xs};
  }
`;
