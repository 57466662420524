import React from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';

import { SubCategoryType } from '@interfaces';

interface CardMenuProps {
  menu: SubCategoryType[];
  onSelect?: (value: any) => void;
}

export const CardMenu: React.FC<CardMenuProps> = ({ menu, onSelect }) => {
  const renderItem = menu.map((item) => {
    return {
      key: item.id,
      label: <Label>{item.name}</Label>,
    };
  });

  return <MenuWrapper items={renderItem} onClick={onSelect} />;
};

const MenuWrapper = styled(Menu)`
  border-radius: 0.4rem;
  padding: 0.4rem 1rem;
`;

const Label = styled.p`
  font-size: ${(props) => props.theme.size.s};
  font-weight: ${(props) => props.theme.weight['400']};
  line-height: 1.5rem;
  margin-bottom: 1.6rem;
  padding: 1.1rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;
