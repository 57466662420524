import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SideBar } from '@components';
import {
  categorySelected,
  fetchCategories,
  fetchProductList,
  resetCategoryId,
  selectCategories,
  selectShowCategorySidebar,
  setPrice,
  setShowCategorySidebar,
  useAppSelector,
} from '@redux';
import { PATH_LISTING, PRICE_PARAMS } from '@configs';
import { CategoriesDataType, CategorySelected } from '@interfaces';
import { useNavigateParams } from '@hooks';

interface IProps {
  children?: string | JSX.Element | JSX.Element[];
}

export const SideBarModule = (props: IProps) => {
  const showCategorySidebar = useAppSelector(selectShowCategorySidebar);
  const selectCategory = useAppSelector(selectCategories);
  const navigate = useNavigateParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  // const isMobile = useMediaQuery('(max-width: 640px)');

  const [minimum, setMinimum] = useState<number>();
  const [maximum, setMaximum] = useState<number>();

  const handleChangeMinimum = (e: number) => {
    setMinimum(+e);
  };

  const handleChangeMaximum = (e: number) => {
    setMaximum(+e);
  };

  const handleCloseSidebar = () => {
    if (showCategorySidebar) {
      dispatch(setShowCategorySidebar(false));
    }
  };

  const handleResetCategoryId = () => {
    dispatch(resetCategoryId(null));
  };

  const handlePressEnter = () => {
    dispatch(setPrice({ priceMin: minimum, priceMax: maximum }));
    dispatch(fetchProductList({ ...PRICE_PARAMS, priceMin: minimum, priceMax: maximum }));
    handleResetCategoryId();
  };

  const handleFilterApply = (prs: { parent?: CategoriesDataType; childId: number }) => {
    const selectCategory: CategorySelected = {
      categories: prs.parent,
      idSubcategory: prs.childId,
    };
    dispatch(categorySelected(selectCategory));
    dispatch(
      fetchProductList({
        start: 0,
        length: 12,
        ...(minimum && { priceMin: minimum }),
        ...(maximum && { priceMax: maximum }),
      }),
    );
    // dispatch(getCategoryId(prs.parent?.id || 47));
    dispatch(setPrice({ priceMin: minimum, priceMax: maximum }));
    handleResetCategoryId();
    navigate(PATH_LISTING, { price: 'all' });
  };

  return (
    <SideBar
      minimum={minimum}
      maximum={maximum}
      handleChangeMinimum={handleChangeMinimum}
      handleChangeMaximum={handleChangeMaximum}
      handleCloseSidebar={handleCloseSidebar}
      data={selectCategory.data.categories}
      showCategorySidebar={showCategorySidebar}
      onPressEnter={handlePressEnter}
      onApplyFilter={handleFilterApply}
    />
  );
};
