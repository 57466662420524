import { memo } from 'react';
import { FormikProps } from 'formik';

import { SharedButton, ShareInput } from '@components';
import { StyledLoginFrame, Text } from './loginStyle';
import { ILogin } from '@interfaces';

interface IProps {
  // register: UseFormRegister<any>;
  formik: FormikProps<ILogin>;
  backToHome?: () => void;
  redirectToSignUp?: () => void;
  redirectToForgot?: () => void;
}

export const LoginComponent = memo((props: IProps) => {
  const { formik, backToHome, redirectToSignUp, redirectToForgot } = props;

  return (
    <StyledLoginFrame className="login__section">
      <div className="heading">
        <h1 className="heading_title">サインイン</h1>
        <span onClick={backToHome} className="back-home redirect-btn">
          ホームに戻る
        </span>
      </div>
      <p className="desc">
        アカウントをお持ちではありませんか？
        <span onClick={redirectToSignUp} className="sub-action redirect-btn">
          サインアップ
        </span>
      </p>
      <form onSubmit={formik.handleSubmit} className="register-form">
        <div className="register-wrap">
          <ShareInput
            label="メールアドレス"
            placeholder="メールアドレスを入力"
            name="email"
            className="input"
            type="text"
            value={formik.values.email}
            errors={formik.errors.email}
            touched={formik.touched.email}
            onChange={formik.handleChange}
            inputMode="email"
            required
          />
          <ShareInput
            label="パスワード"
            placeholder="パスワードを入力"
            name="password"
            className="input"
            type="password"
            value={formik.values.password}
            errors={formik.errors.password}
            touched={formik.touched.password}
            onChange={formik.handleChange}
            required
          />

          <div className="text-right mb-5 flex-right">
            <Text
              onClick={redirectToForgot}
              textColor="#0BDED2"
              pointer
              fontSize="1.2rem"
              width="fit-content"
            >
              パスワードを忘れた方は、こちらら
            </Text>
          </div>

          <SharedButton
            typeHtml="submit"
            text="サインイン"
            className="submit_btn"
            btnStyle="rounded"
            bgColor="#D42A1C"
          />
        </div>
      </form>
    </StyledLoginFrame>
  );
});
