import styled from 'styled-components';
import { Checkbox } from 'antd';

export const StyledCart = styled.div<{
  isMobile?: boolean;
  isEmptyCart?: boolean;
}>`
  .cart-container {
    width: 100%;
    border: ${(props) => (props.isMobile ? 'none' : '1px solid #ECECEC')};
    // border: 1px solid #ECECEC;
    border-radius: ${(props) => (props.isMobile ? '0' : '16px')};
    padding: ${(props) => (props.isMobile ? '0' : '60px 60px 60px 60px')};
    margin-bottom: 100px;
  }

  .step {
    display: flex;
    justify-content: center;

    .ant-steps-item.ant-steps-item-finish {
      &:nth-child(1) {
        .ant-steps-item-container .ant-steps-item-icon:after {
          content: '1';
          color: ${(p) => p.theme?.colors?.body};
          font-weight: 500;
        }
      }

      .ant-steps-item-container {
        .ant-steps-item-tail {
          background: #d42a1c;
        }
      }
    }

    .ant-steps-item.ant-steps-item-finish {
      &:nth-child(2) {
        .ant-steps-item-container .ant-steps-item-icon:after {
          content: '2';
          color: ${(p) => p.theme?.colors?.body};
          font-weight: 500;
        }
      }
    }

    .ant-steps-item-title {
      font-size: ${(props) => (props.isMobile ? '1.2rem' : '1.4rem')};
      font-family: 'Noto Sans JP';
      font-weight: 300;
    }

    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: ${(p) => p.theme?.colors?.button?.background};
    }

    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: ${(p) => p.theme?.colors?.button?.background};
    }
  }

  .ant-steps-finish-icon {
    display: none;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: ${(props) => props.theme?.colors?.primary};
    border-color: ${(props) => props.theme?.colors?.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
    display: none;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 2.1rem;
    left: 64px;
    width: 100%;
    height: 1px;
    background: ${(p) => p.theme?.colors?.bgColor};
    padding: 0;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${(props) => props.theme?.colors?.primary};
    z-index: 2;
  }

  .ant-steps-item > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail {
    background: ${(props) => props.theme?.colors?.primary};
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: ${(props) => props.theme?.colors?.primary};
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-steps-item-container {
    display: inline-grid;
    justify-items: center;
    text-align: center;
  }

  .ant-steps-item-icon {
    width: 4.4rem;
    height: 4.4rem;
  }

  .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: ${(p) => p.theme?.colors?.button?.background};
  }

  .ant-steps-item-title::after {
    display: none;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #6c757d;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
  }

  .ant-steps-vertical {
    display: flex;
    flex-direction: row;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  ant-checkbox-wrapper:hover {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  @media screen and (min-width: 640px) {
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      left: 7.5rem;
    }
  }
  @media screen and (max-width: 640px) {
    .ant-steps-item-container {
      display: inline-grid;
      justify-items: center;
      text-align: center;
    }

    .ant-steps-vertical {
      flex-direction: row;
    }

    .ant-steps-item-finish {
      display: none;
    }

    .ant-steps-item-title {
      font-size: 12px;
    }

    .ant-steps-item-tail::after {
      display: none;
    }
  }

  .cart-container {
    width: 100%;
    margin-top: 0.8rem;

    .cart-empty {
      width: 100%;
      height: 31.9rem;
      //border: 1px solid #ececec;
      border-radius: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &-btn {
        margin-top: 2.4rem;
        width: 28.4rem;
        height: 4.4rem;
        background: ${(p) => p?.theme?.colors?.primary};
        border-radius: 4px;

        span {
          color: ${(p) => p.theme?.colors?.body};
        }
      }

      h5 {
        margin-top: 2.4rem;
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.5rem;
        color: #303030;
      }

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        padding: 0 1.6rem;
      }
    }
  }

  .message {
    width: 100%;
    height: 23px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: ${(p) => p.theme?.colors?.button?.background};
    padding-top: 24px;
    padding-bottom: 46px;
  }

  .check-all {
    display: flex;
    gap: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    padding-bottom: 6.4rem;
  }

  @media screen and (min-width: 640px) {
    .label-checkbox {
      width: 70%;
    }

    title-action {
      flex: 1;
    }

    .title {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }

    .book-price-delete {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }
  }
`;

export const StepsCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 80px;

  .step {
    width: 32px;
    height: 32px;
    background: #d9d9d9;
    border-radius: 50px;
    font-weight: 400;
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    padding-top: 7.5px;
    z-index: 2;
    color: #6c757d;

    .des-step {
      position: absolute;
      width: 96px;
      height: 17px;
      left: 28px;
      top: 270px;
    }

    .ant-steps-item-title {
      font-size: 14px;
    }
  }

  .line {
    position: absolute;
    width: 200px;
    height: 0px;
    border: 1px solid #d9d9d9;
  }

  .process {
    background: ${(props) => props.theme?.colors?.primary};
    color: #ececec;
  }
`;
