import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseResponseProps, BookRequestProps } from '@interfaces';
import { RootState } from '@redux';
import {
  createBookRequest,
  fetchSearchBookRequest,
  fetchBookRequest,
  fetchBookRequestTopicsLibrary,
  fetchPaginationBookRequest,
} from '../action';
import { toast } from 'react-toastify';

export interface BookRequestSliceProps {
  loading: boolean;
  createBookRequestResponse: BaseResponseProps | null;
  bookRequest: BaseResponseProps<BookRequestProps[]> | null;
  bookRequestTopicsLibrary: BaseResponseProps | null;
  bookRequestSearchList: BaseResponseProps<BookRequestProps[]> | null;
}

const initialState: BookRequestSliceProps = {
  loading: false,
  createBookRequestResponse: null,
  bookRequest: null,
  bookRequestTopicsLibrary: null,
  bookRequestSearchList: null,
};

export const bookRequestSlice = createSlice({
  name: 'BookRequestSlice',
  initialState,
  reducers: {
    resetCreateBookRequestResponse: (state) => {
      state.createBookRequestResponse = null;
    },
    resetBookRequest: (state, action) => {
      state.bookRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBookRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createBookRequest.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps>) => {
          state.loading = false;
          state.createBookRequestResponse = payload;
          if (payload.success) {
            toast.success('成功');
          } else {
            toast.error('失敗');
          }
        },
      )
      .addCase(createBookRequest.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchBookRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchBookRequest.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<BookRequestProps[]>>) => {
          state.loading = false;
          state.bookRequest = payload;
        },
      )
      .addCase(fetchBookRequest.rejected, (state) => {
        state.loading = false;
      })

      .addCase(fetchBookRequestTopicsLibrary.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchBookRequestTopicsLibrary.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps>) => {
          state.loading = false;
          state.bookRequestTopicsLibrary = payload;
        },
      )
      .addCase(fetchBookRequestTopicsLibrary.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchSearchBookRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchSearchBookRequest.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps>) => {
          state.loading = false;
          state.bookRequestSearchList = payload;
        },
      )
      .addCase(fetchSearchBookRequest.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchPaginationBookRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchPaginationBookRequest.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps>) => {
          state.loading = false;
          state.bookRequestSearchList = payload;
        },
      )
      .addCase(fetchPaginationBookRequest.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { resetCreateBookRequestResponse, resetBookRequest } = bookRequestSlice.actions;
export const bookRequestReducer = bookRequestSlice.reducer;
export const selectBookRequest = (state: RootState) => state.bookRequest;
