import { IBreadcrumbItem } from '@interfaces';
import { Breadcrumb } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { StyledCategory } from './categories/style';
import {
  categorySelected,
  changePathName,
  RootState,
  selectBreadcrumb,
  selectCategories,
  useAppSelector,
} from '@redux';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToLS } from '@utils';

interface IProps {
  data?: IBreadcrumbItem[];
}

export const Breadcrumbs = memo((props: IProps) => {
  const categorySelected = useAppSelector(
    (state: RootState) => state.category.data.categorySelected,
  );
  const { data } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const breadcrumbSelector = useAppSelector(selectBreadcrumb);

  /*LogApp('location', location);*/
  const currentPath = location?.pathname;
  const pathCheck = currentPath?.startsWith('/category/') ? 'category' : null;
  const dispatch = useDispatch();

  const handleNavigate = (path: string) => {
    dispatch(changePathName(path));
    setToLS('subActive', categorySelected?.categories?.id);
  };

  return (
    <StyledBreadcrumbs className="app-breadcrumbs" separator="">
      <Breadcrumb.Item>
        <NavLink to="/home">ホーム</NavLink>
      </Breadcrumb.Item>
      {breadcrumbSelector.path ? (
        <CustomBreadcrumb />
      ) : (
        <>
          {data?.map((item: IBreadcrumbItem, index: number) => (
            <React.Fragment key={index}>
              <Breadcrumb.Separator>|</Breadcrumb.Separator>
              {pathCheck ? (
                <Breadcrumb.Item>
                  {data?.length === index + 1 ? (
                    <div className="label">{t(`${item.label}`)}</div>
                  ) : (
                    <NavLink to={item.path} onClick={() => handleNavigate(item.label)}>
                      {t(`${item.label}`)}
                    </NavLink>
                  )}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item>
                  {data?.length === index + 1 ? (
                    <div>{t(`path.${item.label}`)}</div>
                  ) : (
                    <NavLink to={item.path} onClick={() => handleNavigate(item.label)}>
                      {t(`path.${item.label}`)}
                    </NavLink>
                  )}
                </Breadcrumb.Item>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </StyledBreadcrumbs>
  );
});

const StyledBreadcrumbs = styled((props) => <Breadcrumb {...props} />)`
  padding-top: 3.4rem;
  @media (max-width: 640px) {
    padding-top: 2.4rem;
  }
  @media (max-width: 374px) {
    padding-top: 4.4rem;
  }
  .ant-breadcrumb-link {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  ol {
    display: flex;
    align-items: center;
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme?.colors.gray['300']};
      font-size: 11.5px;
    }
    .ant-breadcrumb-link {
      a {
        display: flex;
        align-items: center;
      }
      .label {
        display: flex;
        align-items: center;
      }
    }
  }

  li {
    height: 2rem;
    &:not(:last-child) {
      .ant-breadcrumb-link a {
        color: ${(props) => props.theme?.colors.gray['300']};
        &:hover {
          color: ${(props) => props.theme?.colors.primary};
        }
      }
    }

    .ant-breadcrumb-link {
      color: ${(props) => props.theme?.colors.gray['700']};
    }

    &:last-child {
      .ant-breadcrumb-link a {
        color: #000;
      }
    }
  }

  @media (max-width: 640px) {
    .ant-breadcrumb-link {
      font-size: 1.2rem;
    }
  }
`;
