import { Col, Modal, Row } from 'antd';
import styled from 'styled-components';
import {
  BookInfo,
  BookPurchase,
  SkeletonBookDetailDesktop,
  SkeletonBookDetailMobile,
  SkeletonCardItem,
} from '@components';
import { RelatedBooksModule } from '@modules';
import { setToLS, useMediaQuery } from '@utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  changePathName,
  fetchDetailBook,
  fetchSimilarBook,
  fetchWishList,
  getAllBooksInCart,
  removeOneCart,
  resetDataDetailBook,
  resetRating,
  RootState,
  selectBook,
  selectBookReview,
  selectCart,
  useAppSelector,
} from '@redux';
import { AddToCartProps } from '@interfaces';
import { PATH_LOGIN, PATH_READ_BOOK } from '@configs';
import { IconRecycle } from '@components';

export const BookDetailsPage = () => {
  const location = useLocation();
  const { bookId } = useParams();

  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(max-width:1200px)');
  const navigate = useNavigate();

  const [unlike, setUnlike] = useState(false);
  const [currentIdItem, setCurrentIdItem] = useState<number>();

  const bookSelector = useAppSelector(selectBook);
  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const isLogin = accessToken;
  const { loading } = bookSelector;
  const { sendComment } = useAppSelector(selectBookReview);
  const {
    data: { addCart, cartRemoveOne },
  } = useAppSelector(selectCart);

  useEffect(() => {
    return () => {
      dispatch(changePathName(null));
    };
  });

  const memoryPath = () => {
    const currentPath = location.pathname;
    setToLS('memoPath', currentPath);
  };
  const onAddToCart = (bookId: number) => {
    if (isLogin) {
      const addToCartParams: AddToCartProps = {
        ProductId: bookId,
        Quantity: 1,
        ShoppingCartTypeId: 1, // = 1 => add to shopping cart
      };
      dispatch(addToCart(addToCartParams));
    } else {
      memoryPath();
      navigate(PATH_LOGIN);
    }
  };
  const onBuyPaperBook = (bookId: number) => {
    if (isLogin) {
      const book = bookSelector; //todo logic
    } else {
      memoryPath();
      navigate(PATH_LOGIN);
    }
  };
  const onReadBook = (bookId: number) => {
    // if (isLogin) {
    //   navigateParam(`${PATH_READ_BOOK}/${bookId}`);
    // } else {
    //   memoryPath();
    //   navigate(PATH_LOGIN);
    // }
    navigate(`${PATH_READ_BOOK}/${bookId}`, { replace: true });
    memoryPath();
  };
  const fetchAllInfo = () => {
    if (bookId) {
      dispatch(fetchDetailBook(bookId));
      dispatch(fetchSimilarBook(bookId));
    }
  };

  useEffect(() => {
    if (sendComment.data && sendComment.data.success) {
      fetchAllInfo();
    }
  }, [sendComment]);

  const handleLike = (id: number) => {
    if (isLogin) {
      const addToCartParams: AddToCartProps = {
        ProductId: id,
        Quantity: 1,
        ShoppingCartTypeId: 2, // = 2 =>  add to wishlist
      };
      dispatch(addToCart(addToCartParams));
    } else {
      memoryPath();
      navigate(PATH_LOGIN);
    }
  };

  const onConfirmUnlike = () => {
    currentIdItem && dispatch(removeOneCart(currentIdItem));
  };

  const handleUnlike = (id: number) => {
    setCurrentIdItem(id);
    setUnlike(true);
    // dispatch(removeOneCart(id));
  };

  /*  useEffect(() => {
      dispatch(setLoading(bookSelector.loading));
    }, [bookSelector.loading]);*/

  useEffect(() => {
    fetchAllInfo();
    dispatch(resetRating());
  }, [location]);

  useEffect(() => {
    cartRemoveOne?.success && bookId && dispatch(fetchDetailBook(bookId));
    setUnlike(false);
  }, [cartRemoveOne]);

  useEffect(() => {
    if (addCart && addCart.success) {
      dispatch(getAllBooksInCart());
      dispatch(fetchWishList());
    }
    addCart?.success && bookId && dispatch(fetchDetailBook(bookId));
  }, [addCart]);
  useEffect(() => {
    return () => {
      dispatch(resetDataDetailBook(null));
    };
  }, []);

  return (
    <>
      <StyledDetailsPage className="details-page" isMobile={isMobile}>
        <div className="page-contain">
          <Row gutter={[0, 0]} wrap>
            {loading ? (
              <div className="custom-skeleton">
                <SkeletonCardItem type={'horizontal'} size={'large'}></SkeletonCardItem>
              </div>
            ) : (
              <StyleCol className="gutter-row" sm={20} md={24} lg={24} xl={18}>
                <BookInfo
                  onLikeBook={handleLike}
                  onUnlikeBook={handleUnlike}
                  data={bookSelector.data.detailBook?.data || null}
                />
              </StyleCol>
            )}
            {loading ? (
              <>{isLargeScreen ? <SkeletonBookDetailMobile /> : <SkeletonBookDetailDesktop />}</>
            ) : (
              <>
                {!isLargeScreen && (
                  <Col className="gutter-row" xs={24} sm={4} md={24} lg={24} xl={6}>
                    <BookPurchase
                      purBoxClassName="right"
                      shoppingCartId={bookSelector.data.detailBook?.data.shoppingCartId || 0}
                      onAddToCard={(e) => onAddToCart(e)}
                      onBuyPaperBook={(e) => onBuyPaperBook(e)}
                      onReadBook={(e) => onReadBook(e)}
                      data={bookSelector.data.detailBook?.data.productPrice || null}
                    />
                  </Col>
                )}
              </>
            )}
          </Row>
          {/* <RelatedBooksModule data={bookSelector.data.similarBooks} /> */}
        </div>
      </StyledDetailsPage>
      <Modal
        className="modal-cart hide-close"
        style={{ fontSize: isMobile ? '12px' : '14px' }}
        title="確認"
        cancelText="キャンセル"
        okText={
          <div className="flex-center-all">
            <IconRecycle stroke={'#fff'} />
            OK
          </div>
        }
        open={unlike}
        onOk={onConfirmUnlike}
        onCancel={() => setUnlike(false)}
      >
        <p>この本をウィッシュリストから削除してもよろしいですか？</p>
      </Modal>
    </>
  );
};

const StyleCol = styled(Col)`
  padding: 0 !important;
`;

const StyledDetailsPage = styled.section<{
  isMobile?: boolean;
}>`
  position: relative;
  /* width: 100%; */
  .ant-col {
    position: relative;
  }

  .ant-row,
  .ant-row:after,
  .ant-row:before {
    display: ${(props) => (props.isMobile ? 'unset' : 'flex')};
  }

  @media screen and (min-width: 375px) and (max-width: 1024px) {
    .ant-col {
      padding: 0 !important;
    }

    .ant-row {
      margin: 0 !important;
    }
  }
  .custom-skeleton {
    width: calc(100% - 314.5px - 24px);
    @media (max-width: 1200px) {
      width: 100%;
    }
    div:nth-child(1) {
      width: 100%;
      .skeleton-image {
        width: 28rem;
      }
    }
  }
`;
