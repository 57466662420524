import React, { useRef } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { useOnClickOutside } from '@utils';

interface CustomDrawerProps {
  title?: string;
  placement: 'top' | 'right' | 'bottom' | 'left';
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
  closable?: boolean;
  className?: string;
}

export const CustomDrawer: React.FC<CustomDrawerProps> = ({
  title,
  placement = 'left',
  onClose,
  open,
  children,
  closable = false,
  className,
}) => {
  const drawer = useRef<any>(null);

  useOnClickOutside(drawer, () => {
    onClose();
  });

  return (
    <DrawerWrapper
      ref={drawer}
      open={open}
      title={title}
      placement={placement}
      onClose={onClose}
      closable={closable}
      className={className}
    >
      {children}
    </DrawerWrapper>
  );
};

const DrawerWrapper = styled((props) => <Drawer {...props} />)<{
  position?: boolean;
}>`
  .ant-drawer-body {
    padding: 0;
  }
`;
