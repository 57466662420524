import React from 'react';
import { PaginationWrapper } from './style';
import { LogApp } from '@utils';

interface PaginationProps {
  defaultCurrent: number;
  total: number;
  onChangePage: (value: number) => void;
  pageSize: number;
  current?: number;
}

export const Pagination: React.FC<PaginationProps> = React.memo(
  ({ defaultCurrent, total, onChangePage, pageSize, current }) => {
    LogApp({ current, total, pageSize });
    return current ? (
      <PaginationWrapper
        defaultCurrent={defaultCurrent}
        total={total}
        onChange={onChangePage}
        pageSize={pageSize}
        current={current}
        showSizeChanger={false}
      />
    ) : (
      <PaginationWrapper
        showSizeChanger={false}
        defaultCurrent={defaultCurrent}
        total={total}
        onChange={onChangePage}
        pageSize={pageSize}
      />
    );
  },
);
