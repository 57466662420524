import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  createBookRequest,
  fetchBookRequest,
  fetchPaginationBookRequest,
  fetchSearchBookRequest,
  resetBookRequest,
  resetCreateBookRequestResponse,
  RootState,
  selectBookRequest,
  useAppSelector,
} from '@redux';
import * as Yup from 'yup';
import {
  HeaderCustomer,
  IconImage,
  IconPlus,
  Pagination,
  SearchEmptyBox,
  SharedButton,
  ShareInput,
  SkeletonBookRequest,
  UserBookRequest,
} from '@components';
import { StyledBookRequest, StyledModal, StyledUploadButton } from './style';
import { Button, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { deleteSearchParams } from '@utils';
import { useNavigateParams } from '@hooks';
import { PATH_BOOK_REQUEST } from '@configs';

export const BookRequestModule = () => {
  const imageMaxSize = 10;
  const uploadBtnRef = useRef<HTMLInputElement | null>(null);
  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const navigate = useNavigateParams();
  const isLogin = accessToken;
  const dispatch = useDispatch();
  const { loading, createBookRequestResponse, bookRequest, bookRequestSearchList } =
    useAppSelector(selectBookRequest);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [maxSizeImgError, setMaxSizeImgError] = useState<string>();
  const [searchText, setSearchText] = useState('');
  const searchParams = useSearchParams();
  const formik = useFormik({
    initialValues: {
      AuthorName: '',
      Name: '',
      Body: '',
    },
    validationSchema: Yup.object({
      AuthorName: Yup.string().trim().required('著者名が必要です').max(150, `最大 150 文字`),
      Name: Yup.string()
        .trim()
        .required('書籍のタイトルは空にできません')
        .max(150, `最大 150 文字`),
      Body: Yup.string().trim().max(1000, `最大 1000 文字`),
    }),
    onSubmit: (val) => {
      const formData = new FormData();
      formData.append('AuthorName', val.AuthorName);
      formData.append('Name', val.Name);
      formData.append('Body', val.Body);
      if (selectedImage) {
        const imageSize = selectedImage.size / Math.pow(1024, 2);
        if (selectedImage && imageSize > imageMaxSize) {
          setMaxSizeImgError('画像サイズは 10 MB を超えることはできません');
        } else {
          setMaxSizeImgError(undefined);
          selectedImage && formData.append('Files', selectedImage);
          dispatch(createBookRequest(formData));
        }
      } else {
        dispatch(createBookRequest(formData));
      }
    },
  });

  const handleUpload = () => {
    uploadBtnRef && uploadBtnRef?.current?.click();
  };

  const handleChangeImage = (e: ChangeEvent<any>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    formik.resetForm();
    setSelectedImage(undefined);
  };

  const onSearch = (e: string) => {
    setSearchText(e);
    isLogin && dispatch(fetchSearchBookRequest(e));
    deleteSearchParams(searchParams, ['start', 'length']);
  };

  useEffect(() => {
    return () => {
      isLogin && dispatch(fetchSearchBookRequest(''));
    };
  }, []);

  useEffect(() => {
    if (!selectedImage) {
      setPreviewUrl(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  useEffect(() => {
    if (!isLogin) dispatch(resetBookRequest(null));
  }, [isLogin]);

  useEffect(() => {
    isLogin && dispatch(fetchBookRequest());
    if (createBookRequestResponse) {
      if (createBookRequestResponse.success) {
        handleCancel();
      }
      dispatch(resetCreateBookRequestResponse());
    }
  }, [createBookRequestResponse]);
  const handlePageChange = (pageNumber: number) => {
    const page = {
      start: pageNumber - 1,
      length: 12,
    };
    navigate(PATH_BOOK_REQUEST, page);
    dispatch(fetchPaginationBookRequest(createSearchParams(page as any).toString()));
  };
  return (
    <>
      <StyledBookRequest className="book-request" isLogin={isLogin}>
        <div className="book-request-container">
          <div className="book-request-container-right rg-sec">
            <div className="book-request-container-right-header">
              <HeaderCustomer
                onSearch={onSearch}
                variant="search"
                title="本の広場"
                content="感動した本、人にお薦めした本をご紹介ください。 読者同士の交流の場です。ご活用ください。"
              />
            </div>
            <div className="book-request-container-right-registration">
              <p>下記のボタンから、あなたのオススメの本、 情報を知りたい本を登録してください。</p>
              <SharedButton
                prevIcon={<IconPlus />}
                className="new-registration"
                text={'新規登録'}
                onClick={() => setIsModalOpen(!isModalOpen)}
              />
            </div>
            <div className="book-request-container-right-user-request">
              {loading ? (
                <SkeletonBookRequest />
              ) : (
                <>
                  {searchText.length > 0 ? (
                    <>
                      {bookRequestSearchList && bookRequestSearchList.data.length > 0 ? (
                        bookRequestSearchList.data.map((item) => (
                          <UserBookRequest key={item.id} data={item} />
                        ))
                      ) : (
                        <SearchEmptyBox text="リクエストが見つかりません" hasNavBtn={false} />
                      )}
                    </>
                  ) : (
                    <>
                      {bookRequest && bookRequest.data.length > 0 ? (
                        bookRequest.data.map((item) => (
                          <UserBookRequest key={item.id} data={item} />
                        ))
                      ) : (
                        <SearchEmptyBox text="本のリクエストはありません" />
                      )}
                    </>
                  )}
                </>
              )}
              <div className="pagination">
                {bookRequestSearchList?.total !== 0 && (
                  <Pagination
                    // current={+(Number(searchParams[0].get('start') || 0) + 1)}
                    current={1}
                    defaultCurrent={1}
                    total={bookRequestSearchList?.total || 0}
                    onChangePage={handlePageChange}
                    pageSize={10}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledBookRequest>
      <Modal
        className="modal-book-request"
        title="リクエスト"
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        closable={false}
      >
        <StyledModal>
          <form onSubmit={formik.handleSubmit}>
            <div className="container">
              <ShareInput
                label="書籍タイトル"
                name="Name"
                placeholder="書籍タイトルを入力ください"
                onChange={formik.handleChange}
                value={formik.values.Name}
                errors={formik.errors.Name}
                touched={formik.touched.Name}
                required
              />
            </div>
            <div className="container">
              <ShareInput
                name="AuthorName"
                label="著者"
                placeholder="著者をご存知の場合、入力してください"
                onChange={formik.handleChange}
                value={formik.values.AuthorName}
                errors={formik.errors.AuthorName}
                touched={formik.touched.AuthorName}
                required
              />
            </div>
            <div className="container">
              <p className="text-area-label">必須項目:</p>
              <TextArea
                name="Body"
                className="text-area"
                autoSize={{ maxRows: 8, minRows: 6 }}
                placeholder="リクエスト書籍について本の紹介や、オススメしたい点、質問などを入力してください"
                onChange={formik.handleChange}
                value={formik.values.Body}
              />
              {formik.errors.Body ? (
                <>
                  <br />
                  <div className="input-error">{formik.errors.Body}</div>
                </>
              ) : null}
            </div>
            <div className="avatar">
              {previewUrl && (
                <img onClick={handleUpload} src={previewUrl} alt="" className="avatar" />
              )}
              <StyledUploadButton>
                <input
                  ref={uploadBtnRef}
                  type="file"
                  id="avatar"
                  name="avatar"
                  accept="image/png, image/jpeg"
                  onChange={handleChangeImage}
                />
              </StyledUploadButton>
            </div>

            <div className="btn-create">
              <div className="ic-img">
                <div onClick={handleUpload} className="file-picker">
                  <IconImage />
                </div>
                {maxSizeImgError && <div className="input-error">{maxSizeImgError}</div>}
              </div>
              <div className="button-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="new-registration"
                  loading={loading}
                >
                  <IconPlus /> &nbsp; 送信
                </Button>
              </div>
            </div>
          </form>
        </StyledModal>
      </Modal>
    </>
  );
};
