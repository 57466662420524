import { IUserInfo } from '@interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';

interface IAuth {
  accessToken?: string;
  refreshToken?: string;
  userInfo?: IUserInfo;
  role?: any;
}

const initialState: IAuth = {
  accessToken: undefined,
  refreshToken: undefined,
  userInfo: undefined,
  role: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<IUserInfo>) => {
      state.userInfo = action.payload;
    },
    setUserRole: (state, action: PayloadAction<IUserInfo>) => {
      state.role = action.payload;
    },
    logout: () => {
      return initialState;
    },
  },
});

export const { setAccessToken, setRefreshToken, setUserInfo, setUserRole, logout } =
  authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
