import {
  IForgotPassword,
  IForgotPasswordRqBody,
  ILoginBody,
  IRegisterBody,
  ISetPasswordRqBody,
  LoginByFacebookProps,
  LoginWithLineProps,
} from '@interfaces';
import axiosClient from './axiosClient';

export const authAPI = {
  login: (values: ILoginBody) => {
    const url = 'api/Authentication/login';
    return axiosClient.post(url, values);
  },
  register: (values: IRegisterBody) => {
    const url = 'api/Authentication/register';
    return axiosClient.post(url, values);
  },
  forgotPassword: (values?: IForgotPasswordRqBody) => {
    const url = 'api/Customer/passwordRecoverySendEmail';
    return axiosClient.post(url, values);
  },
  resetPassword: (values?: ISetPasswordRqBody) => {
    const url = 'api/Customer/passwordrecovery';
    return axiosClient.post(url, values);
  },
  logout: (values?: IForgotPassword) => {
    const url = 'api/Authentication/logout';
    return axiosClient.post(url, values);
  },
  refreshToken: (values?: IForgotPassword) => {
    const url = 'api/Authentication/refresh-token';
    return axiosClient.post(url, values);
  },
  loginWithLine: (params: LoginWithLineProps) => {
    return axiosClient.post('api/ExternalAuth/Line/webapplogin', params);
  },
  loginByFacebook: (body: LoginByFacebookProps) => {
    const url = 'api/ExternalAuth/Facebook/loginWebApp';
    return axiosClient.post(url, body);
  },
  loginWithApple: (body: any) => {
    return axiosClient.post('api/AppleAuthentication/LoginWeb', body);
  },
};
