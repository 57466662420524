import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CustomerProps,
  CustomerRqBodyProps,
  BaseResponseProps,
  ChangePasswordProps,
} from '@interfaces';
import { customerApi } from '@api';

const url = 'Customer';
interface CustomerParam {
  [key: string]: any;
}
export const updateUserProfile = createAsyncThunk<any, CustomerRqBodyProps>(
  `${url}`,
  async (params, { rejectWithValue }) => {
    try {
      return (await customerApi.updateProfile(
        params,
      )) as unknown as BaseResponseProps<CustomerProps>;
    } catch (e: any) {
      rejectWithValue(e);
    }
  },
);

export const getUserProfileDefault = createAsyncThunk<any>(
  `${url}/get`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await customerApi.getProfile();
      return res as unknown as BaseResponseProps<CustomerProps>;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const uploadAvatar = createAsyncThunk<any, FormData>(
  `${url}/upload-avatar`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await customerApi.updateAvatar(params);
      return res as unknown as BaseResponseProps<CustomerProps>;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const changeUserPassword = createAsyncThunk<any, ChangePasswordProps>(
  `${url}/change-password`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await customerApi.changePassword(params);
      return res as unknown as BaseResponseProps<CustomerProps>;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const changeSettingNotification = createAsyncThunk<any, boolean>(
  `${url}/change-notification`,
  async (params, { rejectWithValue }) => {
    try {
      const res: any = await customerApi.changeSettingNotification(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const getSettingNotification = createAsyncThunk(
  `${url}/get-notification`,
  async (params: CustomerParam, { rejectWithValue }) => {
    try {
      const res: any = await customerApi.getNotification(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
