import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Header } from '@components';
import {
  ALL_BOOKS_PARAMS,
  BOOKS_AND_AUTHORS_PARAMS,
  PATH_DETAILS,
  PATH_LISTING,
  PATH_LOGIN,
} from '@configs';
import { useSelector, useDispatch } from 'react-redux';
import {
  RootState,
  logout,
  fetchProductList,
  fetchWishList,
  getAllBooksInCart,
  getUserProfileDefault,
  resetCategoryId,
  fetchSuggestedData,
  resetCurrentPage,
  setSelectedSearchValue,
  checkSubscriptionProduct,
  resetDataLoginSocial,
  resetCartList,
  resetWishList,
  resetBookRequest,
} from '@redux';
import { getSearchValue } from '@redux';
import { useNavigateParams } from '@hooks';
import { removeFromLS, scrollToTop, setToLS } from '@utils';

let timer: ReturnType<typeof setTimeout>;
export const HeaderModule = () => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigateParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const { auth, product } = useSelector((state: RootState) => state);
  const { accessToken, role } = auth;
  const isLogin = accessToken;
  const { selectedSearchData } = product.data;
  const [searchValue, setSearchValue] = useState<string>('');
  const [openSearchDrawer, setOpenSearchDrawer] = useState<boolean>(false);

  const isSearchType = location.search.includes('search');
  const handleSearchValue = (value: string) => {
    setSearchValue(value.trim());
    dispatch(getSearchValue(value.trim()));

    if (value.trim()) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          fetchSuggestedData({ ...BOOKS_AND_AUTHORS_PARAMS, bookOrAuthorName: value.trim() }),
        );
      }, 500);
    }
  };

  useEffect(() => {
    isLogin && dispatch(checkSubscriptionProduct());
  }, []);

  useEffect(() => {
    if (PATH_LISTING !== location.pathname || !isSearchType) {
      setSearchValue('');
      dispatch(getSearchValue(''));
    }
  }, [location]);

  useEffect(() => {
    if (!isLogin) dispatch(resetCartList(null));
  }, [isLogin]);

  const handleRedirectToLogin = () => {
    navigate(PATH_LOGIN);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetDataLoginSocial());
    dispatch(resetCartList(null));
    dispatch(resetWishList(null));
    dispatch(resetBookRequest(null));
    FB.logout();
  };

  const handleSelectValue = (selectedValue: string) => {
    const bookItem = selectedSearchData.find((data) => data.productName === selectedValue);
    navigate(`${PATH_DETAILS}/${bookItem?.id}`);
    setOpenSearchDrawer(false);
  };

  const handleDispatchSearch = () => {
    dispatch(resetCurrentPage(1));
    dispatch(resetCategoryId(null));
    dispatch(setSelectedSearchValue(searchValue));
    if (!product.booksAndAuthorsParams && !searchValue.trim()) {
      dispatch(fetchProductList(ALL_BOOKS_PARAMS));
      navigate(PATH_LISTING, { filter: 'none' });
    } else {
      dispatch(
        fetchProductList({ ...BOOKS_AND_AUTHORS_PARAMS, bookOrAuthorName: searchValue.trim() }),
      );
      navigate(PATH_LISTING, { search: searchValue.trim() });
      setOpenSearchDrawer(false);
    }
    setToLS('subActive', '999999999');
    setOpenSearchDrawer(false);
    scrollToTop();
  };

  const options = selectedSearchData?.map((data) => {
    return {
      label: data.productName,
      value: data.productName,
    };
  });

  const fetchWishListData = () => {
    dispatch(fetchWishList());
    dispatch(getUserProfileDefault());
    dispatch(getAllBooksInCart());
  };

  const handleSubmit = () => {
    dispatch(resetCurrentPage(1));
    if (!product.booksAndAuthorsParams) {
      dispatch(fetchProductList(ALL_BOOKS_PARAMS));
      dispatch(resetCategoryId(null));
      navigate(PATH_LISTING, { filter: 'none' });
    } else {
      dispatch(
        fetchProductList({
          ...BOOKS_AND_AUTHORS_PARAMS,
          bookOrAuthorName: product.booksAndAuthorsParams,
        }),
      );
      dispatch(resetCategoryId(null));
      dispatch(setSelectedSearchValue(searchValue));
      navigate(PATH_LISTING, { search: searchValue });
    }
    removeFromLS('subActive');
    setOpenSearchDrawer(false);
    dispatch(setSelectedSearchValue(searchValue));
    scrollToTop();
  };

  return (
    <Header
      role={role}
      ref={ref}
      isLogin={isLogin}
      onLogout={handleLogout}
      redirectToLogin={handleRedirectToLogin}
      value={searchValue}
      onSearch={handleSearchValue}
      onSelect={handleSelectValue}
      options={options}
      onPressEnter={handleDispatchSearch}
      onFetchWishList={fetchWishListData}
      openSearchDrawer={openSearchDrawer}
      setOpenSearchDrawer={setOpenSearchDrawer}
      onSubmit={handleSubmit}
      onClickIcon={handleDispatchSearch}
    />
  );
};
