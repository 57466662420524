import styled, { css } from 'styled-components';

interface TextProps {
  textWeight?: string;
  textColor?: string;
  textSize?: string;
  isBefore?: boolean;
}

export const UserSideBarWrapper = styled.div`
  border: 1px solid #ececec;
  border-radius: 1.6rem;
  margin-bottom: 8rem;
  .p-item {
    padding: 1.6rem 2.4rem;
  }
`;

// export const UserHeader = styled.div`
//   margin-bottom: 3.2rem;
//
//   .header-home {
//     color: ${(props) => props.theme.colors.gray['300']};
//     margin-right: 1.6rem;
//   }
//
//   .header-location {
//     color: ${(props) => props.theme.colors.gray['700']};
//     font-size: ${(props) => props.theme.size.s};
//     font-weight: ${(props) => props.theme.weight['400']};
//   }
// `;

export const Account = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;

  .avatar-icon__wrapper {
    margin-right: 0.8rem;

    .icon {
      background-color: #b6b6b6;
      color: ${(props) => props.theme.colors.body};
      border-radius: 50%;

      svg {
        width: 4rem;
        height: 4rem;
        padding: 0.5rem;
      }
    }
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.8rem;

  .icon-wrapper {
    margin-right: 1.5rem;
  }

  .data-item__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .icon-active {
    &:hover {
      cursor: pointer;
    }
  }

  .data-title {
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const Text = styled.p<TextProps>`
  font-size: ${(props) => props.textSize || props.theme.size.xs};
  font-weight: ${(props) => props.textWeight || props.theme.weight['300']};
  color: ${(props) => props.textColor || props.theme.colors.text};

  ${(props) =>
    props.isBefore &&
    css`
      &::before {
        display: block;
        content: '';
        width: 1.6rem;
        height: 0.03rem;
        background-color: #b6b6b6;
        margin: 0 0.8rem;
      }
    `}
`;

export const SubDataWrapper = styled.div<{ isActive?: boolean }>`
  ${(props) => props.isActive && css``}

  .mb-item {
    margin-bottom: 0.8rem;
  }

  .df-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const UserAvatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 50%;
`;
export const UserAvatarLoading = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`;
