import { StyleContainer, StyledButtonGroup, StyledDivider } from './style';
import { IMAGES } from '@assets';
import { SharedButton, ShareInput } from '@components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ISetPassword, ISetPasswordRqBody, BaseResponseProps } from '@interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setLoading, useAppDispatch } from '@redux';
import { authAPI } from '@api';
import { toast } from 'react-toastify';
import { PATH_LOGIN } from '@configs';

export const SetPasswordModule = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const Email = searchParams.get('email') || '';
  const Token = searchParams.get('token') || '';
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .trim()
        .min(8, 'パスワードは8文字～60文字で入力して下さい。')
        .max(60, 'パスワードは8文字～60文字で入力して下さい。')
        .required('パスワードが必要です'),
      confirmPassword: Yup.string()
        .trim()
        .oneOf(
          //confirmPass not match with password
          [Yup.ref('password'), null],
          '新しいパスワードが再入力パスワードと一致しません。もう一度やり直してください。',
        )
        .required('パスワードが必要です'),
    }),
    onSubmit: (val) => {
      void onSubmit(val);
    },
  });

  const onSubmit = async (val: ISetPassword) => {
    const rqBody: ISetPasswordRqBody = {
      Email,
      Token,
      Newpassword: val.password,
      ConfirmNewpassword: val.confirmPassword,
    };

    try {
      dispatch(setLoading(true));
      const res = (await authAPI.resetPassword(rqBody)) as unknown as BaseResponseProps;
      if (res.success) {
        navigate(PATH_LOGIN);
      } else {
        toast.error(res.messages);
        // formik.setErrors({ confirmPassword: res.messages || '' });
      }
    } catch (e: any) {
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <StyleContainer background={IMAGES.backgroundLogin}>
      <div className="header__logo logo app-container">
        <img title="" src={IMAGES.headerLogo} alt="home" />
      </div>
      <div className="redirect-notify">
        <div className="base-header">パスワードを忘れた方</div>
        <StyledDivider height="2.8rem" />
        <form onSubmit={formik.handleSubmit}>
          <ShareInput
            label="新しいパスワード"
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            errors={formik.errors.password}
            touched={formik.touched.password}
            className="input"
            required
          />
          <StyledDivider height="2.8rem" mobileHeight="3.2rem" />
          <ShareInput
            label="パスワードの確認"
            name="confirmPassword"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            errors={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            className="input"
            required
          />
          <StyledDivider height="2.8rem" mobileHeight="3.2rem" />
          <StyledButtonGroup>
            <SharedButton
              typeHtml="button"
              text="リクエスト"
              className="button-item"
              btnStyle="rounded"
              bgColor="#CECECE"
            />
            <SharedButton
              typeHtml="submit"
              text="キャンセル"
              className="button-item"
              btnStyle="rounded"
              bgColor="#D42A1C"
            />
          </StyledButtonGroup>
        </form>
      </div>
    </StyleContainer>
  );
};
