import styled from 'styled-components';

export const StyledPayment = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .payment-container {
    width: 100%;
    max-width: 575px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
  }

  .step {
    display: flex;
    justify-content: center;
  }

  .pay {
    max-width: 370px;
    width: 100%;
    height: 44px;
    background: ${(props) => props.theme?.colors?.bgFooter};
    border-radius: 8px;
    margin-top: 2.4rem;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .input-password {
    width: ${(props) => (props.isMobile ? '88px' : '175px')};
  }

  .divider {
    margin-top: 24px;
    border: 1px solid ${(props) => props.theme?.colors?.gray['200']};
    width: 100%;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail:after {
    display: none;
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    position: absolute;
    top: 15px;
    left: 64px;
    width: 100%;
    height: 2px;
    background: ${(props) => props.theme?.colors?.gray['300']};
    padding: 0;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${(props) => props.theme?.colors?.primary};
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  .ant-steps-item-container {
    display: inline-grid;
    justify-items: center;
    text-align: center;
  }

  .ant-steps-item-title::after {
    display: none;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${(props) => props.theme?.colors?.gray['400']};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #d9d9d9;
  }

  .ant-steps-vertical {
    display: flex;
    flex-direction: row;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  .ant-checkbox-wrapper:hover {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  .ant-steps-finish-icon {
    display: none;
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${(props) => props.theme?.colors?.button?.background};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: ${(props) => props.theme?.colors?.primary};
    border-color: ${(props) => props.theme?.colors?.primary};
  }

  @media screen and (min-width: 640px) {
    .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      left: 80px;
    }
  }
  @media screen and (max-width: 640px) {
    .ant-steps-item-container {
      display: inline-grid;
      justify-items: center;
      text-align: center;
    }

    .ant-steps-vertical {
      flex-direction: row;
    }

    .ant-steps-item-finish {
      display: none;
    }

    .ant-steps-item-title {
      font-size: 12px;
    }

    .ant-steps-item-tail::after {
      display: none;
    }
  }
`;

export const StyledCard = styled.div<{
  isMobile?: boolean;
}>`
  width: 100%;
  display: flex;
  gap: ${(props) => (props.isMobile ? '8px' : '10px')};
  justify-content: center;
  .item-card {
    width: ${(props) => (props.isMobile ? '80px' : '100px')};
    height: ${(props) => (props.isMobile ? '54px' : '68px')};
    background: ${(props) => props.theme?.colors?.bgFooter};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyleInfoCard = styled.div`
  .select-date {
    width: calc(50%);
    .ant-select-selector {
      border: none !important;
    }
  }
  .input-error {
    padding-top: 0.6rem;
    font-size: 14px;
    font-family: 'Noto Sans JP';
  }
  .date-expiry {
    display: flex;
    gap: 16px;

    select {
      height: 48px;
      border-radius: 8px;
    }
    .ant-select {
      border: 1px solid #b6b6b6;
      border-radius: 0.8rem;
      @media (max-width: 640px) {
        padding: 0;
      }
      &.ant-select-focused .ant-select-selector {
        box-shadow: none !important;
      }
    }
  }

  .ant-select-arrow {
    color: ${(props) => props.theme?.colors?.gray['600']};
  }

  .pass-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const StyleTotal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ButtonPayment = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: ${(props) => (props.isMobile ? '24px' : '48px')};
  .checkout-submit {
    background-color: ${(p) => p.theme.colors.primary};
    height: 4.1rem;
    span {
      color: ${(p) => p.theme.colors.body};
    }
  }
`;

export const Text = styled.p<{
  noMarginTop?: boolean;
  noMarginBottom?: boolean;
  fontSize?: boolean;
  color?: string;
  fontWeight?: boolean;
}>`
  margin-top: ${(props) => (props.noMarginTop ? '0' : '16px')};
  margin-bottom: ${(props) => (props.noMarginBottom ? '0' : '8px')};
  font-size: ${(props) => (props.fontSize ? '18px' : '14px')};
  color: ${(props) => props.color};
  font-weight: ${(props) => (props.fontWeight ? 500 : 400)};
`;
