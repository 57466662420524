import styled, { css } from 'styled-components';

export const StyleMobileReadBook = styled.div<{
  $isTextBooks?: boolean;
  $scaleInner?: number;
  $firstPage?: boolean;
  $textBookFont?: string;
}>`
  background: ${(props) => props?.theme?.colors.body};
  width: 100%;
  .change-page_actions {
    box-shadow: rgba(99, 99, 99, 0.2) 0px -8px 8px -8px;
    padding: 2.5rem 1.6rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn {
      width: calc((100% - 4rem) / 2);
    }
  }
  .list-page {
    position: relative;
    .left-icon {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: ${(props) => props?.theme?.colors.bgFooter};
    }
    .right-icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: ${(props) => props?.theme?.colors.bgFooter};
    }
    .image-page {
      img {
        width: 100%;
        padding: 0 1.6rem;
      }
    }
  }

  .preview-book {
    position: relative;
    width: 100%;
    max-height: calc(80vh - 150px);
    min-height: calc(80vh - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    .content {
      width: 100%;
      ${(props) =>
        props?.$isTextBooks &&
        css`
          width: calc(100% - 3.2rem);
          margin: 0 auto;
          overflow: hidden;
        `};
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 60vh;
        svg {
          width: 100vw;
          @media (max-width: 767px) {
            height: fit-content;
            & > g {
              /* -webkit-transform: none; */
              /* transform: none !important; */
              /* transform: translate(0, 0) !important; */

              transform: scale(1) !important;
            }
          }
          @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
            & > g {
              /* -webkit-transform: none; */
              /* transform: none !important; */
              /* transform: translate(0, 0) !important; */
              /* transform: scaleX(1.2) !important; */
            }
          }
          /* margin-left: -8rem; */
          overflow: auto !important;
        }
      }
      .text-default {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        padding: 1.4rem 1.6rem 1.8rem;
        font-family: 'Noto Sans JP';
        font-weight: 400;
        line-height: 2.8rem;
        align-items: flex-start;
        position: relative;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 18rem);
        .content-text {
          font-size: ${(p) => `${(p?.$scaleInner || 1) * 1.2}rem`};
          /* margin-left: ${(p) => `${(p?.$scaleInner || 1) * 1.2 - 1}rem`}; */
          line-height: ${(p) => `${(p?.$scaleInner || 1) * 2.5}rem`};
          font-family: ${({ $textBookFont }) => ($textBookFont ? $textBookFont : 'ShinGoPr6')};
          &:first-child {
            ${(p) =>
              p?.$firstPage &&
              css`
                font-size: ${p?.$scaleInner ? `${(p?.$scaleInner || 1) * 1.8}rem` : `1.8rem`};
                font-weight: 600;
              `};
          }
        }
      }
      .book-cover {
        width: 100%;
        padding: 0 1.6rem 1rem;
        .cover-img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;

export const HeaderReadBook = styled.div`
  padding: 1.25rem 1.6rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
  background: ${(props) => props?.theme?.colors.body};
  .title {
    font-weight: 700;
    font-size: 1.6rem;
    font-style: normal;
    font-family: 'Noto Sans JP';
    color: ${(props) => props?.theme?.colors.text};
  }
`;

export const ToolBar = styled.div`
  margin: 0.8rem 1.6rem 1.6rem 1.6rem;
  border: 1px solid #ececec;
  border-radius: 4px;
  /* height: 4rem; */
  background-color: ${(props) => props?.theme?.colors.bgFooter};
  width: fit-content;
`;

export const NumberPage = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.1rem;
  .current-page {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    border-radius: 6px;
    background: ${(props) => props?.theme?.colors.bgFooter};
    padding: 4px 10px;
    width: 7.2rem;
    /* width: fit-content; */
    .current-page-search {
      width: 100%;
      border: none;
      background: ${(props) => props?.theme?.colors.bgFooter};
      padding: 0;
      text-align: center;
    }
  }
  .total-page {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    border-radius: 6px;
    background: ${(props) => props?.theme?.colors.bgFooter};
    padding: 4px 10px;
    display: flex;
    align-items: center;
  }
`;

export const NameBook = styled.div`
  padding: 5rem 6.5rem 4rem 6.5rem;
  text-align: center;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: ${(props) => props?.theme?.colors.text3};
`;
