import styled from 'styled-components';
import { BookPurchaseIcon, CartPurchaseIcon, StorePurchaseIcon } from '../Icon';

import { SharedButton, SharedSkeleton } from '../shared';
import { LogApp, removeSpecialCharacters, useMediaQuery } from '@utils';
import { ProductPrice } from '@interfaces';
import { FC } from 'react';
import { selectBook, useAppSelector } from '@redux';

interface BookPurchaseProps {
  data: ProductPrice | null;
  onAddToCard: (itemId: number) => void;
  onBuyPaperBook: (itemId: number) => void;
  onReadBook: (itemId: number) => void;
  shoppingCartId: number;
  purBoxClassName?: string;
}

export const BookPurchase: FC<BookPurchaseProps> = ({
  data,
  onAddToCard,
  onBuyPaperBook,
  purBoxClassName,
  onReadBook,
  shoppingCartId = 0,
}) => {
  const isMobile = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(max-width:1200px)');
  const bookSelector = useAppSelector(selectBook);
  const isPurchased = bookSelector.data.detailBook?.data.isPurchased;
  const isbn = bookSelector.data.detailBook?.data.productReviewOverview.isbn || '';

  return (
    <>
      <StyledBookPurchase
        className={`book-purchase norm ${purBoxClassName ? purBoxClassName : ''}`}
      >
        <div className="heading">
          <h3 className="heading__title">書籍購入</h3>
          <p className="price">
            <span className="label">価格</span>
            {data?.price}
          </p>
        </div>
        <div className="actions">
          {shoppingCartId === 0 && !isPurchased && (
            <SharedButton
              onClick={() => data && onAddToCard(data.productId)}
              text="カートに入れる"
              textColor="#fff"
              bgColor="#D42A1C"
              btnStyle="basic"
              prevIcon={<CartPurchaseIcon width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />}
            />
          )}
          <StyledLink>
            <a
              className="link-btn"
              href={`https://store.shopping.yahoo.co.jp/sanwa-co/${removeSpecialCharacters(
                isbn,
                '-',
              )}.html`}
              target="_blank"
            >
              紙の書籍を購入
            </a>
          </StyledLink>
          {/*<SharedButton*/}
          {/*  onClick={onBuyPaperBook}*/}
          {/*  text="紙の書籍を購入"*/}
          {/*  textColor="#D42A1C"*/}
          {/*  borderColor="#D42A1C"*/}
          {/*  bgColor="#fff"*/}
          {/*  btnStyle="basic"*/}
          {/*  prevIcon={<StorePurchaseIcon width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />}*/}
          {/*/>*/}
          <SharedButton
            onClick={() => data && onReadBook(data.productId)}
            bgColor="#359F08"
            text={isPurchased ? '今すぐ読む' : '試し読み'}
            textColor="#fff"
            btnStyle="basic"
            prevIcon={<BookPurchaseIcon width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />}
          />
        </div>
      </StyledBookPurchase>
    </>
  );
};

export const StyledBookPurchase = styled.div`
  @media (min-width: 1024px) and (max-width: 1200px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem;
  position: sticky;
  // width: 100%;
  height: fit-content;
  background: #fff1f1;
  border-radius: 8px;
  .heading {
    text-align: center;
    margin-bottom: 3.2rem;
    .heading__title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.3rem;
      color: #303030;
      margin-bottom: 1.6rem;
      .title_text {
        min-width: 8rem;
        width: 8rem;
      }
    }
    .price {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.9rem;
      color: #d42a1c;
      display: flex;
      align-items: center;
      .title_price {
        min-width: 10rem;
        width: 10rem;
      }
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #303030;
        margin-right: 0.8rem;
      }
    }
  }
  .actions {
    width: 100%;
    @media (max-width: 640px) {
      .ant-skeleton-element .ant-skeleton-input {
        min-width: 9rem;
        width: 9rem;
      }
    }
    .btn {
      padding: 0.8rem 0;
      border-radius: 8px;
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
      .text-btn {
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }

  @media (max-width: 1200px) {
    position: relative;
    border-radius: 0;
    margin: 2rem 0;
    padding: 1.6rem;
    align-items: flex-start;
    .actions {
      display: flex;
      gap: 8px;
      .btn {
        font-size: 12px;
        padding: 1rem 0;
        &:first-child {
          padding: 10px 25px 10px 25px;
          width: 74px;
          height: 44px;
          .text-btn {
            display: none;
          }
        }
        &:not(:first-child) {
          .text-btn {
            font-size: 1.2rem;
          }
          gap: 8px;
        }
      }
    }
    .heading {
      text-align: left;
      .heading__title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }
      .price {
        .label {
          font-size: 12px;
        }
      }
    }
  }
  @media (min-width: 768px) and (min-width: 820px) and (orientation: portrait) {
    .heading {
      .heading__title {
        font-size: ${(props) => props.theme.size.xl};
        font-weight: ${(props) => props.theme.weight['700']};
      }

      .price {
        font-size: ${(props) => props.theme.size.xxl};

        .label {
          font-size: ${(props) => props.theme.size.xl};
        }
      }
    }
  }
`;

const StyledLink = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  border: 0.1rem solid ${(props) => props.theme.colors.primary};
  border-radius: 0.8rem;
  padding: 0.8rem 0;
  background-color: ${(props) => props.theme.colors.body};
  margin-bottom: 1.2rem;

  .link-btn {
    color: ${(props) => props.theme.colors.primary};
    &:hover {
      opacity: 0.85;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

// const StyledBookPurchase = styled((props) => <Affix {...props} />)<{
//   top?: number;
// }>`
// `;
