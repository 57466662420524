export enum enumEnum {
  ENUM = 'ENUM',
}

export enum enumSizeIcon {
  DESKTOP = 58,
  TABLET = 40,
  DEFAULT = 78,
}

export enum enumBreakPoint {
  DESKTOP = 1280,
  TABLET = 1024,
  _2k = 2048,
  _3k = 38,
}

export enum enumPaginationIntroduction {
  _1 = 1,
  _2 = 2,
  _3 = 3,
  _4 = 4,
}

export const pagesIntroduction: enumPaginationIntroduction[] = [
  enumPaginationIntroduction._1,
  enumPaginationIntroduction._2,
  enumPaginationIntroduction._3,
  enumPaginationIntroduction._4,
];

export enum enumThemeMode {
  LIGHT = 1,
  DARK,
}

export enum enumLeftSidebar {
  CATEGORY = 1,
  USER_MENU,
}

export enum enumSidebarItemType {
  DASH = 1,
  RADIO,
}

export enum enumBookType {
  ALL = 1,
  EBOOK,
  READ_ALOUD,
}

export enum enumSortByPrice { // ok
  ALL = 1,
  HIGH_PRICE = 4,
  LOWEST_PRICE = 5,
}

export enum enumNameOrder { // ok
  ALL = 1,
  TITLE_ASCENDING_ORDER = 2,
  TITLE_DESCENDING_ORDER = 3,
}

export enum enumSortByOtherCategories { //  ok
  ALL = 1,
  OLDEST_RELEASE_FIRST = 6,
  RATING_ORDER_OF_REVIEWS = 8,
  ORDER_OF_POPULARITY = 9,
}

export enum enumAppPath {
  PATH_HOME = 1,
  OLDEST_RELEASE_FIRST,
  RATING_ORDER_OF_REVIEWS,
  ORDER_OF_POPULARITY,
}

export enum enumSex {
  MALE = 'male',
  FEMALE = 'female',
}

export enum enumOrderDate {
  Y_M_D = 'year/month/day',
  D_M_Y = 'day/month/year',
  D_Y_M = 'day/year/month',
  M_D_Y = 'month/day/year',
  M_Y_D = 'month/year/day',
  Y_D_M = 'year/day/month',
}

export enum enumTypePath {
  Home = 'home',
  Register = 'register',
  Login = 'login',
}

export enum enumReadBookOrientation {
  Horizonal = 0,
  Vertical = 1,
  Other = 2,
}

export enum enumTextBookFont {
  SHIN_GO_PR6 = 'ShinGoPr6',
  RYUMIN_PRO = 'RyuminPro',
}
