import { RootState } from './index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PurchaseHistoryDataType } from '@interfaces';
import { fetchPurchaseHistory } from '../action';

interface PurchaseHistory {
  isLoading: boolean;
  data: {
    purchaseHistory: PurchaseHistoryDataType[];
  };
  purchaseHistoryTotalItem: number;
}

const initialState: PurchaseHistory = {
  isLoading: true,
  data: {
    purchaseHistory: [],
  },
  purchaseHistoryTotalItem: 0,
};

const purchaseHistorySlice = createSlice({
  name: 'purchaseHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPurchaseHistory.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.data.purchaseHistory = payload.data.orders;
        state.purchaseHistoryTotalItem = payload.data.pagingFilteringContext?.totalItems;
      })
      .addCase(fetchPurchaseHistory.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const purchaseHistoryReducer = purchaseHistorySlice.reducer;
export const selectPurchase = (state: RootState) => state.purchaseHistory;
