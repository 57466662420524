import { createAsyncThunk } from '@reduxjs/toolkit';
import { shoppingCartApi } from '@api';
import { AddToCartProps, BaseResponseProps, PaymentProps } from '@interfaces';
import { DeleteAllType } from '@configs';

const prefix = 'shoppingCart';

export const fetchWishList = createAsyncThunk<any>(
  `${prefix}/wishlist`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.getWishList();
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const searchWishList = createAsyncThunk<any, string>(
  `${prefix}/wishlist/search`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.searchWishList(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchWishlistWithPagination = createAsyncThunk<any, string>(
  `${prefix}/wishlist/pagination`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.getWishListWithPagination(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const addToCart = createAsyncThunk<any, AddToCartProps>(
  `${prefix}/addToCart`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.addToCart(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const getAllBooksInCart = createAsyncThunk<any>(
  `${prefix}/get-cart`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.getBooksInCart();
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const removeOneCart = createAsyncThunk<any, number>(
  `${prefix}/remove-cart`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.removeOne(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const removeAllProduct = createAsyncThunk<any, DeleteAllType>(
  `${prefix}/remove-cart-all`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.removeAll(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const removeMultipleProduct = createAsyncThunk<any, number[]>(
  `${prefix}/remove-cart-multiple`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.removeMultiple(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const paymentAll = createAsyncThunk<any, PaymentProps>(
  `${prefix}/checkout-all`,
  async (p, { rejectWithValue }) => {
    try {
      const res = await shoppingCartApi.checkoutAll(p);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
