import styled from 'styled-components';

import { AppleLoginModule, FacebookLoginModule, LineLoginModule } from '@modules';
import { onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import { appleProvider, auth, fbProvider } from '@service';
import { LogApp } from '@utils';

interface IProps {
  haveDividerDes?: boolean;
}

export const SocialLogin = (props: IProps) => {
  const { haveDividerDes } = props;
  const handleSignInWithFacebook = () => {
    signInWithPopup(auth, fbProvider);
    onAuthStateChanged(auth, (user: any) => {
      LogApp('user-fb', { user });
    });
  };

  const handleSignInWithApple = () => {
    signInWithPopup(auth, appleProvider);
    onAuthStateChanged(auth, (user: any) => {
      LogApp('user-apple', { user });
    });
  };
  return (
    <StyledLoginSocial>
      {haveDividerDes && <div className="divider__des">または</div>}
      <FacebookLoginModule />
      <LineLoginModule />
      <AppleLoginModule />
    </StyledLoginSocial>
  );
};

const StyledLoginSocial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .divider__des {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6c757d;
    display: inline-block;
    margin: auto;
    text-align: center;
    position: relative;
    padding: 0 1rem;
    margin-bottom: 2.8rem;
    &:after {
      width: 7.8rem;
      position: absolute;
      right: 100%;
      top: 50%;
      height: 1px;
      background-color: #ececec;
      content: '';
      z-index: 1;
    }
    &:before {
      position: absolute;
      left: 100%;
      top: 50%;
      height: 1px;
      background-color: #ececec;
      content: '';
      z-index: 1;
      width: 7.8rem;
    }
  }
`;
