import { enumReadBookOrientation } from '@configs';
import styled, { css } from 'styled-components';

export const StyledReadBook = styled.div<{
  $orientation?: number;
  isVisible?: boolean;
  isBorder?: boolean;
  $scaleInner?: number;
  $firstPage?: boolean;
  $textBookFont?: string;
}>`
  .icon-style {
    display: flex;
    float: right;
    top: 2rem;
    position: fixed;
    right: 4rem;
    svg {
      margin: auto;
      g {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 13.4rem;
  overflow: auto;

  .content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 8rem - 10rem);
    overflow: auto;
    border-left: ${(props) => (props.isBorder ? '1px solid #B6B6B6' : 'none')};
    position: relative;

    .cover-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &::-webkit-scrollbar {
      width: 0.5rem;
      border-radius: 0.6rem;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme?.colors.scrollbarThumb};
      border-radius: 0.6rem;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: ${(props) => props.theme?.colors.scrollbarTrack};
    }

    //.inner {
    //  // width: fit-content;
    //  width: 80rem;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //
    //  svg {
    //    margin-top: 170px;
    //    @media (min-width: 1170px) and (max-width: 1220px) {
    //      margin-top: 160px;
    //    }
    //    @media (min-width: 1120px) and (max-width: 1169px) {
    //      margin-top: 165px;
    //    }
    //    @media (max-width: 1119px) {
    //      margin-top: 160px;
    //    }
    //    @media (min-width: 768px) and (max-width: 870px) {
    //      margin-top: 160px;
    //    }
    //    & > g {
    //      @media (min-width: 769px) and (max-width: 820px) {
    //        transform: scale(0.6);
    //      }
    //      @media (min-width: 821px) and (max-width: 870px) {
    //        transform: scale(0.65);
    //      }
    //      @media (min-width: 871px) and (max-width: 920px) {
    //        transform: scale(0.7);
    //      }
    //      @media (min-width: 921px) and (max-width: 970px) {
    //        transform: scale(0.75);
    //      }
    //      @media (min-width: 971px) and (max-width: 1020px) {
    //        transform: scale(0.8);
    //      }
    //      @media (min-width: 1021px) and (max-width: 1070px) {
    //        transform: scale(0.85);
    //      }
    //      @media (min-width: 1071px) and (max-width: 1120px) {
    //        transform: scale(0.9);
    //      }
    //      @media (min-width: 1121px) and (max-width: 1170px) {
    //        transform: scale(0.95);
    //      }
    //      @media (min-width: 1171px) and (max-width: 1220px) {
    //        transform: scale(1);
    //      }
    //      @media (min-width: 1221px) and (max-width: 1270px) {
    //        transform: scale(1.05);
    //      }
    //      @media (min-width: 1271px) and (max-width: 1320px) {
    //        transform: scale(1.1);
    //      }
    //    }
    //  }
    //}
    .inner {
      @media (min-width: 1021px) and (max-width: 1070px) and (max-height: 676px),
        (min-width: 1071px) and (max-width: 1120px) and (max-height: 703px),
        (min-width: 1121px) and (max-width: 1170px) and (max-height: 728px),
        (min-width: 1171px) and (max-width: 1220px) and (max-height: 760px),
        (min-width: 1221px) and (max-width: 1270px) and (max-height: 761px),
        (min-width: 1271px) and (max-width: 1320px) and (max-height: 798px),
        (min-width: 1321px) and (max-width: 1789px) and (max-height: 775px),
        (min-width: 1790px) and (max-height: 894px) {
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        & > svg {
          position: absolute;
          left: 0;
          top: 0;
          width: fit-content;
          height: fit-content;
        }
      }
      @media (min-width: 1321px) and (max-width: 1789px) and (max-height: 775px) {
        & > svg {
          left: 10%;
        }
      }
      @media (min-width: 1790px) and (max-height: 894px) {
        & > svg {
          left: 13%;
        }
      }
      /* #bookSVG_G,   #bookSVG_G2{
        transform: translate(25%, 25%);
      } */
    }
    svg {
      width: 100%;
      height: 100%;
      overflow-x: visible;
      overflow-y: visible;
      /* min-height: 600px; */
      @media (min-width: 1321px) and (max-width: 1789px) {
        width: fit-content;
      }
      .svgText {
        /* font-size: 0.3rem; */
        /* font-family: 'FF3' !important; */
      }
      /* height: 800px; */
    }
  }

  .text-book__content {
    width: 100%;
    padding: 0 2.6rem;
    height: fit-content;
    min-height: calc(100vh - 8rem - 10rem);
    .text-default {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      padding: 2.4rem 5.6rem 1.8rem;
      font-family: 'Noto Sans JP';
      font-weight: 400;
      height: 100%;
      ${(p) =>
        p?.$orientation === enumReadBookOrientation.Vertical &&
        css`
          max-height: calc(100vh - 18rem);
        `};

      line-height: 2.8rem;
      position: relative;
      width: 100%;
      overflow: auto;
      .content-text {
        font-size: ${(p) => `${(p?.$scaleInner || 1) * 1.2}rem`};
        margin-left: ${(p) => `${(p?.$scaleInner || 1) * 1.2 - 1}rem`};
        line-height: ${(p) => `${(p?.$scaleInner || 1) * 2.5}rem`};
        font-family: ${({ $textBookFont }) => ($textBookFont ? $textBookFont : 'ShinGoPr6')};
        &:first-child {
          ${(p) =>
            p?.$firstPage &&
            css`
              font-size: ${p?.$scaleInner ? `${(p?.$scaleInner || 1) * 1.8}rem` : `1.8rem`};
              font-weight: 600;
            `};
        }
      }
    }
  }
  .cover-section {
    position: absolute;
    z-index: 1;
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 0.2rem;
  }
  .preview-book {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(p) =>
      p?.$orientation === enumReadBookOrientation.Vertical &&
      css`
        flex-direction: row-reverse;
      `};
    .content {
      //width: 50%;
    }
  }
`;

export const ContentBook = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  background-color: ${(props) => props.theme?.colors.body};
`;

export const ToolBarDesktop = styled.div`
  padding: 1.2rem 3.6rem;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  .list-icon {
    /* padding: 0.4rem 0.9rem; */
    border: 1px solid #ececec;
    background-color: ${(props) => props?.theme?.colors.bgFooter};
    width: fit-content;
    display: flex;
    justify-content: space-around;
    height: 4.4rem;
    align-items: center;
    .option-icon {
      padding: 0 0.8rem;
    }
  }
`;

export const PageContent = styled.div`
  display: flex;
  .line {
    background-color: ${(props) => props.theme?.colors.button.background};
    width: 1px;
    height: calc(100vh - 13rem);
    transform: translateY(-7.6rem);
  }
`;

export const ImagePage = styled.div`
  width: 50%;
  padding: 6.4rem 7.6rem 4.3rem 7.6rem;
  height: calc(100vh - 7.6rem - 5.3rem);
  overflow: auto;
  img {
    width: 100%;
  }
`;

export const FooterBook = styled.div<{
  $ísTextBookDefault?: boolean;
}>`
  display: flex;
  margin-top: 6rem;
  ${(p) =>
    p.$ísTextBookDefault &&
    css`
      padding-bottom: 3.6rem;
      margin-top: 4.2rem;
    `}
`;

export const NumberPage = styled.div`
  gap: 1rem;
  margin-left: 7.6rem;
  display: flex;
  .current-page {
    width: 8rem;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    border-radius: 6px;
    background: ${(props) => props?.theme?.colors.bgFooter};
    padding: 4px 10px;

    .current-page-search {
      width: 100%;
      border: none;
      background: ${(props) => props?.theme?.colors.bgFooter};
      padding: 0;
      text-align: center;
      height: 100%;
      border-radius: 0;
      &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
  .total-page {
    width: 5.8rem;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.7rem;
    border-radius: 6px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
  }
`;

export const NameBook = styled.div`
  margin-right: 7.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.7rem;
  color: ${(props) => props.theme?.colors.text2};
`;

export const ContentBookWrapper = styled.div<{
  $orientation?: number;
}>`
  //display: flex;
  //justify-content: center;
  //align-items: center;
  position: relative;
  height: 100%;

  .read-book-btn {
    width: 3rem;
    height: 3rem;
    background-color: ${(props) => props.theme.colors.gray['300']};
    padding: 2rem;
    border-radius: 50%;
    z-index: 20;
    opacity: 0.6;
  }

  .btn-text__color {
    color: ${(props) => props.theme.colors.button.text};
  }

  .pre-btn {
    position: absolute;
    top: 50%;
    ${(p) =>
      p?.$orientation === enumReadBookOrientation.Vertical
        ? css`
            right: 0.5rem;
            transform: rotate(180deg);
          `
        : css`
            left: 0.5rem;
          `};
  }

  .next-btn {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    ${(p) =>
      p?.$orientation === enumReadBookOrientation.Vertical
        ? css`
            left: 0.5rem;
            transform: rotate(180deg);
          `
        : css`
            right: 0.5rem;
          `};
  }
`;

export const InnerContent = styled.div<{
  scaleInner?: number;
  translationInner?: string | number;
  positionX?: string | number;
  positionY?: string | number;
  $haveBookCoverImage?: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  scale: ${(props) => props.scaleInner || 1};
  transform-origin: top left;
  /* transform: translate(${(props) => props.positionX || '0'}, ${(props) =>
    props.positionY || '0'}); */
  ${(p) =>
    p?.scaleInner !== 1 &&
    css`
      /* transform: translate(50%, 50%); */
    `}

  svg {
    /* margin-top: 150px; */
    /* @media (min-width: 1170px) and (max-width: 1220px) {
      margin-top: 160px;
    }
    @media (min-width: 1120px) and (max-width: 1169px) {
      margin-top: 165px;
    }
    @media (max-width: 1119px) {
      margin-top: 160px;
    }
    @media (min-width: 768px) and (max-width: 870px) {
      margin-top: 160px;
    } */
    & > g {
      /* transform: scale(1); */
      @media (min-width: 769px) and (max-width: 820px) {
        transform: scale(0.6);
      }
      @media (min-width: 821px) and (max-width: 870px) {
        transform: scale(0.65);
      }
      @media (min-width: 871px) and (max-width: 920px) {
        transform: scale(0.7);
      }
      @media (min-width: 921px) and (max-width: 970px) {
        transform: scale(0.75);
      }
      @media (min-width: 971px) and (max-width: 1020px) {
        transform: scale(1);
      }
      @media (min-width: 1021px) and (max-width: 1070px) {
        transform: scale(0.65);
      }
      @media (min-width: 1071px) and (max-width: 1120px) {
        transform: scale(0.66);
      }
      @media (min-width: 1121px) and (max-width: 1170px) {
        transform: scale(0.69);
        /* & > g {
          transform: translate(12%, 12%);
        } */
      }
      @media (min-width: 1171px) and (max-width: 1220px) {
        transform: scale(0.73);
        /* & > g {
          transform: translate(14%, 14%);
        } */
      }
      @media (min-width: 1221px) and (max-width: 1270px) {
        transform: scale(0.73);
        /* & > g {
          transform: translate(16%, 16%);
        } */
      }
      @media (min-width: 1271px) and (max-width: 1320px) {
        transform: scale(0.74);
        /* & > g {
          transform: translate(18%, 18%);
        } */
      }
      @media (min-width: 1321px) and (max-width: 1789px) {
        transform: scale(0.75);
        /* & > g {
          transform: translate(20%, 20%);
        } */
      }
      @media (min-width: 1790px) {
        transform: scale(0.9);
        /* & > g {
          transform: translate(23%, 23%);
        } */
      }
    }
  }

  ${(props) =>
    props.$haveBookCoverImage &&
    css`
      width: 0;
      height: 0;
      display: none;
    `};
`;
