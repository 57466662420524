import { Button, Col, Divider, Input, Modal, Rate, Row } from 'antd';
import React, { FC, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FavoriteIcon, IconRecycle } from '../Icon';
import { SharedButton } from '../shared';
import { setToLS, useMediaQuery } from '@utils';
import { BookPurchase } from './BookPurchase';
import { AddToCartProps, BookDetailProps, CommentRatingRequestProps } from '@interfaces';
import {
  addToCart,
  deleteAComment,
  postAComment,
  RootState,
  selectBook,
  selectBookReview,
  selectCustomer,
  useAppSelector,
} from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RatingBar } from './RatingBar';
import { CommentItem } from './CommentItem';
import { PATH_LOGIN, PATH_READ_BOOK } from '@configs';

const { TextArea } = Input;

interface BookInfoProps {
  data: BookDetailProps | null;
  onLikeBook: (id: number) => void;
  onUnlikeBook: (id: number) => void;
}

const maxHeightForSeeMoreButton = 40;
const maxLength = 255;

export const BookInfo: FC<BookInfoProps> = ({ data, onLikeBook, onUnlikeBook }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState<boolean>(false);
  const [showDesc, setShowDesc] = useState<boolean>(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentIdComment, setCurrentIdComment] = useState(0);

  const desc1Ref = useRef<HTMLDivElement | null>(null);
  const desc2Ref = useRef<HTMLDivElement | null>(null);
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const isLargeScreen = useMediaQuery('(max-width: 1200px)');

  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const isLogin = accessToken;
  const bookSelector = useAppSelector(selectBook);

  const {
    data: { userProfile },
  } = useAppSelector(selectCustomer);

  const injectHTML = (html: string) => {
    return { __html: html };
  };
  const memoryPath = () => {
    const currentPath = location.pathname;
    setToLS('memoPath', currentPath);
  };
  const onAddToCart = (bookId: number) => {
    if (isLogin) {
      const addToCartParams: AddToCartProps = {
        ProductId: bookId,
        Quantity: 1,
        ShoppingCartTypeId: 1, // = 1 => add to shopping cart
      };
      dispatch(addToCart(addToCartParams));
    } else {
      memoryPath();
      navigate(PATH_LOGIN);
    }
  };
  const onBuyPaperBook = (bookId: number) => {
    if (isLogin) {
      const book = bookSelector;
    } else {
      memoryPath();
      navigate(PATH_LOGIN);
    }
  };

  const onReadBook = (bookId: number) => {
    // if (isLogin) {
    //   const book = bookSelector;
    //   navigate(`${PATH_READ_BOOK}/${bookId}`);
    // } else {
    //   memoryPath();
    //   navigate(PATH_LOGIN);
    // }
    navigate(`${PATH_READ_BOOK}/${bookId}`, { replace: true });
    memoryPath();
  };

  const onPostComment = () => {
    if (rating > 0) {
      const rqBody: CommentRatingRequestProps = {
        ProductId: data?.id || 0,
        ReviewText: comment,
        Rating: rating,
      };
      dispatch(postAComment(rqBody));
      setRating(0);
      setComment('');
    }
  };

  const onDelete = () => {
    dispatch(deleteAComment(currentIdComment));
    setConfirmDelete(false);
  };
  return data ? (
    <>
      <StyledBookInfo className="book-info" isMobile={isMobile}>
        <Row gutter={[0, 0]}>
          <StyledCol className="left-head">
            <img src={data.defaultPictureModel.fullSizeImageUrl} className="book-cover" alt="not" />
          </StyledCol>
          <Col flex="3 1 200px">
            <div className="head-info">
              <h1 className="book-title">{data.defaultPictureModel.title}</h1>
              {data.authors?.slice(0, 2).map((e) => (
                <span key={e.id} className="author">
                  <p className="label">著者:</p>
                  <p className="name">{e.name}</p>
                </span>
              ))}
              <span className="publisher">
                <p className="label">出版社: </p>
                <p className="name">{data.vendor.name}</p>
              </span>
              <div className="actions">
                <div className="rating">
                  <Rate
                    allowHalf
                    disabled
                    defaultValue={
                      (data.productReviewOverview.totalReviews !== 0
                        ? data.productReviewOverview.ratingSum /
                          data.productReviewOverview.totalReviews
                        : data.productReviewOverview.ratingSum) || 0
                    }
                  />
                  <p>({data.productReviewOverview.totalReviews || 0} レビュー)</p>
                </div>
                {!data.isPurchased &&
                  (data?.isWishList && !!data?.wishListId ? (
                    <SharedButton
                      onClick={() => onUnlikeBook(data?.wishListId)}
                      className="add-to-favorite__btn text-btn"
                      textColor={'#D42A1C'}
                      text="お気に入りに追加"
                      prevIcon={<FavoriteIcon fill={'#D42A1C'} />}
                    />
                  ) : (
                    <SharedButton
                      onClick={() => onLikeBook(data?.id)}
                      className="add-to-favorite__btn text-btn"
                      textColor={'#303030'}
                      text="お気に入りに追加"
                      prevIcon={<FavoriteIcon fill={'none'} />}
                    />
                  ))}
              </div>
            </div>
            {isLargeScreen && (
              <BookPurchase
                purBoxClassName="behind"
                shoppingCartId={bookSelector.data.detailBook?.data.shoppingCartId || 0}
                onBuyPaperBook={onBuyPaperBook}
                onAddToCard={(e) => onAddToCart(e)}
                onReadBook={(e) => onReadBook(e)}
                data={bookSelector.data.detailBook?.data.productPrice || null}
              />
            )}
            <Divider className="sm-divider" />
            <div className="detail-info">
              <h2 className="heading">登録情報</h2>
              {/* Category  */}
              {data.productInfoBooks.map((e, i) => (
                <div className="info-field" key={i}>
                  <span className="label">{e.key}:</span>
                  <span className="value">{e.value}</span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Divider className="la-divider" />
        <div className="more-info">
          <StyledDetailsInfo className="desc" showAll={showAll}>
            <h3 className="title">説明</h3>
            <div
              ref={desc1Ref}
              className="details-info"
              dangerouslySetInnerHTML={injectHTML(data.fullDescription)}
            ></div>
            {(!data.fullDescription || data.fullDescription?.length <= 0) && (
              <div className="text-center">説明なし</div>
            )}
            {(desc1Ref.current?.offsetHeight || 0) > maxHeightForSeeMoreButton && (
              <div onClick={() => setShowAll(!showAll)} className="show-all">
                <span className="sa_text">{showAll ? '閉じる' : 'もっと見る'}</span>
              </div>
            )}
            <Divider className="la-divider" />
          </StyledDetailsInfo>
          <StyledDetailsInfo className="desc" showAll={showDesc}>
            <h3 className="title">著者について</h3>
            <div className="details-info" ref={desc2Ref}>
              {data.aboutAuthors}
            </div>
            {(!data.aboutAuthors || data.aboutAuthors?.length <= 0) && (
              <div className="text-center">説明なし</div>
            )}
            {(desc2Ref.current?.offsetHeight || 0) > maxHeightForSeeMoreButton && (
              <div onClick={() => setShowDesc(!showDesc)} className="show-all">
                <span className="sa_text">{showDesc ? '閉じる' : 'もっと見る'}</span>
              </div>
            )}
            <Divider className="la-divider" />
          </StyledDetailsInfo>
        </div>
        <div className="user-rating">
          <div className="box">
            <div className="header">ユーザーレビュー</div>
            <div className="rating-group">
              <Rate
                className="rating-box"
                allowHalf
                disabled
                defaultValue={
                  (data.productReviewOverview.totalReviews !== 0
                    ? data.productReviewOverview.ratingSum / data.productReviewOverview.totalReviews
                    : data.productReviewOverview.ratingSum) || 0
                }
              />
            </div>
            {bookSelector.ratingDetail.map((e) => (
              <div className="rating-wrapper" key={e.order}>
                <RatingBar order={e.order} percent={e.percent} />
              </div>
            ))}
          </div>
          {data.isPurchased ? (
            <div className="box">
              <div className="header">著者について</div>
              <Rate className="rating-box" onChange={setRating} value={rating} />
              <div className="title tt-spacing">5 つ星のうち 5 (1 レビュー)</div>
              <TextArea
                className="comment-box"
                rows={5}
                placeholder="この本についてのあなたの意見を自由に書いてください"
                maxLength={maxLength}
                value={comment}
                onChange={(e) => {
                  if (e.target.value.length <= maxLength) {
                    setComment(e.target.value);
                  }
                }}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    if (!event.shiftKey) {
                      onPostComment();
                    }
                  }
                }}
              />
              <div className="comment-length-counter">
                {comment.length}/{maxLength}
              </div>
              <Button
                disabled={rating === 0}
                onClick={onPostComment}
                htmlType="submit"
                className="button-with-loading submit-btn"
              >
                レビュー送信
              </Button>
            </div>
          ) : null}
        </div>
        <div className="comment-list">
          <div className="box">
            {data.productReviewsModel?.map((e, i) => (
              <CommentItem
                onDeleteClicked={(e) => {
                  setConfirmDelete(true);
                  setCurrentIdComment(e);
                }}
                isSelf={e.customerId === (userProfile?.data.customerId || 0)}
                index={i}
                key={e.customerId}
                data={e}
              />
            ))}
          </div>
        </div>
      </StyledBookInfo>
      <Modal
        className="modal-cart hide-close"
        style={{ fontSize: isMobile ? '12px' : '14px' }}
        title="このコメントを削除しますか?"
        cancelText="キャンセル"
        okText={
          <span className="flex-center-all flex-span">
            <IconRecycle stroke={'#fff'} /> <p>OK</p>
          </span>
        }
        open={confirmDelete}
        onOk={onDelete}
        confirmLoading={confirmLoading}
        onCancel={() => setConfirmDelete(false)}
      >
        <p>本気ですか?</p>
      </Modal>
    </>
  ) : (
    <></>
  );
};

const StyledCol = styled.div`
  padding: 0 !important;
  max-width: 28.1rem;
  min-width: 28.1rem;
  height: 39.6rem;
`;

const StyledBookInfo = styled.div<{
  isMobile?: boolean;
}>`
  padding-right: 1.2rem;
  .left-head {
    margin-right: 2rem;
  }
  .comment-length-counter {
    font-weight: ${(p) => p.theme.weight['400']};
    font-size: ${(p) => p.theme.size.xs};
    line-height: 17px;
    color: ${(p) => p.theme.colors.text};
  }

  .comment-list {
    margin-bottom: 2.4rem;
    border-bottom: 1px solid ${(p) => p.theme.colors.dividerBolder};

    .box {
      margin-top: 2.4rem;
    }
  }

  .rating-wrapper {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .user-rating {
    display: flex;
    justify-content: space-between;

    .box:last-child {
      position: relative;

      .comment-length-counter {
        width: fit-content;
        position: absolute;
        right: 0;
        bottom: 3rem;
      }
    }

    @media (max-width: 810px) {
      flex-direction: column;
      .box {
        width: 100% !important;

        &:last-child {
          margin-top: 2.4rem;
        }
      }
    }

    * {
      font-family: 'Noto Sans JP';
    }

    .submit-btn {
      margin-top: 2rem;
      width: 22.4rem;
      height: 4rem;
      border-radius: 0.8rem !important;
      background-color: ${(p) => p.theme.colors.primary};

      &[disabled] {
        opacity: 0.7;
      }

      * {
        font-weight: ${(p) => p.theme.weight['500']};
        font-size: ${(p) => p.theme.size.s};
        line-height: 20px;
        color: ${(p) => p.theme.colors.body};
      }
    }

    .tt-spacing {
      padding: 3.2rem 0 1.2rem 0;
    }

    .box {
      width: calc(50% - 35.5px);
    }

    .rating-group {
      margin-bottom: 3.2rem;
    }

    .rating-box {
      margin-top: 1.6rem;
    }

    .header {
      font-weight: ${(p) => p.theme.weight['700']};
      font-size: ${(p) => p.theme.size.l};
      line-height: 23px;
      color: ${(p) => p.theme.colors.text};
    }

    .title {
      font-weight: ${(p) => p.theme.weight['400']};
      font-size: ${(p) => p.theme.size.s};
      line-height: 20px;
      color: ${(p) => p.theme.colors.text};
    }

    .comment-box {
      padding: 2rem 1.4rem;
    }
  }

  width: 100%;

  .book-cover {
    width: 100%;
    height: 100%;
  }

  .head-info {
    .book-title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.9rem;
      color: #303030;
      margin-bottom: 1.6rem;
    }

    .author,
    .publisher {
      display: flex;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2.1rem;

      .label {
        color: #6c757d;
        margin-right: 0.4rem;
      }

      .name {
        color: #303030;
      }
    }

    .actions {
      height: 2rem;
      margin-top: 2.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .rating {
        display: flex;
        align-items: flex-end;
        gap: 16px;

        p {
          color: ${(props) => props.theme.colors.gray['400']};
          font-size: ${(props) => (props.isMobile ? '10px' : '14px')};
          font-weight: ${(props) => (props.isMobile ? '500' : '400')};
        }
      }

      .add-to-favorite__btn .text-btn {
        font-style: normal;
        //font-weight: 400;
        // font-size: 1.4rem;
        font-size: ${(props) => (props.isMobile ? '10px' : '14px')};
        font-weight: ${(props) => (props.isMobile ? '500' : '400')};
        line-height: 2rem;
        color: #303030;
      }

      .add-to-favorite__btn {
        gap: 8px;
      }
    }
  }

  .detail-info {
    .heading {
      margin-bottom: 0.8rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2.3rem;
      color: #303030;
    }

    .info-field {
      display: flex;
      align-items: center;
      margin-bottom: 0.4rem;
      height: 2.1rem;

      .label {
        flex: 0 0 12.3rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2.1rem;
        color: #6c757d;
      }
    }
  }

  .la-divider {
    margin: 3.2rem 0;
  }

  .more-info {
    width: 100%;

    .desc {
      width: 100%;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 768px) {
    .head-info {
      .book-title {
        margin-top: 20px;
      }

      .author,
      .publisher {
        font-size: 1.2rem;
      }

      .actions {
        margin-top: 1.4rem;
      }
    }
  }
`;

const StyledDetailsInfo = styled.div<{
  showAll?: boolean;
  noneInfo?: any;
}>`
  .title {
    margin-bottom: 2.6rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2.3rem;
    color: #303030;
  }

  .details-info {
    position: relative;
    overflow: hidden;
    max-height: ${(p) => (p.showAll ? '20rem' : '4.2rem')};
    /* height: ${(p) => (p.showAll ? '20rem' : '5rem')}; */
    margin-bottom: 2.6rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #303030;
    transition: max-height 0.3s ease 0s;
  }

  .show-all {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;

    .sa_text {
      text-align: center;
      font-style: normal;
      margin: 0 auto;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 20px;
      color: ${(p) => (p.showAll ? '#0DBED2' : ' #359f08')};
      ${(p) =>
        p.noneInfo &&
        css`
          color: #000;
          font-weight: 400;
        `}
    }

    &:hover {
      .sa_text {
        color: #303030;
      }
    }
  }

  @media (min-width: 768px) and (min-width: 820px) and (orientation: portrait) {
    .title {
      font-size: ${(props) => props.theme.size.xl};
    }

    .details-info {
      font-size: ${(props) => props.theme.size.l};
    }

    .show-all {
      .sa_text {
        font-size: ${(props) => props.theme.size.xl};
      }
    }
  }
`;
