import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@redux';
import { BaseResponseProps, BaseState, BookReviewProps, IBookDataItem } from '@interfaces';
import { fetchReviewBooksList, deleteAComment, postAComment } from '../action';
import { toast } from 'react-toastify';

interface BookReviewSliceProps {
  loading: boolean;
  bookReviewList: IBookDataItem[];
  sendComment: BaseState;
  deleteComment: BaseState;
}

const initialState: BookReviewSliceProps = {
  loading: true,
  bookReviewList: [],
  deleteComment: {
    loading: false,
    data: null,
  },
  sendComment: {
    data: null,
    loading: false,
  },
};

const bookReviewSlice = createSlice({
  name: 'bookReviewSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewBooksList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchReviewBooksList.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<BookReviewProps[]>>) => {
          state.loading = false;
          state.bookReviewList = payload.data.map((data) => ({
            bookName: data.bookName,
            id: data.id,
            img_url: data.pictureModel.fullSizeImageUrl,
            author: data.authorName,
            published: data.authorName,
            desc: data.pictureModel.alternateText,
            audioReading: data.isAudioBook,
            pictureMobileModel: data.pictureModel,
            ratingSum: data.rating,
            productId: data.id,
            publishDate: data.createdOn,
          })) as IBookDataItem[];
        },
      )
      .addCase(fetchReviewBooksList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(deleteAComment.pending, (state) => {
        state.deleteComment.loading = true;
      })
      .addCase(deleteAComment.fulfilled, (state, { payload }: PayloadAction<BaseResponseProps>) => {
        state.deleteComment.loading = false;
        state.deleteComment.data = payload;
        if (payload.success) {
          toast.success(payload.messages);
        } else {
          toast.error(payload.messages);
        }
      })
      .addCase(deleteAComment.rejected, (state) => {
        state.deleteComment.loading = false;
      })

      .addCase(postAComment.pending, (state) => {
        state.sendComment.loading = true;
      })
      .addCase(postAComment.fulfilled, (state, { payload }: PayloadAction<BaseResponseProps>) => {
        state.sendComment.loading = false;
        state.sendComment.data = payload;
        if (payload.success) {
          toast.success(payload.messages === 'Send review' ? 'レビューを送る' : payload.messages);
        } else {
          toast.error(payload.messages);
        }
      })
      .addCase(postAComment.rejected, (state) => {
        state.sendComment.loading = false;
      });
  },
});

export const bookReviewReducer = bookReviewSlice.reducer;
export const selectBookReview = (state: RootState) => state.bookReview;
