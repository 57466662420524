import { ICon } from '@interfaces';

export const StarIcon = (props: ICon) => {
  const { className, color = '#FD7E14', width = 25, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M12.6632 17.8782L17.3882 20.8782C17.9976 21.2626 18.7476 20.6907 18.5695 19.9876L17.2007 14.6063C17.1637 14.4571 17.1696 14.3006 17.2177 14.1546C17.2658 14.0086 17.3542 13.8792 17.4726 13.7813L21.7101 10.2469C22.2632 9.78755 21.982 8.85943 21.2601 8.81255L15.7289 8.4563C15.5779 8.44752 15.4328 8.39497 15.3113 8.30506C15.1898 8.21516 15.097 8.0918 15.0445 7.95005L12.982 2.7563C12.9274 2.60621 12.8279 2.47656 12.6971 2.38494C12.5663 2.29332 12.4104 2.24417 12.2507 2.24417C12.091 2.24417 11.9352 2.29332 11.8044 2.38494C11.6735 2.47656 11.5741 2.60621 11.5195 2.7563L9.45698 7.95005C9.40441 8.0918 9.3117 8.21516 9.19016 8.30506C9.06862 8.39497 8.92353 8.44752 8.7726 8.4563L3.24135 8.81255C2.51948 8.85943 2.23823 9.78755 2.79135 10.2469L7.02885 13.7813C7.14729 13.8792 7.23565 14.0086 7.28375 14.1546C7.33186 14.3006 7.33774 14.4571 7.30073 14.6063L6.0351 19.5938C5.81948 20.4376 6.71948 21.1219 7.44135 20.6626L11.8382 17.8782C11.9615 17.7998 12.1046 17.7581 12.2507 17.7581C12.3968 17.7581 12.5399 17.7998 12.6632 17.8782V17.8782Z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StarItemIcon = (props: ICon) => {
  const { className, fill = '#FD7E14', width = 20, height = 20 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M10.3444 14.8985L14.2819 17.3985C14.7897 17.7188 15.4147 17.2423 15.2662 16.6563L14.1256 12.172C14.0948 12.0477 14.0997 11.9172 14.1398 11.7955C14.1798 11.6739 14.2535 11.5661 14.3522 11.4845L17.8834 8.53914C18.3444 8.15633 18.11 7.38289 17.5084 7.34383L12.899 7.04696C12.7733 7.03964 12.6524 6.99584 12.5511 6.92092C12.4498 6.846 12.3725 6.7432 12.3287 6.62508L10.61 2.29696C10.5645 2.17188 10.4816 2.06383 10.3726 1.98748C10.2636 1.91113 10.1337 1.87018 10.0006 1.87018C9.86751 1.87018 9.73764 1.91113 9.62863 1.98748C9.51961 2.06383 9.43673 2.17188 9.39123 2.29696L7.67248 6.62508C7.62868 6.7432 7.55141 6.846 7.45013 6.92092C7.34885 6.99584 7.22794 7.03964 7.10217 7.04696L2.49279 7.34383C1.89123 7.38289 1.65686 8.15633 2.11779 8.53914L5.64905 11.4845C5.74774 11.5661 5.82138 11.6739 5.86146 11.7955C5.90155 11.9172 5.90645 12.0477 5.87561 12.172L4.82092 16.3282C4.64123 17.0313 5.39123 17.6016 5.99279 17.2188L9.65686 14.8985C9.75961 14.8332 9.87885 14.7985 10.0006 14.7985C10.1224 14.7985 10.2416 14.8332 10.3444 14.8985Z"
        fill={fill}
      />
    </svg>
  );
};
