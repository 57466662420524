import styled from 'styled-components';

export const StyledRegister = styled.div`
  width: 100%;
  //height: 100%;
  .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin-bottom: 0.8rem;
    color: #303030;
    .heading_title {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 29px;
      text-align: center;
      @media (max-width: 640px) {
        font-weight: 700;
      }
    }
    .back-home {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 17px;
      text-align: center;
    }
  }
  .desc {
    padding: 0;
    margin-bottom: 2.8rem;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    color: #303030;
    .sub-action {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 20px;
      text-align: center;
      color: #0bded2;
      /* color: #0dbed2; */
      margin-left: 8px;
      @media (max-width: 640px) {
        font-size: 1.2rem;
      }
    }
  }
  .register-form {
    .shared-input {
      margin-bottom: 1.6rem;
    }
    .register-cb {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }
    .policy {
      display: flex;
      .policy-desc {
        /* font-family: "Noto Sans JP"; */
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 17px;
        color: #303030;
      }
      .detail {
        margin-left: 1.6rem;
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 17px;
        color: #0dbed2;
        @media (max-width: 640px) {
          margin-left: 0;
        }
      }
    }
    .submit_btn {
      width: 58.4%;
      margin: 0 auto 28px;
    }
  }
  .checkbox-with-validation {
    position: relative;
  }
  @media (max-width: 640px) {
    .header__logo {
      margin-bottom: 2rem;
      padding-top: 4.6rem;
    }
    .register-form {
      .submit_btn {
        width: 100%;
      }
    }
  }
`;
