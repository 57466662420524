import React, { FC, useEffect, useState } from 'react';
import { DeleteAllType, PATH_DETAILS, STYLE_BTN_CONTINUE } from '@configs';
import {
  ButtonCart,
  Check,
  ItemCart,
  StyleBtnRecycle,
  StyledModal,
  Text,
  TotalCart,
} from './style';
import { IconRecycle } from '../../Icon/RecycleBin';
import { SharedButton, SharedImage, SkeletonCartList } from '@components';
import { currency, LogApp, useMediaQuery } from '@utils';
import {
  getAllBooksInCart,
  increment,
  removeAllProduct,
  removeMultipleProduct,
  removeOneCart,
  selectCart,
  selectDeleteProduct,
  useAppSelector,
} from '@redux';
import { useDispatch } from 'react-redux';
import { themes } from '@theme';
import { BookInCartProps } from '@interfaces';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

export interface CartListProps {
  data: BookInCartProps[];
}

export const CartList: FC<CartListProps> = ({ data = [] }) => {
  const {
    data: { cartRemoveOne },
    isLoading,
  } = useAppSelector(selectCart);
  const navigate = useNavigate();
  const deleteAllSelector = useAppSelector(selectDeleteProduct);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width:992px)');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [checkAll, setCheckAll] = useState(false);
  const [list, setList] = useState([...data]);
  const [deleteType, setDeleteType] = useState<'one' | 'all' | 'multiple'>('one');
  const [cartIdSelected, setCartIdSelected] = useState<BookInCartProps[]>([]);
  const dispatch = useDispatch();
  const StyleBtnBack = () => ({
    height: '41px',
    borderRadius: '4px',
    lineHeight: '17px',
    fontWeight: '500',
    borderColor: '#B6B6B6',
    textColor: '#6C757D',
    padding: '0 1.2rem',
  });

  const selectCurrentId = (id: number) => {
    setCurrentId(id);
    setConfirmDelete(true);
  };

  const onDelete = () => {
    switch (deleteType) {
      case 'one': {
        dispatch(removeOneCart(currentId));
        setConfirmLoading(true);
        break;
      }
      case 'all': {
        dispatch(removeAllProduct(DeleteAllType.Cart));
        break;
      }
      case 'multiple': {
        const param = cartIdSelected.map((e) => e.shoppingCartItemId);
        dispatch(removeMultipleProduct(param));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (cartRemoveOne) {
      setConfirmLoading(false);
      setConfirmDelete(false);
      dispatch(getAllBooksInCart());
    }
  }, [cartRemoveOne]);

  useEffect(() => {
    const rmMultiple = deleteAllSelector.deleteMultiple.data;
    if (rmMultiple?.success) {
      dispatch(getAllBooksInCart());
      setConfirmDelete(false);
    }
  }, [deleteAllSelector.deleteMultiple.data]);

  const onToggleSelectAll = () => {
    setCheckAll(!checkAll);
    setList((prev) => prev.map((e) => ({ ...e, checked: !checkAll })));
  };

  const onToggleSelectItem = (index: number) => {
    setList((prev) => prev.map((e, i) => (i === index ? { ...e, checked: !e.checked } : { ...e })));
  };

  useEffect(() => {
    setCheckAll(list.every((e) => e.checked));
    const tickedIds: BookInCartProps[] = [];
    list.forEach((e) => {
      if (e.checked) tickedIds.push(e);
    });
    setCartIdSelected(tickedIds);
  }, [list]);

  useEffect(() => {
    let sum = 0;
    cartIdSelected.forEach((value) => {
      sum += value.price;
    });
  }, [cartIdSelected]);

  useEffect(() => {
    setList([...data]);
  }, [data]);

  useEffect(() => {
    if (deleteAllSelector.data?.success) {
      dispatch(getAllBooksInCart());
    }
  }, [deleteAllSelector]);

  const navigateDetails = (productId?: string | number) => {
    !!productId && navigate(PATH_DETAILS + '/' + productId);
  };

  return (
    <>
      <div>
        <div className="message">購入する書籍を選択してください。</div>
        <ItemCart>
          <Check checked={checkAll} onChange={onToggleSelectAll} />
          <div className="des-item1">
            <p className="book-item-text book-item-title">全て</p>
          </div>
          {!isMobile && !isTablet && (
            <div className="book-price-delete">
              <Text fontSize="14px">単価</Text>
              <Text fontSize="14px">アクション</Text>
            </div>
          )}
        </ItemCart>
        {isLoading ? (
          <code>
            <SkeletonCartList />
          </code>
        ) : (
          <>
            {list.map((item, index) => (
              <ItemCart key={index}>
                {/* {JSON.stringify(item)} */}
                <Check
                  checked={item.checked}
                  value={item.shoppingCartItemId}
                  onChange={() => onToggleSelectItem(index)}
                />
                <SharedImage
                  onClick={() => navigateDetails(item.productId)}
                  src={item.pictureMobileModel.imageUrl}
                  className="redirect-btn"
                  alt=""
                  isPageCart={true}
                />
                <div className="des-item ">
                  <Text
                    onClick={() => navigateDetails(item.productId)}
                    fontSize={isMobile ? '14px' : '20px'}
                    className="book-item-text book-item-title redirect-btn"
                  >
                    {item.bookName}
                  </Text>
                  <p className="book-item-text book-item-author">{item.authorName}</p>
                  {/*<p className="book-item-text book-item-publisher">
                出版社 : <span className="book-item-color">{item.pictureMobileModel}</span>
              </p>*/}
                  {isMobile && (
                    <>
                      <p className="book-item-price">{currency(item.price)}</p>
                      <StyleBtnRecycle onClick={() => selectCurrentId(item.shoppingCartItemId)}>
                        <IconRecycle />
                        <p>削除</p>
                      </StyleBtnRecycle>
                    </>
                  )}
                  {isTablet && <p className="book-item-price">{}</p>}
                </div>
                {!isMobile && !isTablet && (
                  <div className="book-price-delete">
                    <Text fontSize={isMobile ? '14px' : '16px'} className="book-item-price">
                      {currency(item.price)}
                    </Text>
                    <StyleBtnRecycle onClick={() => selectCurrentId(item.shoppingCartItemId)}>
                      <IconRecycle />
                      <p>削除</p>
                    </StyleBtnRecycle>
                  </div>
                )}
                {isTablet && (
                  <div className="book-price-delete">
                    <StyleBtnRecycle
                      onClick={() => {
                        selectCurrentId(item.shoppingCartItemId);
                        setDeleteType('one');
                      }}
                    >
                      <IconRecycle />
                      <p>削除</p>
                    </StyleBtnRecycle>
                  </div>
                )}
              </ItemCart>
            ))}
          </>
        )}
        <TotalCart>
          <div className="row">
            <Text
              fontSize={isMobile ? '14px' : '20px'}
              fontWeight={isMobile ? '400' : '700'}
              className="margin-text"
            >
              合計:
            </Text>
            <Text
              fontSize={isMobile ? '14px' : '20px'}
              fontWeight={isMobile ? '400' : '700'}
              className="margin-text"
            >
              書籍数:
            </Text>
          </div>
          <div className="row text-right">
            <Text fontSize={isMobile ? '14px' : '20px'} className="total margin-text">
              {currency(data.reduce((acc, cur) => acc + cur.price, 0))}{' '}
            </Text>
            <Text fontSize={isMobile ? '14px' : '20px'} className="amount margin-text">
              {data.length} 冊
            </Text>
          </div>
        </TotalCart>
        <ButtonCart>
          <SharedButton
            text={cartIdSelected.length > 0 ? '選択したアイテムを削除する' : '全て削除'}
            className={deleteAllSelector.loading ? 'disabled' : ''}
            style={StyleBtnBack()}
            borderColor={themes.theme.light.colors.gray['300']}
            textColor={themes.theme.light.colors.text2}
            onClick={() => {
              setConfirmDelete(true);
              setDeleteType(cartIdSelected.length > 0 ? 'multiple' : 'all');
            }}
          />
          <SharedButton
            text="次に進む"
            style={STYLE_BTN_CONTINUE}
            bgColor={themes.theme.light.colors.primary}
            textColor={themes.theme.light.colors.button.text}
            onClick={() => dispatch(increment())}
          />
        </ButtonCart>
      </div>
      <StyledModal>
        <Modal
          className="modal-cart hide-close"
          style={{ fontSize: isMobile ? '12px' : '14px' }}
          title={
            deleteType === 'one'
              ? 'この項目を確認'
              : deleteType === 'all'
              ? '全項目確認'
              : '選択したアイテムの削除を確認'
          }
          cancelText="キャンセル"
          okText={
            <span className="flex-center-all flex-span">
              <IconRecycle stroke={'#fff'} /> <p>OK</p>
            </span>
          }
          open={confirmDelete}
          onOk={onDelete}
          confirmLoading={
            deleteType === 'one'
              ? confirmLoading
              : deleteType === 'all'
              ? deleteAllSelector.loading
              : deleteAllSelector.deleteMultiple.loading
          }
          onCancel={() => setConfirmDelete(false)}
        >
          <p>本当に削除しますか?</p>
        </Modal>
      </StyledModal>
    </>
  );
};
