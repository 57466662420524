import { FC, useEffect, useState } from 'react';
import { RadioChangeEvent } from 'antd';
import { useDispatch } from 'react-redux';

import { SideBarItem } from '@components';
import { enumSidebarItemType, CATEGORY_PARAMS, PATH_LISTING } from '@configs';
import { setToLS, useMediaQuery } from '@utils';
import {
  categorySelected,
  changePathName,
  fetchProductList,
  RootState,
  selectSidebar,
  setAppCategory,
  useAppSelector,
} from '@redux';
import { CategoriesDataType, CategorySelected } from '@interfaces';
import { useNavigateParams } from '@hooks';
import { use } from 'i18next';

interface IProps {
  item: CategoriesDataType;
  type?: enumSidebarItemType;
  onRadioGroupChange: (v: number) => void;
  onParentToggle: (v: CategoriesDataType) => void;
}

export const SideBarItemModule: FC<IProps> = ({
  onParentToggle,
  onRadioGroupChange,
  item,
  type,
}) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const category = useAppSelector(selectSidebar).category;
  const dispatch = useDispatch();
  const subActive = useAppSelector(selectSidebar);
  const navigate = useNavigateParams();

  const [showAll, setShowAll] = useState<boolean>(false);

  const handleChangeCategory = ({ target: { value } }: RadioChangeEvent) => {
    onRadioGroupChange(value);
    dispatch(setAppCategory(value));
  };

  const onToggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleSelectData = (value: number) => {
    const selectCategory: CategorySelected = {
      categories: item,
      idSubcategory: value,
    };
    setToLS('subActive', value);
    const subName = selectCategory.categories?.subCategories.find((item) => item.id === value);
    navigate(`${PATH_LISTING}/${subName?.seName}`);
    dispatch(categorySelected(selectCategory));
    dispatch(fetchProductList({ ...CATEGORY_PARAMS, categoryIds: value }));
  };
  return (
    <SideBarItem
      item={item}
      type={type}
      showAll={showAll}
      isMobile={isMobile}
      category={category}
      onToggleShowAll={onToggleShowAll}
      detectParent={onParentToggle}
      handleChangeCategory={handleChangeCategory}
      onSelectData={handleSelectData}
    />
  );
};
