import { Divider } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';

import { enumSidebarItemType, PATH_LISTING } from '@configs';
import { SelectFilterOptionsModule, SideBarItemModule } from '@modules';
import { StyledSideBar } from './styles';
import { CloseIcon, SelectPriceRange } from '@components';
import { useMediaQuery, useOnClickOutside } from '@utils';
import { CategoriesDataType } from '@interfaces';
import { useLocation } from 'react-router-dom';
import { useNavigateParams } from '@hooks';

interface IProps {
  currentSelect?: string;
  data: CategoriesDataType[];
  minimum?: number;
  maximum?: number;
  showCategorySidebar: boolean;
  handleChangeMinimum: (value: any) => void;
  handleChangeMaximum: (value: any) => void;
  handleCloseSidebar: () => void;
  onPressEnter?: () => void;
  onApplyFilter?: (itemProps: { parent?: CategoriesDataType; childId: number }) => void;
}

export const SideBar = memo((props: IProps) => {
  const location = useLocation();
  const navigate = useNavigateParams();
  const {
    currentSelect,
    data,
    maximum,
    minimum,
    showCategorySidebar,
    handleChangeMaximum,
    handleChangeMinimum,
    handleCloseSidebar,
    onPressEnter,
    onApplyFilter,
  } = props;
  const isMobile = useMediaQuery('(max-width: 640px)');
  const sidebar = useRef<HTMLDivElement>(null);
  const [currentId, setCurrentId] = useState(0);
  const [currentParentId, setCurrentParentId] = useState<CategoriesDataType>();
  const [errorText, setErrorText] = useState<string>();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  useOnClickOutside(sidebar, () => {
    handleCloseSidebar();
  });

  const handleParentIdChange = (it: CategoriesDataType) => {
    setCurrentParentId(it);
  };

  useEffect(() => {
    handleCloseSidebar();
  }, [location]);

  return (
    <StyledSideBar showSidebar={showCategorySidebar} ref={sidebar} className="category-sidebar">
      {isMobile && (
        <div className="header">
          <h1 className="header-text">フィルター</h1>
          <div className="close-icon">
            <CloseIcon onClick={handleCloseSidebar} />
          </div>
        </div>
      )}
      <h2 className="heading-text">カテゴリ</h2>
      {data?.map((item) => (
        <SideBarItemModule
          onParentToggle={handleParentIdChange}
          onRadioGroupChange={setCurrentId}
          key={item.id}
          item={item}
          type={enumSidebarItemType.DASH}
        />
      ))}
      <Divider />
      <SelectPriceRange
        errorText={errorText}
        onFilterPress={() => {
          if (max < min) {
            setErrorText('最小数は最大数を超えることはできません');
          } else {
            onApplyFilter &&
              onApplyFilter({
                parent: currentParentId,
                childId: currentId,
              });
            setErrorText(undefined);
            navigate(PATH_LISTING, { priceMin: min, priceMax: max });
            handleCloseSidebar();
          }
        }}
        minimum={minimum}
        maximum={maximum}
        handleChangeMinimum={(e) => {
          handleChangeMinimum(e);
          setMin(e);
          setErrorText(undefined);
        }}
        handleChangeMaximum={(e) => {
          handleChangeMaximum(e);
          setMax(e);
          setErrorText(undefined);
        }}
        onPressEnter={() => {
          onPressEnter && onPressEnter();
          navigate(PATH_LISTING, { priceMin: min, priceMax: max });
        }}
      />
      <SelectFilterOptionsModule onOptionSelected={handleCloseSidebar} />
      {/*{isMobile && (
        <div className="bottom-actions">
          <SharedButton text="クリア" textColor="#fff" bgColor="#CECECE" btnStyle="basic" />
          <SharedButton
            text="フィルターを適用"
            textColor="#fff"
            bgColor="#D42A1C"
            btnStyle="basic"
            onClick={() => {
              onApplyFilter &&
                onApplyFilter({
                  parent: currentParentId,
                  childId: currentId,
                });
              handleCloseSidebar();
            }}
          />
        </div>
      )}*/}
    </StyledSideBar>
  );
});
