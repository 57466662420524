import styled from 'styled-components';
import { useState } from 'react';
import { ForgotPasswordModule, LoginModule } from '@modules';
import { useTheme } from '@theme';
import { setThemeMode, useAppDispatch } from '@redux';
import { enumThemeMode } from '@configs';
import { IMAGES } from '@assets';

export const ForgotPasswordPage = () => {
  return (
    <StyledForgotPassword background={IMAGES.backgroundLogin} className="forgot-page">
      <div className="header__logo logo app-container">
        <img title="" src={IMAGES.headerLogo} />
      </div>
      <div className="auth__section app-container">
        <div className="auth__frame">
          <ForgotPasswordModule />
        </div>
      </div>
    </StyledForgotPassword>
  );
};

const StyledForgotPassword = styled.div<{ background?: string }>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background-size: 100% 100%;
  background-image: ${(p) => `url(${p?.background})`};
  @media (max-width: 640px) {
    background-position-x: 50%;
    background-size: 200% 100%;
  }
  .header__logo {
    padding-top: calc(17 / 360 * 100%);
    height: fit-content;
    @media (max-width: 640px) {
      padding-top: 4.6rem;
      margin: 0 auto 2rem;
      width: 15rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .auth__section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
    @media (min-width: 640px) {
      height: 100%;
    }
    @media (max-width: 640px) {
      /* justify-content: flex-start; */
      align-items: flex-start;
      width: 100%;
      height: 100%;
      padding-bottom: 0%;
      position: relative;
    }
    .auth__frame {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props?.theme.colors.body};
      width: calc(287 / 586 * 100%);
      height: fit-content;
      padding: 4rem;
      border-radius: 0.8rem;
      @media (max-width: 1024px) {
        width: 68%;
      }

      @media (max-width: 640px) {
        border-radius: 1.2rem 1.2rem 0 0;
        width: 100%;
        height: 100%;
        padding: 3.6rem 2rem 100% 2rem;
      }
    }
  }

  @media (max-width: 640px) {
    height: 100vh;
  }
`;
