/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { openToast, store } from 'src/redux';
import axios from 'axios';
import { LogApp } from '@utils';
const queryString = require('query-string');

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,

  headers: {
    'Content-type': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest',
    // charset: 'UTF-8',
    'Access-Control-Allow-Origin': 'https://api.bookskozuchi.com',
    'Access-Control-Allow-Credentials': 'true',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use((config: any) => {
  const role = store.getState().app.role;
  const auth = store.getState().auth;
  const token = auth.accessToken;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  config.headers['Accept'] = `*/*`;
  delete axios.defaults.headers.common['Accept-Encoding'];
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error: any) => {
    if (error.response) {
      // Request made and server responded
      LogApp(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      LogApp(error.request);
      store.dispatch(
        openToast({
          message: error.response.data.message,
          type: 'error',
          autoHideDuration: 2000,
        }),
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      LogApp('Error', error.message);
    }
    throw error;
  },
);

export default axiosClient;
