import { Divider } from 'antd';
import styled from 'styled-components';
import { SharedButton, ShareInput } from '../shared';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ChangePasswordProps } from '@interfaces';
import { changeUserPassword, selectCustomer, setLoading, useAppSelector } from '@redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
// interface IProps {
//   onSubmit?: () => void;
// }

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    data: { changePassword },
  } = useAppSelector(selectCustomer);
  const formik = useFormik<ChangePasswordProps>({
    initialValues: {
      OldPassword: '',
      NewPassword: '',
      ConfirmNewPassword: '',
    },
    validationSchema: Yup.object({
      OldPassword: Yup.string().trim().required('パスワードが必要です'),
      NewPassword: Yup.string()
        .trim()
        .min(8, '新しいパスワードは8文字～60文字で入力して下さい。')
        .max(60, '新しいパスワードは8文字～60文字で入力して下さい。')
        .required('新しいパスワードが必要です'),
      ConfirmNewPassword: Yup.string()
        .trim()
        .oneOf(
          //confirmPass not match with password
          [Yup.ref('NewPassword'), null],
          '新しいパスワードが再入力パスワードと一致しません。もう一度やり直してください。',
        )
        .required('パスワードが必要です'),
    }),
    onSubmit: (val) => {
      dispatch(changeUserPassword(val));
    },
  });

  useEffect(() => {
    if (changePassword) {
      if (changePassword.success) {
        toast.success(changePassword.messages);
      } else {
        toast.error(changePassword.messages);
      }
    }
  }, [changePassword]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading]);

  return (
    <StyledChangePasswordSection className="rg-sec password-section">
      <h1 className="title">パスワードの変更</h1>
      <Divider />
      <form onSubmit={formik.handleSubmit} className="content change-password-form">
        <div className="form-password__info">
          <ShareInput
            label="名現在パスワード"
            placeholder="パスワードを入力"
            name="OldPassword"
            onChange={formik.handleChange}
            value={formik.values.OldPassword}
            errors={formik.errors.OldPassword}
            touched={formik.touched.OldPassword}
            className="input"
            required
          />
          <ShareInput
            label="新しいパスワード"
            placeholder="パスワードを入力"
            name="NewPassword"
            onChange={formik.handleChange}
            value={formik.values.NewPassword}
            errors={formik.errors.NewPassword}
            touched={formik.touched.NewPassword}
            className="input"
            required
          />
          <ShareInput
            label="新しいパスワード（確認用)"
            placeholder="新しいパスワード（確認用)を入力"
            name="ConfirmNewPassword"
            onChange={formik.handleChange}
            value={formik.values.ConfirmNewPassword}
            errors={formik.errors.ConfirmNewPassword}
            touched={formik.touched.ConfirmNewPassword}
            className="input"
            required
          />
          <SharedButton
            typeHtml="submit"
            text="更新"
            className="submit_btn"
            btnStyle="basic"
            bgColor="#D42A1C"
          />
        </div>
      </form>
    </StyledChangePasswordSection>
  );
};

const StyledChangePasswordSection = styled.div`
  margin-top: -5.4rem;
  width: 100%;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: #303030;
  }
  .content {
    display: flex;
    width: 100%;
    margin-bottom: 10rem;
    justify-content: center;
    .form-password__info {
      min-width: 47.5rem;
      width: 45%;
      @media (max-width: 640px) {
        width: 100% !important;
        min-width: unset !important;
      }
      .shared-input {
        margin-bottom: 1.6rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 640px) {
          flex-direction: column;
          align-items: flex-start;
          .input__label {
            margin-bottom: 0.8rem !important;
          }
          .group-input {
            width: 100% !important;
            max-width: unset !important;
          }
        }
        .group-input {
          max-width: 27.6rem;
          width: 60%;
          .ant-input {
            background: #f9f9f9;
            padding: 1.2rem 1.6rem;
            border: none;
            border-radius: 0;

            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 2rem;
            color: #303030;
          }
        }
        .input__label {
          font-style: normal;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #000000;
          margin-bottom: 0;
        }
      }

      .submit_btn {
        max-width: 15rem;
        @media (max-width: 640px) {
          width: 100%;
          max-width: unset;
          margin-top: 2.4rem;
        }
      }
    }
  }
  @media (max-width: 640px) {
  }
`;
