import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { Dropdown } from 'antd';

import { themes } from '@theme';
import {
  CardList,
  CardMenu,
  Category,
  IconChevronLeft,
  IconChevronRight,
  SharedSkeleton,
  SkeletonCategory,
} from '@components';
import { IMAGES } from '@assets';
import { ListItem, NavigateButton, StyledHome } from './styles';
import { IBookDataItem, ICategory } from '@interfaces';
import {
  BREAK_POINTS,
  PATH_CATEGORIES,
  PATH_LOGIN,
  PATH_MY_BOOKS,
  PATH_SUBSCRIBER_RANK,
  PATH_SUBSCRIPTION,
} from '@configs';
import { Banner } from './Banner';
import { NavLink } from 'react-router-dom';
import { useNavigateParams } from '@hooks';
import { setToLS } from '@utils';
import { useSelector } from 'react-redux';
import { selectAuth, selectProduct, useAppSelector } from '@redux';

interface HomeNoduleProps {
  recommendedBooks: IBookDataItem[];
  bestSellerBooks: IBookDataItem[];
  lastestBooks: IBookDataItem[];
  categories: ICategory[];
  isLoadingImg?: boolean;
  isLogin: string;
  onNavigateBestSeller: () => void;
  onNavigateLatestBook: () => void;
  onNavigateRecommendBook: () => void;
  onSelectSubMenu: (category?: any, selectedValue?: any) => void;
}

export const HomeNodule: React.FC<HomeNoduleProps> = ({
  recommendedBooks,
  bestSellerBooks,
  lastestBooks,
  categories,
  isLoadingImg,
  isLogin,
  onNavigateRecommendBook,
  onNavigateBestSeller,
  onNavigateLatestBook,
  onSelectSubMenu,
}) => {
  SwiperCore.use([Navigation]);
  const prevButton = useRef<HTMLDivElement>(null);
  const nextButton = useRef<HTMLDivElement>(null);
  const productSelector = useAppSelector(selectProduct);
  const { isLoading } = productSelector;
  const onBeforeInit = (Swiper: SwiperCore): void => {
    if (typeof Swiper.params.navigation !== 'boolean') {
      const navigation = Swiper.params.navigation;
      if (navigation) {
        navigation.prevEl = prevButton.current;
        navigation.nextEl = nextButton.current;
        Swiper.navigation.update();
      }
    }
  };

  const { userInfo } = useAppSelector(selectAuth);

  const navigate = useNavigateParams();

  const genSwiperSlice = () => {
    return categories?.map((category, index) => {
      return (
        <SwiperSlide zoom key={index}>
          <Dropdown
            overlay={
              <CardMenu
                menu={category.subCategories || []}
                onSelect={(selectedSub) => {
                  setToLS('subActive', Number(selectedSub.key));
                  onSelectSubMenu(category, selectedSub);
                }}
              />
            }
            key={category.category_id}
          >
            <div className="swiper-slide-category-item">
              <Category
                navigateTo={category.navigateTo}
                category_id={category.category_id}
                category_name={category.category_name}
                img_url={category.img_url}
                subCategories={category.subCategories}
              />
            </div>
          </Dropdown>
        </SwiperSlide>
      );
    });
  };

  return (
    <StyledHome>
      {isLoadingImg ? (
        <SharedSkeleton type="image" active={isLoadingImg} imageClassName="w-full" />
      ) : (
        <Link
          className="home-banner"
          to={
            isLogin
              ? userInfo?.hasSubcription
                ? PATH_SUBSCRIBER_RANK
                : PATH_SUBSCRIPTION
              : PATH_LOGIN
          }
        >
          <img src={IMAGES.imageBanner} alt="not" />
        </Link>
      )}

      <Banner />

      <div className="container-category-list">
        <div className="category-header font-noto">
          <h5 className="category-header__title">カテゴリ</h5>
          <NavigateButton>
            <span className="navigate-title">
              <p onClick={() => navigate(PATH_CATEGORIES)}>全てのカテゴリ</p>
            </span>
            <IconChevronRight width={16} height={16} color={themes.theme.light.colors.text} />
          </NavigateButton>
        </div>
        <div className="list-category">
          <Swiper
            onBeforeInit={onBeforeInit}
            spaceBetween={16}
            slidesPerView={6}
            slidesPerGroup={6}
            breakpoints={BREAK_POINTS}
            navigation={{
              prevEl: prevButton.current,
              nextEl: nextButton.current,
            }}
            modules={[Navigation]}
          >
            {genSwiperSlice()}
          </Swiper>

          <div className="prev-btn pagination-btn" ref={prevButton}>
            <IconChevronLeft width={25} height={25} color={themes.theme.light.colors.button.text} />
          </div>
          <div className="next-btn pagination-btn" ref={nextButton}>
            <IconChevronRight
              width={25}
              height={25}
              color={themes.theme.light.colors.button.text}
            />
          </div>
        </div>
      </div>
      <div className="container-recommand">
        <CardList
          blockTitle="おすすめの本"
          data={recommendedBooks}
          onNavigate={onNavigateRecommendBook}
        />
      </div>
      <div className="container-about">
        {isLoading ? (
          <div className="skeleton">
            <SharedSkeleton type={'input'} active inputClassName="skeleton-input"></SharedSkeleton>
            <SharedSkeleton type={'input'} active inputClassName="skeleton-input"></SharedSkeleton>
            <SharedSkeleton type={'input'} active inputClassName="skeleton-input"></SharedSkeleton>
            <SharedSkeleton type={'input'} active inputClassName="skeleton-input"></SharedSkeleton>
          </div>
        ) : (
          <>
            <div className="container-about-header">
              <img src={IMAGES.ImgAbout} alt="not" />
              <h2 className="container-about-title font-noto">KOZUCHIについて</h2>
            </div>
            <div className="container-about-content">
              <ul>
                <ListItem>
                  KOZUCHIは、国内最大級の電子書籍プラットフォームで、あらゆる電子書籍を揃え
                  ています。ジャンルは、文庫、新書を含めた実用書、ビジネス書、人文書、経済書、理工
                  書、医学書など、一般書から専門書まで、あらゆるジャンルを集めています
                </ListItem>
                <ListItem>
                  KOZUCHI最大の特長は、電子書籍を音声合成で読み上げる機能（TTS）を搭載して
                  いることです。登録されているすべての本を、読んで聴くことができます。
                  また、音声速度、音声タイプ、音声トーン、文字の大きさ、
                  書体などを設定できます。（特許申請中）
                </ListItem>
              </ul>
              <div className="footer-desc">
                <div className="description">
                  【お断り】PDF、ePubFIX型ファイルは文字のサイズの変更や読み上げなど動作しないものもあります。
                  順次アップデートを配信する予定です。
                </div>
                <img src={IMAGES.aboutBottom} alt="not" />
              </div>
            </div>
          </>
        )}
      </div>
      <div className="container-bestseller">
        <CardList
          enableBadge="index"
          blockTitle="ベストセラー"
          data={bestSellerBooks}
          onNavigate={onNavigateBestSeller}
        />
      </div>
      {/*<div className="home-banner-bottom">
        <img src={IMAGES.homeBannerBottom} alt="not" onClick={navigateByBanner} />
      </div>*/}
      <div className="container-latest">
        <CardList blockTitle="最新書籍" data={lastestBooks} onNavigate={onNavigateLatestBook} />
      </div>
    </StyledHome>
  );
};
