import styled from 'styled-components';

export const StyledPolicy = styled.div`
  .header-policy {
    padding-bottom: 2.4rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border-bottom: 1px solid #ececec;
    font-weight: ${(props) => props.theme.weight['700']};
    font-size: ${(props) => props.theme.size.xl};
    line-height: 2.9rem;
    font-family: 'Noto Sans JP';
    font-style: normal;
  }
`;
export const StyledContentPolicy = styled.div<{
  isMobile?: boolean;
}>`
  .title {
    font-weight: ${(props) =>
      props.isMobile ? props.theme.weight['500'] : props.theme.weight['700']};
    font-size: 2.8rem;
    line-height: 4.1rem;
    margin: 2.4rem 0 2rem 0;
    font-family: 'Noto Sans JP';
    font-style: normal;
  }
  .purpose {
    margin-top: 0.8rem;
    li {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: ${(props) => props.theme.weight['400']};
      font-size: ${(props) => props.theme.size.s};
      line-height: 2rem;
      color: ${(props) => props.theme.colors.gray['600']};
      margin-left: 1.6rem;
    }
  }
  .container {
    margin-top: 2rem;
    h4 {
      font-weight: ${(props) => props.theme.weight['700']};
      font-size: ${(props) => props.theme.size.s};
      line-height: 2rem;
      color: ${(props) => props.theme.colors.gray['600']};
    }
    p {
      font-weight: ${(props) => props.theme.weight['400']};
      font-size: ${(props) => props.theme.size.s};
      line-height: 2rem;
      margin-top: 0.8rem;
      text-align: justify;
    }
  }
  .contact {
    margin-top: 2.4rem;
    .date {
      margin-top: 2rem;
    }
  }
  @media screen and (min-width: 1024px) {
    .container {
      p {
        padding-left: 5rem;
      }
    }
  }
`;
