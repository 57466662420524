import querystring from 'querystring';
import { ProductDataType } from '@interfaces';
import { PATH_DETAILS } from '@configs';
import queryString from 'query-string';

export const queryParamsParser = (url?: string) => {
  const rootUrl = url || location.search;
  const replaceQuerySymbolUrl = rootUrl.replace(/\?/gi, '');
  const arrayParams = replaceQuerySymbolUrl.split('&');
  return arrayParams.reduce(
    (a, c) => ({ ...a, [c.split('=')[0]]: decodeURIComponent(c.split('=')[1]) }),
    {} as { [key: string]: string },
  );
};

// Capitalize
export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function lowercase(word: string) {
  return word.toLowerCase();
}

// Format price
export function formatPrice(price: string) {
  const number = parseFloat(price);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
}

// Get wind direction

function toCamelCase(word: string) {
  return word
    .toLowerCase()
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/ (.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/ /g, '');
}

export function objectToCamelCase(origObj: any) {
  //WHY: any object to Camel case
  return Object.keys(origObj).reduce(function (newObj: any, key) {
    const val = origObj[key];
    const newVal = typeof val === 'object' ? objectToCamelCase(val) : val;
    newObj[toCamelCase(key)] = newVal;
    return newObj;
  }, {});
}

export function getYears() {
  const currentYear = new Date(Date.now()).getFullYear();
  const years = [];
  for (let i = 1900; i <= currentYear; i++) {
    years.push(i);
  }
  return years;
}

export const getColorActive = (path: string, location: string) => {
  return path === location ? '#D42A1C' : '#303030';
};

export const getStatusActiveColor = (check: string, type: 'text' | 'color') => {
  switch (type) {
    case 'text':
      return check === 'completed' ? '購入完了' : 'キャンセル済み';
    case 'color':
      return check === 'completed' ? '#359F08' : '#D42A1C';
    default:
      return check;
  }
};

export const getPCStatusActiveColor = (check: boolean, type: 'text' | 'color') => {
  switch (type) {
    case 'text':
      return check ? '購入完了' : 'キャンセル済み';
    case 'color':
      return check ? '#51c51f' : '#D42A1C';
    default:
      return;
  }
};

export const scrollToTop = () => {
  document.body.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const removeQueryString = (params: string) => {
  const result = params.split('?').filter((chart) => chart !== '');
  return querystring.parse(result[0]);
};

export const parseString = (params: string) => {
  return queryString.parse(params);
};

export const mapData = (dataList: ProductDataType[]) => {
  if (!dataList) return [];
  const newData = dataList.map((data) => {
    return {
      id: data.id,
      title: data.productName,
      img_url: data.defaultPictureModel.imageUrl,
      desc: data.fullDescription,
      author: data.authorName,
      price: data.price,
      navigateUrl: `${PATH_DETAILS}/${data.id}`,
      rating: data.ratingSum,
      isAudioReading: data.audioReading,
    };
  });
  return newData;
};

export const getAudioReadingValue = (value: number) => {
  switch (value) {
    case 2:
      return false;
    case 3:
      return true;
  }
};

export const getSortPrice = (value: number) => {
  switch (value) {
    case 4:
      return '価格の高い順';
    case 5:
      return '価格の低い順';
  }
};

export const getOrderByNameValue = (value: number) => {
  switch (value) {
    case 2:
      return 'タイトル昇順';
    case 3:
      return 'タイトル降順';
  }
};

export const getSortByOtherCategories = (value: number) => {
  switch (value) {
    case 6:
      return 'リリースの古い順';
    case 9:
      return 'レビューの評価順';
    case 8:
      return '人気順';
  }
};

type Params = {
  [x: string]: any;
};

export const hasKeyIn = (obj: Params, keyParams: string) => {
  return Object.keys(obj).some((key) => key === keyParams);
};

export const hasValue = (value: number | null) => {
  return value ? true : false;
};

export const renderSkeletonItem = (amount: number) => {
  return new Array(amount).fill(1);
};
export const deleteSearchParams = (
  [urlSearchParams, setSearchParams]: [URLSearchParams, any],
  deleteKey: string[],
) => {
  deleteKey.forEach((e) => {
    const key = urlSearchParams.has(e);
    key && urlSearchParams.delete(e);
  });
  setSearchParams(urlSearchParams);
};

export const setSearchParams = (
  [urlSearchParams, setSearchParams]: [URLSearchParams, any],
  params: { [key: string]: any },
) => {
  Object.keys(params).forEach((e) => {
    urlSearchParams.set(e, params[e]);
  });
  setSearchParams(urlSearchParams);
};

export const currency = (n: number): string => {
  return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(n);
};

export const getLastPage = (total: number) => {
  if (total % 2 === 0) {
    const lastPage = total / 2 - 1;
    return lastPage;
  } else {
    const lastPage = Math.floor(total / 2);
    return lastPage;
  }
};

export const timeConverter = (s: string) => {
  const insertZero = (t: number) => ('0' + t).slice(-2);
  const date = new Date(s);
  const year = date.getFullYear();
  const month = insertZero(date.getMonth() + 1);
  const day = insertZero(date.getDate());
  const hour = insertZero(date.getHours());
  const minute = insertZero(date.getMinutes());
  const second = insertZero(date.getSeconds());
  return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
};

export const removeSpecialCharacters = (params: string, character: string) => {
  if (params.includes(character)) {
    return params.split('-').join('');
  } else {
    return params;
  }
};

export function swapElement(array: any, indexA: any, indexB: any) {
  const tmp = array[indexA];
  array[indexA] = array[indexB];
  array[indexB] = tmp;
}
