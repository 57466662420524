import { IMAGES } from '@assets';
import { PATH_DETAIL_READ_BOOK, PATH_READ_BOOK, PATH_SUBSCRIPTION } from '@configs';
import { useNavigateParams } from '@hooks';
import { RootState, subscriptionRank, useAppSelector } from '@redux';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ResponseProps } from 'src/interfaces/subscriptionRank.interface';
import { fetchSubscriptionRank } from 'src/redux/action/subscriptionRankAction';

import { StyledSubscribeRank } from './style';

export const SubscribeRankModule = () => {
  const dispatch = useDispatch();
  const {
    data: { subscriptionRanks },
  } = useAppSelector(subscriptionRank);

  const {
    auth: { userInfo },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(fetchSubscriptionRank());
  }, []);
  const navigate = useNavigate();
  const handleNavigateRead = (dataBook: ResponseProps) => {
    // navigate(`${PATH_READ_BOOK}/${dataBook.id}`, { replace: true });
    navigate(`${PATH_READ_BOOK}/${dataBook.id}`);
  };

  useEffect(() => {
    if (!userInfo?.hasSubcription) {
      navigate(PATH_SUBSCRIPTION);
    }
  }, []);

  return (
    <StyledSubscribeRank>
      <div className="subscribe-rank">
        <div className="subscribe-rank-banner">
          <img src={IMAGES.subscribeRankBanner} alt="not" />
        </div>
        <div className="subscribe-rank-container">
          <div className="subscribe-rank-container-left">
            <ol>
              {subscriptionRanks && subscriptionRanks.length > 50
                ? subscriptionRanks?.slice(0, 50).map((item) => (
                    <li key={item.id} onClick={() => handleNavigateRead(item)}>
                      <p>
                        <Link to={''}>
                          {item.sttHomePage}&emsp;{item.name}
                        </Link>
                      </p>
                      <p>{item.authorNames}</p>
                    </li>
                  ))
                : subscriptionRanks?.map((item) => (
                    <li key={item.id} onClick={() => handleNavigateRead(item)}>
                      <p>
                        <Link to={''}>
                          {item.sttHomePage}&emsp;{item.name}
                        </Link>
                      </p>
                      <p>{item.authorNames}</p>
                    </li>
                  ))}
            </ol>
          </div>
          <div className="subscribe-rank-container-right">
            <ol>
              {subscriptionRanks &&
                subscriptionRanks.length > 50 &&
                subscriptionRanks?.slice(50).map((item) => (
                  <li key={item.id} onClick={() => handleNavigateRead(item)}>
                    <p>
                      <Link to={''}>
                        {item.sttHomePage}&emsp;{item.name}
                      </Link>
                    </p>
                    <p>{item.authorNames}</p>
                  </li>
                ))}
            </ol>
          </div>
        </div>
        <div className="subscribe-rank-line"></div>
        <p className="subscribe-rank-text-bottom">
          本ランキングは「青空文庫」のアクセスランキング (2022.04.01 -
          2022.04.30)に準拠しています。他の作品は検索機能にてお探しください。
        </p>
      </div>
    </StyledSubscribeRank>
  );
};
