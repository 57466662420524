import { memo } from 'react';

export const SignupSuccessIcon = memo(() => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M78.182 16C79.1856 16 80.0002 16.7964 80.0002 17.7778C80.0002 18.7591 79.1856 19.5556 78.182 19.5556C77.1783 19.5556 76.3638 18.7591 76.3638 17.7778C76.3638 16.7964 77.1783 16 78.182 16Z"
      fill="#F5C0BC"
    />
    <path
      d="M78.182 62.2224C79.1856 62.2224 80.0002 63.0189 80.0002 64.0002C80.0002 64.9815 79.1856 65.778 78.182 65.778C77.1783 65.778 76.3638 64.9815 76.3638 64.0002C76.3638 63.0189 77.1783 62.2224 78.182 62.2224Z"
      fill="#F5C0BC"
    />
    <path
      d="M69.0915 72.8889C71.1006 72.8889 72.7279 74.48 72.7279 76.4445C72.7279 78.4089 71.1006 80 69.0915 80C67.0824 80 65.4551 78.4089 65.4551 76.4445C65.4551 74.48 67.0824 72.8889 69.0915 72.8889Z"
      fill="#D42A1C"
    />
    <path
      d="M65.4554 0C66.4591 0 67.2736 0.796444 67.2736 1.77778C67.2736 2.75911 66.4591 3.55555 65.4554 3.55555C64.4518 3.55555 63.6372 2.75911 63.6372 1.77778C63.6372 0.796444 64.4518 0 65.4554 0Z"
      fill="#F5C0BC"
    />
    <path
      d="M40.0007 5.3335C19.9169 5.3335 3.63672 21.2517 3.63672 40.889C3.63672 60.5264 19.9169 76.4446 40.0007 76.4446C60.0846 76.4446 76.3648 60.5264 76.3648 40.889C76.3648 21.2517 60.0846 5.3335 40.0007 5.3335Z"
      fill="#D42A1C"
    />
    <path
      d="M40.001 67.5555C24.9391 67.5555 12.728 55.6159 12.728 40.8888C12.728 26.1617 24.9391 14.2222 40.001 14.2222C55.063 14.2222 67.2741 26.1617 67.2741 40.8888C67.2741 55.6159 55.063 67.5555 40.001 67.5555Z"
      fill="white"
    />
    <path
      d="M53.5183 32.4837C54.9385 33.8721 54.9385 36.1228 53.5183 37.5112L40.6618 50.0837L35.52 55.1112L30.3763 50.0837L26.5199 46.3112C25.811 45.6179 25.4546 44.7077 25.4546 43.7974C25.4546 42.8872 25.811 41.977 26.5199 41.2835C27.9401 39.895 30.2419 39.895 31.6617 41.2835L35.52 45.0542L48.3766 32.4837C49.0876 31.7903 50.0167 31.4419 50.9476 31.4419C51.8785 31.4419 52.8076 31.7903 53.5183 32.4837Z"
      fill="#D42A1C"
    />
    <path
      d="M12.7279 76.4443C13.7315 76.4443 14.5461 77.2408 14.5461 78.2221C14.5461 79.2034 13.7315 79.9999 12.7279 79.9999C11.724 79.9999 10.9097 79.2034 10.9097 78.2221C10.9097 77.2408 11.724 76.4443 12.7279 76.4443Z"
      fill="#F5C0BC"
    />
    <path
      d="M8.18222 3.55542C10.6932 3.55542 12.7277 5.54475 12.7277 7.99986C12.7277 10.455 10.6932 12.4443 8.18222 12.4443C5.6711 12.4443 3.63672 10.455 3.63672 7.99986C3.63672 5.54475 5.6711 3.55542 8.18222 3.55542Z"
      fill="#D42A1C"
    />
    <path
      d="M1.8182 17.7776C2.82185 17.7776 3.6364 18.574 3.6364 19.5554C3.6364 20.5367 2.82185 21.3331 1.8182 21.3331C0.814372 21.3331 0 20.5367 0 19.5554C0 18.574 0.814372 17.7776 1.8182 17.7776Z"
      fill="#F5C0BC"
    />
    <path
      d="M1.8182 62.2224C2.82185 62.2224 3.6364 63.0189 3.6364 64.0002C3.6364 64.9815 2.82185 65.778 1.8182 65.778C0.814372 65.778 0 64.9815 0 64.0002C0 63.0189 0.814372 62.2224 1.8182 62.2224Z"
      fill="#F5C0BC"
    />
  </svg>
));
