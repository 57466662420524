import type { RadioChangeEvent } from 'antd';
import { Radio } from 'antd';
import { useEffect, useState } from 'react';

import { StyleContainer } from './style';
import { PATH_MY_REVIEW } from '@configs';
import { BookItem, Pagination } from '@components';
import { fetchReviewBooksList, selectBookReview, useAppSelector } from '@redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SkeletonCardItem } from '@components';
import { scrollToTop } from '@utils';

const { Group } = Radio;

const myReviewDefaultParams = {
  pageSize: 10,
  radioGroup: [
    { label: '公開', value: '0' },
    { label: '保留', value: '1' },
    { label: '全て', value: '2' },
  ],
};

export const MyPreviewModule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useSearchParams();
  const { bookReviewList, loading } = useAppSelector(selectBookReview);
  const [value, setValue] = useState('全て');

  useEffect(() => {
    dispatch(fetchReviewBooksList(''));
  }, []);

  useEffect(() => {
    dispatch(
      fetchReviewBooksList(
        `?start=${+(searchParams[0].get('start') || 0)}&length=${myReviewDefaultParams.pageSize}`,
      ),
    );
    scrollToTop();
  }, [location]);

  const filterByPageChange = (e: number) => {
    navigate(`${PATH_MY_REVIEW}?start=${e - 1}&length=${myReviewDefaultParams.pageSize}`);
  };

  const handleChange = (e: RadioChangeEvent) => {
    setValue(() => e.target.value);
  };

  return (
    <StyleContainer className="my-preview">
      <div className="my-preview-right rg-sec">
        <div className="my-preview-right__header">
          <h5>マイレビュー</h5>
          {/*<div className="my-preview-right__header-filter">
            <Group onChange={handleChange} value={value}>
              {myReviewDefaultParams.radioGroup.map((e) => (
                <Radio value={e.value} key={e.value}>
                  {e.label}
                </Radio>
              ))}
            </Group>
          </div>*/}
        </div>
        <div className="my-preview-right__line" />
        <div className="my-preview-right__content">
          {loading ? (
            <>
              <SkeletonCardItem type="horizontal" size="regular" />
              <SkeletonCardItem type="horizontal" size="regular" />
              <SkeletonCardItem type="horizontal" size="regular" />
              <SkeletonCardItem type="horizontal" size="regular" />
              <SkeletonCardItem type="horizontal" size="regular" />
              <SkeletonCardItem type="horizontal" size="regular" />
              <SkeletonCardItem type="horizontal" size="regular" />
            </>
          ) : null}
          {bookReviewList.length > 0 && (
            <>
              <div className="my-preview-right__content-title">{bookReviewList.length} 件</div>
              <div className="my-preview-right__content-list">
                {bookReviewList &&
                  bookReviewList.map((data, index) => (
                    <div className="wish-list-item" key={index}>
                      <BookItem removeFromWishlist={() => {}} data={data} type="preview" />
                      <div className="my-preview-right__line" />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        {bookReviewList.length > 0 ? (
          <div className="my-preview-right__pagination">
            <Pagination
              current={+(searchParams[0].get('start') || 0) + 1}
              pageSize={myReviewDefaultParams.pageSize}
              total={bookReviewList.length}
              defaultCurrent={1}
              onChangePage={filterByPageChange}
            />
          </div>
        ) : (
          <p> あなたのレビューはまだありません</p>
        )}
      </div>
    </StyleContainer>
  );
};
