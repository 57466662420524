import { RootState } from './index';
import { createSlice } from '@reduxjs/toolkit';
import { fetchSubscriptionRank } from '../action/subscriptionRankAction';
import { ResponseProps } from 'src/interfaces/subscriptionRank.interface';

interface SubscriptionRankType {
  isLoading: boolean;
  data: {
    subscriptionRanks: ResponseProps[] | null;
  };
}

const initialState: SubscriptionRankType = {
  isLoading: false,
  data: {
    subscriptionRanks: null,
  },
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionRank.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubscriptionRank.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data.subscriptionRanks = payload;
      })
      .addCase(fetchSubscriptionRank.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const subscriptionReducer = subscriptionSlice.reducer;
export const subscriptionRank = (state: RootState) => state.subscriptionRank;
