import styled from 'styled-components';
export const StyledSetting = styled.div`
  background-color: ${(props) => props.theme.colors.bgHeader};
  .voice-select {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
    background: #f5f5f5;
    background-image: none;
    height: 4rem;
    padding: 0.2rem 1.2rem;
    color: #000;
    cursor: pointer;
    font-size: 1.4rem;
    font-family: 'Noto Sans JP';
    font-weight: 400;
  }
`;
export const StyledHeaderSetting = styled.div<{
  fontSize?: string;
}>`
  width: 100%;
  height: 48px;
  display: flex;
  box-shadow: 0 3px 6px rgb(0 0 0 / 7%);
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  .header-write {
    width: 100%;
    text-align: center;
    background-color: ${(props) => props.theme.colors.bgHeader};
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: ${(props) => props.theme.weight['700']};
    font-size: ${(props) => props.fontSize};
    color: ${(props) => props.theme.colors.gray['600']};
    line-height: 23px;
  }
  .btn-close {
    background-color: ${(props) => props.theme.colors.bgHeader};
  }
`;

export const StyleContentSetting = styled.div`
  margin-top: 24px;
  padding-right: 16px;
  padding-left: 16px;
  .ant-slider-track {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .ant-slider-handle {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0.4rem;
    background: ${(props) => props.theme.colors.bgFooter};
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${(props) => props.theme.colors.primary};
  }
  .ant-slider:hover .ant-slider-track {
    background-color: ${(props) => props.theme.colors.primary};
  }
  .ant-tooltip-inner {
    background: white !important;
  }
  .setting-speed {
    .ic-speed {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
  .voice-type {
    margin-top: 6.5rem;
    p {
      margin-bottom: 16px;
    }
  }
  .pitch {
    margin-top: 44px;
    .level {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
`;
