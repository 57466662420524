import { useDispatch } from 'react-redux';

import { SelectFilterOptions } from '@components';
import {
  fetchProductList,
  selectSidebar,
  setBookType,
  setNameOrder,
  setSortByOtherCategories,
  setSortByPrice,
  useAppSelector,
  resetCategoryId,
  resetCurrentPage,
} from '@redux';
import {
  getAudioReadingValue,
  getOrderByNameValue,
  getSortByOtherCategories,
  getSortPrice,
} from '@utils';
import { RadioChangeEvent } from 'antd';
import {
  ALL_BOOKS_PARAMS,
  BOOK_TYPE_PARAMS,
  FILTER_NONE_PATH,
  PATH_LISTING,
  PRODUCT_SORTING_PARAMS,
} from '@configs';
import { FC } from 'react';
import { useNavigateParams } from '@hooks';

interface SelectFilterOptionsProps {
  onOptionSelected: () => void;
}

export const SelectFilterOptionsModule: FC<SelectFilterOptionsProps> = ({ onOptionSelected }) => {
  const { bookType, sortByPrice, nameOrder, sortByOtherCategories } = useAppSelector(selectSidebar);
  const navigate = useNavigateParams();
  const dispatch = useDispatch();

  const handleResetCategoryId = () => {
    dispatch(resetCategoryId(null));
  };

  const handleResetCurrentPage = () => {
    dispatch(resetCurrentPage(1));
  };
  const handleDispatchSorting = (value: number) => {
    dispatch(fetchProductList({ ...PRODUCT_SORTING_PARAMS, productSorting: value }));
  };

  const handleBookTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setBookType(value));
    handleResetCurrentPage();
    onOptionSelected();
    if (value === 1) {
      dispatch(fetchProductList(ALL_BOOKS_PARAMS));
      navigate(PATH_LISTING, FILTER_NONE_PATH);
    } else {
      dispatch(
        fetchProductList({ ...BOOK_TYPE_PARAMS, isAudioReading: getAudioReadingValue(value) }),
      );
      handleResetCategoryId();
      navigate(PATH_LISTING, { isAudioReading: getAudioReadingValue(value) });
    }
  };

  const handleSortByPriceChange = ({ target: { value } }: RadioChangeEvent) => {
    onOptionSelected();
    handleResetCurrentPage();
    dispatch(setSortByPrice(value));
    if (value === 1) {
      dispatch(fetchProductList(ALL_BOOKS_PARAMS));
      navigate(PATH_LISTING, FILTER_NONE_PATH);
    } else {
      handleDispatchSorting(value);
      handleResetCategoryId();
      navigate(PATH_LISTING, { sortByPrice: getSortPrice(value) });
    }
  };

  const handleNameOrderChange = ({ target: { value } }: RadioChangeEvent) => {
    onOptionSelected();
    handleResetCurrentPage();
    dispatch(setNameOrder(value));
    if (value === 1) {
      dispatch(fetchProductList(ALL_BOOKS_PARAMS));
      navigate(PATH_LISTING, FILTER_NONE_PATH);
    } else {
      handleDispatchSorting(value);
      handleResetCategoryId();
      navigate(PATH_LISTING, { orderByName: getOrderByNameValue(value) });
    }
  };

  const handleSortByOtherCategoriesChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setSortByOtherCategories(value));
    handleResetCurrentPage();
    onOptionSelected();
    if (value === 1) {
      dispatch(fetchProductList(ALL_BOOKS_PARAMS));
      handleResetCategoryId();
      navigate(PATH_LISTING, FILTER_NONE_PATH);
    } else {
      handleDispatchSorting(value);
      handleResetCategoryId();
      navigate(PATH_LISTING, { sortByOtherCategories: getSortByOtherCategories(value) });
    }
  };

  return (
    <SelectFilterOptions
      bookType={bookType}
      sortByPrice={sortByPrice}
      nameOrder={nameOrder}
      sortByOtherCategories={sortByOtherCategories}
      handleBookTypeChange={handleBookTypeChange}
      handleSortByPriceChange={handleSortByPriceChange}
      handleNameOrderChange={handleNameOrderChange}
      handleSortByOtherCategoriesChange={handleSortByOtherCategoriesChange}
    />
  );
};
