import styled from 'styled-components';
import { Pagination } from 'antd';

export const PaginationWrapper = styled(Pagination)`
  .ant-pagination-item-active {
    background-color: ${(props) => props.theme.colors.primary};
    border-color: transparent;
  }

  .ant-pagination-item-active a {
    color: ${(props) => props.theme.colors.button.text} !important;
  }

  .ant-pagination-item {
    border-radius: 0.6rem;
  }

  .ant-pagination-item a {
    color: ${(props) => props.theme.colors.gray['400']};
  }

  .ant-pagination-item-link {
    border-radius: 0.6rem;
  }

  @media (min-width: 375px) and (max-width: 640px) {
    text-align: center;
    margin-top: 2rem;
  }
`;
