import { ICon } from '@interfaces';

export const IconPlus = (props: ICon) => {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <path
        d="M12.5 21.0001C17.4706 21.0001 21.5 16.9706 21.5 12.0001C21.5 7.0295 17.4706 3.00006 12.5 3.00006C7.52944 3.00006 3.5 7.0295 3.5 12.0001C3.5 16.9706 7.52944 21.0001 12.5 21.0001Z"
        stroke="white"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M8.75 12.0001H16.25"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8.25006V15.7501"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
