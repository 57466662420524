import { FC } from 'react';
import styled from 'styled-components';

interface RatingBarProps {
  order: number;
  percent: number;
}

export const RatingBar: FC<RatingBarProps> = ({ order, percent }) => {
  return (
    <StyledRatingBar>
      <div className="order text">{order}星</div>
      <div className="ink-bar">
        <div className="active-bar" style={{ width: `${percent}%` || 0 }}></div>
      </div>
      <div className="percent text">{percent}%</div>
    </StyledRatingBar>
  );
};

const StyledRatingBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text {
    font-weight: ${(p) => p.theme.weight['400']};
    font-size: ${(p) => p.theme.size.s};
    line-height: 20px;
    color: ${(p) => p.theme.colors.text};
  }
  .ink-bar {
    width: calc(100% - 70px);
    margin: 0 12px;
    height: 12px;
    border-radius: 40px;
    //background-color: #FD7E14;
    background-color: #d9d9d9;
    transform: translateY(1px);
    position: relative;
    overflow: hidden;
    .active-bar {
      background-color: #fd7e14;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .percent {
    width: 40px;
    min-width: 40px;
  }
  .order {
    width: 25px;
    min-width: 25px;
  }
`;
