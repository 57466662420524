import styled, { css } from 'styled-components';

export const StyleSkeletonCardItem = styled.div`
  width: 100%;

  .skeleton-img {
    width: 100%;
    height: 24rem;
  }

  .ant-skeleton-element .ant-skeleton-input {
    min-width: unset;
    height: 2rem;
  }

  .skeleton-input-title {
    margin-top: 0.8rem;
    width: 80%;
  }

  .skeleton-input-subtitle {
    margin-top: 0.8rem;
    width: 50%;
  }
`;
export const StyledSkeletonDetail = styled.div`
  display: flex;
  @media (max-width: 768px) {
    display: block;
  }
  gap: 10px;

  .skeleton-image {
    .ant-skeleton-element .ant-skeleton-image {
      width: 28rem;
      height: 40rem;
      @media (max-width: 768px) {
        margin-bottom: 2rem;
      }
    }
  }
  .large {
    .ant-skeleton-element .ant-skeleton-image {
      width: 28rem;
      height: 40rem;
    }
  }
  .regular {
    .ant-skeleton-element .ant-skeleton-image {
      width: 19rem;
      height: 24rem;
    }
  }

  .skeleton-input {
    width: 100%;

    .skeleton-input-title {
      margin-bottom: 10px;
      width: 80%;
    }

    .margin {
      margin-bottom: 10px;
    }

    .skeleton-input-subtitle {
      margin-bottom: 10px;

      &-first {
        width: 70%;
      }

      &-second {
        width: 50%;
      }

      &-third {
        width: 30%;
      }

      &-fourth {
        width: 10%;
      }
    }
  }
`;
