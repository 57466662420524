import { SharedSkeleton, StyledBookPurchase } from '@components';

export const SkeletonBookDetailDesktop = () => {
  return (
    <StyledBookPurchase className="book-purchase">
      <div className="heading">
        <h3 className="heading__title">
          <SharedSkeleton type={'input'} inputClassName="title_text" active />
        </h3>
        <p className="price">
          <SharedSkeleton type={'input'} inputClassName="title_price" active />
        </p>
      </div>
      <div className="actions heading">
        <SharedSkeleton type={'input'} inputClassName="btn" active />
        <SharedSkeleton type={'input'} inputClassName="btn" active />
        <SharedSkeleton type={'input'} inputClassName="btn" active />
      </div>
    </StyledBookPurchase>
  );
};

export const SkeletonBookDetailMobile = () => {
  return (
    <StyledBookPurchase className="book-purchase">
      <div className="heading">
        <h3 className="heading__title">
          <SharedSkeleton type={'input'} inputClassName="title_text" active />
        </h3>
        <p className="price">
          <SharedSkeleton type={'input'} inputClassName="title_price" active />
        </p>
      </div>
      <div className="actions">
        <SharedSkeleton type={'input'} active />
        <SharedSkeleton type={'input'} active />
        <SharedSkeleton type={'input'} active />
      </div>
    </StyledBookPurchase>
  );
};
