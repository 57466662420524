import axiosClient from './axiosClient';
import { CommentRatingRequestProps } from '@interfaces';

const url = 'api/Review';

export const bookReviewApi = {
  getReviewList: (paramsSearch: string) => {
    return axiosClient.get(`${url}${paramsSearch}`);
  },
  sendComment: (rqBody: CommentRatingRequestProps) => {
    return axiosClient.post(url, rqBody);
  },
  deleteComment: (productId: number) => {
    return axiosClient.delete(`${url}?id=${productId}`);
  },
};
