import { StyleContainer, StyledHeader, StyledDescription, NotificationPageProps } from './style';
import { IMAGES } from '@assets';
import { SharedButton } from '@components';
import { useEffect } from 'react';

export const NotificationPage = (prs: NotificationPageProps) => {
  const {
    buttonText,
    buttonColor = '#D42A1C',
    headerText,
    onButtonClick,
    headerAlignment = 'left',
    description,
    buttonType = 'rounded',
    descriptionAlignment,
    icon,
  } = prs;
  return (
    <StyleContainer background={IMAGES.backgroundLogin}>
      <div className="header__logo logo app-container">
        <img title="" src={IMAGES.headerLogo} alt="home" />
      </div>
      <div className="overlay-inset-all"></div>
      <div className="redirect-notify">
        {icon && <div className="icon-header">{icon}</div>}
        <StyledHeader className="base-header" headerAlignment={headerAlignment}>
          {headerText}
        </StyledHeader>
        <StyledDescription
          className="base-desc desc-spacing"
          descriptionAlignment={descriptionAlignment}
        >
          {description}
        </StyledDescription>
        <SharedButton
          typeHtml="button"
          text={buttonText}
          btnStyle={buttonType}
          bgColor={buttonColor}
          onClick={onButtonClick}
        />
      </div>
    </StyleContainer>
  );
};
