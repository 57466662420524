import React from 'react';
import styled from 'styled-components';
import { SharedSkeleton } from '@components';

export const SkeletonCategory = () => {
  return (
    <StyledSkeletonCategory>
      <SharedSkeleton type={'image'} imageClassName="skeleton-image" active></SharedSkeleton>
      <SharedSkeleton type={'input'} inputClassName="skeleton-input" active></SharedSkeleton>
    </StyledSkeletonCategory>
  );
};

const StyledSkeletonCategory = styled.div`
  text-align: center;
  .skeleton-image {
    .ant-skeleton-image {
      width: 60px;
      height: 60px;
    }
  }
  .skeleton-input {
    margin-top: 5px;
    height: 24px;
    @media (max-width: 640px) {
      min-width: 11rem;
      width: 11rem;
    }
  }
`;
