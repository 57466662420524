import React from 'react';
import { StyledContentTerm, StyledTerm } from './style';
import { useMediaQuery } from '@utils';

export const Term = () => {
  const isMobile = useMediaQuery('(max-width:640px)');
  return (
    <StyledTerm>
      <div className="header-term">特商法・表記</div>
      <StyledContentTerm isMobile={isMobile}>
        <div className="title">ソフトウェア利用許諾契約</div>
        <div className="contract">
          <p>
            三和書籍有限会社（以下「弊社」といいます）は、お客様に、以下の条件で本ソフトの使用を許諾します。
          </p>
          <p>（契約の成立）</p>
        </div>
        <div className="contract">
          <p>第１条</p>
          <p>
            お客様が、本件ソフトウェアの全部又は一部をコンピュータのハードディスク等の記憶装置へ保存したとき、又は本件ソフトウェアを使用したときは、本契約の締結に同意したものとみなされ、本契約は成立し効力を生ずるものとします。
          </p>
          <p>（契約の成立）</p>
        </div>
        <div className="contract">
          <p>第２条</p>
          <p>本ソフトに関する著作権等の知的財産権は、すべて弊社に帰属します。</p>
          <p>（契約の成立）</p>
        </div>
        <div className="contract">
          <p>第３条</p>
          <p>
            １．
            弊社は、お客様に対し、本契約の条項に従って、本ソフトを使用する非独占的な権利を許諾します
            <br />
            ２．
            お客様は、お客様のコンピュータに搭載されたハードディスクその他の記憶装置に本ソフトをインストールし、使用することができます。
          </p>
          <p>（契約の成立）</p>
        </div>
        <div className="contract">
          <p>第４条</p>
          <p>
            １． お客様は、本ソフトの全部又は一部を複製することはできません
            <br />
            ２．
            お客様は、本ソフトの改変、リバースエンジニアリング、逆コンパイル又は逆アセンブルをすることはできません
            <br />
            ３．
            お客様には、第三者に対し本ソフトの使用を許諾し、又は第三者に対し本ソフトを販売、貸与若しくはリースすることはできません。（免責）
          </p>
        </div>
        <div className="contract">
          <p>第5条</p>
          <p>
            １．
            弊社は、お客様に対し、本ソフトに関して、その動作、商品性、特定用途への適合性その他一切の保証を行いません
            <br />
            ２．
            弊社は、お客様及び第三者が本ソフトに関連して直接又は間接に被ったいかなる損害についても、一切責任を負いません。お客様は、本ソフトの使用に関連して第三者からお客様になされた請求に関連する損害、損失あるいは責任より弊社を免責するものとします。
          </p>
          <p>（契約の成立）</p>
        </div>
        <div className="contract">
          <p>第6条</p>
          <p>
            １．
            お客様は、本ソフトをコンピュータのハードディスク等の記憶装置及びメモリーからすべて消去することにより、本契約を終了させることができます
            <br />
            ２．
            お客様が本契約のいずれかの条項に違反したときは、弊社は、お客様に対し何らの通知、催告を行うことなく直ちに本契約を終了させることができます。
          </p>
          <p>（合意管轄）</p>
        </div>
        <div className="contract">
          <p>第7条</p>
          <p>
            本契約に関する一切の紛争については、東京地方裁判所を第一審の専属管轄裁判所とします。
          </p>
        </div>
        <p className="issued">
          <p className="date">2021</p>年7月制定
        </p>
      </StyledContentTerm>
    </StyledTerm>
  );
};
