import React, { useEffect, useState } from 'react';
import { ItemDetail, PurchaseItemWrapper, PurchaseTitle, Text } from './style';
import { BookItem } from '../BookItem';
import { BookItemData, PurchaseHistoryDataType } from '@interfaces';
import { Simulate } from 'react-dom/test-utils';
import { getPCStatusActiveColor, getStatusActiveColor, LogApp, timeConverter } from '@utils';

interface PurchaseItemProps {
  data: PurchaseHistoryDataType;
}

export const PurchaseItem: React.FC<PurchaseItemProps> = (props) => {
  const { data } = props;
  const {
    paymentMethod,
    authorizationTransactionId,
    createdOn,
    orderTotal,
    orderStatus,
    id,
    orderItemModels,
    customOrderNumber,
    isComplete,
  } = data;
  LogApp('check9999', data);

  const [bookList, setBookList] = useState<BookItemData[]>([]);
  useEffect(() => {
    const listBook = orderItemModels.map((data) => ({
      id: data.id,
      bookName: data.productName,
      authorName: data.authorNames,
      price: data.unitPrice,
      pictureMobileModel: {
        imageUrl: data.defaultPictureModel.imageUrl,
        title: data.defaultPictureModel.title,
      },
      isAudioBook: data.audioReading,
      productId: data.productId,
      vendorName: data.vendorName,
      audioReading: data.audioReading,
      published: data.published,
    })) as BookItemData[];
    setBookList(listBook);
  }, [orderItemModels]);

  return (
    <PurchaseItemWrapper>
      <PurchaseTitle>
        <Text
          className="no-responsive"
          textColor={getPCStatusActiveColor(isComplete, 'color')}
          textWeight={500}
        >
          {customOrderNumber}
        </Text>
        <Text
          className="no-responsive"
          textColor={getPCStatusActiveColor(isComplete, 'color')}
          textWeight={500}
        >
          {getPCStatusActiveColor(isComplete, 'text')}
        </Text>
      </PurchaseTitle>

      <div className="df-wrapper px-16">
        <div className="mr-l-4">
          <Text className="no-responsive" isTitle>
            購入日時:
          </Text>
          <Text className="no-responsive" isTitle>
            支払金額:
          </Text>
          <Text className="no-responsive" isTitle>
            決済方法:
          </Text>
          <Text className="no-responsive" isTitle>
            認証トランザクションID:
          </Text>
        </div>
        <div>
          <Text className="detail">{timeConverter(createdOn)}</Text>
          <Text className="detail" textColor="#D42A1C" textWeight={700}>
            {orderTotal}
          </Text>
          <Text className="detail">{paymentMethod}</Text>
          <Text className="detail">{authorizationTransactionId}</Text>
        </div>
      </div>

      <ItemDetail className="px-16">
        {bookList.map((item) => {
          return (
            <div key={item.id} className="purchase-book-item">
              <BookItem
                removeFromWishlist={() => {}}
                type="purchase"
                data={item as any}
                isPurchasedBook={true}
              />
            </div>
          );
        })}
      </ItemDetail>
    </PurchaseItemWrapper>
  );
};
