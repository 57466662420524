import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ICONS } from '@assets';
import { SharedButton } from '@components';
import { PATH_HOME } from '@configs';

interface SearchEmptyBoxProps {
  text?: string;
  hasNavBtn?: boolean;
}

export const SearchEmptyBox: FC<SearchEmptyBoxProps> = ({
  hasNavBtn = true,
  text = 'ウィッシュリストに書籍がありません。',
}) => {
  return (
    <StyledSearchEmptyBox className="empty-box">
      <img src={ICONS.emptyBooks} alt="" />
      <div className="explain-text">{text}</div>
      {hasNavBtn && (
        <Link to={PATH_HOME}>
          <SharedButton
            text="今すぐ本を探そう！"
            bgColor="#D42A1C"
            className="home-nav-btn"
            btnStyle="basic"
          />
        </Link>
      )}
    </StyledSearchEmptyBox>
  );
};

export const StyledSearchEmptyBox = styled.div`
  width: 100%;
  &.empty-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 4.9375rem 0;
    .explain-text {
      font-weight: ${(props) => props.theme.weight['500']};
      margin-top: 2.125rem;
      margin-bottom: 3rem;
      font-size: ${(props) => props.theme.size.s};
    }
    .home-nav-btn {
      width: fit-content;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`;
