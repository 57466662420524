import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { LoginComponent } from '@components';
import { PATH_FORGOT_PASSWORD, PATH_HOME, PATH_SIGN_UP } from '@configs';
import { getFromLS, LogApp } from '@utils';
import { ILogin, ILoginRes, BaseResponseProps } from '@interfaces';
import {
  RootState,
  setAccessToken,
  setLoading,
  setRefreshToken,
  setUserInfo,
  setUserRole,
  useAppDispatch,
} from '@redux';
import { authAPI } from '@api';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const LoginModule = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    auth: { accessToken },
  } = useSelector((state: RootState) => state);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email('入力した電子メールアドレスは無効です。もう一度やり直してください。')
        .required('メールアドレスが必要です'),
      password: Yup.string().trim().required('パスワードが必要です'),
    }),
    onSubmit: (values: ILogin) => {
      handleLogin(values);
    },
  });

  const handleLogin = async (values: ILogin) => {
    const body = {
      Email: values.email,
      Password: values.password,
      FirebaseToken: 'null',
      DeviceId: 'null',
      ClientId: 'null',
      ClientSecret: 'null',
      OsPlatform: 'null',
    };
    const memoPath = getFromLS('memoPath');
    try {
      dispatch(setLoading(true));
      const res = (await authAPI.login(body)) as unknown as BaseResponseProps<ILoginRes>;
      const data = res?.data;
      if (res?.success) {
        dispatch(setUserInfo(data?.customer));
        dispatch(setAccessToken(data?.accessToken.tokenString));
        dispatch(setRefreshToken(data?.refreshToken.tokenString));
        dispatch(setUserRole(data?.role));
        dispatch(setLoading(false));
        if (memoPath) {
          navigate(memoPath);
          localStorage.removeItem('memoPath');
          dispatch(setLoading(false));
        } else {
          navigate(PATH_HOME);
          dispatch(setLoading(false));
        }
      } else {
        // formik.setErrors({ password: res.messages || 'Invalid username or password ' });
        toast.error(res.messages || 'Invalid username or password');
        dispatch(setLoading(false));
      }
    } catch (err: any) {
      dispatch(setLoading(false));
      formik.setErrors({ password: err?.message || 'Invalid username or password ' });
      return { slotId: '', studentId: '' };
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (accessToken) {
      navigate(PATH_HOME);
    }
  }, []);

  const handleRedirectToSignUp = () => {
    navigate(PATH_SIGN_UP);
  };

  const handleRedirectToForgot = () => {
    navigate(PATH_FORGOT_PASSWORD);
  };

  const handleBackToHome = () => {
    navigate(PATH_HOME);
  };

  return (
    <LoginComponent
      formik={formik}
      redirectToSignUp={handleRedirectToSignUp}
      redirectToForgot={handleRedirectToForgot}
      backToHome={handleBackToHome}
    />
  );
};
