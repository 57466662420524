import React, { forwardRef, memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ArrowToTop } from '../../Icon';
import { SharedButton } from '../button';

export const ScrollToTop = (props: any) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location?.pathname]);
  return children;
};

export const ScrollToTopComponent = (props: any) => {
  const { children, dependencies } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [dependencies]);
  return children;
};

export const ScrollToTopUsingRef = forwardRef((props: any, ref: any) => {
  const { children, dependencies } = props;

  useEffect(() => {
    ref.current.scrollTo({ top: 0, left: 0 });
  }, [dependencies, ref]);
  return children;
});

export const ButtonScrollToTop = memo(() => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleToggle, true);
    return () => window.removeEventListener('scroll', handleToggle);
  }, []);

  const handleToggle = () => {
    const scrolled = document.body.scrollTop;
    if (scrolled > 400) {
      setVisible(true);
    } else if (scrolled <= 400) {
      setVisible(false);
    }
  };

  const handleScrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledButtonScroll>
      {visible && (
        <SharedButton
          className="btn-scroll-to-top"
          onClick={handleScrollToTop}
          prevIcon={<ArrowToTop />}
        />
      )}
    </StyledButtonScroll>
  );
});

const StyledButtonScroll = styled.div`
  .btn-scroll-to-top {
    position: fixed;
    z-index: 10;
    bottom: 5.5rem;
    right: 3.7rem;
    width: 6rem;
    height: 6rem;
    background-color: ${(p) => p?.theme.colors?.primary};

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      right: 1rem;
    }
  }
`;
