import { IMAGES } from '@assets';
import styled, { css } from 'styled-components';

export const SubscribeWrapper = styled.div<{ wrap?: boolean }>`
  @media (min-width: 640px) {
    margin-top: -4.6rem;
    .subs-img__wrapper {
      position: relative;
      display: flex;
      padding-top: 1.6rem;
      overflow: hidden;
      // justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 4rem;
      background: url(${IMAGES.bgBookshelves});
      /* background-size: cover; */
      background-repeat: repeat;
    }

    .subs-filter-title {
      color: ${(props) => props.theme.colors.text};
      font-weight: ${(props) => props.theme.weight['700']};
      font-size: ${(props) => props.theme.size.s};
      margin-bottom: 2.4rem;
    }

    .hr {
      width: 100%;
      border-color: ${(props) => props.theme.colors.gray['700']};
      margin: 4rem 0;
    }

    .bookshelves-bar {
      width: 100%;
      margin-bottom: 0.9rem;
    }
    .bookshelves-bar-bottom {
      width: 100%;
    }

    .bookshelves-head-bar {
      width: 100%;
      position: absolute;
      top: -1.6rem;
    }
  }

  @media (max-width: 640px) {
    .title {
      display: flex;
      justify-content: space-between;
      .subs-filter-title {
        margin-bottom: 4px;
        color: ${(props) => props.theme.colors.text};
        font-weight: ${(props) => props.theme.weight['700']};
        font-size: ${(props) => props.theme.size.s};
      }
      .button-right-vector {
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
          color: #359f08;
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;
        }
        img {
          width: 4px;
          height: 8px;
          margin-left: 3px;
        }
      }
    }

    .hr {
      width: 100%;
      border-color: ${(props) => props.theme.colors.gray['700']};
    }

    .bookshelves-bar,
    .bookshelves-head-box,
    .bookshelves-bar-bottom,
    .bookshelves-head-bar {
      display: none;
    }
  }
`;

export const SubsImageWrapper = styled.div<{ $multiRows?: boolean }>`
  @media (min-width: 640px) {
    ${({ $multiRows }) =>
      !$multiRows
        ? css`
            background-size: 100% 100% !important;
          `
        : css`
            background-size: 100% 20% !important;
          `}
  }
`;

export const ImageWrapper = styled.div<{ isLastItem?: boolean }>`
  @media (max-width: 640px) {
    width: 50%;
    padding: 0 7.5px;
    margin: 20px 0;
    img {
      width: 100%;
      height: 22.7rem;
    }
  }

  @media (min-width: 641px) {
    /* width: calc(100% / 6 - 1.5rem);
    margin: 0 0.75rem; */
    width: calc(100% / 6 - 3.5rem);
    margin: 0 1.75rem;
    img {
      height: 100%;
      width: 100%;
    }

    &:last-child {
      margin-right: ${(props) => (props.isLastItem ? 0 : 'inherit')};
    }
  }
`;

export const Border = styled.div<{ borderColor?: string; mb?: string }>`
  border-bottom: 1px solid ${(props) => props.borderColor || props.theme.colors.gray['200']};
  margin-bottom: ${(props) => props.mb || '2.4rem'};
  margin-top: ${(props) => props.mb || '2.4rem'};
`;

export const ListItem = styled.div`
  .subs-img__wrapper {
    display: flex;
    flex-wrap: wrap;
    /* margin: 0 -7.5px; */
    width: 100%;
    @media (max-width: 640px) {
      margin: 0 !important;
    }
    .item-skeleton {
      width: 100%;
      height: 20rem;
    }
  }
  @media (max-width: 640px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
