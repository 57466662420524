import { ICon } from '@interfaces';

export const BookOpenIcon = (props: ICon) => {
  const { className, color = '#fff', width = 25, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M12.75 8.25006C12.75 7.45441 13.0661 6.69135 13.6287 6.12874C14.1913 5.56613 14.9544 5.25006 15.75 5.25006H21.75C21.9489 5.25006 22.1397 5.32908 22.2803 5.46973C22.421 5.61038 22.5 5.80115 22.5 6.00006V18.0001C22.5 18.199 22.421 18.3897 22.2803 18.5304C22.1397 18.671 21.9489 18.7501 21.75 18.7501H15.75C14.9544 18.7501 14.1913 19.0661 13.6287 19.6287C13.0661 20.1914 12.75 20.9544 12.75 21.7501"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 18.0001C3 18.199 3.07902 18.3897 3.21967 18.5304C3.36032 18.671 3.55109 18.7501 3.75 18.7501H9.75C10.5456 18.7501 11.3087 19.0661 11.8713 19.6287C12.4339 20.1914 12.75 20.9544 12.75 21.7501V8.25006C12.75 7.45441 12.4339 6.69135 11.8713 6.12874C11.3087 5.56613 10.5456 5.25006 9.75 5.25006H3.75C3.55109 5.25006 3.36032 5.32908 3.21967 5.46973C3.07902 5.61038 3 5.80115 3 6.00006V18.0001Z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
