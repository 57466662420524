import { SelectDatePicker } from '@components';
import { LogApp } from '@utils';
import { useCallback, useEffect, useState } from 'react';

interface IProps {
  currentDate?: Date | null;
  onDateChange: (date: Date | null) => void;
}

export const SelectDateOfBirthModule = (props: IProps) => {
  const [date, setDate] = useState<Date | null>();
  // LogApp('date', String(date));

  const onDateChange = useCallback((dateParams: Date | null) => {
    setDate(dateParams);
    props.onDateChange(dateParams ? dateParams : null);
  }, []);

  return <SelectDatePicker value={props.currentDate} onDateChange={onDateChange} hideLabels />;
};
