import { useLayoutEffect } from 'react';

import { InnerApp } from '@components';
import { IInnerAppProps } from '@interfaces';
import { selectApp, setLoading, useAppDispatch, useAppSelector } from '@redux';

export const InnerAppModule = (props: IInnerAppProps) => {
  const { loading } = useAppSelector(selectApp);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (loading) {
      dispatch(setLoading(false));
    }
  }, []);

  return <InnerApp {...props} />;
};
