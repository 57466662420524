import styled from 'styled-components';

export const StyledDivider = styled.div<{ height: string; mobileHeight?: string }>`
  width: 100%;
  height: ${(props) => props.height || '1rem'};
  @media (max-width: 640px) {
    height: ${(props) => props.mobileHeight || '3.2rem'};
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn.button-item {
    width: calc(50% - 4px);
  }
`;

export const StyleContainer = styled.div<{ background?: string }>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: 100% 100%;
  background-image: ${(p) => `url(${p?.background})`};
  @media screen and (max-width: 640px) {
    background-position-x: 50%;
    background-size: 200% 100%;
  }

  .header__logo {
    padding-top: calc(17 / 360 * 100%);
    height: fit-content;
    @media (max-width: 640px) {
      padding-top: 4.6rem;
      margin: 0 auto 2rem;
      width: 15rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .redirect-notify {
    background-color: ${(props) => props?.theme.colors.body};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    padding: 4rem;
    border-radius: 8px;
    @media (min-width: 641px) {
      width: 547px;
    }
    @media (max-width: 575px) {
      width: 100%;
      top: 106px;
      left: 0;
      transform: translate(0, 0);
      height: calc(100vh - 106px);
      border-radius: 8px 8px 0 0;
      padding: 3.6rem 2.1rem 0 2rem;
    }
  }
  .desc-spacing {
    margin: 0.8rem 0 2.8rem 0;
  }
`;
