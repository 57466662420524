import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { ButtonPayment, StyledCard, StyledPayment, StyleInfoCard, StyleTotal, Text } from './style';
import { ICONS } from '@assets';
import { SharedButton, ShareInput } from '@components';
import { currency, useMediaQuery } from '@utils';
import { useDispatch } from 'react-redux';
import { BookInCartProps, PaymentProps } from '@interfaces';
import { monthSelect, yearSelect } from './Payment.const';
import { AutoComplete, Button } from 'antd';
import {
  checkSubscriptionProduct,
  decrement,
  getAllBooksInCart,
  paymentAll,
  resetDataCheckout,
  selectCart,
  selectCheckout,
  selectSubscription,
  subscribeSubscriptionProduct,
  useAppSelector,
} from '@redux';
import { useNavigate } from 'react-router-dom';
import { PATH_CHECKOUT_COMPLETE, PATH_SUBSCRIPTION } from '@configs';

const StyleBtnBack = (isMobile: boolean) => ({
  width: isMobile ? '7.1rem' : '17.5rem',
  height: '4.1rem',
  borderRadius: '.4rem',
  lineHeight: '17px',
  fontWeight: '500',
  borderColor: '#B6B6B6',
  textColor: '#6C757D',
});

interface PaymentCompProps {
  paymentType?: 'checkout' | 'subscription';
}

export const Payment: FC<PaymentCompProps> = ({ paymentType = 'checkout' }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 640px)');

  const [cardNumber, setCardNumber] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cvv, setCvv] = useState('');
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const { loading, data } = useAppSelector(selectCheckout);
  const {
    data: { cart },
  } = useAppSelector(selectCart);
  const { subscribe } = useAppSelector(selectSubscription);

  useEffect(() => {
    subscribe.data && subscribe.data.success && dispatch(checkSubscriptionProduct());
  }, [subscribe]);

  useEffect(() => {
    if (data && data.success) {
      dispatch(getAllBooksInCart());
      navigate(PATH_CHECKOUT_COMPLETE);
    }
  }, [data]);
  useEffect(() => {
    return () => {
      dispatch(resetDataCheckout(null));
    };
  });

  const handlePaymentSubmit = (e: any) => {
    e.preventDefault();
    const body: PaymentProps = {
      CardNumber: cardNumber,
      ExpireYear: year,
      CardholderName: cardHolder,
      CardCode: cvv,
      ExpireMonth: month,
    };
    if (!cardNumber.length || !year.length || !cardHolder.length || !cvv.length || !month.length) {
      setError(true);
    } else {
      //! ready to submit
      switch (paymentType) {
        case 'checkout': {
          dispatch(paymentAll(body));
          break;
        }
        case 'subscription': {
          dispatch(subscribeSubscriptionProduct(body as any));
          break;
        }
        default:
          break;
      }
      setError(false);
    }
  };

  const onlyNumberSetter = (value: string, setter: SetStateAction<any>, maxlength?: number) => {
    const regexOnlyNumber = /^[0-9\b]+$/;
    if (!maxlength) {
      if (value === '' || regexOnlyNumber.test(value)) {
        setter(value);
      }
    } else {
      if (value === '' || (regexOnlyNumber.test(value) && value.length <= maxlength)) {
        setter(value);
      }
    }
  };

  return (
    <StyledPayment isMobile={isMobile}>
      <form onSubmit={handlePaymentSubmit}>
        <div className="payment-container">
          <StyledCard isMobile={isMobile}>
            <div className="item-card">
              <img src={ICONS.visaIcon} alt="Visa" />
            </div>
            <div className="item-card">
              <img src={ICONS.masterCardIcon} alt="MasterCard" />
            </div>
            <div className="item-card">
              <img src={ICONS.jcbCardIcon} alt="JCB" />
            </div>
            <div className="item-card">
              <img src={ICONS.dinerClubIcon} alt="DinerClub" />
            </div>
          </StyledCard>
          {paymentType === 'checkout' ? (
            <StyleTotal>
              <div className="pay">
                <Text noMarginBottom noMarginTop>
                  支払金額:
                </Text>
                <Text noMarginBottom noMarginTop fontSize color="#D42A1C" fontWeight>
                  {cart?.data?.items
                    ? currency(cart?.data?.items.reduce((acc, cur) => acc + cur.price, 0))
                    : ''}
                </Text>
              </div>
            </StyleTotal>
          ) : null}

          <StyleInfoCard>
            <Text>カード番号*:</Text>
            <ShareInput
              placeholder="カード番号"
              onChange={(e: any) => onlyNumberSetter(e.target.value, setCardNumber, 16)}
              value={cardNumber}
            />
            {error && !cardNumber.length && <div className="input-error">カード番号が必要です</div>}
            <Text>有効期限*:</Text>
            <div className="date-expiry">
              <AutoComplete
                size="large"
                className="select-date"
                options={monthSelect}
                value={month}
                placeholder="月"
                onChange={(e) => setMonth(e)}
              />
              <AutoComplete
                size="large"
                value={year}
                className="select-date"
                options={yearSelect}
                placeholder="年"
                onChange={(e) => setYear(e)}
              />
            </div>
            <div className="date-expiry">
              {error && !month.length && (
                <div className="select-date input-error">月が必要です</div>
              )}
              {error && !year.length && <div className="select-date input-error">年が必要です</div>}
            </div>
            <Text>カードホルダー*:</Text>
            <ShareInput
              value={cardHolder}
              onChange={(e: any) => setCardHolder(e.target.value)}
              placeholder="カード名義人"
            />
            {error && !year.length && <div className="input-error">カード名義人必須</div>}
            <Text>CVV*:</Text>
            <div className="pass-card">
              <div className="input-password">
                <ShareInput
                  value={cvv}
                  type="password"
                  onChange={(e: any) => onlyNumberSetter(e.target.value, setCvv, 3)}
                  placeholder="***"
                />
              </div>
              <Text noMarginBottom noMarginTop>
                カード裏面に3桁のセキュリティコード
              </Text>
            </div>
            {error && !year.length && <div className="input-error">CVVが必要です</div>}
          </StyleInfoCard>
          {isMobile && <div className="divider"></div>}
          <ButtonPayment isMobile={isMobile}>
            <SharedButton
              text="戻る"
              style={StyleBtnBack(true)}
              borderColor="#B6B6B6"
              textColor="#6C757D"
              onClick={() => {
                paymentType === 'checkout' ? dispatch(decrement()) : navigate(PATH_SUBSCRIPTION);
              }}
            />
            <Button
              loading={paymentType === 'checkout' ? loading : subscribe.loading}
              htmlType="submit"
              className="button-with-loading checkout-submit"
            >
              お支払い
            </Button>
          </ButtonPayment>
        </div>
      </form>
    </StyledPayment>
  );
};
