// import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { closeToast, selectToast, useAppSelector } from 'src/redux';

export const ComponentToast = () => {
  //page hooks
  // const toast = useAppSelector(selectToast).toast;
  // const dispatch = useDispatch();

  // const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  //     if (reason === 'clickaway') {
  //         return;
  //     }

  //     dispatch(closeToast());
  // };
  return (
    <>
      {/* <Snackbar
                anchorOrigin={{
                    vertical: toast.vertical || "top",
                    horizontal: toast.horizontal || "right",
                }}
                open={toast.open}
                autoHideDuration={toast.autoHideDuration}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={toast.type}>
                    {toast.message}
                </Alert>
            </Snackbar> */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        limit={1}
      />
    </>
  );
};
