import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchBestSellerProduct, fetchProductList, fetchSuggestedData } from '../action';
import { ProductDataType } from '@interfaces';
import { RootState } from '@redux';

interface StateType {
  isLoading: boolean;
  data: {
    recommendedBooks: ProductDataType[];
    bestSellerBooks: ProductDataType[];
    lastedBooks: ProductDataType[];
    productSearchResults: ProductDataType[];
    productTypeList: ProductDataType[];
    allBooks: ProductDataType[];
    selectedSearchData: ProductDataType[];
  };
  total: number | null;
  booksAndAuthorsParams: string | null;
  categoryId: null | number;
  recommendedBooksTotal: number;
  bestSellerBooksTotal: number;
  lastedBooksTotal: number;
  searchTotalItem: number;
  currentPage: number;
  selectedBooksAndAuthorsParams: string | null;
}

const initialState: StateType = {
  isLoading: false,
  data: {
    recommendedBooks: [],
    bestSellerBooks: [],
    lastedBooks: [],
    productSearchResults: [],
    productTypeList: [],
    allBooks: [],
    selectedSearchData: [],
  },
  total: null,
  booksAndAuthorsParams: null,
  categoryId: null,
  recommendedBooksTotal: 0,
  bestSellerBooksTotal: 0,
  lastedBooksTotal: 0,
  searchTotalItem: 0,
  currentPage: 1,
  selectedBooksAndAuthorsParams: null,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    getSearchValue: (state, action: PayloadAction<string>) => {
      state.booksAndAuthorsParams = action.payload;
    },
    getCategoryId: (state, action: PayloadAction<number>) => {
      state.categoryId = action.payload;
    },
    resetCategoryId: (state, action: PayloadAction<null>) => {
      state.categoryId = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    resetCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSelectedSearchValue: (state, action: PayloadAction<string>) => {
      state.selectedBooksAndAuthorsParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get recommended book and latest book
      .addCase(fetchProductList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.meta.arg.isHighlightBooks) {
          state.data.recommendedBooks = action?.payload?.data;
          state.recommendedBooksTotal = action.payload.total;
        } else if (action.meta.arg.isLastestBooks) {
          state.data.lastedBooks = action.payload.data;
          state.lastedBooksTotal = action.payload.total;
        } else if (action.meta.arg.bookOrAuthorName && action.meta.arg.bookOrAuthorName.length) {
          state.data.productSearchResults = action?.payload?.data;
          state.total = action?.payload?.total;
        } else if (action.meta.arg.categoryIds) {
          state.data.productTypeList = action?.payload?.data;
          state.total = action?.payload?.total;
        } else if (action.meta.arg.priceMin && action.meta.arg.priceMax) {
          state.data.productTypeList = action?.payload?.data;
          state.total = action.payload.total;
        } else if (action.meta.arg.isAudioReading !== undefined) {
          state.data.productTypeList = action?.payload?.data;
          state.total = action.payload.total;
        } else if (action.meta.arg.ascending || action.meta.arg.descending) {
          state.data.productTypeList = action?.payload?.data;
          state.total = action.payload.total;
        } else if (action.meta.arg.orderByName) {
          state.data.productTypeList = action?.payload?.data;
          state.total = action.payload.total;
        } else if (action.meta.arg.productSorting) {
          state.data.productTypeList = action?.payload?.data;
          state.total = action.payload.total;
        } else if (action.meta.arg.length || action.meta.arg.start >= 0) {
          state.data.allBooks = action?.payload?.data;
          state.total = action?.payload?.total;
        }
      })
      .addCase(fetchProductList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchBestSellerProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBestSellerProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.bestSellerBooks = action.payload.data;
        state.bestSellerBooksTotal = action.payload.total;
      })
      .addCase(fetchBestSellerProduct.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchSuggestedData.pending, (state) => {})
      .addCase(fetchSuggestedData.fulfilled, (state, action) => {
        state.data.selectedSearchData = action.payload.data;
        state.searchTotalItem = action.payload.total;
      })
      .addCase(fetchSuggestedData.rejected, (state) => {});
  },
});
export const {
  getSearchValue,
  getCategoryId,
  resetCategoryId,
  setCurrentPage,
  resetCurrentPage,
  setSelectedSearchValue,
} = productSlice.actions;
export const selectProduct = (state: RootState) => state.product;
export default productSlice.reducer;
