import { CloseIcon, CustomDrawer, SharedButton, ShareInput, TextContentViewer } from '@components';
import { StyleMobileReadBook, HeaderReadBook, ToolBar, NumberPage, NameBook } from './style';
import { IMAGES } from '@assets';
import { useTheme } from '@theme';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { LogApp, useMediaQuery } from '@utils';
import { selectApp, useAppSelector } from '@redux';
interface IProps {
  title?: string;
  fileUrl?: string;
  onDocumentLoadSuccess?: (value: any) => void;
  pageOne?: any;
  pageTwo?: any;
  bookCoverImage?: string;
  speakCharIndex?: number;
  speakCharLength?: number;
  isSpeaking?: boolean;
  scaleInner?: number;
  onChangePage: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onClickPrev: () => void;
  onClickNext: () => void;
  topActions?: JSX.Element | JSX.Element[];
  settingContent?: JSX.Element | JSX.Element[];
  currentPage: number;
  disabledNextButton?: boolean;
  disablePrevButton?: boolean;
  openSettingSpeak: boolean;
  isTextBooks?: boolean;
  totalPage: number;
  bookDefaultContent?: string;
  pageIndex: number;
  onPressEnter?: (e: any) => void;
  onCancelReadBook: () => void;
  onCloseSpeakSetting: () => void;
  onSwipeStart: (e: any) => void;
  onSwipeMove: (e: any) => void;
  onSwipeEnd: (e: any) => void;
}

export const MobileReadBook = forwardRef((props: IProps, ref: React.Ref<any>) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const navigate = useNavigate();
  const {
    title,
    fileUrl,
    pageOne,
    pageTwo,
    isTextBooks,
    bookCoverImage,
    bookDefaultContent,
    topActions,
    settingContent,
    openSettingSpeak,
    speakCharIndex,
    speakCharLength,
    isSpeaking,
    pageIndex,
    onDocumentLoadSuccess,
    onChangePage,
    onClickPrev,
    onClickNext,
    currentPage,
    disabledNextButton,
    disablePrevButton,
    totalPage,
    onPressEnter,
    onCancelReadBook,
    onCloseSpeakSetting,
    onSwipeStart,
    onSwipeMove,
    onSwipeEnd,
    scaleInner,
  } = props;

  const { theme } = useTheme();
  const { textBookFont } = useAppSelector(selectApp);

  LogApp('SC12', scaleInner);

  return (
    <StyleMobileReadBook
      className="read-section readb-sec__mb"
      $isTextBooks={isTextBooks}
      $scaleInner={scaleInner}
      $firstPage={pageIndex === 0}
      $textBookFont={textBookFont}
    >
      <HeaderReadBook className="read headR_mb">
        <div className="title">{title}</div>
        <span style={{ cursor: 'pointer' }}>
          <CloseIcon onClick={() => navigate(-1)} />
        </span>
      </HeaderReadBook>
      <ToolBar className="mobile-toolbar">
        <div className="list-icon">
          {/* <div className="edit" onClick={() => setOpenEdit(true)}>
            <EditIcon />
          </div> */}
          {/* <IncreaseIcon /> */}
          {/* <DecreaseIcon /> */}
          {/* <EyeIcon />
          <PlayIcon />
          <StopIcon />
          <div className="setting" onClick={onSet}>
            <SettingIcon />
          </div> */}
          {topActions}
        </div>
      </ToolBar>
      <div
        className="preview-book"
        id="preview-book"
        onTouchStart={onSwipeStart}
        onTouchMove={onSwipeMove}
        onTouchEnd={onSwipeEnd}
      >
        <div className="content first-content" id="first-page">
          {!bookDefaultContent ? (
            !bookCoverImage ? (
              <div ref={ref} className="inner" dangerouslySetInnerHTML={{ __html: pageOne }} />
            ) : (
              <div className="book-cover">
                <img className="cover-img" src={bookCoverImage} alt="" />
              </div>
            )
          ) : (
            <div className="inner text-default" id="txt-book-box">
              <TextContentViewer
                text={bookDefaultContent}
                highLightIndex={speakCharIndex}
                highLightLength={speakCharLength}
                isSpeaking={isSpeaking}
              />
            </div>
          )}
        </div>
      </div>
      <div className="change-page_actions">
        <SharedButton
          text="戻る"
          btnStyle="basic"
          onClick={onClickPrev}
          className="bg-btn pre-btn"
          textClassName="btn-text__color"
          bgColor="#6f5f30"
          disabled={currentPage < 1}
        />
        <SharedButton
          text="次"
          btnStyle="basic"
          onClick={onClickNext}
          className="bg-btn next-btn"
          textClassName="btn-text__color"
          bgColor="#a88723"
          disabled={currentPage >= totalPage}
        />
      </div>
      <NumberPage>
        <div className="current-page">
          <ShareInput
            onChange={onChangePage}
            defaultValue={currentPage}
            key={`currentPage-${currentPage}`}
            type="number"
            max={totalPage}
            min={1}
            // onPressEnter={onPressEnter}
          />
        </div>
        <div className="total-page">の{totalPage}</div>
      </NumberPage>
      <NameBook>{title}</NameBook>
      {/* <CustomDrawer
        className="drawer-read-book"
        open={openEdit}
        placement={'left'}
        onClose={closeDrawer}
        children={
          openEditCharater ? (
            <SettingCharacter closeDrawer={changeClose} />
          ) : (
            <Write call={isOpenCharate} closeDrawer={changeClose} />
          )
        }
      ></CustomDrawer> */}
      <CustomDrawer
        className="drawer-read-book"
        open={openSettingSpeak}
        placement={'left'}
        onClose={onCloseSpeakSetting}
      >
        {settingContent}
      </CustomDrawer>
    </StyleMobileReadBook>
  );
});
