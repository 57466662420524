import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BaseResponseProps,
  BookDetailProps,
  BookItemData,
  IBookDataItem,
  MyBooksResponseProps,
  PurchaseHistoryDataType,
  SimilarBookProps,
} from '@interfaces';
import {
  fetchDetailBook,
  fetchMyBooksList,
  fetchMyBooksListWithParams,
  fetchSimilarBook,
} from '../action';
import { RootState } from '@redux';
import { PATH_DETAILS } from '@configs';

export interface RatingDetailProps {
  order: number;
  percent: number;
}

const defaultRatingProps: RatingDetailProps[] = [
  {
    order: 5,
    percent: 0,
  },
  {
    order: 4,
    percent: 0,
  },
  {
    order: 3,
    percent: 0,
  },
  {
    order: 2,
    percent: 0,
  },
  {
    order: 1,
    percent: 0,
  },
];

export interface BookSliceProps {
  loading: boolean;
  data: {
    detailBook: BaseResponseProps<BookDetailProps> | null;
    similarBooks: IBookDataItem[];
    myBooksList: BaseResponseProps<MyBooksResponseProps> | null;
    purchaseHistory: PurchaseHistoryDataType[] | null;
    orderItemModel: BookItemData[];
  };
  ratingDetail: RatingDetailProps[];
  purchaseHistoryTotalItem: number;
}

const initialState: BookSliceProps = {
  loading: false,
  data: {
    detailBook: null,
    similarBooks: [],
    myBooksList: null,
    purchaseHistory: [],
    orderItemModel: [],
  },
  ratingDetail: defaultRatingProps,
  purchaseHistoryTotalItem: 0,
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    resetRating: (state) => {
      state.ratingDetail = defaultRatingProps;
    },
    resetDataDetailBook: (state, action) => {
      state.data.detailBook = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetailBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchDetailBook.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<BookDetailProps>>) => {
          state.loading = false;
          state.data.detailBook = payload;
          if (payload.data.productReviewsModel.length > 0) {
            const total = payload.data.productReviewOverview.totalReviews;
            state.ratingDetail = defaultRatingProps.map((x) => ({
              order: x.order,
              percent:
                (payload.data.productReviewsModel.filter((e) => e.rating === x.order).length /
                  total) *
                100,
            }));
          }
        },
      )
      .addCase(fetchDetailBook.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchSimilarBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchSimilarBook.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<SimilarBookProps[]>>) => {
          state.loading = false;
          state.data.similarBooks = payload.data.map((e) => ({
            price: e.price,
            author: e.authorName,
            desc: e.fullDescription,
            img_url: e.defaultPictureModel.fullSizeImageUrl,
            rating: e.ratingSum,
            id: e.id,
            title: e.defaultPictureModel.title,
            navigateUrl: `${PATH_DETAILS}/${e.id}`,
          })) as IBookDataItem[];
        },
      )
      .addCase(fetchSimilarBook.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchMyBooksList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchMyBooksList.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<MyBooksResponseProps>>) => {
          state.loading = false;
          state.data.myBooksList = payload;
        },
      )
      .addCase(fetchMyBooksList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchMyBooksListWithParams.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchMyBooksListWithParams.fulfilled,
        (state, { payload }: PayloadAction<BaseResponseProps<MyBooksResponseProps>>) => {
          state.loading = false;
          state.data.myBooksList = payload;
        },
      )
      .addCase(fetchMyBooksListWithParams.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { resetRating, resetDataDetailBook } = bookSlice.actions;
export const bookReducer = bookSlice.reducer;
export const selectBook = (state: RootState) => state.book;
