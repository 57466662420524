import { LogApp, useMediaQuery } from '@utils';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  BookFontIcon,
  CloseCircleIcon,
  PauseSpeakIcon,
  PlayVoiceIcon,
  ResumeSpeakIcon,
  SettingSpeakIcon,
  SpeakerIcon,
  StopSpeakIcon,
} from '../Icon';
import { DecreaseIcon } from '../Icon/Decrease';
import { EyeIcon } from '../Icon/Eye';
import { IncreaseIcon } from '../Icon/Increase';
import { SelectInput } from '../SelectInput';
import { OPTIONS_SELECT_FONT, enumTextBookFont } from '@configs';
import { selectApp, setTextBookFont, useAppDispatch, useAppSelector } from '@redux';

interface IProps {
  speaking?: boolean;
  showSpeakOptions?: boolean;
  isPause?: boolean;
  supported?: boolean;
  isTextBooks?: boolean;
  onSpeak: () => void;
  onPause: () => void;
  onResume: () => void;
  onCancel: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onShowSpeakOptions?: () => void;
  onHideSpeakOptions?: () => void;
  onOpenSpeakSetting?: () => void;
  currentZoomNumber?: number;
}

export const ReadActions = (props: IProps) => {
  const {
    speaking,
    showSpeakOptions,
    supported,
    isPause,
    isTextBooks,
    onSpeak,
    onPause,
    onResume,
    onCancel,
    onZoomIn,
    onZoomOut,
    onShowSpeakOptions,
    onHideSpeakOptions,
    onOpenSpeakSetting,
    currentZoomNumber,
  } = props;
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const [showSelectFont, setShowSelectFont] = useState(false);
  const { textBookFont } = useAppSelector(selectApp);

  LogApp('SC13', currentZoomNumber);
  LogApp('SC13.1', textBookFont);

  return (
    <StyledListIcon className="list-icon" $isMobile={isMobile} $showSpeakOptions={showSpeakOptions}>
      {!isMobile ? (
        <>
          {showSelectFont ? (
            <>
              <div className="font-select">
                <SelectInput
                  value={textBookFont}
                  options={OPTIONS_SELECT_FONT}
                  onChange={(value) => dispatch(setTextBookFont(value))}
                />
              </div>
              <div onClick={() => setShowSelectFont(false)} className="app-btn option-icon ">
                <CloseCircleIcon fill={'#c97f3f'} />
              </div>
            </>
          ) : (
            <div onClick={() => setShowSelectFont(true)} className="app-btn option-icon ">
              <BookFontIcon />
            </div>
          )}
          <div
            className={`app-btn decrease-icon option-icon ${
              (
                isTextBooks
                  ? currentZoomNumber && currentZoomNumber < 0.65
                  : currentZoomNumber && currentZoomNumber <= 1
              )
                ? 'btn-disabled'
                : ''
            }`}
            onClick={onZoomOut}
          >
            <DecreaseIcon
              color={
                (
                  isTextBooks
                    ? currentZoomNumber && currentZoomNumber < 0.65
                    : currentZoomNumber && currentZoomNumber <= 1
                )
                  ? '#B6B6B6'
                  : '#000'
              }
            />
          </div>
          <div
            className={`app-btn increase-icon option-icon ${
              currentZoomNumber && currentZoomNumber >= 5 ? 'btn-disabled' : ''
            }`}
            onClick={onZoomIn}
          >
            <IncreaseIcon
              color={currentZoomNumber && currentZoomNumber >= 5 ? '#B6B6B6' : '#000'}
            />
          </div>

          {supported &&
            (showSpeakOptions ? (
              <React.Fragment>
                <div className="app-btn option-icon" onClick={onOpenSpeakSetting}>
                  <SettingSpeakIcon />
                </div>
                {speaking ? (
                  <>
                    {!isPause ? (
                      <div className="app-btn option-icon" onClick={onPause}>
                        <PauseSpeakIcon />
                      </div>
                    ) : (
                      <div className="app-btn option-icon" onClick={onResume}>
                        <ResumeSpeakIcon />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {isPause ? (
                      <div className="app-btn option-icon" onClick={onResume}>
                        <ResumeSpeakIcon />
                      </div>
                    ) : (
                      <div className="app-btn option-icon" onClick={onSpeak}>
                        <PlayVoiceIcon />
                      </div>
                    )}
                    {/* <div className="app-btn option-icon" onClick={onSpeak}>
                      <PlayVoiceIcon />
                    </div> */}
                  </>
                )}
                <div className="app-btn option-icon" onClick={onCancel}>
                  <StopSpeakIcon />
                </div>
                <div className="app-btn option-icon" onClick={onHideSpeakOptions}>
                  <EyeIcon />
                </div>
              </React.Fragment>
            ) : (
              <div onClick={onShowSpeakOptions} className="app-btn option-icon">
                <SpeakerIcon />
              </div>
            ))}
        </>
      ) : (
        <>
          {supported &&
            (showSpeakOptions ? (
              <React.Fragment>
                <div className="app-btn option-icon" onClick={onHideSpeakOptions}>
                  <EyeIcon />
                </div>
                {speaking ? (
                  <>
                    {!isPause ? (
                      <div className="app-btn option-icon" onClick={onPause}>
                        <PauseSpeakIcon />
                      </div>
                    ) : (
                      <div className="app-btn option-icon" onClick={onResume}>
                        <ResumeSpeakIcon />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {isPause ? (
                      <div className="app-btn option-icon" onClick={onResume}>
                        <ResumeSpeakIcon />
                      </div>
                    ) : (
                      <div className="app-btn option-icon" onClick={onSpeak}>
                        <PlayVoiceIcon />
                      </div>
                    )}
                    {/* <div className="app-btn option-icon" onClick={onSpeak}>
                      <PlayVoiceIcon />
                    </div> */}
                  </>
                )}
                <div className="app-btn option-icon" onClick={onCancel}>
                  <StopSpeakIcon />
                </div>
                <div className="app-btn option-icon" onClick={onOpenSpeakSetting}>
                  <SettingSpeakIcon />
                </div>
              </React.Fragment>
            ) : (
              <div onClick={onShowSpeakOptions} className="app-btn option-icon">
                <SpeakerIcon />
              </div>
            ))}
          {isTextBooks && (
            <>
              <div
                className={`app-btn decrease-icon option-icon ${
                  (isTextBooks && currentZoomNumber && currentZoomNumber < 0.65) ||
                  (!isTextBooks && currentZoomNumber && currentZoomNumber <= 1)
                    ? 'btn-disabled'
                    : ''
                }`}
                onClick={onZoomOut}
              >
                <DecreaseIcon
                  color={
                    (isTextBooks && currentZoomNumber && currentZoomNumber < 0.65) ||
                    (!isTextBooks && currentZoomNumber && currentZoomNumber <= 1)
                      ? '#B6B6B6'
                      : '#000'
                  }
                />
              </div>
              <div
                className={`app-btn increase-icon option-icon ${
                  currentZoomNumber && currentZoomNumber >= 5 ? 'btn-disabled' : ''
                }`}
                onClick={onZoomIn}
              >
                <IncreaseIcon
                  color={currentZoomNumber && currentZoomNumber >= 5 ? '#B6B6B6' : '#000'}
                />
              </div>
              {showSelectFont ? (
                <>
                  <div className="font-select">
                    <SelectInput
                      value={textBookFont}
                      options={OPTIONS_SELECT_FONT}
                      onChange={(value) => dispatch(setTextBookFont(value))}
                    />
                  </div>
                  <div onClick={() => setShowSelectFont(false)} className="app-btn option-icon ">
                    <CloseCircleIcon fill={'#c97f3f'} />
                  </div>
                </>
              ) : (
                <div onClick={() => setShowSelectFont(true)} className="app-btn option-icon ">
                  <BookFontIcon />
                </div>
              )}
            </>
          )}
        </>
      )}
    </StyledListIcon>
  );
};

export const StyledListIcon = styled.div<{
  $showSpeakOptions?: boolean;
  $isMobile?: boolean;
  $isDisabled?: boolean;
}>`
  .font-select {
    margin-left: 6px;
    .ant-select {
      font-size: 1.36rem;
    }
    .ant-select-selector {
      height: fit-content !important;
    }
  }
  ${(p) =>
    p.$isMobile &&
    css`
      padding: 0.5rem 1.2rem;
      display: flex;
      justify-content: space-between;
      .option-icon {
        padding: 0 0.8rem;
        display: flex;
        align-self: center;
      }
      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    `}
`;
