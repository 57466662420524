import { RadioChangeEvent } from 'antd';
import { Fragment, memo } from 'react';
import styled, { css } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { IconChevronDown } from '@components';
import {
  DEFAULT_CATEGORY_ITEM_HEIGH,
  DEFAULT_MOBILE_CATEGORY_ITEM_HEIGH,
  enumSidebarItemType,
  PATH_LISTING,
  PATH_PARENT_CATEGORY,
} from '@configs';
import { CategoriesDataType } from '@interfaces';
import { changePathName, getCategoryId, resetCurrentPage, useAppDispatch } from '@redux';
import { getFromLS, setToLS } from '@utils';

interface IProps {
  item: CategoriesDataType;
  type?: enumSidebarItemType;
  showAll?: boolean;
  isMobile?: boolean;
  category?: string;
  onToggleShowAll?: () => void;
  handleChangeCategory: (value: RadioChangeEvent) => void;
  onSelectData?: (value: number) => void;
  detectParent: (it: CategoriesDataType) => void;
}

export const SideBarItem = memo((props: IProps) => {
  const {
    type,
    item,
    showAll,
    isMobile,
    category,
    onToggleShowAll,
    handleChangeCategory,
    onSelectData,
    detectParent,
  } = props;
  //const { currentActive } = useAppSelector(selectSidebar);
  const currentActive = getFromLS('subActive');
  const dispatch = useAppDispatch();
  const subItemHeight = isMobile ? DEFAULT_MOBILE_CATEGORY_ITEM_HEIGH : DEFAULT_CATEGORY_ITEM_HEIGH;
  const navigate = useNavigate();
  const handleNavigateCategoryById = (itemCategory: CategoriesDataType) => {
    navigate(`${PATH_PARENT_CATEGORY}/${itemCategory.name}`);
    setToLS('subActive', itemCategory.id);
    dispatch(changePathName(itemCategory.name));
    setToLS('categoryId', itemCategory.id);
  };
  const handleResetCurrentPage = () => {
    dispatch(resetCurrentPage(1));
  };
  return (
    <StyledSideBarItem
      showAll={showAll}
      length={item?.subCategories?.length}
      subItemHeight={subItemHeight}
      className="categories__item"
      isMobile={isMobile}
    >
      <div className="item__head" onClick={() => isMobile && onToggleShowAll && onToggleShowAll()}>
        <h2
          className={`heading${currentActive === item.id ? ' navlink-active' : ''}`}
          onClick={() => handleNavigateCategoryById(item)}
        >
          {item?.name}
        </h2>
        {isMobile && (
          <div className="show-all">
            <IconChevronDown
              className="show-all__icon"
              width={24}
              height={24}
              strokeWidth={2}
              color={showAll ? '#359F08' : '#303030'}
            />
          </div>
        )}
      </div>
      {!isMobile ? (
        <Fragment>
          <div className="sub-categories__list">
            {item?.subCategories?.map((value) => (
              <div
                key={value.id}
                className={`sub-item${currentActive === value.id ? ' active' : ''}`}
                onClick={() => {
                  onSelectData && onSelectData(value?.id);
                  dispatch(getCategoryId(value?.id));
                  handleResetCurrentPage();
                }}
              >
                <p className="item-title">{value?.name}</p>
              </div>
            ))}
          </div>
          {item?.subCategories?.length > 3 && (
            <div onClick={onToggleShowAll} className="show-all">
              <IconChevronDown
                className="show-all__icon"
                width={16}
                height={16}
                strokeWidth={3}
                color={showAll ? '#0DBED2' : '#359F08'}
              />
              <span className="sa_text">{showAll ? '閉じる' : 'もっと見る'}</span>
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div className="sub-categories__list" onClick={() => detectParent(item)}>
            {/*<Radio.Group value={category} onChange={handleChangeCategory}>
              {item?.subCategories?.map((value) => (
                <SharedCheckbox
                  className="filter-category__cb"
                  size={'2rem'}
                  value={value?.id}
                  type="radio"
                  text={value?.name}
                  key={value.id}
                />
              ))}
            </Radio.Group>*/}
            {item?.subCategories?.map((e) => (
              <>
                <Link
                  onClick={() => {
                    onSelectData && onSelectData(e?.id);
                    dispatch(getCategoryId(e?.id));
                    dispatch(changePathName(null));
                    handleResetCurrentPage();
                  }}
                  className={`mb-navlink${currentActive === e.id ? ' navlink-active' : ''}`}
                  to={`${PATH_LISTING}/${e.seName}`}
                >
                  {e?.name}
                </Link>
              </>
            ))}
          </div>
        </Fragment>
      )}
    </StyledSideBarItem>
  );
});

const StyledSideBarItem = styled.div<{
  showAll?: boolean;
  length?: number;
  subItemHeight?: string;
  isMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .navlink-active {
    color: ${(props) => props.theme.colors.primary} !important;
  }
  .mb-navlink {
    margin-bottom: 1.6rem;
    display: block;
    padding-left: 1.6rem;
    color: ${(p) => p.theme.colors.text};
    &:hover {
      color: ${(p) => p.theme.colors.information};
    }
  }
  .categories__item {
    &:not(:last-child) {
      .sub-categories__list {
        ${(p) => Number(p?.length) <= 3 && `margin-bottom: 2.4rem;`};
      }
    }
  }
  .sub-categories__list {
    width: 100%;
  }
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 23px;
    color: #303030;
    margin-bottom: 0;
    &.active {
      color: ${(props) => props.theme.colors.primary};
    }
    &:hover {
      cursor: pointer;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .sub-categories__list {
    transition: all 0.2s ease 0s;
    height: ${(p) =>
      p.showAll || Number(p.length) <= 3
        ? `calc(${p.subItemHeight}*${Number(p.length)})`
        : `calc(${p.subItemHeight}*3)`};
    overflow: hidden;
    margin-top: 1.8rem;
    .sub-item {
      height: ${(p) => p.subItemHeight ?? 'fit-content'};
      padding-left: 24px;
      position: relative;
      /* &:first-child {
        margin-top: 1.8rem;
      } */
      &.active .item-title {
        color: ${(props) => props.theme.colors.primary} !important;
      }
      &:after {
        position: absolute;
        left: 0;
        top: 50%;
        background-color: #cecece;
        content: '';
        width: 10px;
        z-index: 1;
        height: 1px;
      }
      .item-title {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: 0.2s;
        padding-bottom: 0.8rem;
        color: #303030;
        &:hover {
          margin-left: 0.8rem;
          cursor: pointer;
          color: #d42a1c;
        }
      }
    }
  }
  .show-all {
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    .sa_text {
      margin-left: 0.8rem;
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 20px;
      color: ${(p) => (p.showAll ? '#0DBED2' : ' #359f08')};
    }
    .show-all__icon {
      margin-left: -0.2rem;
      transition: 0.35s;
      ${(p) =>
        p.showAll &&
        css`
          transform: rotate(180deg);
        `}
    }
    &:hover {
      .sa_text {
        color: #303030;
      }
      @media (min-width: 640px) {
        .show-all__icon {
          stroke: #303030;
        }
      }
    }
  }

  // for mobile
  @media (max-width: 640px) {
    .item__head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.8rem;
      .heading {
        font-size: 1.4rem;
      }
      .show-all {
        margin-bottom: 0;
      }
    }

    .sub-categories__list {
      transition: all 0.2s ease 0s;
      height: ${(p) => (p.showAll ? `calc((${p.subItemHeight} + 16px) *${Number(p.length)})` : 0)};
      overflow: hidden;
      margin-top: 0.8rem;
      .filter-category__cb {
        /* &:not(:last-child) { */
        margin-bottom: 1.6rem;
        /* } */
        .radio-label {
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;
          color: #303030;
        }
      }
    }
  }
`;
