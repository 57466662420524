import { ICONS } from '@assets';
import { enumOrderDate } from '@configs';
import { SelectDatepicker } from 'react-select-datepicker';
import styled, { css } from 'styled-components';

interface IProps {
  value?: Date | null;
  onDateChange: (date: Date | null) => void;
  order?: enumOrderDate;
  yearPlaceholder?: string;
  monthPlaceholder?: string;
  dayPlaceholder?: string;
  yearLabel?: string;
  monthLabel?: string;
  dayLabel?: string;
  hideLabels?: boolean;
}

export const SelectDatePicker = (props: IProps) => {
  const {
    value,
    onDateChange,
    order = enumOrderDate.Y_M_D,
    yearPlaceholder = '年',
    monthPlaceholder = '月',
    dayPlaceholder = '日',
    yearLabel,
    monthLabel,
    dayLabel,
    hideLabels,
  } = props;

  const selectDownIcon = ICONS.selectDownIcon;

  return (
    <StyledSelectDatePicker
      selectedDate={value}
      onDateChange={onDateChange}
      hideLabels={hideLabels}
      order={order}
      labels={{
        yearLabel: yearLabel,
        monthLabel: monthLabel,
        dayLabel: dayLabel,
        yearPlaceholder: yearPlaceholder,
        monthPlaceholder: monthPlaceholder,
        dayPlaceholder: dayPlaceholder,
        months: {
          '1': '1月',
          '2': '2月',
          '3': '3月',
          '4': '4月',
          '5': '5月',
          '6': '6月',
          '7': '7月',
          '8': '8月',
          '9': '9月',
          '10': '10月',
          '11': '11月',
          '12': '12月',
        },
      }}
      selectDownIcon={selectDownIcon}
    />
  );
};

export const StyledSelectDatePicker = styled((props) => <SelectDatepicker {...props} />)<{
  selectDownIcon?: string;
}>`
  width: 100%;
  .rsd__select-container-year,
  .rsd__select-container-month,
  .rsd__select-container-day {
    width: calc((100% - 4.8rem) / 3);
    &:focus-visible {
      outline: none;
    }
    /* outline: none!important; */
    select {
      padding: 1.2rem 1.6rem;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 2rem;
      color: #303030;
      border: none;
      background: ${(p) => p.selectDownIcon && `url(${p.selectDownIcon}) no-repeat right #f9f9f9`};
      background-size: 1.6rem;
      background-position-x: calc(100% - 1.6rem);
      ${(p) =>
        p.selectDownIcon &&
        css`
          -moz-appearance: none; /* Firefox */
          -webkit-appearance: none; /* Safari and Chrome */
          appearance: none;
        `}
      outline: none!important;
      ◊ &:focus-visible,
      &:visited,
      &:focus-within,
      &:active {
        outline: none;
      }
    }
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .rsd__select-container-year {
    margin-right: 2.4rem;
  }

  .rsd__select-container-month {
    margin-right: 2.4rem;
  }
  .rsd__select-container-day {
  }
`;
