import { Pagination, SearchEmptyBox, SharedImage, SharedSkeleton, UserFilter } from '@components';
import { Border, ImageWrapper, ListItem, SubsImageWrapper, SubscribeWrapper } from './style';
import {
  deleteSearchParams,
  queryParamsParser,
  renderSkeletonItem,
  scrollToTop,
  setSearchParams,
  useMediaQuery,
} from '@utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchMyBooksList, fetchMyBooksListWithParams, selectBook, useAppSelector } from '@redux';
import { createSearchParams, Link, useLocation, useSearchParams } from 'react-router-dom';
import { useNavigateParams } from '@hooks';
import { PATH_DETAILS, PATH_MY_BOOKS } from '@configs';
import { IMAGES } from '@assets';

export const CustomerModule = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const navigate = useNavigateParams();
  const location = useLocation();
  const searchParams = useSearchParams();
  const [categoryId, setCategoryId] = useState<string>();
  const [vendorId, setVendorId] = useState<string>();
  const [searchText, setSearchText] = useState<string>();

  const isMobileView = useMediaQuery('(max-width: 640px)');

  const dispatch = useDispatch();
  const {
    data: { myBooksList },
    loading,
  } = useAppSelector(selectBook);

  const handlePageChange = (pageNumber: number) => {
    const page = {
      start: pageNumber - 1,
      length: isMobileView ? 12 : 30,
      ...(vendorId && { vendorId }),
      ...(categoryId && { BookType: categoryId }),
    };
    navigate(PATH_MY_BOOKS, page);
    dispatch(fetchMyBooksListWithParams(createSearchParams(page as any).toString()));
  };

  useEffect(() => {
    const start = searchParams[0].get('start') || '0';
    // const length = searchParams[0].get('length') || '12';
    const length = isMobileView ? '12' : '30';
    const vendorId = searchParams[0].get('vendorId');
    const BookType = searchParams[0].get('BookType');
    const allParams = {
      ...(start && { start }),
      ...(length && { length }),
      ...(vendorId && { vendorId }),
      ...(BookType && { BookType }),
    };
    (searchText?.length || 0) === 0 &&
      dispatch(fetchMyBooksListWithParams(createSearchParams(allParams as any).toString()));
    scrollToTop();
  }, [location]);

  const handleSearch = (search: string) => {
    setSearchText(search);
    dispatch(fetchMyBooksList(search));
    deleteSearchParams(searchParams, ['start', 'length', 'vendorId', 'BookType']);
  };

  const handleFilter = () => {
    const searchObj = {
      ...(vendorId && { vendorId }),
      ...(categoryId && { BookType: categoryId }),
    };
    setSearchParams(searchParams, searchObj);
    deleteSearchParams(searchParams, ['start', 'length']);
    dispatch(fetchMyBooksListWithParams(createSearchParams(searchObj).toString()));
  };

  return (
    <SubscribeWrapper className="rg-sec">
      <UserFilter
        onCategoryChanged={setCategoryId}
        onAuthorChanged={setVendorId}
        onSearch={handleSearch}
        onFilterClick={handleFilter}
      />
      <Border />

      {isMobile ? (
        <div className="title">
          <p className="subs-filter-title">マイブック</p>
          {/*<div className="button-right-vector">
            <p className="text">もっと見る </p>
            <img src={ICONS.rightArrow} alt="" />
          </div>*/}
        </div>
      ) : (
        <p className="subs-filter-title">マイブック一覧</p>
      )}
      <ListItem>
        {loading ? (
          <SubsImageWrapper className="subs-img__wrapper">
            <SharedImage
              boxClassName="bookshelves-head-bar"
              className="bookshelves-head-bar"
              src={IMAGES.bookshelvesHeadBar}
            />
            {renderSkeletonItem(30).map((data, index) => {
              return index !== 0 && index !== 29 && (index + 1) % 6 === 0 ? (
                <>
                  <ImageWrapper>
                    <SharedSkeleton type={'image'} imageClassName="item-skeleton" active />
                  </ImageWrapper>
                  <SharedImage className="bookshelves-bar" src={IMAGES.bookshelvesBar} />
                </>
              ) : (
                <ImageWrapper key={index}>
                  <SharedSkeleton type={'image'} imageClassName="item-skeleton" active />
                </ImageWrapper>
              );
            })}
            <SharedImage className="bookshelves-bar-bottom" src={IMAGES.bookshelvesBar} />
          </SubsImageWrapper>
        ) : (
          <>
            {myBooksList && myBooksList?.data?.myBooks?.length > 0 ? (
              <SubsImageWrapper
                $multiRows={myBooksList?.data?.myBooks?.length > 6}
                className="subs-img__wrapper"
              >
                <>
                  <SharedImage
                    boxClassName="bookshelves-head-box"
                    className="bookshelves-head-bar"
                    src={IMAGES.bookshelvesHeadBar}
                  />
                  {myBooksList?.data?.myBooks?.map((data, index) => {
                    return index !== 0 && index !== 29 && (index + 1) % 6 === 0 ? (
                      <>
                        <ImageWrapper key={data.id}>
                          <Link to={`${PATH_DETAILS}/${data.id}`}>
                            <SharedImage
                              src={data?.defaultPictureModel?.fullSizeImageUrl}
                              alt="img-item"
                            />
                          </Link>
                        </ImageWrapper>
                        {/* <hr className="hr" /> */}
                        <SharedImage
                          className="bookshelves-bar"
                          boxClassName="bookshelves-bar"
                          src={IMAGES.bookshelvesBar}
                        />
                      </>
                    ) : (
                      <ImageWrapper key={data.id}>
                        <Link to={`${PATH_DETAILS}/${data.id}`}>
                          <SharedImage
                            src={data?.defaultPictureModel?.fullSizeImageUrl}
                            alt="img-item"
                          />
                        </Link>
                      </ImageWrapper>
                    );
                  })}
                  {/* </div> */}
                  <SharedImage
                    boxClassName="bookshelves-bar-bottom"
                    className="bookshelves-bar-bottom"
                    src={IMAGES.bookshelvesBar}
                  />
                </>
              </SubsImageWrapper>
            ) : (
              <SearchEmptyBox text="探している書籍が見つかりません。" />
            )}
          </>
        )}
      </ListItem>
      {!isMobile && <Border />}

      {myBooksList && myBooksList.data.myBooks.length > 0 && (
        <Pagination
          current={Number(searchParams[0].get('start') || 0) + 1}
          defaultCurrent={1}
          total={myBooksList?.data.total || 0}
          onChangePage={handlePageChange}
          pageSize={isMobileView ? 12 : 30}
        />
      )}
    </SubscribeWrapper>
  );
};
