export default {
  details: '書籍詳細',
  category: '実用・ビジネス',
  profile: 'プロフィール',
  ['my-books']: 'マイブック',
  ['wish-list']: 'ウィッシュリスト',
  ['subscription-rank']: 'サブスクリプション',
  ['book-request']: '本の広場',
  ['purchase-history']: '購入履歴',
  ['my-review']: 'マイレビュー',
  ['categories']: 'カテゴリ',
  cart: 'カート',
  payment: 'カート',
  checkout: 'カート',
  bookstore: '書店',
  policy: 'ウィッシュリスト',
  term: 'ウィッシュリスト',
  ['notification-setting']: '通知設定',
  ['change-password']: 'パスワードの変更',
  search: '検索',
  ['company-profile']: 'ウィッシュリスト',
  ['special-commer']: 'ウィッシュリスト',
  ['checkout-complete']: 'カート',
  ['parent-category']: '古典文学',
};
