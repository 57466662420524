import styled from 'styled-components';

export const SubscriptionBlock1 = styled.div`
  @media (min-width: 641px) {
    width: 100%;
    height: 40.6rem;
    background-color: #5288c5;
    //padding: 0;
    gap: 4rem;
    position: relative;
    top: 8rem;
    padding-top: 6.7rem;
    .title {
      text-align: center;
      color: #fff;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.5rem;
    }
    .list-item {
      margin-top: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6.7rem;
    }
  }
  @media (min-width: 375px) and (max-width: 640px) {
    width: 100%;
    position: relative;
    height: 33.9rem;
    background-color: #5288c5;
    padding: 2.4rem 0;
    gap: 2.4rem;
    left: 0;
    //top: 14.7rem;
    top: 11rem;
    z-index: 2;
    .title {
      text-align: center;
      height: 3.5rem;
      font-family: 'Noto Sans 3P';
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.5rem;
      color: #fff;
    }
    .list-item {
      padding: 0 1.6rem;
      margin-top: 4px;
    }
  }
`;

export const Item = styled.div`
  @media (min-width: 641px) {
    &.item:nth-child(2) {
      .information-item {
        padding: 1.2rem 4.9rem 0 4.9rem;
      }
    }
    &.item:nth-child(3) {
      .information-item {
        padding: 1.2rem 3.9rem 0 3.9rem;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    height: 19.7rem;
    width: 21.8rem;
    text-align: center;
    margin: 0 3rem;
    border-radius: 1.2rem;
    justify-content: space-between;
    .information-item {
      text-align: left;
      width: 100%;
      padding: 1.2rem 1.9rem 0 1.9rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 300;
      font-size: 2rem;
      line-height: 2.9rem;
      color: #6c757d;
    }
    .image-item {
      width: 6rem;
      height: 6rem;
    }
    .price-item {
      width: 100%;
      margin-bottom: 1.2rem;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 2.9rem;
      color: #000;
      .time-item {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 300;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #b6b6b6;
        margin-left: 4px;
      }
    }
  }
  display: flex;

  @media (min-width: 375px) and (max-width: 640px) {
    &.item:first-child {
      .information-item {
        text-align: left;
      }
    }
    display: flex;
    padding: 1.2rem 1.2rem;
    gap: 3.2rem;
    height: 6.4rem;
    background-color: #fff;
    border-radius: 1.2rem;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
    .information-item {
      width: 35%;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: center;
      color: #6c757d;
      display: flex;
      justify-content: flex-start;
    }
    .image-item {
      width: 4rem;
      height: 4rem;
    }
    .price-item {
      width: 33.3%;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.7rem;
      text-align: center;
      color: #000;
      display: flex;
      justify-content: flex-end;
      .time-item {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.7rem;
        text-align: center;
        color: #b6b6b6;
        margin-left: 4px;
      }
    }
  }
`;
