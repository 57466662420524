import { FC } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface LoadingProps {
  text?: string;
  className?: string;
}

export const LoginLoading: FC<LoadingProps> = ({ className, children }) => {
  return createPortal(
    <StyledLoadingPortal className={className ? className : ''}>{children}</StyledLoadingPortal>,
    document.querySelector('body') || document.body,
  );
};

const StyledLoadingPortal = styled.div`
  position: fixed;
  inset: 0;
  background-color: #00000061;
`;
