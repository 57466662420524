import styled from 'styled-components';

export const StyleContainer = styled.div`
  width: 100%;
  margin-bottom: 13.9rem;
  /*&.wish-list {
    @media (max-width: 640px) {
      margin-top: 4rem;
    }
  }*/
  .wish-list-wrapper-right__header {
    @media (max-width: 1023px) {
      margin-top: 2.5rem;
    }
  }
  .wish-list-wrapper {
    width: 100%;
    display: flex;
    .skeleton-wishlist {
      margin-bottom: 16px;
    }
    &-left {
      width: 27.5rem;
      margin-right: 2.4rem;
    }
    &-line {
      width: 100%;
      height: 0.1rem;
      background-color: #d9d9d9;
    }
    &-right {
      flex: 1;
      margin-top: -4.6rem;
      &__header {
        width: 100%;
      }
      .wish-list-content {
        margin-top: 2.4rem;
        &-total {
          font-family: 'Noto Sans JP';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 2.4rem;
        }
        .wish-list-item {
          width: 100%;
        }
        .wish-list-item ~ .wish-list-item {
          margin-top: 2.4rem;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .wish-list-wrapper {
      &-left {
        width: 22rem;
      }
    }
  }
  .paginator {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    .ant-pagination {
      width: fit-content;
    }
  }
`;
