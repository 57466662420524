import { SharedSkeleton } from '@components';
import { renderSkeletonItem } from '@utils';
import { CategoriesStyled, ItemCategories, ListCategories } from 'src/modules/categories/style';
import styled from 'styled-components';

export const SkeletonListCategory = () => {
  return (
    <ItemCategories>
      {renderSkeletonItem(1).map((data, index) => (
        <div className="name-categories">
          <SharedSkeleton type={'input'} active />
        </div>
      ))}
      <ul>
        {renderSkeletonItem(5).map((data, index) => (
          <li>
            <SharedSkeleton type={'input'} active />
          </li>
        ))}
      </ul>
    </ItemCategories>
  );
};
