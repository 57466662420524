import { NotificationPage } from '@components';
import { useNavigate } from 'react-router-dom';
import { PATH_LOGIN, PATH_SET_PASSWORD } from '@configs';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { queryParamsParser } from '@utils';

export const ForgotPasswordSuccess = () => {
  const { email, token } = queryParamsParser();
  const navigate = useNavigate();
  const handleClick = () => navigate(PATH_LOGIN);

  useEffect(() => {
    if (email && token) {
      navigate({
        pathname: PATH_SET_PASSWORD,
        search: createSearchParams({
          email,
          token,
        }).toString(),
      });
    }
  }, []);

  return (
    <NotificationPage
      buttonText="続ける"
      headerAlignment="center"
      descriptionAlignment="center"
      onButtonClick={handleClick}
      headerText="パスワードを忘れた方"
      description="パスワード再設定のためのメールがお客様に送信されました"
    />
  );
};
