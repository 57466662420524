import styled from 'styled-components';

export const StyledHome = styled.div`
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  padding-bottom: 5rem;
  overflow: visible;
  //li {
  //  list-style: none;
  //}
  .footer-desc {
    display: flex;
  }
  a {
    color: #303030;
  }
  .font-noto {
    font-family: 'Noto Sans JP';
    font-style: normal;
  }
  .home-banner {
    width: 100%;
    height: 55.2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .w-full {
    width: 100%;
    height: 55.2rem;

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      height: 17.4rem;
    }
  }
  .container-category-list {
    margin-top: 6.4rem;
    .category-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h5 {
        font-weight: 700;
        font-size: 2.4rem;
        line-height: 3.5rem;
        color: #303030;
        margin-bottom: 0;
      }
      a {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #303030;

        &:hover {
          color: ${(p) => p.theme.colors.primary};
          svg {
            polyline {
              stroke: ${(p) => p.theme.colors.primary};
            }
          }
        }
      }

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        padding: 0 1.6rem;

        .category-header__title {
          font-size: ${(props) => props.theme.size.xl};
        }

        .category-header__link span {
          font-size: ${(props) => props.theme.size.xs};
        }
      }
    }
    .list-category {
      display: flex;
      margin-top: 3.2rem;
      position: relative;
      width: 100%;
      /* height: 20rem; */
      .swiper {
        position: initial;
        .swiper-wrapper {
          position: initial;
          width: 100%;
          .swiper-slide {
            position: initial;
            width: 100%;
            .swiper-zoom-container {
              position: initial;
              width: 100%;
              display: flex;
              .swiper-slide-category-item {
                width: 100%;
              }
              .swiper-slide-category-item ~ .swiper-slide-category-item {
                margin-left: 1.6rem;

                //@media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
                //  margin: 0 1.6rem;
                //}
              }

              @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
                justify-content: space-between;
                padding: 0 1.6rem;

                .swiper-slide-category-item {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .pagination-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        z-index: 10;
        width: 3.5rem;
        height: 3.5rem;
        background-color: #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
      .next-btn {
        right: -2rem;

        @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
          right: 0rem;
        }
      }
      .prev-btn {
        left: -2rem;

        @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
          left: 0rem;
        }
      }
      .swiper-button-disabled {
        display: none;
      }
    }
  }
  .container-recommand {
    margin-top: 6.4rem;
  }
  .container-about {
    margin: 6.4rem auto;
    width: 77.2rem;
    border: 3px solid #009688;
    padding: 2.4rem;
    position: relative;
    .skeleton {
      .skeleton-input {
        margin-bottom: 10px;
      }
      .ant-skeleton-element {
        width: 100%;
      }
      .ant-skeleton-input {
        min-width: unset;
        width: 100%;
      }
    }
    &-header {
      display: flex;
      align-items: center;
      img {
        width: 5rem;
        height: 5rem;
      }

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        justify-content: space-between;
        margin-bottom: 2.8rem;

        .container-about-title {
          font-size: 2.8rem;
          margin-left: 0;
        }
      }

      @media (min-width: 320px) and (max-width: 375px) {
        .container-about-title {
          font-size: 2.6rem;
          margin-left: 0;
        }
      }
    }
    &-title {
      margin-left: 0.8rem;
      font-weight: 500;
      font-size: 4rem;
      line-height: 5.8rem;
    }
    &-content {
      padding-right: 3.1rem;
      //ul {
      //  li {
      //    list-style-type: square;
      //    list-style-position: inside;
      //    font-family: 'Noto Sans JP';
      //    font-style: normal;
      //  }
      //}
      //& > p {
      //  width: 60.6rem;
      //}
      img {
        width: 12.3rem;
        height: 10rem;
        /*position: absolute;
        bottom: 1.3rem;
        right: 1.3rem;*/
      }

      @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
        padding-right: 0;
      }
    }
    @media (max-width: 820px) {
      width: 90%;
      &-content {
        & > p {
          width: calc(100% - 7rem);
        }
        img {
          width: 10.3rem;
          height: 8rem;
        }
      }
    }
    @media (max-width: 768px) {
      width: 90%;
      &-content {
        & > p {
          width: calc(100% - 7rem);
        }
        img {
          width: 10.3rem;
          height: 8rem;
        }
      }
    }

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      margin-top: 3rem;
      margin-bottom: 3.2rem;
    }
  }
  .home-banner-bottom {
    margin: 6.4rem 0;
    width: 100%;
    height: auto;
    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      padding: 0 1.6rem;
      margin-top: 3rem;
      margin-bottom: 3.2rem;
    }
    @media (max-width: 640px) {
      height: 57px;
    }
  }
`;

export const ListItem = styled.li<{ mt?: string }>`
  list-style-type: square;
  list-style-position: inside;
  font-family: 'Noto Sans JP';
  font-weight: ${(props) => props.theme.weight['500']};
  font-size: ${(props) => props.theme.size.l};
  line-height: 2.4rem;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${(props) => props.mt || 0};

  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    font-size: ${(props) => props.theme.size.s};
    word-break: break-all;
  }
`;

export const NavigateButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .navigate-title {
    font-size: ${(props) => props.theme.size.s};
    font-weight: ${(props) => props.theme.weight['400']};
    color: ${(props) => props.theme.colors.text};

    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  &:hover {
    svg {
      polyline {
        stroke: ${(p) => p.theme.colors.primary};
      }
    }
  }
`;
