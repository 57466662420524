import { enumTextBookFont, enumThemeMode } from '@configs';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { themes } from '@theme';

import { RootState } from '.';

interface IState {
  mobile: boolean;
  fullscreen: boolean;
  loading: boolean;
  appStateId: string;
  themeMode: enumThemeMode;
  bookCoverImage: string;
  bookCoverImageTwo: string;
  textBookFont: string;
}

const initialState: IState = {
  mobile: false,
  fullscreen: false,
  loading: false,
  appStateId: '',
  themeMode: enumThemeMode.LIGHT,
  bookCoverImage: '',
  bookCoverImageTwo: '',
  textBookFont: enumTextBookFont.SHIN_GO_PR6,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    resetApp: () => {
      return initialState;
    },
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFullscreen: (state) => {
      const element = document.querySelector('#root');
      //@ts-ignore
      const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
      //@ts-ignore
      element.requestFullScreen =
        //@ts-ignore
        element.requestFullScreen ||
        //@ts-ignore
        element.webkitRequestFullScreen ||
        //@ts-ignore
        element.mozRequestFullScreen ||
        function () {
          return false;
        };
      //@ts-ignore
      document.cancelFullScreen =
        //@ts-ignore
        document.cancelFullScreen ||
        //@ts-ignore
        document.webkitCancelFullScreen ||
        //@ts-ignore
        document.mozCancelFullScreen ||
        function () {
          return false;
        };
      //@ts-ignore
      isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();

      state.fullscreen = !isFullscreen;
    },
    setAppStateId: (state, action: PayloadAction<string>) => {
      state.appStateId = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<enumThemeMode>) => {
      state.themeMode = action.payload;
    },
    setTextBookFont: (state, action: PayloadAction<string>) => {
      state.textBookFont = action.payload;
    },
    setBookCoverImage: (state, action: PayloadAction<string>) => {
      state.bookCoverImage = action.payload;
    },
    setBookCoverImageTwo: (state, action: PayloadAction<string>) => {
      state.bookCoverImageTwo = action.payload;
    },
  },
});

export const {
  resetApp,
  setMobile,
  setFullscreen,
  setLoading,
  setAppStateId,
  setThemeMode,
  setTextBookFont,
  setBookCoverImage,
  setBookCoverImageTwo,
} = appSlice.actions;

export const selectApp = (state: RootState) => state.app;
export const selectAppLoading = (state: RootState) => state.app.loading;
export const selectAppStateId = (state: RootState) => state.app.appStateId;
export const selectThemeMode = (state: RootState) => state.app.themeMode;

export default appSlice.reducer;
