import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PATH_LOGIN, USER_MENU_SIDE_BAR } from '@configs';
import {
  DataContainer,
  SubMenuContainer,
  Text,
  UserSettingHeader,
  UserSettingWrapper,
} from './styles';
import { BookRequestIcon, CloseIcon, IconChevronDown, IconChevronRight } from '@components';
import { getColorActive } from '@utils';
import { useNavigateParams } from '@hooks';
import { useSelector } from 'react-redux';
import { RootState } from '@redux';

interface SubTitleType {
  id: number;
  title: string;
  path: string;
}

interface DataType {
  id: number;
  icon: any;
  title: string;
  path?: string;
  subTitles?: SubTitleType[];
  isLogout?: boolean;
  isLoginAdmin?: boolean;
  isUserInfo?: boolean;
}

interface MobileUserSettingProps {
  avatar?: string;
  userName: string;
  userMail: string;
  onClose: () => void;
  onLogout: () => void;
}

export const MobileUserSetting: React.FC<MobileUserSettingProps> = ({
  avatar,
  userMail,
  userName,
  onClose,
  onLogout,
}) => {
  const location = useLocation();
  const navigate = useNavigateParams();
  const [showSubMenu, setShowSubMenu] = useState<boolean>(true);
  const {
    auth: { role },
  } = useSelector((state: RootState) => state);

  const showAdminLink =
    role?.includes('Administrator') ||
    role?.includes('Vendors') ||
    role?.includes('SuperAdministrators');

  const handleOpenSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  const handleSelectData = (data: DataType) => {
    if (data?.isLoginAdmin) {
      const adminUrl = process.env.REACT_APP_ADMIN_LINK;
      window?.open(adminUrl, '_blank', 'noopener,noreferrer');
      return;
    }
    if (data.isLogout) {
      onLogout();
      navigate(PATH_LOGIN);
    }
    if (data.subTitles?.length) {
      setShowSubMenu(!showSubMenu);
    }

    if (data.path) {
      navigate(data.path);
    }
  };

  const handleSelectSubData = (data: SubTitleType) => {
    if (data.path) navigate(data.path);
  };

  const mobileSidebar = [
    ...USER_MENU_SIDE_BAR,
    ...(showAdminLink
      ? [
          {
            id: 12,
            title: '管理画面',
            path: 'path_admin',
            isLoginAdmin: true,
          },
        ]
      : []),
  ];
  return (
    <UserSettingWrapper>
      <UserSettingHeader className="px-24">
        <div className="d-f">
          <div className="setting-avatar">
            <img src={avatar} alt="avatar" className="" />
          </div>
          <div>
            <Text fontWeight={500}>{userName}</Text>
            <Text fontSize="1.2rem" fontWeight={300}>
              {userMail}
            </Text>
          </div>
        </div>
        <CloseIcon onClick={onClose} />
      </UserSettingHeader>
      <div className="px-24">
        {mobileSidebar
          ?.filter((item: any) => item.id !== 0)
          .map((data: any) => {
            const Icon = data?.icon;
            const isSubTitleLength = data?.subTitles?.length;
            return (
              <>
                <DataContainer
                  key={data?.id}
                  onClick={() => handleSelectData(data)}
                  isTopBorder={data?.isLogout}
                >
                  <div className="d-f-item">
                    <span className="setting-wrapper">
                      {!data?.isLogout && Icon ? <Icon /> : null}
                    </span>
                    <Text
                      colorText={getColorActive(data?.path || '', location.pathname)}
                      mt={data?.isLogout ? '0.8rem' : 'none'}
                    >
                      {data?.title}
                    </Text>
                  </div>

                  <span>
                    {isSubTitleLength &&
                      (showSubMenu ? (
                        <IconChevronDown
                          className="icon-active"
                          width={16}
                          height={16}
                          color="#303030"
                          onClick={handleOpenSubMenu}
                        />
                      ) : (
                        <IconChevronRight
                          className="icon-active"
                          width={16}
                          height={16}
                          color="#303030"
                          onClick={handleOpenSubMenu}
                        />
                      ))}
                  </span>
                </DataContainer>

                {showSubMenu && (
                  <SubMenuContainer>
                    {data?.subTitles?.map((subData: any) => {
                      return (
                        <div
                          key={subData?.id}
                          className="sub-data-wrapper"
                          onClick={() => handleSelectSubData(subData)}
                        >
                          <Text
                            className="sub-data-wrapper"
                            isBefore
                            colorText={getColorActive(subData?.path, location.pathname)}
                            fontWeight={400}
                          >
                            {subData?.title}
                          </Text>
                        </div>
                      );
                    })}
                  </SubMenuContainer>
                )}
              </>
            );
          })}
      </div>
    </UserSettingWrapper>
  );
};
