import styled from 'styled-components';

export const StyleContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4.6rem;

  .my-preview {
    &-left {
      width: 27.5rem;
      margin-right: 2.4rem;
    }

    &-right {
      flex: 1;
      font-family: 'Noto Sans JP';
      font-style: normal;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          font-weight: 700;
          font-size: 2.4rem;
          line-height: 3.5rem;
          width: fit-content;
        }

        &-filter {
          margin-left: 1.6rem;

          .ant-radio-group {
            .ant-radio-wrapper ~ .ant-radio-wrapper {
              margin-left: 2.4rem;
            }

            .ant-radio-wrapper {
              margin: 0;

              .ant-radio {
                &:hover {
                  .ant-radio-inner {
                    border-color: ${(p) => p.theme.colors.primary};
                  }
                }
              }

              & > span {
                /* &:first-child {
                  border-color: ${(p) => p.theme.colors.primary};
                  &:hover {
                    border-color: ${(p) => p.theme.colors.primary};
                  }
                } */

                &:last-child {
                  margin-left: 1.6rem;
                  padding: 0;
                }
              }
            }

            .ant-radio-checked {
              &::after {
                border: 1px solid ${(p) => p.theme.colors.primary};
              }

              .ant-radio-inner {
                border-color: ${(p) => p.theme.colors.primary};

                &::after {
                  background-color: ${(p) => p.theme.colors.primary};
                }

                &:hover {
                  border-color: ${(p) => p.theme.colors.primary};
                }
              }
            }
          }
        }
      }

      &__line {
        margin: 2.4rem 0;
        width: 100%;
        height: 0.1rem;
        background-color: #d9d9d9;
      }

      &__pagination {
        text-align: center;
      }
      &__content {
        &-title {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2rem;
          color: ${(p) => p.theme?.colors?.button?.background};
          margin-bottom: 2.4rem;
        }
      }

      @media (max-width: 420px) {
        &__header {
          display: unset;

          &-filter {
            margin: 20px 0 0 0;
          }
        }
      }
      @media (min-width: 1023px) {
        margin-top: -5.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    display: unset;
    .book-item-rate-date {
      flex-direction: column;
      align-items: flex-start !important;

      .book-item-date {
        margin: 8px 0 0 0 !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 820px) and (orientation: portrait) {
    .my-preview {
      &-right {
        &__header {
          h5 {
            font-size: ${(props) => props.theme.size.xl};
          }
        }
      }
    }
  }
`;
