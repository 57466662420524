import styled from 'styled-components';

export const StyledBookRequest = styled.div<{
  isLogin?: string;
}>`
  width: 100%;
  min-height: 100vh;
  margin-bottom: 10.8rem;

  .ant-modal-footer {
    display: none;
  }

  .book-request-container {
    display: flex;
    width: 100%;

    &-left {
      /* margin-top: 2.4rem; */
      width: 27.5rem;
    }

    &-right {
      //margin-top: -4.6rem;
      //margin-left: 2.4rem;
      /* width: 100%; */
      flex: 1;
      @media (min-width: 1025px) {
        margin-top: ${(props) => (props.isLogin ? '-5.5rem' : 'unset')};
      }

      &-line {
        margin: 2.4rem 0;
        width: 100%;
        height: 0.1rem;
        background-color: #d9d9d9;
      }

      &-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-left {
          /* width: 100%; */
          flex: 5;
          font-family: 'Noto Sans JP';
          font-style: normal;
          color: #303030;
        }

        &-right {
          margin-left: 3rem;
          width: 23.5rem;
        }
      }

      &-user-request {
        margin-top: 4.8rem;
        .pagination {
          margin-top: 2.4rem;
          text-align: center;
        }
      }

      &-registration {
        margin-top: 2.4rem;
        padding: 3.2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: 'Noto Sans JP';
        font-style: normal;

        p {
          font-weight: 400;
          font-size: 2rem;
          line-height: 2.9rem;
          text-align: center;
          color: #303030;
          width: 100%;
          max-width: 40.5rem;
          /* padding: 0 23.4rem; */
          margin-bottom: 0;
        }

        .new-registration {
          margin-top: 2.4rem;
          background-color: #d42a1c;
          width: 18.1rem;
          height: 4rem;
          span {
            color: #fff;
          }
        }
      }
    }
  }

  //@media (min-width: 768px) and (max-width: 1024px) {
  //  .book-request-container {
  //    &-left {
  //      width: 22rem;
  //    }
  //  }
  //}
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .book-request-container {
      &-right {
        &-header {
          flex-direction: column;

          &-right {
            margin-top: 3rem;
            width: calc(100% - 2rem);
          }
        }

        &-registration {
          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) and (min-height: 820px) and (orientation: landscape) {
    margin-top: 3rem;
  }
`;

export const StyledModal = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  * {
    font-family: 'Noto Sans JP';
  }
  @media (min-width: 641px) {
    padding: 0 10rem;
  }
  @media (max-width: 640px) {
    padding: 12px;
  }
  .container {
    margin-top: 2rem;

    p {
      margin-bottom: 0.8rem;
    }
  }

  .ic-img {
    .file-picker {
      margin: 0.5rem 0;
      width: fit-content;
      cursor: pointer;
    }
    img {
      max-width: 100% !important;
    }
  }

  .avatar {
    margin-top: 1.2rem;
    img {
      max-width: 100%;
    }
    // height: 24px;
  }

  .btn-create {
    margin-top: 2.4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .ic-img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .new-registration {
    background: ${(props) => props.theme.colors.primary};
    min-width: 15.4rem;
    width: 27.6rem;
    height: 4.4rem;
    border: none !important;
    border-radius: 0.4rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    span {
      display: inline-block;
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      transform: translateY(-0.5px);
    }
  }

  .container:nth-child(3) {
    .shared-input {
      .group-input {
        .textarea.ant-input {
          border-radius: 0.8rem !important;
        }
      }
    }
  }
`;

export const StyledUploadButton = styled.div`
  display: none;

  input {
    display: none;
  }
`;
