import { useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/css/navigation';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

import { themes } from '@theme';
import { CardItem, IconChevronLeft, IconChevronRight } from '@components';
import { CardLink, StyledCommon, StyledSwiper } from './style';
import { IBookDataItem } from '@interfaces';
import { BREAK_POINTS } from '@configs';
import { selectProduct, useAppSelector } from '@redux';

interface ICardItem {
  blockTitle?: string;
  title?: string;
  visibleNumber?: boolean;
  amount?: number;
  amountSlice?: number;
  haveRating?: boolean;
  data: IBookDataItem[];
  onChangeIndex?: (index: SwiperCore) => void;
  indexActive?: SwiperCore;
  enableBadge?: string | 'index';
  onNavigate?: () => void;
}

export const CardList = ({
  visibleNumber,
  amount,
  blockTitle,
  amountSlice,
  data,
  title,
  haveRating,
  onChangeIndex,
  enableBadge,
  onNavigate,
}: ICardItem) => {
  SwiperCore.use([Navigation]);
  const prevButton = useRef<HTMLDivElement>(null);
  const nextButton = useRef<HTMLDivElement>(null);
  const productSelector = useAppSelector(selectProduct);
  const { isLoading } = productSelector;
  const onBeforeInit = (Swiper: SwiperCore): void => {
    onChangeIndex && onChangeIndex(Swiper);
    if (typeof Swiper.params.navigation !== 'boolean') {
      const navigation = Swiper.params.navigation;
      if (navigation) {
        navigation.prevEl = prevButton.current;
        navigation.nextEl = nextButton.current;
        Swiper.navigation.update();
      }
    }
  };

  const genSwiperSlice = () => {
    return data.map((item, index) => (
      <SwiperSlide zoom key={index}>
        <div className="swiper-slide-item" key={item.id}>
          <CardItem
            isLoading={isLoading}
            badge={
              enableBadge
                ? enableBadge === 'index'
                  ? String(index + 1)
                  : item[enableBadge]
                : undefined
            }
            dataCard={item}
            visibleNumber={visibleNumber}
            amount={amount}
            haveRating={haveRating}
          />
        </div>
      </SwiperSlide>
    ));
  };
  return (
    <StyledCommon className="book-list">
      {!!blockTitle && (
        <div className="list-card-header font-noto">
          <h3 className="list-card-header-title">{blockTitle}</h3>
          <div className="list-card-header-line"></div>
          <CardLink onClick={onNavigate}>
            <span className="icon-title">もっと見る</span>
            <IconChevronRight width={16} height={16} color="#359f08" className="icon-right-color" />
          </CardLink>
        </div>
      )}
      {!!title && <h3 className="head-title">{title}</h3>}
      <div className="list-card-item">
        <StyledSwiper
          onBeforeInit={onBeforeInit}
          spaceBetween={16}
          slidesPerView={6}
          slidesPerGroup={6}
          navigation={{
            prevEl: prevButton.current,
            nextEl: nextButton.current,
          }}
          modules={[Navigation]}
          onActiveIndexChange={onChangeIndex}
          breakpoints={BREAK_POINTS}
        >
          {genSwiperSlice()}
        </StyledSwiper>
        <div className="prev-btn pagination-btn" ref={prevButton}>
          <IconChevronLeft width={25} height={25} color={themes.theme.light.colors.button.text} />
        </div>
        <div className="next-btn pagination-btn" ref={nextButton}>
          <IconChevronRight width={25} height={25} color={themes.theme.light.colors.button.text} />
        </div>
      </div>
    </StyledCommon>
  );
};
