import styled from 'styled-components';

export const StyleContainer = styled.div`
  width: 100%;
  display: flex;
  .book-item__left {
    display: block;
    margin-right: 2.4rem;
    width: 17.5rem;
    overflow: hidden;
    transition: 0.2s ease-out;
    position: relative;
    height: 24.8rem;
    @media (max-width: 768px) {
      height: 23rem;
    }
    img {
      &:first-child {
        width: 100%;
        height: 100%;
        /* object-fit: contain; */
      }
      &:not(:first-child) {
        position: absolute;
        right: 0.8rem;
        bottom: 0.8rem;
        width: 2.6rem;
        height: 2.6rem;
      }
    }
    &:hover {
      img {
        &:first-child {
          transform: scale(1.04);
          transition: transform 0.2s ease-out;
        }
      }
    }
  }
  .book-item__right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: 'Noto Sans JP';
    font-style: normal;
    width: calc(100% - 19.9rem);
    &-top {
      .book-item-title {
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.9rem;
        width: fit-content;
        margin: 0;
        cursor: pointer;
        transition: all 0.3s ease-out;
        &:hover {
          color: ${(p) => p.theme.colors.primary};
        }
        @media (min-width: 768px) {
          font-size: 2rem;
        }
      }
      .book-item-text {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #303030;
        margin-top: 0.8rem;
      }
      .book-item-color {
        color: #0bded2;
      }
      /* .book-item-author,
      .book-item-publisher {
      }
      .book-item-publisher {
        span {
          color: #0bded2;
        }
      } */
      .book-item-price {
        font-weight: 700;
        color: ${(p) => p.theme.colors.primary};
      }

      .book-item-rate-date {
        display: flex;
        align-items: center;
        .list-star {
          display: flex;
          align-items: center;
        }
        .book-item-date {
          margin-left: 1.6rem;
          color: #6c757c;
        }
      }
      .book-item-preview-title {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2.3rem;
        color: #000000;
        width: fit-content;
      }
      .book-item-preview-content {
        width: fit-content;
      }
    }
    &-bottom {
      display: flex;
      .book-item-btn-wish-list {
        .prev-icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .text-btn {
          font-size: 1.2rem;
          @media (min-width: 768px) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  @media (max-width: 376px) {
    .book-item-rate-date {
      flex-direction: column;
      align-items: flex-start !important;
      .book-item-date {
        margin: 8px 0 0 0 !important;
      }
    }
  }
`;
