import axiosClient from './axiosClient';

const prefixUrl = 'api/Common';

export const commonApi = {
  getCategorySelect: () => {
    return axiosClient.get(`${prefixUrl}/GetAvailableCategories`);
  },
  getAuthorSelect: () => {
    return axiosClient.get(`${prefixUrl}/GetAvailableVendors`);
  },
};
