import { createAsyncThunk } from '@reduxjs/toolkit';
import { productApi } from '@api';
import { BaseResponseProps } from '@interfaces';

const prefix = 'book';
interface BookParam {
  [x: string]: any;
}
export const fetchDetailBook = createAsyncThunk<any, string>(
  `${prefix}/detail-book`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await productApi.getDetailBooks(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchSimilarBook = createAsyncThunk<any, string>(
  `${prefix}/similar-book`,
  async (params, { rejectWithValue }) => {
    try {
      const res = await productApi.getSimilarBooks(params);
      return res as unknown as BaseResponseProps;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchMyBooksList = createAsyncThunk<any, string>(
  `${prefix}/my-books`,
  async (params, { rejectWithValue }) => {
    try {
      return await productApi.getMyBooksList(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const fetchMyBooksListWithParams = createAsyncThunk<any, string>(
  `${prefix}/my-books-with-pagination`,
  async (params, { rejectWithValue }) => {
    try {
      return await productApi.getMyBooksListWithPagination(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
export const fetchPurchaseHistory = createAsyncThunk(
  `${prefix}/purchase-history`,
  async (params: BookParam, { rejectWithValue }) => {
    try {
      return await productApi.getPurchaseHistory(params);
    } catch (e) {
      rejectWithValue(e);
    }
  },
);
