import { IMAGES } from '@assets';
import React from 'react';
import styled from 'styled-components';
import { selectAuth, useAppSelector } from '@redux';
import { useNavigate } from 'react-router-dom';
import { PATH_PARENT_CATEGORY } from '@configs';

export const Banner = () => {
  const authSlice = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const navigateByBanner = () => {
    navigate(PATH_PARENT_CATEGORY);
    // if (authSlice.accessToken) {
    //   if (authSlice?.userInfo?.hasSubcription) {
    //     navigate(PATH_SUBSCRIBER_RANK);
    //   } else {
    //     navigate(PATH_SUBSCRIPTION);
    //   }
    // } else {
    //   navigate(PATH_PARENT_CATEGORY);
    // }
  };
  return (
    <StyledContainer className="sub-banner" onClick={navigateByBanner}>
      <img src={IMAGES.homeBannerBottom} alt="not" onClick={navigateByBanner} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin: 5.6rem 0 0;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
    padding: 0 1.6rem;
    margin-top: 3rem;
    margin-bottom: 3.2rem;
  }
  @media (max-width: 640px) {
    height: 57px;
  }
`;
