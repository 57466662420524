import { ICon } from '@interfaces';

export const IconChevronDown = (props: ICon) => {
  const {
    onClick,
    width = 24,
    height = 24,
    className,
    color = '#000',
    strokeWidth = 2,
    viewBox = '0 0 24 24',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      role="img"
      onClick={onClick}
      className={className}
    >
      <polyline points="6 9 12 15 18 9" />
    </svg>
  );
};
