import { IMAGES } from '@assets';
import {
  DATA_COMPANY_PROFILE_1,
  DATA_COMPANY_PROFILE_2,
  DATA_COMPANY_PROFILE_3,
  DATA_COMPANY_PROFILE_4,
} from '@configs';
import { useMediaQuery } from '@utils';
import {
  CompanyProfileStyled,
  PageTitle,
  ListBook,
  ListInformation,
  PageImage,
  AboutUs,
} from './style';

export const CompanyProfileModule = () => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <CompanyProfileStyled>
      <div className="title-text">会社概要</div>
      {isMobile && <div className="header-customer-line" />}
      <PageTitle>会社概要</PageTitle>
      <div className="name-text">時代を半歩先取り</div>
      <div className="detail-text">
        弊社は人文系の学術専門書を発行する出版社として出発しましたが、最近ではそれ以外に「福祉」「環境」「バリ
        アフリー」「東洋医学」「知的財産権」などをキーワードとして総合的な出版活動を展開しております。混迷の時代と言われている現代に「時代を半歩先取りす
        る出版」を目指しております。
      </div>
      <AboutUs>
        {DATA_COMPANY_PROFILE_1.map((item) => (
          <div className="about-information">
            <div className="name">{item.name}</div>
            <div className="des">{item.des}</div>
            <div className="tel">{item.tel}</div>
            <div className="fax">{item.fax}</div>
          </div>
        ))}
      </AboutUs>
      <PageImage>
        <img src={IMAGES.map} alt="" />
      </PageImage>
      <AboutUs>
        {DATA_COMPANY_PROFILE_2.map((item) => (
          <div className="about-information">
            <div className="name">{item.name}</div>
            <div className="des">{item.des}</div>
          </div>
        ))}
      </AboutUs>
      <div className="title-list-book">沿革</div>
      <ListBook>
        {DATA_COMPANY_PROFILE_3.map((item) => (
          <div className="list-book">
            <img src={item.img} alt="" />
            <div className="des">{item.des}</div>
            <div className="time">{item.time}</div>
          </div>
        ))}
      </ListBook>
      <div className="page-information">主要書籍</div>
      <ListInformation>
        {DATA_COMPANY_PROFILE_4.map((item) => (
          <ul>
            <li>{item}</li>
          </ul>
        ))}
      </ListInformation>
    </CompanyProfileStyled>
  );
};
