import { ChangeProfile } from '@components';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, selectCustomer, uploadAvatar, useAppSelector } from '@redux';

export const ChangeProfileModule = () => {
  const uploadBtnRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState<any>();
  const [previewUrl, setPreviewUrl] = useState<string>();
  const {
    data: { changeAvatar },
    isLoading,
  } = useAppSelector(selectCustomer);

  useEffect(() => {
    if (!selectedImage) {
      setPreviewUrl(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedImage);
    setPreviewUrl(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedImage]);

  /*LogApp('IMAGE_UPLOAD', selectedImage);
  LogApp('IMAGE URL', previewUrl);*/

  const handleUpload = () => {
    uploadBtnRef && uploadBtnRef?.current?.click();
  };

  const handleChangeImage = (e: ChangeEvent<any>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedImage(undefined);
      return;
    }
    setSelectedImage(e.target.files[0]);
  };

  const updateAvatar = () => {
    const formData = new FormData();
    if (selectedImage) {
      formData.append('uploadedFile', selectedImage, selectedImage.name);
      dispatch(uploadAvatar(formData));
    }
  };

  return (
    <ChangeProfile
      onSubmit={updateAvatar}
      onUpload={handleUpload}
      uploadBtnRef={uploadBtnRef}
      onChangeImage={handleChangeImage}
      previewUrl={previewUrl}
    />
  );
};
