import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { LoginAppleIcon, SharedButton } from '@components';
import { onAuthStateChanged, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { appleProvider, auth } from '@service';
import { LogApp, getFromLS } from '@utils';
import { setAccessToken, setLoading, setLoadingCustomer, setRefreshToken, setUserInfo, useAppDispatch } from '@redux';
import { authAPI } from '@api';
import { PATH_HOME } from '@configs';
import { toast } from 'react-toastify';

export const AppleLoginModule = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignInWithApple = async () => {
    const result:any = await signInWithPopup(auth, appleProvider);
    const credential = OAuthProvider.credentialFromResult(result);
    LogApp({ result });
    LogApp({ credential });
    LogApp('user-apple', result?.user);
    const code = await result?.user?.getIdToken();
    const body = {
      // code: code || credential?.accessToken,
      // code: result?.user?.accessToken,
      // firebaseToken: credential?.idToken,
      // deviceId: result?.user?.uid,
      IdToken: credential?.idToken,
      AccessToken: credential?.accessToken,
    };
    try {
      dispatch(setLoading(true));
      const res: any = await authAPI.loginWithApple(body);
      LogApp('login apple res', res);
      if (res?.success) {
        // dispatch(setUserInfoLine(res));
        dispatch(setUserInfo(res?.data.customer));
        dispatch(setAccessToken(res?.data.accessToken.tokenString));
        dispatch(setRefreshToken(res?.data.refreshToken.tokenString));
        const memoPath = getFromLS('memoPath');
        if (memoPath) {
          navigate(memoPath);
          localStorage.removeItem('memoPath');
          dispatch(setLoading(false));
        } else {
          navigate(PATH_HOME);
          dispatch(setLoading(false));
        }
      }
    } catch (err: any) {
      toast.error('エラー！エラーが発生しました。後でもう一度試してください');
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <StyledAppleBtn
      text="Appleでサインイン"
      prevIcon={<LoginAppleIcon />}
      textColor="#3467A6"
      borderColor="#3467A6"
      btnStyle="social"
      textClassName="text-hover-color"
      onClick={handleSignInWithApple}
    />
  );
};

const StyledAppleBtn = styled((props) => <SharedButton {...props} />)<{
  color?: string;
}>`
  width: 76%;
  border-radius: 0.8rem;

  .text-hover-color {
    &:hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`;
