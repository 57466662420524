import { ChangeEvent, Dispatch, memo, SetStateAction, useState } from 'react';
import { FormikProps } from 'formik';

import { SharedButton, SharedCheckbox, ShareInput } from '@components';
import { StyledRegister } from './registerStyle';
import { IRegister } from '@interfaces';
import styled from 'styled-components';

interface IProps {
  formik: FormikProps<IRegister>;
  onSubmit?: () => void;
  backToHome?: () => void;
  redirectToSignIn?: () => void;
  onShowPolicyDetail?: () => void;
}

export const RegisterComponent = memo((props: IProps) => {
  const { formik, onSubmit, backToHome, redirectToSignIn, onShowPolicyDetail } = props;
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();

  const handleInputChange = (
    e: ChangeEvent,
    setStateFunc: Dispatch<SetStateAction<string | undefined>>,
  ) => {
    formik.handleChange(e);
    if ((e.target as HTMLInputElement).value.length > 60) {
      setStateFunc('名前は最大60文字までとなります。もう一度やり直してください。');
    } else {
      setStateFunc(undefined);
    }
  };

  return (
    <StyledRegister>
      <div className="heading">
        <h1 className="heading_title">アカウント新規作成</h1>
        <span onClick={backToHome} className="back-home redirect-btn">
          ホームに戻る
        </span>
      </div>
      <p className="desc">
        すでにアカウントをお持ちですか?
        <span onClick={redirectToSignIn} className="sub-action redirect-btn">
          サインイン
        </span>
      </p>
      <form onSubmit={formik.handleSubmit} className="register-form">
        <div className="register-wrap">
          <ShareInput
            label="名"
            placeholder="名を入力"
            name="firstName"
            onChange={(e: ChangeEvent) => handleInputChange(e, setFirstNameError)}
            value={formik.values.firstName}
            errors={formik.errors.firstName}
            customErrorMessage={firstNameError}
            touched={formik.touched.firstName}
            className="input"
            required
          />
          <ShareInput
            label="姓"
            placeholder="名を入力"
            name="lastName"
            onChange={(e: ChangeEvent) => handleInputChange(e, setLastNameError)}
            value={formik.values.lastName}
            errors={formik.errors.lastName}
            customErrorMessage={lastNameError}
            touched={formik.touched.lastName}
            className="input"
            required
          />
          <ShareInput
            label="メールアドレス"
            placeholder="メールアドレスを入力"
            name="email"
            onChange={(e: ChangeEvent) => handleInputChange(e, setEmailError)}
            value={formik.values.email}
            errors={formik.errors.email}
            customErrorMessage={emailError}
            touched={formik.touched.email}
            className="input"
            type="email"
            required
          />
          <ShareInput
            label="パスワード"
            placeholder="パスワードを入力"
            type="password"
            name="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            errors={formik.errors.password}
            touched={formik.touched.password}
            className="input"
            required
          />
          <ShareInput
            label="パスワードの確認"
            placeholder="パスワードの確認を入力"
            name="confirmPassword"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            errors={formik.errors.confirmPassword}
            touched={formik.touched.confirmPassword}
            className="input"
            required
          />
          <StyledTermBox>
            <SharedCheckbox
              className="register-cb"
              size="2.4rem"
              id="agreePrivacy"
              name="agreePrivacy"
              type="checkbox"
              error={formik.errors.agreePolicy}
              touched={formik.touched.agreePolicy}
              text={
                <div className="policy">
                  <span className="policy-desc">
                    サービス利用規約及び個人情報保護方針に同意します{' '}
                  </span>
                </div>
              }
              onChange={() => formik.setFieldValue('agreePolicy', !formik.values.agreePolicy)}
              checked={formik.values.agreePolicy}
            />
            <span onClick={onShowPolicyDetail} className="term-detail">
              (詳細)
            </span>
          </StyledTermBox>
          <SharedButton
            typeHtml="submit"
            text="サインイン"
            className="submit_btn"
            btnStyle="rounded"
            bgColor="#D42A1C"
          />
        </div>
      </form>
    </StyledRegister>
  );
});

const StyledTermBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;
  .register-cb {
    margin-bottom: 0 !important;
  }

  .term-detail {
    cursor: pointer;
    margin-left: 1.6rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 17px;
    color: #0dbed2;
  }
`;
