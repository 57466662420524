import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface StepState {
  value: number;
}

const initialState: StepState = {
  value: 0,
};

const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    increment: (state) => {
      if (state.value < 3) state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    resetStep: (state) => {
      state.value = 0;
    },
    jumpTo: (state, { payload }: PayloadAction<1 | 2 | 0>) => {
      state.value = payload;
    },
  },
});
export const { increment, decrement, resetStep, jumpTo } = stepSlice.actions;
export default stepSlice.reducer;
