import styled, { css } from 'styled-components';

export const StyledSideBar = styled.div<{
  showSidebar?: boolean;
  isMobile?: boolean;
}>`
  background-color: ${(props) => props.theme.colors.bgFooter};
  border-radius: 1.25rem;
  padding: 2.9rem 2.4rem;
  width: 100%;
  padding-bottom: 9px;
  margin-bottom: 8rem;

  .heading-text {
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 23px;
    color: #303030;
    text-align: left;
    margin-bottom: 2rem;
  }

  @media (max-width: 992px) {
    position: fixed !important;
    left: -30rem;
    top: 0;
    width: 300px !important;
    z-index: 10000 !important;
    background-color: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 40%);
    transition: 0.3s;
    border-radius: 0;
    height: 100vh;
    ${(p) =>
      p.showSidebar &&
      css`
        left: 0rem;
        overflow: scroll;
      `}
  }

  @media (max-width: 640px) {
    position: fixed !important;
    left: -100vw;
    top: 0;
    width: 100vw !important;
    z-index: 10000 !important;
    background-color: #fff;
    box-shadow: 0 0 6px rgb(0 0 0 / 40%);
    transition: 0.3s;
    border-radius: 0;
    height: 100vh;
    padding: 2.4rem 1.6rem 8rem;
    .heading-text {
      font-size: 1.8rem;
    }
    .header {
      /* justify-content: center; */
      align-items: center;
      padding: 0.8rem 1.6rem;
      width: 100vw;
      height: 4.8rem;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      position: absolute;
      top: 0;
      left: 0;
      .header-text {
        height: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.3rem;
        color: #303030;
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
      }
      .close-icon {
        position: absolute;
        top: 0;
        height: 4.8rem;
        right: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .heading-text {
      margin-top: 4.8rem;
    }
    .bottom-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 0.8rem;
      .btn {
        width: calc((100% - 0.8rem) / 2);
      }
    }
    ${(p) =>
      p.showSidebar &&
      css`
        left: 0rem;
        overflow: scroll;
      `}
  }
`;
