import { Input, InputRef } from 'antd';
import React, { CSSProperties, forwardRef } from 'react';

import { IHandleBlur, IHandleChange } from '@interfaces';
import { StyleInputContainer } from './style';
import { Search } from '@components';

interface IInputProps {
  inputType?: 'default' | 'primary' | 'search';
  sizeSearch?: 'small';
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  name?: string;
  label?: string;
  errors?: string;
  className?: string;
  placeholder?: string;
  placeholderColor?: string;
  containerClassName?: string;
  value?: string | number;
  defaultValue?: string | number;
  touched?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  autoComplete?: 'off' | 'on';
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  containerRef?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement>;
  onClick?: () => void;
  onSearch?: () => void;
  onBlur?: IHandleBlur;
  onChange: IHandleChange | any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>;
  customErrorMessage?: string;
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
  pattern?: string;
  max?: number;
  min?: number;
}
export const ShareInput = forwardRef((props: IInputProps, ref?: React.Ref<InputRef>) => {
  const {
    inputType,
    sizeSearch,
    type,
    name,
    label,
    errors,
    className,
    placeholder,
    placeholderColor,
    containerClassName,
    value,
    defaultValue,
    touched,
    disabled,
    readOnly,
    required,
    autoFocus,
    autoComplete,
    style,
    inputStyle,
    containerRef,
    onClick,
    onSearch,
    onBlur,
    onChange,
    onKeyDown,
    onPressEnter,
    customErrorMessage,
    inputMode,
    pattern,
    min,
    max,
  } = props;
  const genInput = () => (
    <>
      <Input
        ref={ref}
        id={name}
        name={name}
        className={className}
        value={value}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={defaultValue}
        style={{ ...inputStyle }}
        autoFocus={autoFocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onPressEnter={onPressEnter}
        inputMode={inputMode}
        pattern={pattern}
        min={min}
        max={max}
      />
      {!!errors && touched && <p className="input-text-error">{errors}</p>}
      {!!customErrorMessage && <p className="input-text-error">{customErrorMessage}</p>}
    </>
  );
  return (
    <StyleInputContainer
      className={containerClassName ? `${containerClassName} shared-input` : 'shared-input'}
      style={style}
      ref={containerRef}
      placeholderColor={placeholderColor}
      inputType={inputType}
      sizeSearch={sizeSearch}
    >
      {label && (
        <label className="input__label" htmlFor={name || label}>
          {label}
          {required && <span className="required">*</span>}:
        </label>
      )}
      <div className="group-input">
        {genInput()}
        {inputType === 'search' && (
          <div onClick={onSearch} className="input-icon-search">
            {sizeSearch === 'small' ? (
              <Search width={20} height={20} color="#6C757D" />
            ) : (
              <Search width={24} height={24} color="#fff" />
            )}
          </div>
        )}
      </div>
    </StyleInputContainer>
  );
});
