export const IMAGES = {
  imageCategoryItem: require('./images/category-item.png'),
  imageBanner: require('./images/home-banner-top.png'),
  imageTest: require('./images/product-item.png'),
  Speaker: require('./images/speaker.png'),
  ImgAbout: require('./images/about.png'),
  banner: require('./images/banner/banner-1.jpg'),
  homeBanner: require('./images/banner/home-banner.jpeg'),
  homeBannerBottom: require('./images/banner/sub-banner.png'),
  headerLogo: require('./images/thumbs/logo.png'),
  backgroundLogin: require('./images/banner/bg-forgot-password.jpg'),
  cardVisa: require('./images/card-visa.png'),
  cardMaster: require('./images/card-master.png'),
  cardAmerica: require('./images/card-america.png'),
  cardJCB: require('./images/card-jcb.png'),
  cardDinersClub: require('./images/card-dinersClub.png'),
  appStore: require('./images/app-store.png'),
  googlePlay: require('./images/google-play.png'),
  aboutBottom: require('./images/about-bottom.png'),
  subscribeRankBanner: require('./images/subscribe-rank-banner.png'),
  cart: require('./images/cart.png'),
  bookCover: require('./images/book-cover.png'),
  health: require('./images/health.png'),
  hobbies: require('./images/hobbies.png'),
  humanSocial: require('./images/human_social.png'),
  science: require('./images/science.png'),
  pratical: require('./images/pratical.png'),
  politics: require('./images/politics.png'),
  architecture: require('./images/architecture.png'),
  scale: require('./images/scale.png'),
  bookshelf: require('./images/bookshelf.png'),
  map: require('./images/company-profile/map.png'),
  imageExample: require('./images/imageExample.png'),
  bgBookshelves: require('./images/bgBookshelves.png'),
  bookshelvesBar: require('./images/bookshelvesBar.png'),
  bookshelvesHeadBar: require('./images/bookshelves-head-bar.png'),
};

export const ICONS = {
  selectDownIcon: require('./icons/select-down.png'),
  tickIcon: require('./icons/Frame 319.png'),
  teaLeafIcon: require('./icons/tea-leaf 1.png'),
  teaLeafIcon2: require('./icons/tea-leaf 1 (1).png'),
  teaLeafIcon3: require('./icons/tea-leaf 1 (2).png'),
  rightArrow: require('./icons/Vector.png'),
  visaIcon: require('./icons/card-1.png'),
  masterCardIcon: require('./icons/card-2.png'),
  dinerClubIcon: require('./icons/card-3.png'),
  jcbCardIcon: require('./icons/card-4.png'),
  emptyBooks: require('./icons/ic-book-empty.png'),
  defaultAvatar: require('./icons/default-avatar.jpg'),
  CheckoutSuccessIcon: require('./images/ic-checkout-success.png'),
};
