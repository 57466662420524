import styled from 'styled-components';
import { theme } from 'antd';

export const StyleContainer = styled.div`
  width: 100%;

  .header-customer-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Noto Sans JP';
    font-style: normal;

    /* @start không đc xóa, sủa, nghi đè hoặc thêm vào dòng @media này */
    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      .header-input__wrapper {
        width: 100%;
      }
    }
    /* @end không đc xóa, sủa, nghi đè hoặc thêm vào dòng @media này */
  }

  h5 {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3.5rem;
    color: #303030;

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      font-size: 2rem;
    }
  }

  .header-customer-content {
    margin-top: 0.4rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #303030;
  }

  //.header-customer-search {
  //  width: 100%;
  //  margin-left: 3rem;
  //}

  .header-input__wrapper {
    width: 30%;

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      width: 100%;
      margin-top: 16px;
    }

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
      .header-customer-content {
        margin: 0 !important;
        height: 1.6rem !important;
      }

      .header-input__wrapper {
        width: 100%;
      }
    }

    @media (min-width: 1025px) and (max-height: 768px) and (orientation: landscape) {
      margin-top: 3rem;

      h5 {
        font-size: 2rem;
      }

      .header-customer-content {
        font-size: 1.4rem;
        margin-top: 0.8rem;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 3rem;

      h5 {
        font-size: 2rem;
      }

      .header-customer-content {
        font-size: 1.4rem;
        margin-top: 0.8rem;
      }

      .header-input__wrapper {
        margin-top: 1.4rem;
        width: 100%;
      }
    }

    @media (min-width: 375px) and (max-width: 640px) and (orientation: portrait) {
      .header-customer-content {
        font-size: ${(props) => props.theme.size.xs};
      }
    }
  }
  .header-customer-line {
    margin: 2.4rem 0;
    width: 100%;
    height: 0.1rem;
    background-color: #d9d9d9;
  }

  @media (max-width: 1200px) and (min-height: 820px) and (orientation: landscape) {
    margin-top: 3rem;
  }

  @media (min-width: 376px) and (max-width: 640px) and (orientation: portrait) {
    //margin-top: 2.4rem;
  }
`;
