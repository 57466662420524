import { useSelector } from 'react-redux';
import { RootState } from '@redux';

import { enumLeftSidebar } from '@configs';
import { IInnerAppProps } from '@interfaces';
import { BreadcrumbsModule, SideBarModule, UserSideBarModule } from '@modules';
import { AppBreadCrumbsWrapper, StyledCol, StyledInnerPage, StyledRow } from './innerAppStyle';
import { useMediaQuery } from '@utils';

interface IProps extends IInnerAppProps {
  data?: any;
}

export const InnerApp = (props: IProps) => {
  const { children, leftSidebar, breadcrumbs } = props;
  const isMobile = useMediaQuery('(max-width: 640px)');
  const gutter = isMobile ? [0, 0] : [40, 0];

  const { auth } = useSelector((state: RootState) => state);
  const { accessToken } = auth;
  const isLogin = accessToken;

  const genLeftCol = () => {
    if (leftSidebar === enumLeftSidebar.CATEGORY)
      return (
        <StyledCol className="app-left_sidebar" sm={7} xl={8}>
          <SideBarModule />
        </StyledCol>
      );
    if (leftSidebar === enumLeftSidebar.USER_MENU && isLogin)
      return (
        <StyledCol className="app-left_col" span={5}>
          <UserSideBarModule />
        </StyledCol>
      );
  };
  return (
    <StyledInnerPage className="app-container cart-main" leftSidebar={leftSidebar}>
      <AppBreadCrumbsWrapper>{breadcrumbs && <BreadcrumbsModule />}</AppBreadCrumbsWrapper>
      <StyledRow gutter={gutter}>
        {genLeftCol()}
        <StyledCol flex="auto">{children}</StyledCol>
      </StyledRow>
    </StyledInnerPage>
  );
};
