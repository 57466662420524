import { SharedButton, SkeletonCheckout } from '@components';
import {
  ButtonCheckout,
  ItemCheckout,
  StyledCheckout,
  StyledCheckoutText,
  TotalCheckout,
} from './style';
import { LOCAL_STORAGE_KEY, STYLE_BTN_CONTINUE } from '@configs';
import { currency, getFromLS, useMediaQuery } from '@utils';
import { decrement, increment, selectCart, useAppSelector } from '@redux';
import { useDispatch } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import { BookInCartProps } from '@interfaces';
interface CartListProps {
  data: BookInCartProps[];
}
export const Checkout: FC<CartListProps> = ({ data }) => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const dispatch = useDispatch();
  const { isLoading } = useAppSelector(selectCart);
  const StyleBtnBack = (isMobile: boolean) => ({
    width: isMobile ? '71px' : '175px',
    height: '41px',
    borderRadius: '4px',
    lineHeight: '17px',
    fontWeight: '500',
    borderColor: '#B6B6B6',
    textColor: '#6C757D',
  });

  return (
    <StyledCheckout>
      <div className="message">注文内容を確認します。</div>
      <ItemCheckout>
        {!isMobile && (
          <div className="book-price">
            <StyledCheckoutText>単価</StyledCheckoutText>
          </div>
        )}
      </ItemCheckout>
      {isLoading ? (
        <SkeletonCheckout />
      ) : (
        <>
          {data.map((value, index) => (
            <ItemCheckout key={index}>
              <img src={value.pictureMobileModel.imageUrl} alt="" />
              <div className="des-item ">
                <StyledCheckoutText
                  fontSize={isMobile ? '14px' : '20px'}
                  className="book-item-text book-item-title"
                >
                  {value.bookName}
                </StyledCheckoutText>
                <p className="book-item-text book-item-author">{value.authorName}</p>
                {/* <p className="book-item-text book-item-publisher">
                  出版社 : <span className="book-item-color">{value.}</span>
                </p>*/}
                {isMobile && (
                  <>
                    <p className="book-item-price">{currency(value.price)}</p>
                  </>
                )}
              </div>
              {!isMobile && (
                <div className="book-price">
                  <StyledCheckoutText
                    fontSize={isMobile ? '14px' : '16px'}
                    className="book-item-price"
                  >
                    {currency(value.price)}{' '}
                  </StyledCheckoutText>
                </div>
              )}
            </ItemCheckout>
          ))}
        </>
      )}
      <TotalCheckout>
        <div className="row">
          <StyledCheckoutText
            fontSize={isMobile ? '14px' : '20px'}
            fontWeight={isMobile ? '400' : '700'}
            className="margin-text"
          >
            合計:
          </StyledCheckoutText>
          <StyledCheckoutText
            fontSize={isMobile ? '14px' : '20px'}
            fontWeight={isMobile ? '400' : '700'}
            className="margin-text"
          >
            書籍数:
          </StyledCheckoutText>
        </div>
        <div className="row text-right">
          <StyledCheckoutText fontSize={isMobile ? '14px' : '20px'} className="total margin-text">
            {currency(data.reduce((acc, cur) => acc + cur.price, 0))}
          </StyledCheckoutText>
          <StyledCheckoutText fontSize={isMobile ? '14px' : '20px'} className="amount margin-text">
            {data.length} 冊
          </StyledCheckoutText>
        </div>
      </TotalCheckout>
      <ButtonCheckout>
        <SharedButton
          text="戻る"
          style={StyleBtnBack(isMobile)}
          borderColor="#B6B6B6"
          textColor=" #6C757D"
          onClick={() => dispatch(decrement())}
        ></SharedButton>
        <SharedButton
          text="次に進む"
          style={STYLE_BTN_CONTINUE}
          textColor="#FFF"
          onClick={() => dispatch(increment())}
        ></SharedButton>
      </ButtonCheckout>
    </StyledCheckout>
  );
};
